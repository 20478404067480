import { AxiosResponse } from 'axios';

import { dispatch as storeDispatch } from '@store/index';
import {
  actions,
  CalculateTransactionParamsRequestPayload,
  HexUpdateRequestPayload,
  ReCalculateTransactionParamsRequestPayload,
} from '@store/transaction';

import { FeaturesService } from '@services/features';

import { Feature } from '@enums';
import {
  ApiError,
  AsyncDispatch,
  TransactionHistory,
  TransactionParams,
} from '@types';

import { ApiService } from '../api';

/**
 * Transaction service.
 *
 * @author Ihar Kazlouski
 * @class TransactionServiceClass
 * @category Services
 */
class TransactionServiceClass {
  /**
   * Calculate transaction params api.
   *
   * @author Ihar Kazlouski
   * @param {CalculateTransactionParamsRequestPayload} payload request payload.
   * @return {Promise<ApiError | AxiosResponse<TransactionParams>>} calculate transaction promise.
   */
  async apiCalculateTransactionParamsPost (
    payload: CalculateTransactionParamsRequestPayload,
  ): Promise<ApiError | AxiosResponse<TransactionParams>> {
    return ApiService.apiPost(
      '/btc/transaction/parameters',
      { ...payload },
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Calculate transaction.
   *
   * @author Ihar Kazlouski
   * @param {CalculateTransactionParamsRequestPayload} payload request payload.
   * @return {Promise<ApiError | AxiosResponse<TransactionParams>>} calculate transaction promise.
   */
  async calculateTransaction (
    payload: CalculateTransactionParamsRequestPayload,
  ): Promise<ApiError | TransactionParams> {
    const dispatch: AsyncDispatch<TransactionParams | ApiError> = storeDispatch;

    return dispatch(actions.calculateTransactionRequest({ ...payload }));
  }

  /**
   * Cancel transaction api.
   *
   * @author Ihar Kazlouski
   * @param {string} transactionId transaction guid .
   * @return {Promise<ApiError | AxiosResponse<TransactionParams>>} cancel transaction promise.
   */
  async apiCancelTransactionPut (
    transactionId: string,
  ): Promise<ApiError | AxiosResponse<Record<string, unknown>>> {
    return ApiService.apiPut(
      '/btc/transaction/cancel',
      { transactionId },
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Cancel transaction.
   *
   * @author Ihar Kazlouski
   * @param {transactionId} transactionId request payload.
   * @return {Promise<ApiError | AxiosResponse<TransactionParams>>} cancel transaction promise.
   */
  async cancelTransaction (
    transactionId: string,
  ): Promise<ApiError | Record<string, unknown>> {
    const dispatch: AsyncDispatch<Record<string, unknown> | ApiError> =
      storeDispatch;

    return dispatch(actions.cancelTransactionRequest({ transactionId }));
  }

  /**
   * Recalculate transaction params api.
   *
   * @author Ihar Kazlouski
   * @param {ReCalculateTransactionParamsRequestPayload} payload request payload.
   * @return {Promise<ApiError | AxiosResponse<TransactionParams>>} recalculate transaction promise.
   */
  async apiReCalculateTransactionParamsPost (
    payload: ReCalculateTransactionParamsRequestPayload,
  ): Promise<ApiError | AxiosResponse<TransactionParams>> {
    return ApiService.apiPost(
      '/btc/transaction/recalculate-parameters',
      { ...payload },
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Recalculate transaction.
   *
   * @author Ihar Kazlouski
   * @param {CalculateTransactionParamsRequestPayload} payload request payload.
   * @return {Promise<ApiError | AxiosResponse<TransactionParams>>} recalculate transaction promise.
   */
  async reCalculateTransaction (
    payload: ReCalculateTransactionParamsRequestPayload,
  ): Promise<ApiError | TransactionParams> {
    const dispatch: AsyncDispatch<TransactionParams | ApiError> = storeDispatch;

    return dispatch(actions.reCalculateTransactionRequest({ ...payload }));
  }

  /**
   * Update transaction hex api.
   *
   * @author Ihar Kazlouski
   * @param {HexUpdateRequestPayload} payload request payload.
   * @return {Promise<ApiError | AxiosResponse<Record<string, unknown>>>} update transaction hex promise.
   */
  async apiUpdateHex (
    payload: HexUpdateRequestPayload,
  ): Promise<ApiError | AxiosResponse<Record<string, unknown>>> {
    return ApiService.apiPut(
      '/btc/transaction/update-hex',
      { ...payload },
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Update transaction hex.
   *
   * @author Ihar Kazlouski
   * @param {HexUpdateRequestPayload} payload request payload.
   * @return {Promise<ApiError | AxiosResponse<Record<string, unknown>>>} update transaction hex promise.
   */
  async updateHex (
    payload: HexUpdateRequestPayload,
  ): Promise<ApiError | Record<string, unknown>> {
    const dispatch: AsyncDispatch<Record<string, unknown> | ApiError> =
      storeDispatch;

    return dispatch(actions.hexUpdateRequest({ ...payload }));
  }

  /**
   * Get transactions history.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<History>>} transactions history promise.
   */
  async apiTransactionsHistoryGet (): Promise<
  ApiError | AxiosResponse<TransactionHistory[]>
  > {
    return ApiService.apiGet(
      '/btc/transaction/history',
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }
}

const TransactionService = new TransactionServiceClass();

export { TransactionService };
