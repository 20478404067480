import { i18n } from '@localization';

export const getTransactionsHistoryType = (): Record<string, string> => ({
  BtcToQcExchange:     i18n.t('pages.history.btcToQcExchange'),
  QcWithdraw:          i18n.t('pages.history.qcWithdraw'),
  QcTransfer:          i18n.t('pages.history.qcTransfer'),
  QcTransferRecipient: i18n.t('pages.history.qcTransfer'),
});

export const getTransactionsHistoryStatus = (): Record<string, string> => ({
  InProgress:                 i18n.t('pages.history.inProgress'),
  Completed:                  i18n.t('pages.history.completed'),
  ErrorWritingInQuantumChain: i18n.t('pages.history.errorWritingInQuantumChain'),
  RejectedByBitcoinNetwork:   i18n.t('pages.history.rejectedByBitcoinNetwork'),
  StuckInBitcoinNetwork:      i18n.t('pages.history.stuckInBitcoinNetwork'),
});
