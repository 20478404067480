export const INIT_LOADER_SHOWING = 'INIT_LOADER_SHOWING';
export const LOADER_SHOW = 'LOADER_SHOW';
export const INIT_LOADER_HIDING = 'INIT_LOADER_HIDING';
export const HIDE_LOADER = 'HIDE_LOADER';
export const INIT_REQUEST_COUNTER_INCREASING =
  'INIT_REQUEST_COUNTER_INCREASING';
export const REQUEST_COUNTER_INCREASE = 'REQUEST_COUNTER_INCREASE';
export const INIT_REQUEST_COUNTER_DECREASING =
  'INIT_REQUEST_COUNTER_DECREASING';
export const REQUEST_COUNTER_DECREASE = 'REQUEST_COUNTER_DECREASE';
