import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { db } from '@db';

import { Box, Button, Typography } from '@mui/material';

import { getModalSelector } from '@store/modals';

import { GridsService, ModalsService, WalletService } from '@services';
import { useDeviceSize } from '@hooks';

import { Grids, Modals } from '@enums';
import { BitcoinWallets } from '@types';

import { Modal } from '@elements';

/**
 * Delete wallet modal component.
 *
 * @author Ihar Kazlouski
 * @function DeleteWalletModal
 * @category Modals
 * @return {FC} delete wallet modal component.
 */
const DeleteWalletModal: FC = () => {
  const deviceSize = useDeviceSize();
  const modal = useSelector(getModalSelector(Modals.DeleteWallet));
  const { t } = useTranslation();

  /**
   * Api requests for api delete.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const apiRequests = async (transformResponse: (request: BitcoinWallets[]) => BitcoinWallets[]): Promise<void> => {
    await WalletService.deleteWallet((modal?.data as { id: string }).id);

    await GridsService.update<BitcoinWallets[], BitcoinWallets[]>(
      Grids.BitcoinHoldings,
      WalletService.apiWalletsGet,
      transformResponse,
    );
  };

  /**
   * Delete wallet acceptation.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const deleteWallet = (): void => {
    const transformResponse = (request: BitcoinWallets[]): BitcoinWallets[] => {
      return request;
    };

    void db.wallets.where('name').equals((modal?.data as { name: string }).name).delete();

    void apiRequests(transformResponse);

    ModalsService.close(Modals.DeleteWallet);
  };

  return (
    <Modal maxWidth='sm' fullWidth>
      <Modal.Title>
        {t('pages.bitcoinHoldings.areYouSure')}{' '}
        {(modal?.data as { name: string }).name}?
      </Modal.Title>
      <Modal.Content>
        <Typography textAlign='center'>
          {t('pages.bitcoinHoldings.revertAfterDeleting')}
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Box
          pt={4}
          pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
          display='flex'
          justifyContent='space-around'
        >
          <Button variant='contained' onClick={deleteWallet}>
            {t('pages.bitcoinHoldings.delete')}
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};

export { DeleteWalletModal };
