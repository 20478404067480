/**
 * Transform hex to bytes.
 *
 * @author Ihar Kazlouski
 * @param {string} hex hex.
 * @return {Uint8Array | readonly number[]} byte array.
 */
const hexToBytes = (hex: string): Uint8Array | readonly number[] => {
  const bytes = [];
  for (let c = 0; c < hex.length; c += 2) {
    bytes.push(parseInt(hex.substr(c, 2), 16));
  }

  return bytes;
};

export { hexToBytes };
