import { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';

import { TransactionService } from '@services';
import { ConstantsUtil } from '@utils';

import { ApiError, PayloadAction, TransactionParams } from '@types';

import {
  CALCULATE_TRANSACTION_REQUEST,
  CANCEL_TRANSACTION_REQUEST,
  HEX_UPDATE_REQUEST,
  RE_CALCULATE_TRANSACTION_REQUEST,
} from './transaction.action-types';
import { actions } from './transaction.actions';
import {
  CalculateTransactionParamsRequestPayload,
  CancelTransactionRequestPayload,
  HexUpdateRequestPayload,
  ReCalculateTransactionParamsRequestPayload,
} from './transaction.types';

/**
 * Calculate transaction saga worker.
 *
 * @author Ihar Kazlouski
 * @function calculateTransactionSagaWorker
 * @category Sagas
 * @subcategory Transaction
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<BitcoinWallets>>} data.
 */
function* calculateTransactionSagaWorker (
  action: PayloadAction<CalculateTransactionParamsRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<TransactionParams>
  > {
  try {
    const { data } = yield call(
      TransactionService.apiCalculateTransactionParamsPost,
      action.payload,
    );

    yield put(
      actions.calculateTransactionSuccess({
        transactionParams: data,
      }),
    );

    yield put({
      type:   `${CALCULATE_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      wallet: data,
    });
  } catch (error) {
    yield put(
      actions.calculateTransactionFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${CALCULATE_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Calculate transaction saga watcher.
 *
 * @author Ihar Kazlouski
 * @function calculateTransactionSagaWatcher
 * @category Sagas
 * @subcategory Transaction
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* calculateTransactionSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([
    takeLatest(CALCULATE_TRANSACTION_REQUEST, calculateTransactionSagaWorker),
  ]);
}

/**
 * Cancel transaction saga worker.
 *
 * @author Ihar Kazlouski
 * @function cancelTransactionSagaWorker
 * @category Sagas
 * @subcategory Transaction
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<Record<string, unknown>>} data.
 */
function* cancelTransactionSagaWorker (
  action: PayloadAction<CancelTransactionRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<Record<string, unknown>>
  > {
  try {
    const { data } = yield call(
      TransactionService.apiCancelTransactionPut,
      action.payload.transactionId,
    );

    yield put(actions.cancelTransactionSuccess());

    yield put({
      type:   `${CANCEL_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      wallet: data,
    });
  } catch (error) {
    yield put(
      actions.cancelTransactionFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${CANCEL_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Cancel transaction saga watcher.
 *
 * @author Ihar Kazlouski
 * @function cancelTransactionSagaWatcher
 * @category Sagas
 * @subcategory Transaction
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* cancelTransactionSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([
    takeLatest(CANCEL_TRANSACTION_REQUEST, cancelTransactionSagaWorker),
  ]);
}

/**
 * Recalculate transaction saga worker.
 *
 * @author Ihar Kazlouski
 * @function reCalculateTransactionSagaWorker
 * @category Sagas
 * @subcategory Transaction
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<TransactionParams>>} data.
 */
function* reCalculateTransactionSagaWorker (
  action: PayloadAction<ReCalculateTransactionParamsRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<TransactionParams>
  > {
  try {
    const { data } = yield call(
      TransactionService.apiReCalculateTransactionParamsPost,
      action.payload,
    );

    yield put(
      actions.reCalculateTransactionSuccess({
        transactionParams: data,
      }),
    );

    yield put({
      type:   `${RE_CALCULATE_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      wallet: data,
    });
  } catch (error) {
    yield put(
      actions.reCalculateTransactionFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${RE_CALCULATE_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Recalculate transaction saga watcher.
 *
 * @author Ihar Kazlouski
 * @function reCalculateTransactionSagaWatcher
 * @category Sagas
 * @subcategory Transaction
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* reCalculateTransactionSagaWatcher (): Generator<
AllEffect<ForkEffect>
> {
  yield all([
    takeLatest(
      RE_CALCULATE_TRANSACTION_REQUEST,
      reCalculateTransactionSagaWorker,
    ),
  ]);
}

/**
 * Update hex saga worker.
 *
 * @author Ihar Kazlouski
 * @function updateHexSagaWorker
 * @category Sagas
 * @subcategory Transaction
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<Record<string, unknown>>>} data.
 */
function* updateHexSagaWorker (
  action: PayloadAction<HexUpdateRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<Record<string, unknown>>
  > {
  try {
    yield call(
      TransactionService.apiUpdateHex,
      action.payload,
    );

    yield put(actions.hexUpdateSuccess());

    yield put({
      type: `${HEX_UPDATE_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
    });
  } catch (error) {
    yield put(
      actions.hexUpdateFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${HEX_UPDATE_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Update hex saga watcher.
 *
 * @author Ihar Kazlouski
 * @function updateHexSagaWatcher
 * @category Sagas
 * @subcategory Transaction
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* updateHexSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(HEX_UPDATE_REQUEST, updateHexSagaWorker)]);
}

/**
 * Transaction saga.
 *
 * @author Ihar Kazlouski
 * @function transactionSaga
 * @category Sagas
 * @subcategory Transaction
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* transactionSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(calculateTransactionSagaWatcher),
    fork(cancelTransactionSagaWatcher),
    fork(reCalculateTransactionSagaWatcher),
    fork(updateHexSagaWatcher),
  ]);
}
