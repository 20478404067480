import * as React from 'react';

import { Typography } from '@mui/material';

import { Body, SectionContainer, Title } from './section.styles';

import { SectionComponentProps } from './section.types';

/**
 * Section component.
 *
 * @author Ihar Kazlouski
 * @function Section
 * @category components
 * @return {FC} button component.
 */
const Section = React.forwardRef<HTMLDivElement, SectionComponentProps>(
  ({ title, children }, ref) => {
    return (
      <SectionContainer ref={ref} data-testid={`${title}-section`}>
        {title && (
          <Title>
            <Typography variant='h4'>{title}</Typography>
          </Title>
        )}
        <Body>{children}</Body>
      </SectionContainer>
    );
  },
);

Section.displayName = 'Section';

export { Section };
