import { createSelector } from 'reselect';

import { IrohaState } from '@store/iroha/iroha.types';
import { AppState } from '@store/root.reducer';

/**
 * Get iroha state.
 *
 * @author Ihar Kazlouski
 * @function getIrohaState
 * @category Selectors
 * @subcategory Iroha
 * @param {AppState} state state.
 * @return {IrohaState} iroha state.
 */
const getIrohaState = (state: AppState): IrohaState => state.iroha;

export const getIrohaStateSelector = createSelector(
  getIrohaState,
  (irohaState: IrohaState) => irohaState,
);
