import { Box, Button, Grid, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledModalContainer = styled(Box)<{
  theme?: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    padding:                        theme.spacing(4),
    borderRadius:                   pxToRem(12),
    [theme.breakpoints.down('md')]: {
      display:        'flex',
      alignItems:     'center',
      flexDirection:  'column',
      flex:           '1 0',
      justifyContent: 'center',
      padding:        theme.spacing(3, 2),
    },
  };

  return styles;
});

const StyledModalButton = styled(Button)(() => {
  const styles: OverridesStyleRules = {
    minWidth: pxToRem(168),
  };

  return styles;
});

const StyledGrid = styled(Grid)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    [theme.breakpoints.down('md')]: {
      marginTop: pxToRem(24),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: pxToRem(20),
    },
  };

  return styles;
});

export { StyledGrid, StyledModalButton, StyledModalContainer };
