import { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';

import { WalletService } from '@services';
import { ConstantsUtil } from '@utils';

import { AddressOutputs, ApiError, BitcoinResponseAddresses, BitcoinWallets, PayloadAction } from '@types';

import {
  ADD_NEW_ADDRESS_REQUEST,
  ADD_NEW_WALLET_REQUEST,
  CHECK_WALLET_NAME_REQUEST,
  DELETE_WALLET_ADDRESS_REQUEST,
  DELETE_WALLET_REQUEST, GET_ADDRESS_OUTPUTS_REQUEST,
  REFRESH_WALLET_REQUEST,
} from './wallet.action-types';
import { actions } from './wallet.actions';
import {
  AddNewAddressRequestPayload,
  AddNewWalletRequestPayload,
  AddNewWalletSuccessPayload,
  CheckWalletNameRequestPayload,
  DeleteWalletAddressRequestPayload,
  DeleteWalletRequestPayload, GetAddressOutputsRequestPayload,
  RefreshWalletRequestPayload,
} from './wallet.types';

/**
 * Delete wallet saga worker.
 *
 * @author Ihar Kazlouski
 * @function deleteWalletSagaWorker
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<BitcoinWallets>>} data.
 */
function* deleteWalletSagaWorker (
  action: PayloadAction<DeleteWalletRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<BitcoinWallets>
  > {
  try {
    const { data } = yield call(
      WalletService.apiWalletDelete,
      action.payload.id,
    );

    yield put(actions.deleteWalletSuccess());

    yield put({
      type:   `${DELETE_WALLET_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      wallet: data,
    });
  } catch (error) {
    yield put(
      actions.deleteWalletFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${DELETE_WALLET_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Delete wallet saga.
 *
 * @author Ihar Kazlouski
 * @function deleteWalletSagaWatcher
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* deleteWalletSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(DELETE_WALLET_REQUEST, deleteWalletSagaWorker)]);
}

/**
 * Check wallet name saga worker.
 *
 * @author Ihar Kazlouski
 * @function checkWalletNameSagaWorker
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<CheckWalletNameRequestPayload>>} data.
 */
function* checkWalletNameSagaWorker (
  action: PayloadAction<CheckWalletNameRequestPayload>,
): Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<boolean>> {
  try {
    const { data } = yield call(
      WalletService.apiWalletNameCheck,
      action.payload.name,
    );

    yield put(actions.checkWalletNameSuccess());

    yield put({
      type:    `${CHECK_WALLET_NAME_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: { isWalletExist: data },
    });
  } catch (error) {
    yield put(
      actions.checkWalletNameFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${CHECK_WALLET_NAME_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Check wallet name saga.
 *
 * @author Ihar Kazlouski
 * @function checkWalletNameSagaWatcher
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* checkWalletNameSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(CHECK_WALLET_NAME_REQUEST, checkWalletNameSagaWorker)]);
}

/**
 * Add new wallet saga worker.
 *
 * @author Ihar Kazlouski
 * @function addNewWalletWalletSagaWorker
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<BitcoinWallets>>} data.
 */
function* addNewWalletWalletSagaWorker (
  action: PayloadAction<AddNewWalletRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<AddNewWalletSuccessPayload>
  > {
  try {
    const { data } = yield call(WalletService.apiAddWalletPost, {
      name:      action.payload.name,
      addresses: action.payload.addresses,
      type:      action.payload.type,
    });
    yield put(
      actions.addNewWalletSuccess({
        ...data,
      }),
    );

    yield put({
      type:    `${ADD_NEW_WALLET_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: { ...data },
    });
  } catch (error) {
    yield put(
      actions.addNewWalletFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${ADD_NEW_WALLET_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Add new wallet wallet saga.
 *
 * @author Ihar Kazlouski
 * @function addNewWalletSagaWatcher
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* addNewWalletSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(ADD_NEW_WALLET_REQUEST, addNewWalletWalletSagaWorker)]);
}

/**
 * Delete wallet address saga worker.
 *
 * @author Ihar Kazlouski
 * @function deleteWalletAddressSagaWorker
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<BitcoinResponseAddresses>>} data.
 */
function* deleteWalletAddressSagaWorker (
  action: PayloadAction<DeleteWalletAddressRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<BitcoinResponseAddresses>
  > {
  try {
    const { data } = yield call(
      WalletService.apiWalletAddressDelete,
      action.payload.id,
    );

    yield put(actions.deleteWalletAddressSuccess());

    yield put({
      type:    `${DELETE_WALLET_ADDRESS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      address: data,
    });
  } catch (error) {
    yield put(
      actions.deleteWalletAddressFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${DELETE_WALLET_ADDRESS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Delete wallet address saga.
 *
 * @author Ihar Kazlouski
 * @function deleteWalletAddressSagaWatcher
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* deleteWalletAddressSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(DELETE_WALLET_ADDRESS_REQUEST, deleteWalletAddressSagaWorker)]);
}

/**
 * Refresh wallet saga worker.
 *
 * @author Ihar Kazlouski
 * @function refreshWalletSagaWorker
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<BitcoinResponseAddresses>>} data.
 */
function* refreshWalletSagaWorker (
  action: PayloadAction<RefreshWalletRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<BitcoinResponseAddresses>
  > {
  try {
    const { data } = yield call(
      WalletService.apiRefreshWalletGet,
      action.payload.id,
    );

    yield put(actions.refreshWalletSuccess());

    yield put({
      type:    `${REFRESH_WALLET_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: data,
    });
  } catch (error) {
    yield put(
      actions.refreshWalletFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${REFRESH_WALLET_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Refresh wallet saga.
 *
 * @author Ihar Kazlouski
 * @function refreshWalletSagaWatcher
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* refreshWalletSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(REFRESH_WALLET_REQUEST, refreshWalletSagaWorker)]);
}

/**
 * Get address outputs saga worker.
 *
 * @author Ihar Kazlouski
 * @function getOutputsSagaWorker
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<GetAddressOutputsRequestPayload>>} data.
 */
function* getOutputsSagaWorker (
  action: PayloadAction<GetAddressOutputsRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<AddressOutputs>
  > {
  try {
    const { data } = yield call(
      WalletService.apiAddressOutputsGet,
      action.payload.address,
    );

    yield put(actions.getAddressOutputsSuccess());

    yield put({
      type:    `${GET_ADDRESS_OUTPUTS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: data,
    });
  } catch (error) {
    yield put(
      actions.getAddressOutputsFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${GET_ADDRESS_OUTPUTS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Get address outputs saga.
 *
 * @author Ihar Kazlouski
 * @function getAddressOutputsSagaWatcher
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* getAddressOutputsSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(GET_ADDRESS_OUTPUTS_REQUEST, getOutputsSagaWorker)]);
}

/**
 * Add new address saga worker.
 *
 * @author Ihar Kazlouski
 * @function addNewAddressSagaWorker
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<AddNewAddressRequestPayload>>} data.
 */
function* addNewAddressSagaWorker (
  action: PayloadAction<AddNewAddressRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<BitcoinResponseAddresses>
  > {
  try {
    const { data } = yield call(
      WalletService.apiAddAddressPost,
      action.payload,
    );

    yield put(actions.addNewAddressSuccess());

    yield put({
      type:    `${ADD_NEW_ADDRESS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: data,
    });
  } catch (error) {
    yield put(
      actions.addNewAddressFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${ADD_NEW_ADDRESS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Add new address saga watcher.
 *
 * @author Ihar Kazlouski
 * @function addAddressSagaWatcher
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* addNewAddressSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(ADD_NEW_ADDRESS_REQUEST, addNewAddressSagaWorker)]);
}

/**
 * Wallet saga.
 *
 * @author Ihar Kazlouski
 * @function walletSaga
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* walletSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(deleteWalletSagaWatcher),
    fork(checkWalletNameSagaWatcher),
    fork(addNewWalletSagaWatcher),
    fork(deleteWalletAddressSagaWatcher),
    fork(refreshWalletSagaWatcher),
    fork(getAddressOutputsSagaWatcher),
    fork(addNewAddressSagaWatcher),
  ]);
}
