export enum Routes {
  // Public
  All = '*',
  Main = '/',
  Login = '/login',
  Phone = '/phone',
  Sms = '/sms',
  SignUp = '/register',
  EmailConfirm = '/email-confirm',
  SuccessSignUp = '/success-register',
  ChangePassword = '/change-password',

  // Private
  BitcoinHoldings = '/bitcoin-holdings',
  QuantumVault = '/quantum-vault',
  Support = '/support',
  History = '/history',
  WalletMore = '/bitcoin-holdings/wallet/:id',
  WalletCreation = '/bitcoin-holdings/wallet-creation',
  KeysImport = '/bitcoin-holdings/keys-import',
  WalletImportSeed = '/bitcoin-holdings/wallet-import-seed',
  WalletType = '/bitcoin-holdings/wallet-creation/wallet-type',
  SeedPhraseCreation = '/bitcoin-holdings/wallet-creation/seed-phrase-creation',
  SeedPhraseConfirm = '/bitcoin-holdings/wallet-creation/seed-phrase-confirmation',

  Error = '/error',
}
