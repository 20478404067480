import { AxiosError } from 'axios';

import { history } from '@history';

import { AuthService, LoaderService } from '@services';
import { ConstantsUtil, cryptoSimpleDecryption, encryptDecrypt } from '@utils';

import { ApiResponse, Routes } from '@enums';
import { ApiError } from '@types';

/**
 * Error interceptor, catch all API errors
 *
 * @author Ihar Kazlouski
 * @function ErrorInterceptor
 * @category Interceptors
 * @param {AxiosError<ApiError>} error api error.
 * @return {Promise<ApiError>} api error.
 */
const ErrorInterceptor = (error: AxiosError<ApiError>): Promise<ApiError> => {
  if (error.response) {
    switch (error.response.status) {
      case ApiResponse.Unauthorized: {
        localStorage.removeItem(ConstantsUtil.localStorage.cipher);

        history.push(Routes.Login);

        break;
      }

      case ApiResponse.NotFound: {
        history.push(Routes.Error);

        break;
      }

      default: {
        break;
      }
    }
  }

  if (typeof error.response?.data === 'string' &&  (error.response?.data as string)?.length !== 0) {
    const transformedResponse = new Promise((resolve, reject) => {
      LoaderService.incRequestCounter();
      const salt = localStorage.getItem(ConstantsUtil.localStorage.salt) || '';
      const encryptedPassword = localStorage.getItem(ConstantsUtil.localStorage.cipher) || '';
      const password = encryptDecrypt(encryptedPassword, salt);
      const currentUser = localStorage.getItem(
        ConstantsUtil.localStorage.currentUser,
      ) || '';
      AuthService.getKeyFromContainer(4, password, currentUser, salt);

      const listener = (e: CustomEventInit): void => {
        if (error.response?.data) {
          error.response.data = JSON.parse(cryptoSimpleDecryption(error.response?.data as unknown as string, e.detail, salt)) as ApiError;

          LoaderService.decRequestCounter();

          document.removeEventListener('webWorker', listener);

          resolve(error.response?.data);
        }
      };

      document.addEventListener('webWorker', listener);
    });

    return Promise.reject(transformedResponse);
  } else {
    return Promise.reject(error.response?.data);
  }
};

export { ErrorInterceptor };
