import {
  all,
  AllEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';

import { PayloadAction } from '@types';

import {
  CREATE_FORM_STATE_ASYNC,
  DELETE_FORM_STATE_ASYNC,
  UPDATE_FORM_STATE_ASYNC,
} from './forms.action-types';
import { actions } from './forms.actions';
import {
  FormState,
  FormStateDeletePayload,
  FormStateInitPayload,
} from './forms.types';

/**
 * Create form saga worker.
 *
 * @author Ihar Kazlouski
 * @function createFormSagaWorker
 * @category Sagas
 * @subcategory Forms
 * @return {Generator<PutEffect>} action data.
 */
function* createFormSagaWorker (
  action: PayloadAction<FormStateInitPayload>,
): Generator<PutEffect> {
  yield put(actions.createForm(action.payload));
}

/**
 * Create form to saga watcher.
 *
 * @author Ihar Kazlouski
 * @function createFormSagaWatcher
 * @category Sagas
 * @subcategory Forms
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* createFormSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(CREATE_FORM_STATE_ASYNC, createFormSagaWorker)]);
}

/**
 * Update form saga worker.
 *
 * @author Ihar Kazlouski
 * @function updateFormSagaWorker
 * @category Sagas
 * @subcategory Forms
 * @return {Generator<PutEffect>} action data.
 */
function* updateFormSagaWorker (
  action: PayloadAction<FormState>,
): Generator<PutEffect> {
  yield put(actions.updateFormState(action.payload));
}

/**
 * Update form to saga watcher.
 *
 * @author Ihar Kazlouski
 * @function updateFormSagaWatcher
 * @category Sagas
 * @subcategory Forms
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* updateFormSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(UPDATE_FORM_STATE_ASYNC, updateFormSagaWorker)]);
}

/**
 * Delete form saga worker.
 *
 * @author Ihar Kazlouski
 * @function deleteFormSagaWorker
 * @category Sagas
 * @subcategory Forms
 * @return {Generator<PutEffect>} action data.
 */
function* deleteFormSagaWorker (
  action: PayloadAction<FormStateDeletePayload>,
): Generator<PutEffect> {
  yield put(actions.deleteFormState(action.payload));
}

/**
 * Delete form to saga watcher.
 *
 * @author Ihar Kazlouski
 * @function createFormSagaWatcher
 * @category Sagas
 * @subcategory Forms
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* deleteFormSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(DELETE_FORM_STATE_ASYNC, deleteFormSagaWorker)]);
}

/**
 * Forms saga.
 *
 * @author Ihar Kazlouski
 * @function formsSaga
 * @category Sagas
 * @subcategory Forms
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* formsSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(createFormSagaWatcher),
    fork(updateFormSagaWatcher),
    fork(deleteFormSagaWatcher),
  ]);
}
