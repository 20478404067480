import { AxiosResponse } from 'axios';

import { AuthService, LoaderService } from '@services';
import { ConstantsUtil, cryptoSimpleDecryption, encryptDecrypt } from '@utils';

/**
 * Response interceptor, catch all API responses
 *
 * @author Ihar Kazlouski
 * @function ResponseInterceptor
 * @category Interceptors
 * @param {AxiosResponse} response response.
 * @return {AxiosResponse} response.
 */
const ResponseInterceptor = (response: AxiosResponse): Promise<AxiosResponse> | AxiosResponse => {
  if (typeof response.data === 'string' && response.data.length > 0) {
    return (async (): Promise<AxiosResponse> => {
      const transformedResponsePromise = new Promise((resolve, reject) => {
        LoaderService.incRequestCounter();

        const salt = localStorage.getItem(ConstantsUtil.localStorage.salt) || '';
        const encryptedPassword = localStorage.getItem(ConstantsUtil.localStorage.cipher) || '';
        const password = encryptDecrypt(encryptedPassword, salt);
        const currentUser = localStorage.getItem(
          ConstantsUtil.localStorage.currentUser,
        ) || '';

        AuthService.getKeyFromContainer(4, password, currentUser, salt);

        const listener = (e: CustomEventInit): void => {
          const transformedResponse = JSON.parse(cryptoSimpleDecryption(response.data, e.detail, salt)) as Record<string, unknown>;

          LoaderService.decRequestCounter();

          document.removeEventListener('webWorker', listener);

          resolve(transformedResponse);
        };

        document.addEventListener('webWorker', listener);
      });

      const responseData = await transformedResponsePromise;

      response.data = responseData;

      return  response;
    })();
  } else {
    return response;
  }
};

export { ResponseInterceptor };
