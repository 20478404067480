import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Button } from '@mui/material';

import { colors } from '@themes';
import { StyledListItem } from './nav-item.styles';

import { NavItemProps } from './nav-item.types';

/**
 * Side bar navigation item.
 *
 * @author Ihar Kazlouski
 * @function NavItem
 * @category components
 * @param {string} href navigation path.
 * @param {ReactNode} icon icon component.
 * @param {string} title title.
 * @return {FC} navigation item component.
 */
const NavItem: FC<NavItemProps> = ({ href, icon, title, ...restProps }) => {
  const location = useLocation();
  const active =
    location.pathname === href ||
    `/${location.pathname.split('/')[1]}` === href;

  return (
    <StyledListItem disableGutters {...restProps}>
      <Button
        data-testid={`nav-item-${title}-button`}
        component='a'
        href={href}
        startIcon={icon}
        disableRipple
        sx={{
          backgroundColor: active ? colors.lightGray : 'transparent',
          borderRadius:    1,
          color:           active ? 'secondary.main' : 'neutral.300',
          fontWeight:      active ? 'fontWeightBold' : '600',
          justifyContent:  'flex-start',
          px:              3,
          textAlign:       'left',
          textTransform:   'none',
          width:           '100%',

          '& .MuiButton-startIcon': {
            color: active ? 'secondary.main' : 'neutral.400',
          },

          '&:hover': {
            backgroundColor: colors.lightGray,
          },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
      </Button>
    </StyledListItem>
  );
};

export { NavItem };
