/**
 * Download data to computer.
 *
 * @author Ihar Kazlouski
 * @param {string} data data.
 * @return {void}
 */
const exportData = (data: string, name: string): void => {
  const text = `data:text/json;charset=utf-8,${encodeURIComponent(data || '')}`;
  const link = document.createElement('a');
  link.href = text;
  link.download = `${name}.json`;

  link.click();
};

/**
 * Download data to computer.
 *
 * @author Ihar Kazlouski
 * @param {string[]} data data.
 * @param {string} name file name.
 * @return {void}
 */
const exportTxtData = (data: string[], name: string): void => {
  const text = 'data:text/plain;charset=utf-8,' + encodeURIComponent(data.reduce((acc, cur, index) => {
    return acc + cur.replace(/\n/g, '%0D%0A') + (data.length ===  index + 1 ? '' : ', ');
  }, ''));

  const link = document.createElement('a');
  link.href = text;
  link.download = `${name}.txt`;

  link.click();
};

export { exportData, exportTxtData };
