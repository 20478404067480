import { createSelector } from 'reselect';

import { GridState } from '@store/grids/grids.types';
import { Selector } from '@store/modals/modals.types';
import { AppState } from '@store/root.reducer';

/**
 * Get Grids array.
 *
 * @author Ihar Kazlouski
 * @function getGrids
 * @category Selectors
 * @subcategory Grids
 * @param {AppState} state state.
 * @return {GridsState[]} modals state.
 */
const getGrids = (state: AppState): GridState[] => {
  return state.grids;
};

export const getGridsSelector = createSelector(getGrids, (grids) => grids);

export const getGridSelector = (
  gridId: string,
): Selector<GridState | undefined> => {
  return createSelector(getGrids, (grids) => {
    return grids.find((grid) => grid.gridId === gridId);
  });
};
