import { ReactElement } from 'react';

import { GridApiLoadingComponentProps } from '@components/api-grid/api-grid-loading.types';

/**
 * Api grid loading component.
 *
 * @author Ihar Kazlouski
 * @function ApiGridLoading
 * @category components
 * @return {ReactNode} children.
 */
function ApiGridLoading ({
  children,
}: GridApiLoadingComponentProps): ReactElement<unknown> {
  return <>{children}</>;
}

ApiGridLoading.displayName = 'ApiGridLoading';

export { ApiGridLoading };
