import React, { FC } from 'react';

import { Box, Grid, IconButton } from '@mui/material';

import { useDeviceSize } from '@hooks';

import { Icon } from '@elements/icon';

import close from '@images/close.image.svg';
import logo from '@images/logo.svg';

import { StyledModalTitleTypography } from './modal-title.styled';
import { ModalTitleProps } from './modal-title.types';

/**
 * ModalTitleSlot component.
 *
 * @author Ihar Kazlouski
 * @function ModalTitle
 * @category components
 * @param {ModalFooterProps} content props.
 * @return {JSX.Element} ModalTitleSlot component.
 */
const ModalTitle: FC<ModalTitleProps> = ({
  children,
  handleClose,
}): JSX.Element => {
  const deviceSize = useDeviceSize();

  const titleContent =
    typeof children === 'string' || typeof children === 'object' ? (
      <>
        <Box ml={deviceSize.desktop ? 4.75 : 0}>
          <Box display='flex' justifyContent='space-around'>
            <Icon src={logo} width={100} height={90} color='amazon' />
          </Box>
          <StyledModalTitleTypography mt={2} variant='h5' textAlign='center'>
            {children}
          </StyledModalTitleTypography>
        </Box>
      </>
    ) : typeof children === 'undefined' ? (
      <Box ml={deviceSize.desktop ? 4.75 : 0}>
        <Box display='flex' justifyContent='space-around'>
          <Icon src={logo} width={100} height={90} color='amazon' />
        </Box>
      </Box>
    ) : (
      children
    );

  return (
    <Grid
      container
      flexWrap='nowrap'
      alignItems='flex-start'
      justifyContent='center'
    >
      <Grid item xs={12} md='auto' lg>
        {titleContent}
      </Grid>
      {deviceSize.desktop && (
        <Grid item xs='auto'>
          <IconButton onClick={handleClose}>
            <Icon src={close} width={22} height={22} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

ModalTitle.displayName = 'ModalTitle';

export { ModalTitle };
