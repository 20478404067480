import * as yup from 'yup';

import { i18n } from '@localization';

const transferToAnotherUserSchema = (total: number): yup.BaseSchema => {
  return yup.object().shape({
    qtcAmount: yup
      .number()
      .required(i18n.t('validation.required') || '')
      .max(total, i18n.t('validation.notEnoughFunds') || '')
      .min(0.001, i18n.t('validation.minSum') || ''),
    accountRecipient: yup
      .string()
      .required(i18n.t('validation.required') || '')
      .matches(
        /[a-z0-9@]+$/,
        i18n.t('validation.accountValidation') || '',
      ).max(36, i18n.t('validation.accountValidation') || ''),
  });
};

export { transferToAnotherUserSchema };
