export const DELETE_WALLET_REQUEST = 'DELETE_WALLET_REQUEST';
export const DELETE_WALLET_SUCCESS = 'DELETE_WALLET_SUCCESS';
export const DELETE_WALLET_FAILURE = 'DELETE_WALLET_FAILURE';
export const CHECK_WALLET_NAME_REQUEST = 'CHECK_WALLET_NAME_REQUEST';
export const CHECK_WALLET_NAME_SUCCESS = 'CHECK_WALLET_NAME_SUCCESS';
export const CHECK_WALLET_NAME_FAILURE = 'CHECK_WALLET_NAME_FAILURE';
export const ADD_NEW_WALLET_REQUEST = 'ADD_NEW_WALLET_REQUEST';
export const ADD_NEW_WALLET_SUCCESS = 'ADD_NEW_WALLET_SUCCESS';
export const ADD_NEW_WALLET_FAILURE = 'ADD_NEW_WALLET_FAILURE';
export const DELETE_WALLET_ADDRESS_REQUEST = 'DELETE_WALLET_ADDRESS_REQUEST';
export const DELETE_WALLET_ADDRESS_SUCCESS = 'DELETE_WALLET_ADDRESS_SUCCESS';
export const DELETE_WALLET_ADDRESS_FAILURE = 'DELETE_WALLET_ADDRESS_FAILURE';
export const REFRESH_WALLET_REQUEST = 'REFRESH_WALLET_REQUEST';
export const REFRESH_WALLET_SUCCESS = 'REFRESH_WALLET_SUCCESS';
export const REFRESH_WALLET_FAILURE = 'REFRESH_WALLET_FAILURE';
export const GET_ADDRESS_OUTPUTS_REQUEST = 'GET_ADDRESS_OUTPUTS_REQUEST';
export const GET_ADDRESS_OUTPUTS_SUCCESS = 'GET_ADDRESS_OUTPUTS_SUCCESS';
export const GET_ADDRESS_OUTPUTS_FAILURE = 'GET_ADDRESS_OUTPUTS_FAILURE';
export const ADD_NEW_ADDRESS_REQUEST = 'ADD_NEW_ADDRESS_REQUEST';
export const ADD_NEW_ADDRESS_SUCCESS = 'ADD_NEW_ADDRESS_SUCCESS';
export const ADD_NEW_ADDRESS_FAILURE = 'ADD_NEW_ADDRESS_FAILURE';
