import * as yup from 'yup';

import { i18n } from '@localization';

const addMoneySchema = (): yup.BaseSchema =>
  yup.object().shape({
    btcAmount: yup
      .number()
      .required(i18n.t('validation.required') || '')
      .min(0.001, i18n.t('validation.minSum') || ''),
  });

export { addMoneySchema };
