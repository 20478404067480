import React, { FC, useEffect, useRef } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';

import { getLoaderSelector } from '@store/loader';

import { LoaderService } from '@services';
import { usePrevious } from '@hooks';

import { LoaderContainer, StyledModal } from './loader.styles';

/**
 * Loader component.
 *
 * @author Ihar Kazlouski
 * @function Loader
 * @category components
 * @return {FC} modal component.
 */
const Loader: FC = () => {
  const { requestCounter, isShowLoader } = useSelector(getLoaderSelector);
  const dispatch = useDispatch();
  const timeoutCounter = useRef(0);
  const prevCounterState = usePrevious<typeof requestCounter>(requestCounter);

  useEffect(() => {
    let timeout;

    if (prevCounterState === 0 && requestCounter > 0) {
      timeout = setTimeout(() => {
        LoaderService.showLoader();
        timeout = null;
      }, 100);
    }

    if (prevCounterState && prevCounterState > 0 && requestCounter === 0) {
      if (timeout) {
        clearTimeout(timeout);
      }
    }

    if (prevCounterState !== requestCounter) {
      void Promise.all([
        new Promise<void>((resolve, reject) => {
          setTimeout(() => {
            timeoutCounter.current += 1;
            if (
              prevCounterState &&
              prevCounterState > 0 &&
              requestCounter === 0
            ) {
              timeoutCounter.current -= 1;
              resolve();
            } else {
              timeoutCounter.current -= 1;
              reject(new Error());
            }
          }, 3500);
        }),
        new Promise<void>((resolve) => {
          if (
            prevCounterState &&
            prevCounterState > 0 &&
            requestCounter === 0
          ) {
            resolve();
          }
        }),
      ])
        .then(() => {
          if (!timeoutCounter || !requestCounter) {
            LoaderService.hideLoader();
          }
        })
        .catch(() => {
          // ToDo: error handling
        });
    }
  }, [dispatch, prevCounterState, requestCounter]);

  return (
    <StyledModal
      role='progressbar'
      disableEnforceFocus
      disableAutoFocus
      disableEscapeKeyDown
      open={isShowLoader}
      aria-labelledby='loader'
      data-testid='loader-modal'
      closeAfterTransition
    >
      <LoaderContainer>
        <ThreeCircles
            height='100'
            width='100'
            color='#4fa94d'
            wrapperStyle={{}}
            wrapperClass=''
            visible={isShowLoader}
            ariaLabel='three-circles-rotating'
            outerCircleColor=''
            innerCircleColor=''
            middleCircleColor=''
        />
      </LoaderContainer>
    </StyledModal>
  );
};

export { Loader };
