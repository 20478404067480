import {
  HIDE_LOADER,
  INIT_LOADER_HIDING,
  INIT_LOADER_SHOWING,
  INIT_REQUEST_COUNTER_DECREASING,
  INIT_REQUEST_COUNTER_INCREASING,
  LOADER_SHOW,
  REQUEST_COUNTER_DECREASE,
  REQUEST_COUNTER_INCREASE,
} from '@store/loader/loader.action-types';

export const actions = {
  /**
   * Init loader showing.
   *
   * @author Ihar Kazlouski
   * @function initModalShowing
   * @category Actions
   */
  initLoaderShowing: () =>
    ({
      type: INIT_LOADER_SHOWING,
    } as const),

  /**
   * Show loader.
   *
   * @author Ihar Kazlouski
   * @function showLoader
   * @category Actions
   * @subcategory Loader
   */
  showLoader: () =>
    ({
      type: LOADER_SHOW,
    } as const),

  /**
   * Init loader hiding.
   *
   * @author Ihar Kazlouski
   * @function initLoaderHiding
   * @category Actions
   * @subcategory Loader
   */
  initLoaderHiding: () =>
    ({
      type: INIT_LOADER_HIDING,
    } as const),

  /**
   * Hide loader.
   *
   * @author Ihar Kazlouski
   * @function hideLoader
   * @category Actions
   * @subcategory Loader
   */
  hideLoader: () =>
    ({
      type: HIDE_LOADER,
    } as const),

  /**
   * Init increasing of request counter.
   *
   * @author Ihar Kazlouski
   * @function initIncreasingRequestCounter
   * @category Actions
   * @subcategory Loader
   */
  initIncreasingRequestCounter: () =>
    ({
      type: INIT_REQUEST_COUNTER_INCREASING,
    } as const),

  /**
   * Increase request counter.
   *
   * @author Ihar Kazlouski
   * @function increaseRequestCounter
   * @category Actions
   * @subcategory Loader
   */
  increaseRequestCounter: () =>
    ({
      type: REQUEST_COUNTER_INCREASE,
    } as const),

  /**
   * Init decreasing of request counter..
   *
   * @author Ihar Kazlouski
   * @function initDecreasingRequestCounter
   * @category Actions
   * @subcategory Loader
   */
  initDecreasingRequestCounter: () =>
    ({
      type: INIT_REQUEST_COUNTER_DECREASING,
    } as const),

  /**
   * Decrease request counter.
   *
   * @author Ihar Kazlouski
   * @function decreaseRequestCounter
   * @category Actions
   * @subcategory Loader
   */
  decreaseRequestCounter: () =>
    ({
      type: REQUEST_COUNTER_DECREASE,
    } as const),
};
