import { Box, styled } from '@mui/material';

const SectionContainer = styled(Box)(() => ({}));

const Title = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(2.5),
}));

const Body = styled(Box)(() => ({}));

export { Body, SectionContainer, Title };
