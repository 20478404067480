import { combineReducers } from 'redux';

import authReducer from './auth/auth.reducers';
import formsReducer from './forms/forms.reducers';
import gridsReducer from './grids/grids.reducers';
import irohaReducer from './iroha/iroha.reducer';
import lamportReducer from './lamport/lamport.reducer';
import loaderReducer from './loader/loader.reducer';
import modalsReducer from './modals/modals.reducers';
import themeReducer from './theme/theme.reducer';
import transactionReducer from './transaction/transaction.reducer';
import walletReducer from './wallet/wallet.reducer';

const rootReducer = combineReducers({
  theme:       themeReducer,
  forms:       formsReducer,
  modals:      modalsReducer,
  auth:        authReducer,
  grids:       gridsReducer,
  wallet:      walletReducer,
  transaction: transactionReducer,
  loader:      loaderReducer,
  lamport:     lamportReducer,
  iroha:       irohaReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
