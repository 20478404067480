import axios, { AxiosResponse } from 'axios';

import { dispatch as storeDispatch } from '@store/index';
import { actions, SendLamportPubKeyRequestPayload } from '@store/lamport';

import { AuthService } from '@services/auth';
import { FeaturesService } from '@services/features';
import { LoaderService } from '@services/loader';
import { ModalsService } from '@services/modals';
import { ConstantsUtil, QKeyReader } from '@utils';

import { Feature, Modals } from '@enums';
import { ApiError, AsyncDispatch, LamportKeys, ParsedContainer } from '@types';

import { i18n } from '@localization';

import { ApiService } from '../api';

/**
 * Lamport service.
 *
 * @author Ihar Kazlouski
 * @class LamportServiceClass
 * @category Services
 */
class LamportServiceClass {
  /**
   * Send lamport public key api.
   *
   * @author Ihar Kazlouski
   * @param {SendLamportPubKeyRequestPayload} payload request payload.
   * @return {Promise<ApiError | AxiosResponse<Record<string, unknown>>>} send lamport public key promise.
   */
  async apiSendLamportPubKeyPost (
    payload: SendLamportPubKeyRequestPayload,
  ): Promise<ApiError | AxiosResponse<Record<string, unknown>>> {
    return ApiService.apiPost(
      '/qc/lamport-key',
      { ...payload },
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Send lamport public key.
   *
   * @author Ihar Kazlouski
   * @param {SendLamportPubKeyRequestPayload} payload request payload.
   * @return {Promise<ApiError | AxiosResponse<Record<string, unknown>>>} send lamport public key promise.
   */
  async sendLamportPubKey (
    payload: SendLamportPubKeyRequestPayload,
  ): Promise<ApiError | Record<string, unknown>> {
    const dispatch: AsyncDispatch<Record<string, unknown> | ApiError> =
      storeDispatch;

    return dispatch(actions.sendLamportPubKeyRequest({ ...payload }));
  }

  /**
   * Get lamport keys api.
   *
   * @author Ihar Kazlouski
   * @param {SendLamportPubKeyRequestPayload} payload request payload.
   * @return {Promise<ApiError | AxiosResponse<Record<string, unknown>>>} get lamport keys promise.
   */
  async apiLamportKeysPost (clientNumber: string): Promise<
  ApiError | AxiosResponse<Record<string, unknown>>
  > {
    const http = axios.create({
      baseURL: 'https://bc-qkeys.terraquantum.swiss/',
    });
    return await http.post(
      'api/v2/lamport-private',
      JSON.stringify({
        clientNumber: clientNumber,
      }),
      {
        headers: {
          'Content-type': ConstantsUtil.headers.applicationJson,
        },
      },
    );
  }

  /**
   * Get lamport keys.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<Record<string, unknown>>>} send lamport public key promise.
   */
  async getLamportKeys (
    password: string,
  ): Promise<ApiError | LamportKeys | undefined> {
    const dispatch: AsyncDispatch<LamportKeys | ApiError> = storeDispatch;
    const containers = AuthService.getContainerFromLs();

    if (containers) {
      const currentUser = localStorage.getItem(
        ConstantsUtil.localStorage.currentUser,
      );
      const parsedContainer = (
        JSON.parse(containers || '') as ParsedContainer[]
      ).find((item) => item.login === currentUser);
      const container = JSON.stringify(parsedContainer);

      if (container) {
        const getContainerPromise = new Promise((resolve) => {
          LoaderService.incRequestCounter();

          AuthService.getDecryptedContainer(
            container,
            true,
            password,
            false,
          );

          const listener = (e: CustomEventInit): void => {
            LoaderService.decRequestCounter();

            document.removeEventListener('webWorker', listener);

            resolve(e.detail);
          };

          document.addEventListener('webWorker', listener);
        });

        try {
          LoaderService.incRequestCounter();
          const decryptedContainer = await getContainerPromise as QKeyReader;

          if (decryptedContainer?.keyNumber && decryptedContainer?.clientNumber) {
            return dispatch(actions.getLamportKeysRequest({ clientNumber: decryptedContainer.clientNumber }));
          } else {
            throw new Error(decryptedContainer as unknown as string);
          }
        } catch (error) {
          LoaderService.decRequestCounter();
          if (Array.isArray(error)) {
            await ModalsService.open(Modals.Error, {
              content: i18n.t('modals.error.operationFailed'),
            });

            void ModalsService.closeAll();
          } else {
            void ModalsService.open(Modals.Error, {
              content: i18n.t('modals.error.wrongPassword'),
            });
          }
        }
      } else {
        void ModalsService.open(Modals.UploadKeys, {
          content: i18n.t('modals.uploadKeys.content'),
        });
      }
    } else {
      await ModalsService.open(Modals.UploadKeys, {
        content: i18n.t('modals.uploadKeys.content'),
      });

      return await this.getLamportKeys(password);
    }
  }
}

const LamportService = new LamportServiceClass();

export { LamportService };
