import { IconButton, styled } from '@mui/material';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledFormInputIconWrapper = styled(IconButton)(() => {
  const styles: OverridesStyleRules = {
    display: 'flex',
  };

  return styles;
});

export { StyledFormInputIconWrapper };
