import React, { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Container } from '@mui/material';

import { PublicLayoutProps } from '@hocs/layouts/public-layout/public-layout.types';

import { Header, SideBarComponent } from '@components';

import { DashboardLayoutRoot, StyledMain } from './private-layout.styles';

/**
 * Private layout hoc component.
 *
 * @author Ihar Kazlouski
 * @function PrivateLayout
 * @category Hocs
 * @return {JSX.Element} wrapped component.
 */
const PrivateLayout: FC<PublicLayoutProps> = ({ inline }): JSX.Element => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  /**
   * Side bar open function.
   *
   * @author Ihar Kazlouski
   * @function onSideBarOpen
   * @return {void}
   */
  const onSideBarOpen = (): void => {
    setSidebarOpen(true);
  };

  /**
   * Side bar clos function.
   *
   * @author Ihar Kazlouski
   * @function onClose
   * @return {void}
   */
  const onClose = (): void => {
    setSidebarOpen(false);
  };

  return (
    <>
      <DashboardLayoutRoot>
        <Header onSideBarOpen={onSideBarOpen} />
        <StyledMain inline={inline} component='main'>
          {inline ? (
            <Outlet />
          ) : (
            <Container fixed>
              <Outlet />
            </Container>
          )}
        </StyledMain>
      </DashboardLayoutRoot>
      <SideBarComponent open={isSidebarOpen} onClose={onClose} />
    </>
  );
};

export { PrivateLayout };
