import React, { useLayoutEffect, useState } from 'react';
import { Router, RoutesProps } from 'react-router-dom';

import { BrowserHistory } from 'history';

/**
 * App router component.
 *
 * @author Ihar Kazlouski
 * @component
 * @category components
 * @param {BrowserHistory} history history.
 * @return {React.ReactElement | null} app router component.
 */
const AppRouter = ({
  history,
  ...props
}: { history: BrowserHistory } & RoutesProps): React.ReactElement | null => {
  const [state, setState] = useState({
    action:   history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

export { AppRouter };
