import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

import authSaga from './auth/auth.sagas';
import formsSaga from './forms/forms.sagas';
import gridsSaga from './grids/grids.sagas';
import irohaSaga from './iroha/iroha.sagas';
import lamportSaga from './lamport/lamport.sagas';
import loaderSaga from './loader/loader.saga';
import modalsSaga from './modals/modals.sagas';
import transactionSaga from './transaction/transaction.sagas';
import walletSaga from './wallet/wallet.sagas';

export function* rootSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(formsSaga),
    fork(modalsSaga),
    fork(authSaga),
    fork(gridsSaga),
    fork(walletSaga),
    fork(transactionSaga),
    fork(loaderSaga),
    fork(lamportSaga),
    fork(irohaSaga),
  ]);
}
