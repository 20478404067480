import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, Grid } from '@mui/material';

import { actions } from '@store/lamport';
import { getModalSelector } from '@store/modals';

import { AuthService, LamportService, LoaderService, ModalsService } from '@services';
import { ConstantsUtil } from '@utils';
import { useDeviceSize } from '@hooks';

import { Modals } from '@enums';
import { LamportKeys } from '@types';

import { Form, Modal, PasswordInputView } from '@elements';

import { i18n } from '@localization';

/**
 * Password modal.
 *
 * @author Ihar Kazlouski
 * @function PasswordModal
 * @category Modals
 * @return {FC} password modal component.
 */
const PasswordModal: FC = () => {
  const { t } = useTranslation();
  const deviceSize = useDeviceSize();
  const modal = useSelector(getModalSelector(Modals.Password));

  /**
   * Submit modal.
   *
   * @author Ihar Kazlouski
   * @param {string} password password.
   * @return {void}
   */
  const handleSubmit = (password: string) => async (): Promise<void> => {
    try {
      if ((modal?.data as { isCheckLamport: boolean })?.isCheckLamport) {
        const keys = await LamportService.getLamportKeys(password) as LamportKeys;

        await LamportService.sendLamportPubKey({ lamportPublicKey: keys?.publicKey });

        ModalsService.closeWithAnswer(Modals.Password, { accepted: true, data: keys });
      } else {
        const login =
            localStorage.getItem(ConstantsUtil.localStorage.currentUser) || '';
        const salt =
            localStorage.getItem(ConstantsUtil.localStorage.salt) || '';
        const getFirstKey = new Promise((resolve) => {
          LoaderService.incRequestCounter();

          AuthService.getKeyFromContainer(1, password, login, salt, 'hex');

          const listener = (e: CustomEventInit): void => {
            LoaderService.decRequestCounter();

            document.removeEventListener('webWorker', listener);

            resolve(e.detail);
          };

          document.addEventListener('webWorker', listener);
        });

        try {
          const firstKey = (await getFirstKey) as string;

          if (typeof firstKey === 'string') {
            ModalsService.close(Modals.Password);
          } else {
            throw new Error(firstKey as string);
          }
        } catch (error) {
          void ModalsService.open(Modals.Error, {
            content: i18n.t('modals.error.wrongPassword'),
          });
        }
      }
    } catch (error) {
      void ModalsService.open(Modals.Error, {
        content: t('modals.error.operationFailed'),
      });
    }
  };

  return (
    <Modal maxWidth='sm' fullWidth>
      <Modal.Title>{t('modals.password.enterPassword')}</Modal.Title>
      <Modal.Content>
        <Form
          onSubmit={(values): (() => void) =>
            handleSubmit(values.password as string)
          }
          subscription={{
            pristine:   true,
            submitting: true,
            invalid:    true,
            values:     true,
          }}
          render={({ pristine, submitting, invalid, values }): JSX.Element => {
            return (
              <Grid container>
                <Grid item xs={12}>
                  <PasswordInputView
                    variant='outlined'
                    name='password'
                    label={t('modals.password.password')}
                    fullWidth
                    pristine={pristine}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  pt={4}
                  pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
                  display='flex'
                  justifyContent='space-around'
                >
                  <Grid item xs={12} md={2} lg={3}>
                    <Button
                      disabled={pristine || submitting || invalid}
                      variant='contained'
                      fullWidth
                      onClick={handleSubmit(values.password as string)}
                    >
                      {t('modals.password.further')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        />
      </Modal.Content>
    </Modal>
  );
};

export { PasswordModal };
