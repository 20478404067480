import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';

import { history } from '@history';

import { WithTheme } from '@hocs';

import '@localization';
import './styles/index.css';

import { AppRouter } from './components/app-router';
import AppComponent from './app.component';
import store from './store';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container as HTMLElement);

root.render(
  <Provider store={store}>
    <WithTheme>
      <AppRouter history={history}>
        <AppComponent />
      </AppRouter>
    </WithTheme>
  </Provider>,
);
