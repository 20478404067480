import { dispatch } from '@store/index';
import { actions } from '@store/loader';

/**
 * Loader service.
 *
 * @author Ihar Kazlouski
 * @const LoaderService
 * @category Components
 * @subcategory Loader
 */
const LoaderService = {
  /**
   * Show loader.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  showLoader (): void {
    dispatch(actions.initLoaderShowing());
  },

  /**
   * Hide loader.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  hideLoader (): void {
    dispatch(actions.initLoaderHiding());
  },

  /**
   * Increase count of the requests.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  incRequestCounter (): void {
    dispatch(actions.initIncreasingRequestCounter());
  },

  /**
   * Decrease count of the requests.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  decRequestCounter (): void {
    dispatch(actions.initDecreasingRequestCounter());
  },
};

export { LoaderService };
