import { Box, BoxProps, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledPublicWrapper = styled(Box)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    minHeight:       '100vh',
    maxWidth:        '100%',
    display:         'flex',
    alignItems:      'center',
    backgroundColor: theme.colors.white,
  };
  return styles;
});

const StyledMain = styled(Box)<BoxProps & { inline?: boolean }>(
  ({ theme, inline }) => {
    const styles: OverridesStyleRules = {
      flex:          '1',
      width:         '100%',
      paddingTop:    inline ? 0 : theme.spacing(4),
      paddingBottom: inline ? 0 : theme.spacing(4),
    };
    return styles;
  },
);

export { StyledMain, StyledPublicWrapper };
