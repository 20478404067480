import { produce } from 'immer';

import { ApiError } from '@types';

import {
  CALCULATE_TRANSACTION_FAILURE,
  CALCULATE_TRANSACTION_REQUEST,
  CALCULATE_TRANSACTION_SUCCESS,
  CANCEL_TRANSACTION_FAILURE,
  CANCEL_TRANSACTION_REQUEST,
  CANCEL_TRANSACTION_SUCCESS,
  HEX_UPDATE_FAILURE,
  HEX_UPDATE_REQUEST,
  HEX_UPDATE_SUCCESS,
  RE_CALCULATE_TRANSACTION_FAILURE,
  RE_CALCULATE_TRANSACTION_REQUEST,
  RE_CALCULATE_TRANSACTION_SUCCESS,
} from './transaction.action-types';
import { ActionsTypes, TransactionState } from './transaction.types';

const initialState: TransactionState = {
  pending:           false,
  errors:            [] as ApiError[],
  transactionParams: {
    transactionId:      '',
    paymentAddresses:   [],
    transactionSize:    0,
    comissionByteBtc:   '',
    comissionRecommBtc: '',
    totalBtc:           0,
    changeAmount:       0,
    wallets:            [],
    totalAmount:        0,
    btcAmount:          '',
    comissionSize:      0,
    comissionAmount:    0,
  },
};

/**
 * Wallet reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Wallet
 * @param {TransactionState} state state.
 * @param {ActionsTypes} action action.
 * @return {TransactionState} transaction state.
 */
export default (state = initialState, action: ActionsTypes): TransactionState => {
  switch (action.type) {
    case CALCULATE_TRANSACTION_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case CALCULATE_TRANSACTION_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.transactionParams = action.payload.transactionParams;
        draft.errors = [];
      });
    }

    case CALCULATE_TRANSACTION_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    case CANCEL_TRANSACTION_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case CANCEL_TRANSACTION_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = [];
      });
    }

    case CANCEL_TRANSACTION_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    case RE_CALCULATE_TRANSACTION_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case RE_CALCULATE_TRANSACTION_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.transactionParams = action.payload.transactionParams;
        draft.errors = [];
      });
    }

    case RE_CALCULATE_TRANSACTION_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    case HEX_UPDATE_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case HEX_UPDATE_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = [];
      });
    }

    case HEX_UPDATE_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
