import * as React from 'react';

import { IconComponentProps } from '@elements/icon/icon.types';

import { StyledIcon, StyledSvgIcon } from './icon.styles';

/**
 * Icon component.
 *
 * @author Ihar Kazlouski
 * @function Icon
 * @category components
 * @return {FC} icon component.
 */
export const Icon = ({
  className,
  color,
  ...props
}: IconComponentProps): JSX.Element => (
  <StyledSvgIcon
    component='i'
    inheritViewBox
    className={className}
    color={color}
  >
    <StyledIcon color={color} {...props} />
  </StyledSvgIcon>
);
