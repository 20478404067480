import { Box, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledModalContentContainer = styled(Box)<{
  theme?: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    marginTop: theme.spacing(2.5),

    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(6.5),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3.5),
    },
  };

  return styles;
});

export { StyledModalContentContainer };
