import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import { ConstantsUtil } from '@utils';

import {
  API_GRID_FAILURE,
  API_GRID_FETCH,
  API_GRID_SUCCESS,
  API_GRID_UPDATE_FETCH,
  CLOSE_ALL_GRIDS,
  CLOSE_ALL_GRIDS_REQUEST,
  CLOSE_GRID,
  CLOSE_GRID_REQUEST,
  GRID_INIT,
  GRID_INIT_REQUEST,
  UPDATE_GRID,
  UPDATE_GRID_REQUEST,
} from './grids.action-types';
import {
  GridApiFetchPayload,
  GridClosePayload,
  GridPayload,
} from './grids.types';

export const actions = {
  /**
   * Request grid initiation.
   *
   * @author Ihar Kazlouski
   * @function gridInitRequest
   * @category Actions
   * @subcategory Grids
   * @param {ModalClosePayload} payload payload.
   */
  gridInitRequest: <T>(payload: GridPayload<T>) =>
    ({
      type: GRID_INIT_REQUEST,
      payload,
    } as const),

  /**
   * Grid init.
   *
   * @author Ihar Kazlouski
   * @function gridInit
   * @category Actions
   * @subcategory Grids
   * @param {ModalClosePayload} payload payload.
   */
  gridInit: <T>(payload: GridPayload<T>) =>
    ({
      type: GRID_INIT,
      payload,
    } as const),

  /**
   * Request grid updating.
   *
   * @author Ihar Kazlouski
   * @function gridUpdateRequest
   * @category Actions
   * @subcategory Grids
   * @param {ModalPayload} payload payload.
   */
  gridUpdateRequest: <T>(payload: GridPayload<T>) =>
    ({
      type: UPDATE_GRID_REQUEST,
      payload,
    } as const),

  /**
   * Update grid.
   *
   * @author Ihar Kazlouski
   * @function gridUpdate
   * @category Actions
   * @subcategory Grids
   * @param {ModalPayload} payload payload.
   */
  gridUpdate: <T>(payload: GridPayload<T>) =>
    ({
      type: UPDATE_GRID,
      payload,
    } as const),

  /**
   * Request grid closing.
   *
   * @author Ihar Kazlouski
   * @function gridCloseRequest
   * @category Actions
   * @subcategory Grids
   * @param {GridClosePayload} payload payload.
   */
  gridCloseRequest: (payload: GridClosePayload) =>
    ({
      type: CLOSE_GRID_REQUEST,
      payload,
    } as const),

  /**
   * Close grid.
   *
   * @author Ihar Kazlouski
   * @function gridClose
   * @category Actions
   * @subcategory Grids
   * @param {GridClosePayload} payload payload.
   */
  gridClose: (payload: GridClosePayload) =>
    ({
      type: CLOSE_GRID,
      payload,
    } as const),

  /**
   * Request all grids closing.
   *
   * @author Ihar Kazlouski
   * @function closeAllGridsRequest
   * @category Actions
   * @subcategory Grids
   */
  closeAllGridsRequest: () =>
    ({
      type: CLOSE_ALL_GRIDS_REQUEST,
    } as const),

  /**
   * Close all grids.
   *
   * @author Ihar Kazlouski
   * @function closeAllGrids
   * @category Actions
   * @subcategory Grids
   */
  closeAllGrids: () =>
    ({
      type: CLOSE_ALL_GRIDS,
    } as const),

  /**
   * Api grid fetch.
   *
   * @author Ihar Kazlouski
   * @function gridApiFetch
   * @category Actions
   * @subcategory Grids
   * @param {GridApiFetchPayload<T, R>} payload payload.
   */
  gridApiFetch: <T, R>(payload: GridApiFetchPayload<T, R>) =>
    ({
      type:              API_GRID_FETCH,
      payload,
      [WAIT_FOR_ACTION]: `${API_GRID_FETCH}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${API_GRID_FETCH}/${ConstantsUtil.actions.ASYNC_FAILED}`,
    } as const),

  /**
   * Grid api success.
   *
   * @author Ihar Kazlouski
   * @function gridApiSuccess
   * @category Actions
   * @subcategory Grids
   * @param {GridPayload<T>} payload payload.
   */
  gridApiSuccess: <T>(payload: GridPayload<T>) =>
    ({
      type: API_GRID_SUCCESS,
      payload,
    } as const),

  /**
   * Grid api failure.
   *
   * @author Ihar Kazlouski
   * @function gridApiFailure
   * @category Actions
   * @subcategory Grids
   * @param {GridPayload<T>} payload payload.
   */
  gridApiFailure: <T>(payload: GridPayload<T>) =>
    ({
      type: API_GRID_FAILURE,
      payload,
    } as const),

  /**
   * Api update grid fetch.
   *
   * @author Ihar Kazlouski
   * @function gridApiUpdateFetch
   * @category Actions
   * @subcategory Grids
   * @param {GridApiFetchPayload<T, R>} payload payload.
   */
  gridApiUpdateFetch: <T, R>(payload: GridApiFetchPayload<T, R>) =>
    ({
      type:              API_GRID_UPDATE_FETCH,
      payload,
      [WAIT_FOR_ACTION]: `${API_GRID_UPDATE_FETCH}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${API_GRID_UPDATE_FETCH}/${ConstantsUtil.actions.ASYNC_FAILED}`,
    } as const),
};
