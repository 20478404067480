import React, { cloneElement, FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Backdrop, Toolbar } from '@mui/material';

import { getModalOpenStateSelector } from '@store/modals/modals.selectors';

import { ModalsService } from '@services';
import { useDeviceSize } from '@hooks';

import { ModalProps } from '@elements/modal/';

import { StyledDialog, StyledPaper } from './modal.styles';

import { ModalComponentProps } from './modal.types';
import { modalVariants } from './modal.variants';

/**
 * Modal component.
 *
 * @author Ihar Kazlouski
 * @function ModalComponent
 * @category components
 * @param {string} modalId modal id.
 * @return {FC} modal component.
 */
const ModalComponent: FC<ModalComponentProps> = ({ modalId }) => {
  const isOpen = useSelector(getModalOpenStateSelector(modalId));
  const component: FC = modalVariants[modalId];

  const deviceSize = useDeviceSize();

  /**
   * Reject modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const rejectModal = useCallback((): void => {
    ModalsService.closeWithAnswer(modalId, { rejected: true });
  }, [modalId]);

  /**
   * Accept modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const acceptModal = useCallback((): void => {
    ModalsService.closeWithAnswer(modalId, { accepted: true });
  }, [modalId]);

  /**
   * Close modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const handleClose = useCallback((): void => {
    rejectModal();
  }, [rejectModal]);

  const initialChildProps = useMemo(
    () => ({
      handleClose,
      acceptModal,
      rejectModal,
      modalId,
    }),
    [acceptModal, handleClose, modalId, rejectModal],
  );

  const modalChild = component ? component(initialChildProps) : null;

  const children = useMemo(
    () => modalChild && cloneElement<ModalProps>(modalChild, initialChildProps),
    [initialChildProps, modalChild],
  );

  const modalProps = children?.props
    ? {
        maxWidth:  children.props.maxWidth,
        fullWidth: children.props.fullWidth,
      }
    : {};

  const mobileProps = !deviceSize.desktop
    ? {
        fullScreen: true,
      }
    : {};

  return (
    <>
      <StyledDialog
        data-testid={`${modalId}-modal`}
        aria-labelledby={modalId}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        PaperComponent={StyledPaper}
        PaperProps={{
          elevation: 0,
        }}
        {...mobileProps}
        {...modalProps}
      >
        {!deviceSize.desktop && <Toolbar />}
        {children}
      </StyledDialog>
    </>
  );
};

export { ModalComponent };
