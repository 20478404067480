import { createSelector } from 'reselect';

import { AppState } from '@store/root.reducer';

import { LoaderState } from './loader.types';

/**
 * Get loader state.
 *
 * @author Ihar Kazlouski
 * @function getLoaderState
 * @category Selectors
 * @subcategory Loader
 * @param {AppState} state state.
 * @return {LoaderState} loader state.
 */
const getLoaderState = (state: AppState): LoaderState => {
  return state.loader;
};

export const getLoaderSelector = createSelector(
  getLoaderState,
  (loader) => loader,
);
