import { AppBar, Container, styled } from '@mui/material';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow:       theme.shadows[3],
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  display:        'flex',
  justifyContent: 'space-between',
  alignItems:     'center',
}));

export { StyledAppBar, StyledContainer };
