import { ConstantsUtil } from '@utils/constants';

import { ApiErrorObject } from '@types';

import { i18n } from '@localization';

import { ConstantsError } from './errors-handler.types';

/**
 * Errors handler util.
 *
 * @param {ApiErrorObject[] | Record<string, unknown>} errors errors.
 * @author Ihar Kazlouski
 * @return {string[]} array of errors.
 */
const errorsHandlerUtil = (errors: ApiErrorObject[] | Record<string, unknown> | string): string => {
  if (Array.isArray(errors)) {
    const handledErrors = errors.map((error) => {
      return (
        ConstantsUtil.errors?.[error?.code as keyof ConstantsError] ||
        i18n.t('modals.error.somethingGoesWrong')
      );
    });

    return handledErrors?.[0];
  } else {
    return ConstantsUtil.errors?.[errors as keyof ConstantsError] || i18n.t('modals.error.somethingGoesWrong');
  }
};

export { errorsHandlerUtil };
