import { produce } from 'immer';

import {
  CREATE_FORM_STATE,
  DELETE_FORM_STATE,
  UPDATE_FORM_STATE,
} from './forms.action-types';
import { ActionsTypes, FormState } from './forms.types';

const initialState: FormState[] = [];
const initialFormState = {
  dirty:                      false,
  dirtyFields:                {},
  dirtyFieldsSinceLastSubmit: {},
  dirtySinceLastSubmit:       false,
  errors:                     {},
  hasSubmitErrors:            false,
  hasValidationErrors:        false,
  invalid:                    false,
  modified:                   {},
  modifiedSinceLastSubmit:    false,
  pristine:                   true,
  submitFailed:               false,
  submitSucceeded:            false,
  submitting:                 false,
  touched:                    {},
  valid:                      true,
  validating:                 false,
  values:                     {},
  visited:                    {},
};
/**
 * forms reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Forms
 * @param {FormState} state state.
 * @param {ActionsTypes} action action.
 * @return {FormState[]} forms state.
 */
const formsReducer = (
  state = initialState,
  action: ActionsTypes,
): FormState[] => {
  switch (action.type) {
    case CREATE_FORM_STATE:
      return produce(state, (draft) => {
        const idx = state.findIndex((form) => {
          return form.formId === action.payload.formId;
        });

        if (idx < 0) {
          draft.push({ formId: action.payload.formId, ...initialFormState });
        }
      });
    case UPDATE_FORM_STATE:
      return produce(state, (draft) => {
        const idx = state.findIndex(
          (form) => form.formId === action.payload.formId,
        );
        if (idx >= 0) {
          draft[idx] = action.payload;
        } else {
          draft.push(action.payload);
        }
      });
    case DELETE_FORM_STATE:
      return produce(state, (draft) => {
        const idx = state.findIndex(
          (form) => form.formId === action.payload.formId,
        );
        if (idx >= 0) {
          draft.splice(idx, 1);
        }
      });
    default:
      return state;
  }
};

export default formsReducer;
