import React, { ElementType } from 'react';

/**
 * Suspense hoc component.
 *
 * @author Ihar Kazlouski
 * @function WithSuspense
 * @category Hocs
 * @param {ElementType | JSX.Element | null} Module function.
 * @return {JSX.Element} wrapped component.
 */
const WithSuspense = (
  Module: ElementType | JSX.Element | null,
): JSX.Element => (
  <React.Suspense fallback={<span />}>
    {typeof Module === 'object' ? Module : <Module />}
  </React.Suspense>
);

export { WithSuspense };
