import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { AuthService } from '@services/auth';
import { LoaderService } from '@services/loader';
import {
  ConstantsUtil,
  cryptoSimpleEncryption,
  encryptDecrypt,
} from '@utils';
import { ErrorInterceptor, LoaderInterceptor, ResponseInterceptor } from '@interceptors';

import { ApiError } from '@types';

axios.defaults.withCredentials = true;

const http = axios.create({
  baseURL: process.env.baseUrl,
});

http.interceptors.request.use(
  (request: AxiosRequestConfig) => LoaderInterceptor({ request }),
  (error: AxiosError<ApiError>) => LoaderInterceptor({ error }),
);
http.interceptors.response.use(
  (response: AxiosResponse) => LoaderInterceptor({ response }),
  (error: AxiosError<ApiError>) => LoaderInterceptor({ error }),
);

http.interceptors.request.use((request) => request, ErrorInterceptor);
http.interceptors.response.use((response) => response, ErrorInterceptor);

http.interceptors.response.use((response: AxiosResponse) => ResponseInterceptor(response));

/**
 * Api service.
 *
 * @author Ihar Kazlouski
 * @class ApiServiceClass
 * @category Services
 */
class ApiServiceClass {
  /**
   * Api body encryption.
   *
   * @author Ihar Kazlouski
   * @param {Record<string, unknown>} body body.
   * @return {string} encrypted body.
   */
  bodyEncryption (body: Record<string, unknown>): Promise<string> {
    const encryptedBody = new Promise((resolve, reject) => {
      LoaderService.incRequestCounter();

      const salt = localStorage.getItem(ConstantsUtil.localStorage.salt) || '';
      const encryptedPassword = localStorage.getItem(ConstantsUtil.localStorage.cipher) || '';
      const password = encryptDecrypt(encryptedPassword, salt);
      const currentUser = localStorage.getItem(
        ConstantsUtil.localStorage.currentUser,
      ) || '';

      AuthService.getKeyFromContainer(4, password, currentUser, salt);

      const listener = (e: CustomEventInit): void => {
        LoaderService.decRequestCounter();

        document.removeEventListener('webWorker', listener);

        resolve(cryptoSimpleEncryption(JSON.stringify(body), e.detail, salt));
      };

      document.addEventListener('webWorker', listener);
    });

    return encryptedBody as Promise<string>;
  }

  /**
   * Api get function.
   *
   * @author Ihar Kazlouski
   * @param {string} path path.
   * @return {Promise<ApiError | AxiosResponse>} data promise.
   */
  async apiGet (
    path: string,
    encrypted = false,
  ): Promise<ApiError | AxiosResponse> {
    return await http.get(path, {
      headers: {
        'Content-type': encrypted
          ? ConstantsUtil.headers.applicationOctetStream
          : ConstantsUtil.headers.applicationJson,

        'X-Encoded': encrypted,
      },
    });
  }

  /**
   * Api post function.
   *
   * @author Ihar Kazlouski
   * @param {Object} body body.
   * @param {Record<string, unknown>} path path.
   * @return {Promise<ApiError | AxiosResponse>} data promise.
   */
  async apiPost (
    path: string,
    body: Record<string, unknown>,
    encrypted = false,
  ): Promise<ApiError | AxiosResponse> {
    let json: string;

    if (encrypted) {
      json = await this.bodyEncryption(body);
    } else {
      json = JSON.stringify(body);
    }

    return await http.post(path, json, {
      headers: {
        'Content-type': encrypted
          ? ConstantsUtil.headers.applicationOctetStream
          : ConstantsUtil.headers.applicationJson,

        'X-Encoded': encrypted,
      },
    });
  }

  /**
   * Api put function.
   *
   * @author Ihar Kazlouski
   * @param {Record<string, unknown>} body body.
   * @param {string} path path.
   * @return {Promise<ApiError | AxiosResponse>} data promise.
   */
  async apiPut (
    path: string,
    body: Record<string, unknown>,
    encrypted = false,
  ): Promise<ApiError | AxiosResponse> {
    let json: string;

    if (encrypted) {
      json = await this.bodyEncryption(body);
    } else {
      json = JSON.stringify(body);
    }

    return await http.put(path, json, {
      headers: {
        'Content-type': encrypted
          ? ConstantsUtil.headers.applicationOctetStream
          : ConstantsUtil.headers.applicationJson,

        'X-Encoded': encrypted,
      },
    });
  }

  /**
   * Api delete function.
   *
   * @author Ihar Kazlouski
   * @param {string} path path.
   * @param {number} id id.
   * @return {Promise<ApiError | AxiosResponse>} data promise.
   */
  async apiDelete (
    path: string,
    id: string | number,
    encrypted = false,
  ): Promise<ApiError | AxiosResponse> {
    return await http.delete(`${path}/${id}`, {
      headers: {
        'Content-type': encrypted
          ? ConstantsUtil.headers.applicationOctetStream
          : ConstantsUtil.headers.applicationJson,

        'X-Encoded': encrypted,
      },
    });
  }
}

const ApiService = new ApiServiceClass();

export { ApiService };
