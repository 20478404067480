import { Box, styled, TableHead } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  overflowX: 'auto',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
}));

export { StyledBox, StyledTableHead };
