import { AxiosResponse } from 'axios';

import { actions } from '@store/grids';
import { dispatch } from '@store/index';

import { ApiError, AsyncDispatch } from '@types';

/**
 * Grids service.
 *
 * @author Ihar Kazlouski
 * @const GridsService
 * @category Components
 * @subcategory Grids
 */
const GridsService = {
  /**
   * Init grid.
   *
   * @author Ihar Kazlouski
   * @param {string} gridId gridId.
   * @param {array} items grid items.
   * @param {boolean} isLoading is grid loaded.
   * @param {boolean} isError fetch error.
   * @return {void}
   */
  init<T>(
    gridId: string,
    items: T | null,
    isLoading: boolean,
    isError: boolean,
  ): void {
    dispatch(
      actions.gridInitRequest<T>({
        gridId,
        items,
        isLoading,
        isError,
      }),
    );
  },

  /**
   * Update grid.
   *
   * @author Ihar Kazlouski
   * @param {string} gridId gridId.
   * @param {array} items grid items.
   * @param {() => Promise<AxiosResponse<T | R> | ApiError> | Promise<T>} api grid api.
   * @param {((request: R) => T) | undefined} transformResponse grid transformer.
   * @return {Promise<AxiosResponse<T | R> | ApiError> | Promise<T>} items promise.
   */
  update<T, R>(
    gridId: string,
    api: () => Promise<AxiosResponse<T | R> | ApiError> | Promise<T>,
    transformResponse: ((request: R) => T) | undefined,
  ): Promise<AxiosResponse<T | R> | ApiError> | Promise<T> {
    const asyncDispatch: AsyncDispatch<T> = dispatch;

    return asyncDispatch(
      actions.gridApiUpdateFetch<T, R>({
        gridId,
        api,
        transformResponse,
      }),
    );
  },

  /**
   * Close grid.
   *
   * @author Ihar Kazlouski
   * @param {string} gridId gridId.
   * @return {void}
   */
  close (gridId: string): void {
    dispatch(actions.gridCloseRequest({ gridId }));
  },

  /**
   * Close all grids.
   *
   * @author Ihar Kazlouski
   * @param {string} gridId gridId.
   * @return {void}
   */
  closeAll (): void {
    dispatch(actions.closeAllGridsRequest());
  },

  /**
   * Call grid api.
   *
   * @author Ihar Kazlouski
   * @param {string} gridId gridId.
   * @param {() => Promise<AxiosResponse<T | R> | ApiError> | Promise<T>} api grid api.
   * @param {((request: R) => T) | undefined} transformResponse grid transformer.
   * @return {Promise<AxiosResponse<T | R> | ApiError> | Promise<T>} items promise.
   */
  api<T, R>(
    gridId: string,
    api: () => Promise<AxiosResponse<T | R> | ApiError> | Promise<T>,
    transformResponse: ((request: R) => T) | undefined,
  ): Promise<AxiosResponse<T | R> | ApiError> | Promise<T> {
    const asyncDispatch: AsyncDispatch<T> = dispatch;

    return asyncDispatch(
      actions.gridApiFetch<T, R>({
        gridId,
        api,
        transformResponse,
      }),
    );
  },
};

export { GridsService };
