import { Box, Divider, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { pxToRem } from '@themes';

const StyledContentContainer = styled(Box)(({ theme }) => ({
  display:       'flex',
  flexDirection: 'column',
  height:        '100%',
}));

const StyledDivider = styled(Divider)<{ theme?: DefaultTheme }>(({ theme }) => ({
  borderColor:  theme.colors.oxfordBlue,
  marginBottom: pxToRem(24),
}));

export { StyledContentContainer, StyledDivider };
