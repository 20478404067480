import { createSelector } from 'reselect';

import { AuthState } from '@store/auth/auth.types';
import { AppState } from '@store/root.reducer';

/**
 * Get auth state.
 *
 * @author Ihar Kazlouski
 * @function getAuthState
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {AuthState} auth state.
 */
const getAuthState = (state: AppState): AuthState => state.auth;

/**
 * Get is item in local storage selector.
 *
 * @author Ihar Kazlouski
 * @function getIsItemInLocalStorage
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {boolean} isItemInLocalStorage.
 */
const getIsItemInLocalStorage = (state: AppState): boolean =>
  state.auth.isItemInLocalStorage;

/**
 * Get is item in local storage selector.
 *
 * @author Ihar Kazlouski
 * @function getIsItemInLocalStorage
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {boolean} isItemInLocalStorage.
 */
const getUserPhoneNumber = (state: AppState): string => state.auth.phoneNumber;

export const getAuthStateSelector = createSelector(
  getAuthState,
  (authState: AuthState) => authState,
);

export const getUserPhoneNumberSelector = createSelector(
  getUserPhoneNumber,
  (phoneNumber: string) => phoneNumber,
);

export const getIsItemInLocalStorageSelector = createSelector(
  getIsItemInLocalStorage,
  (isItemInLocalStorage: boolean) => isItemInLocalStorage,
);
