/**
 * Get valid children .
 *
 * @author Ihar Kazlouski
 * @param {type: FC<T>} type children type.
 * @return {boolean} is child valid.
 */
import React, { FC, isValidElement } from 'react';

function getValidChild<T> (type: FC<T>) {
  return (
    child: React.ReactChild | React.ReactFragment | React.ReactPortal,
  ): child is React.ReactElement<T> => {
    return isValidElement(child) ? child.type === type : false;
  };
}

export { getValidChild };
