import { dispatch as storeDispatch } from '@store/index';
import { actions, ModalPayload } from '@store/modals';

import { AsyncDispatch } from '@types';

/**
 * Modals controller.
 *
 * @author Ihar Kazlouski
 * @const ModalsServiceClass
 * @category Services
 * @subcategory Modals
 */
class ModalsServiceClass {
  /**
   * Open modal.
   *
   * @author Ihar Kazlouski
   * @param {string} modalId modalId.
   * @param {T} data modal data.
   * @return {void}
   */
  open<T = unknown>(modalId: string, data?: T): Promise<ModalPayload | Error> {
    const dispatch: AsyncDispatch<ModalPayload | Error> = storeDispatch;

    const modalAction = dispatch(
      actions.modalInit({
        modalId,
        data,
        isOpen: true,
      }),
    );

    modalAction.catch((error: Error): Error => error);

    return modalAction;
  }

  /**
   * Close modal.
   *
   * @author Ihar Kazlouski
   * @param {string} modalId modalId.
   * @param {Dispatch} dispatch dispatch.
   * @return {void}
   */
  close (modalId: string): void {
    storeDispatch(actions.modalInitClosing({ modalId }));
  }

  /**
   * Close modal with answer.
   *
   * @author Ihar Kazlouski
   * @param {string} modalId modalId.
   * @param {Dispatch} dispatch dispatch.
   * @param {object} answer answer.
   * @return {void}
   */
  closeWithAnswer (
    modalId: string,
    { accepted = false, rejected = false, data = {} },
  ): void {
    storeDispatch(
      actions.modalInitClosingWithAnswer({
        modalId,
        accepted,
        rejected,
        data,
      }),
    );
  }

  /**
   * Close all modals.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  closeAll (): void {
    storeDispatch(actions.modalInitAllClosing());
  }
}

const ModalsService = new ModalsServiceClass();

export { ModalsService };
