import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import { ConstantsUtil } from '@utils';

import {
  ADD_NEW_ADDRESS_FAILURE,
  ADD_NEW_ADDRESS_REQUEST,
  ADD_NEW_ADDRESS_SUCCESS,
  ADD_NEW_WALLET_FAILURE,
  ADD_NEW_WALLET_REQUEST,
  ADD_NEW_WALLET_SUCCESS,
  CHECK_WALLET_NAME_FAILURE,
  CHECK_WALLET_NAME_REQUEST,
  CHECK_WALLET_NAME_SUCCESS,
  DELETE_WALLET_ADDRESS_FAILURE,
  DELETE_WALLET_ADDRESS_REQUEST,
  DELETE_WALLET_ADDRESS_SUCCESS,
  DELETE_WALLET_FAILURE,
  DELETE_WALLET_REQUEST,
  DELETE_WALLET_SUCCESS,
  GET_ADDRESS_OUTPUTS_FAILURE,
  GET_ADDRESS_OUTPUTS_REQUEST,
  GET_ADDRESS_OUTPUTS_SUCCESS,
  REFRESH_WALLET_FAILURE,
  REFRESH_WALLET_REQUEST,
  REFRESH_WALLET_SUCCESS,
} from './wallet.action-types';
import {
  AddNewAddressFailurePayload,
  AddNewAddressRequestPayload,
  AddNewWalletFailurePayload,
  AddNewWalletRequestPayload,
  AddNewWalletSuccessPayload,
  CheckWalletNameFailurePayload,
  CheckWalletNameRequestPayload,
  DeleteWalletAddressFailurePayload,
  DeleteWalletAddressRequestPayload,
  DeleteWalletFailurePayload,
  DeleteWalletRequestPayload,
  GetAddressOutputsFailurePayload,
  GetAddressOutputsRequestPayload,
  RefreshWalletFailurePayload,
  RefreshWalletRequestPayload,
} from './wallet.types';

export const actions = {
  /**
   * Delete wallet request action.
   *
   * @author Ihar Kazlouski
   * @function deleteWalletRequest
   * @category Actions
   * @subcategory Wallet
   * @return {DeleteWalletRequestPayload} delete wallet request.
   */
  deleteWalletRequest: (payload: DeleteWalletRequestPayload) =>
    ({
      type:              DELETE_WALLET_REQUEST,
      [WAIT_FOR_ACTION]: `${DELETE_WALLET_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${DELETE_WALLET_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Delete wallet success request action.
   *
   * @author Ihar Kazlouski
   * @function deleteWalletTodoSuccess
   * @category Actions
   * @subcategory Wallet
   * @return {DeleteWalletSuccessPayload} delete wallet success.
   */
  deleteWalletSuccess: () =>
    ({
      type: DELETE_WALLET_SUCCESS,
    } as const),

  /**
   * Delete wallet failure request action.
   *
   * @author Ihar Kazlouski
   * @function deleteWalletFailure
   * @category Actions
   * @subcategory Wallet
   * @param {DeleteWalletFailurePayload} payload payload.
   * @return {DeleteWalletFailurePayload} delete wallet failure.
   */
  deleteWalletFailure: (payload: DeleteWalletFailurePayload) =>
    ({
      type: DELETE_WALLET_FAILURE,
      payload,
    } as const),

  /**
   * Check is wallet exists request action.
   *
   * @author Ihar Kazlouski
   * @function checkWalletNameRequest
   * @category Actions
   * @subcategory Wallet
   * @return {CheckWalletNameRequestPayload} check is wallet exists request.
   */
  checkWalletNameRequest: (payload: CheckWalletNameRequestPayload) =>
    ({
      type:              CHECK_WALLET_NAME_REQUEST,
      [WAIT_FOR_ACTION]: `${CHECK_WALLET_NAME_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${CHECK_WALLET_NAME_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Check is wallet exists success action.
   *
   * @author Ihar Kazlouski
   * @function checkWalletNameRequest
   * @category Actions
   * @subcategory Wallet
   * @return {} check is wallet exists success.
   */
  checkWalletNameSuccess: () =>
    ({
      type: CHECK_WALLET_NAME_SUCCESS,
    } as const),

  /**
   * Check is wallet exists failure action.
   *
   * @author Ihar Kazlouski
   * @function checkWalletNameFailure
   * @category Actions
   * @subcategory Wallet
   * @param {CheckWalletNameFailurePayload} payload payload.
   * @return {CheckWalletNameFailurePayload} is wallet exists failure.
   */
  checkWalletNameFailure: (payload: CheckWalletNameFailurePayload) =>
    ({
      type: CHECK_WALLET_NAME_FAILURE,
      payload,
    } as const),

  /**
   * Add new wallet request action.
   *
   * @author Ihar Kazlouski
   * @function addNewWalletRequest
   * @category Actions
   * @subcategory Wallet
   * @return {BitcoinDbWallet} add new wallet request.
   */
  addNewWalletRequest: (payload: AddNewWalletRequestPayload) =>
    ({
      type:              ADD_NEW_WALLET_REQUEST,
      [WAIT_FOR_ACTION]: `${ADD_NEW_WALLET_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${ADD_NEW_WALLET_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Add new wallet success action.
   *
   * @author Ihar Kazlouski
   * @function addNewWalletSuccess
   * @category Actions
   * @subcategory Wallet
   * @return {AddNewWalletSuccessPayload} add new wallet success.
   */
  addNewWalletSuccess: (payload: AddNewWalletSuccessPayload) =>
    ({
      type: ADD_NEW_WALLET_SUCCESS,
      payload,
    } as const),

  /**
   * Add new wallet failure action.
   *
   * @author Ihar Kazlouski
   * @function addNewWalletFailure
   * @category Actions
   * @subcategory Wallet
   * @param {AddNewWalletFailurePayload} payload payload.
   * @return {AddNewWalletFailurePayload} add new wallet failure.
   */
  addNewWalletFailure: (payload: AddNewWalletFailurePayload) =>
    ({
      type: ADD_NEW_WALLET_FAILURE,
      payload,
    } as const),

  /**
   * Delete wallet address request action.
   *
   * @author Ihar Kazlouski
   * @function deleteWalletAddressRequest
   * @category Actions
   * @subcategory Wallet
   * @return {DeleteWalletAddressRequestPayload} delete address wallet request.
   */
  deleteWalletAddressRequest: (payload: DeleteWalletAddressRequestPayload) =>
    ({
      type:              DELETE_WALLET_ADDRESS_REQUEST,
      [WAIT_FOR_ACTION]: `${DELETE_WALLET_ADDRESS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${DELETE_WALLET_ADDRESS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Delete wallet address success request action.
   *
   * @author Ihar Kazlouski
   * @function deleteWalletAddressSuccess
   * @category Actions
   * @subcategory Wallet
   * @return {DeleteWalletAddressSuccessPayload} delete wallet address success.
   */
  deleteWalletAddressSuccess: () =>
    ({
      type: DELETE_WALLET_ADDRESS_SUCCESS,
    } as const),

  /**
   * Delete wallet address failure request action.
   *
   * @author Ihar Kazlouski
   * @function deleteWalletAddressFailure
   * @category Actions
   * @subcategory Wallet
   * @param {DeleteWalletAddressFailurePayload} payload payload.
   * @return {DeleteWalletAddressFailurePayload} delete wallet address failure.
   */
  deleteWalletAddressFailure: (payload: DeleteWalletAddressFailurePayload) =>
    ({
      type: DELETE_WALLET_ADDRESS_FAILURE,
      payload,
    } as const),

  /**
   * Refresh wallet request action.
   *
   * @author Ihar Kazlouski
   * @function refreshWalletRequest
   * @category Actions
   * @subcategory Wallet
   * @return {RefreshWalletRequestPayload} refresh wallet request.
   */
  refreshWalletRequest: (payload: RefreshWalletRequestPayload) =>
    ({
      type:              REFRESH_WALLET_REQUEST,
      [WAIT_FOR_ACTION]: `${REFRESH_WALLET_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${REFRESH_WALLET_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Refresh wallet success action.
   *
   * @author Ihar Kazlouski
   * @function refreshWalletSuccess
   * @category Actions
   * @subcategory Wallet
   * @return {DeleteWalletAddressSuccessPayload} refresh wallet success.
   */
  refreshWalletSuccess: () =>
    ({
      type: REFRESH_WALLET_SUCCESS,
    } as const),

  /**
   * Refresh wallet failure action.
   *
   * @author Ihar Kazlouski
   * @function refreshWalletFailure
   * @category Actions
   * @subcategory Wallet
   * @param {RefreshWalletFailurePayload} payload payload.
   * @return {RefreshWalletFailurePayload} refresh wallet failure.
   */
  refreshWalletFailure: (payload: RefreshWalletFailurePayload) =>
    ({
      type: REFRESH_WALLET_FAILURE,
      payload,
    } as const),

  /**
   * Get address outputs request action.
   *
   * @author Ihar Kazlouski
   * @function getAddressOutputsRequest
   * @category Actions
   * @subcategory Wallet
   * @return {RefreshWalletRequestPayload} get address outputs request.
   */
  getAddressOutputsRequest: (payload: GetAddressOutputsRequestPayload) =>
    ({
      type:              GET_ADDRESS_OUTPUTS_REQUEST,
      [WAIT_FOR_ACTION]: `${GET_ADDRESS_OUTPUTS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${GET_ADDRESS_OUTPUTS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Get address outputs success action.
   *
   * @author Ihar Kazlouski
   * @function getAddressOutputsSuccess
   * @category Actions
   * @subcategory Wallet
   */
  getAddressOutputsSuccess: () =>
    ({
      type: GET_ADDRESS_OUTPUTS_SUCCESS,
    } as const),

  /**
   * Get address outputs failure action.
   *
   * @author Ihar Kazlouski
   * @function getAddressOutputsFailure
   * @category Actions
   * @subcategory Wallet
   * @param {GetAddressOutputsFailurePayload} payload payload.
   * @return {GetAddressOutputsFailurePayload} get address outputs failure.
   */
  getAddressOutputsFailure: (payload: GetAddressOutputsFailurePayload) =>
    ({
      type: GET_ADDRESS_OUTPUTS_FAILURE,
      payload,
    } as const),

  /**
   * Add new address request action.
   *
   * @author Ihar Kazlouski
   * @function addNewAddressRequest
   * @category Actions
   * @subcategory Wallet
   * @return {AddNewAddressRequestPayload} add new address request.
   */
  addNewAddressRequest: (payload: AddNewAddressRequestPayload) =>
    ({
      type:              ADD_NEW_ADDRESS_REQUEST,
      [WAIT_FOR_ACTION]: `${ADD_NEW_ADDRESS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${ADD_NEW_ADDRESS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Add new address success action.
   *
   * @author Ihar Kazlouski
   * @function addNewAddressSuccess
   * @category Actions
   * @subcategory Wallet
   * @return {DeleteWalletAddressSuccessPayload} add new address success.
   */
  addNewAddressSuccess: () =>
    ({
      type: ADD_NEW_ADDRESS_SUCCESS,
    } as const),

  /**
   * Add new address failure action.
   *
   * @author Ihar Kazlouski
   * @function addNewAddressFailure
   * @category Actions
   * @subcategory Wallet
   * @param {AddNewAddressFailurePayload} payload payload.
   * @return {AddNewAddressFailurePayload} Add new address failure.
   */
  addNewAddressFailure: (payload: AddNewAddressFailurePayload) =>
    ({
      type: ADD_NEW_ADDRESS_FAILURE,
      payload,
    } as const),
};
