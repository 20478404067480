import { useMediaQuery } from '@mui/material';

import { DefaultTheme } from '@types';

import { DeviceSize } from './device-size.types';

/**
 * Device size hook.
 *
 * @author Ihar Kazlouski
 * @function useDeviceSize
 * @category helper
 * @return {{ mobile: boolean; tablet: boolean; desktop: boolean; }} return device sizes.
 */
export const useDeviceSize = (): DeviceSize => {
  return {
    mobile: useMediaQuery((theme: DefaultTheme) =>
      theme.breakpoints.down('sm'),
    ),
    tablet: useMediaQuery((theme: DefaultTheme) =>
      theme.breakpoints.between('sm', 'md'),
    ),
    desktop: useMediaQuery((theme: DefaultTheme) => theme.breakpoints.up('lg')),
  };
};
