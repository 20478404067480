import {
  all,
  AllEffect,
  call,
  CallEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { ConstantsUtil } from '@utils';

import { ApiError, PayloadAction } from '@types';

import {
  API_GRID_FETCH,
  API_GRID_UPDATE_FETCH,
  CLOSE_ALL_GRIDS_REQUEST,
  CLOSE_GRID_REQUEST,
  GRID_INIT_REQUEST,
  UPDATE_GRID_REQUEST,
} from './grids.action-types';
import { actions } from './grids.actions';
import {
  GridApiFetchPayload,
  GridClosePayload,
  GridPayload,
} from './grids.types';

/**
 * Grid init saga worker.
 *
 * @author Ihar Kazlouski
 * @function gridInitSagaWorker
 * @category Sagas
 * @subcategory Grids
 * @return {Generator<PutEffect>} action data.
 */
function* gridInitSagaWorker (
  action: PayloadAction<GridPayload<unknown>>,
): Generator<PutEffect> {
  yield put(
    actions.gridInit<unknown>({
      gridId:    action.payload.gridId,
      items:     action.payload.items,
      isError:   action.payload.isError,
      isLoading: action.payload.isLoading,
    }),
  );
}

/**
 * Grid init saga watcher.
 *
 * @author Ihar Kazlouski
 * @function gridInitSagaWatcher
 * @category Sagas
 * @subcategory Grids
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* gridInitSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(GRID_INIT_REQUEST, gridInitSagaWorker)]);
}

/**
 * Update grid saga worker.
 *
 * @author Ihar Kazlouski
 * @function updateGridSagaWorker
 * @category Sagas
 * @subcategory Grids
 * @return {Generator<PutEffect>} action data.
 */
function* updateGridSagaWorker (
  action: PayloadAction<GridPayload<unknown>>,
): Generator<PutEffect> {
  yield put(
    actions.gridUpdate<unknown>({
      gridId:    action.payload.gridId,
      items:     action.payload.items,
      isError:   action.payload.isError,
      isLoading: action.payload.isLoading,
    }),
  );
}

/**
 * Update grid saga watcher.
 *
 * @author Ihar Kazlouski
 * @function updateGridSagaWatcher
 * @category Sagas
 * @subcategory Grids
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* updateGridSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(UPDATE_GRID_REQUEST, updateGridSagaWorker)]);
}

/**
 * Close grid saga worker.
 *
 * @author Ihar Kazlouski
 * @function closeGridSagaWorker
 * @category Sagas
 * @subcategory Grids
 * @return {Generator<PutEffect>} action data.
 */
function* closeGridSagaWorker (
  action: PayloadAction<GridClosePayload>,
): Generator<PutEffect> {
  yield put(
    actions.gridClose({
      gridId: action.payload.gridId,
    }),
  );
}

/**
 * Close grid saga watcher.
 *
 * @author Ihar Kazlouski
 * @function closeGridSagaWatcher
 * @category Sagas
 * @subcategory Grids
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* closeGridSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(CLOSE_GRID_REQUEST, closeGridSagaWorker)]);
}

/**
 * Close all grids saga worker.
 *
 * @author Ihar Kazlouski
 * @function closeAllGridsSagaWorker
 * @category Sagas
 * @subcategory Grids
 * @return {Generator<PutEffect>} action data.
 */
function* closeAllGridsSagaWorker (): Generator<PutEffect> {
  yield put(actions.closeAllGrids());
}

/**
 * Close all grids saga watcher.
 *
 * @author Ihar Kazlouski
 * @function closeAllGridsSagaWatcher
 * @category Sagas
 * @subcategory Grids
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* closeAllGridsSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(CLOSE_ALL_GRIDS_REQUEST, closeAllGridsSagaWorker)]);
}

/**
 * Grid api saga worker.
 *
 * @author Ihar Kazlouski
 * @function gridApiSagaWorker
 * @category Sagas
 * @subcategory Grids
 * @return {Generator<PutEffect | CallEffect>} action data.
 */
function* gridApiSagaWorker (
  action: PayloadAction<GridApiFetchPayload<unknown, unknown>>,
): Generator<PutEffect | CallEffect> {
  try {
    yield put(
      actions.gridInit<unknown>({
        gridId:    action.payload.gridId,
        items:     null,
        isError:   false,
        isLoading: true,
      }),
    );

    const response = yield call(action.payload.api);
    const responseWithData = response as { data: unknown[] };
    let items;

    if (responseWithData?.data) {
      if (action.payload.transformResponse) {
        items = action.payload.transformResponse(responseWithData?.data);
      } else {
        items = (response as { data: unknown[] })?.data;
      }
    } else {
      if (action.payload.transformResponse) {
        items = action.payload.transformResponse(response);
      } else {
        items = response;
      }
    }

    yield put(
      actions.gridApiSuccess({
        items,
        gridId:    action.payload.gridId,
        isLoading: false,
        isError:   false,
      }),
    );

    yield put({
      type:    `${API_GRID_FETCH}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: items,
    });
  } catch (error) {
    yield put(
      actions.gridApiFailure({
        items:     null,
        gridId:    action.payload.gridId,
        isLoading: false,
        isError:   true,
      }),
    );

    yield put({
      type:    `${API_GRID_FETCH}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload: error as ApiError,
    });
  }
}

/**
 * Grid api saga watcher.
 *
 * @author Ihar Kazlouski
 * @function gridApiSagaWatcher
 * @category Sagas
 * @subcategory Grids
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* gridApiSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeEvery(API_GRID_FETCH, gridApiSagaWorker)]);
}

/**
 * Grid update api saga worker.
 *
 * @author Ihar Kazlouski
 * @function gridUpdateApiSagaWorker
 * @category Sagas
 * @subcategory Grids
 * @return {Generator<PutEffect | CallEffect>} action data.
 */
function* gridUpdateApiSagaWorker (
  action: PayloadAction<GridApiFetchPayload<unknown, unknown>>,
): Generator<PutEffect | CallEffect> {
  try {
    const response = yield call(action.payload.api);
    const responseWithData = response as { data: unknown[] };
    let items;

    if (responseWithData?.data) {
      if (action.payload.transformResponse) {
        items = action.payload.transformResponse(responseWithData?.data);
      } else {
        items = (response as { data: unknown[] })?.data;
      }
    } else {
      if (action.payload.transformResponse) {
        items = action.payload.transformResponse(response);
      } else {
        items = response;
      }
    }

    yield put(
      actions.gridApiSuccess({
        items,
        gridId:    action.payload.gridId,
        isLoading: false,
        isError:   false,
      }),
    );

    yield put({
      type:    `${API_GRID_UPDATE_FETCH}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: items,
    });
  } catch (error) {
    yield put(
      actions.gridApiFailure({
        items:     null,
        gridId:    action.payload.gridId,
        isLoading: false,
        isError:   true,
      }),
    );

    yield put({
      type:    `${API_GRID_UPDATE_FETCH}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload: error as ApiError,
    });
  }
}

/**
 * Grid update api saga watcher.
 *
 * @author Ihar Kazlouski
 * @function gridUpdateApiSagaWatcher
 * @category Sagas
 * @subcategory Grids
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* gridUpdateApiSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeEvery(API_GRID_UPDATE_FETCH, gridUpdateApiSagaWorker)]);
}

/**
 * Grids saga.
 *
 * @author Ihar Kazlouski
 * @function gridsSaga
 * @category Sagas
 * @subcategory Modals
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* gridsSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(gridInitSagaWatcher),
    fork(updateGridSagaWatcher),
    fork(closeGridSagaWatcher),
    fork(closeAllGridsSagaWatcher),
    fork(gridApiSagaWatcher),
    fork(gridUpdateApiSagaWatcher),
  ]);
}
