import { FC } from 'react';
import { Field } from 'react-final-form';

import { useTheme } from '@mui/material';

import { FormFieldProps } from './form-field.types';

/**
 * FormField component.
 *
 * @author Ihar Kazlouski
 * @function FormField
 * @category components
 * @return {JSX.Element} textfield form component.
 */
const FormField: FC<FormFieldProps> = ({
  name,
  component,
  type,
  parse,
  format,
  formatOnBlur,
  value,
  ...restProps
}) => {
  const FieldComponent = component;
  const theme = useTheme();

  return (
    <Field
      name={name}
      type={type}
      parse={parse}
      format={format}
      value={value}
      formatOnBlur={formatOnBlur}
      render={({ input, meta: { error, touched } }): JSX.Element => {
        const errorField = Boolean(touched) && Boolean(error);
        const handlers: ('onBlur' | 'onFocus' | 'onChange')[] = [
          'onBlur',
          'onFocus',
          'onChange',
        ];
        const preparedInput = { ...input, value: input.value || '' };

        for (const handler of handlers) {
          if (handler && restProps && restProps[handler]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            preparedInput[handler] = (e: any): void => {
              input[handler](e);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              restProps[handler](e);
            };
          }
        }

        if (type === 'checkbox' || type === 'radio') {
          return (
            <FieldComponent
              data-testid={`${name}-field`}
              type={type}
              {...restProps}
              {...preparedInput}
              theme={theme}
            />
          );
        } else {
          return (
            <FieldComponent
              data-testid={`${name}-field`}
              type={type}
              {...restProps}
              {...preparedInput}
              helperText={errorField && (error as string)}
              theme={theme}
              error={errorField}
            />
          );
        }
      }}
    />
  );
};

export { FormField };
