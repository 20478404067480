export enum Modals {
  DownloadKeys = 'DownloadKeys',
  UploadKeys = 'UploadKeys',
  UploadWallet = 'UploadWallet',
  DownloadKeysNotification = 'DownloadKeysNotification',
  Error = 'Error',
  DeleteWallet = 'DeleteWallet',
  CreatedWallet = 'CreatedWallet',
  AddressCreated = 'AddressCreated',
  TooMuchWallets = 'TooMuchWallets',
  DeleteWalletAddress = 'DeleteWalletAddress',
  UpdatedWallet = 'UpdatedWallet',
  DownloadIrohaKey = 'DownloadIrohaKey',
  FailedIrohaAccountCreation = 'FailedIrohaAccountCreation',
  IsWalletBalanceActual = 'IsWalletBalanceActual',
  Transaction = 'Transaction',
  AddMoney = 'AddMoney',
  Password = 'Password',
  PrivateKey = 'PrivateKey',
  DownloadedPrivateKeys = 'DownloadedPrivateKeys',
  InvalidSignature = 'InvalidSignature',
  WithdrawQc = 'WithdrawQc',
  WithdrawTransaction = 'WithdrawTransaction',
  SuccessfulWithdraw = 'SuccessfulWithdraw',
  SuccessfulTransfer = 'SuccessfulTransfer',
  TransferToAnotherUser = 'TransferToAnotherUser',
  TransferToAnotherUserTransaction = 'TransferToAnotherUserTransaction',
}
