import { createSelector } from 'reselect';

import { AppState } from '@store/root.reducer';

import { Theme } from '@enums';

/**
 * Get theme name.
 *
 * @author Yulian Kavaliou
 * @function getTheme
 * @category Selectors
 * @subcategory Theme
 * @param {AppState} state state.
 * @return {Theme} theme.
 */
const getTheme = (state: AppState): Theme => state.theme;

export const getThemeSelector = createSelector(
  getTheme,
  (theme: Theme) => theme,
);
