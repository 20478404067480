import { AxiosResponse } from 'axios';

import { dispatch as storeDispatch } from '@store/index';
import {
  actions,
  GetIrohaTransferToAnotherAccountParamsRequestPayload,
  GetIrohaWithdrawTransactionParamsRequestPayload,
  TransferIrohaTransactionRequestPayload,
  TransferIrohaTransactionToAnotherAccountRequestPayload,
  WithdrawIrohaTransactionRequestPayload,
} from '@store/iroha';

import { FeaturesService } from '@services';

import { Feature } from '@enums';
import {
  ApiError,
  AsyncDispatch,
  IrohaAccount,
  IrohaTransferToAnotherAccountTransactionParams,
  IrohaTransferTransactionToAnotherAccountResult,
  IrohaWitdrawQcTransactionParams,
  IrohaWithdrawTransactionResult,
  QuantumBalance,
} from '@types';

import { ApiService } from '../api';

/**
 * Iroha service.
 *
 * @author Ihar Kazlouski
 * @class IrohaServiceClass
 * @category Services
 */
class IrohaServiceClass {
  /**
   * Get iroha account api.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<IrohaAccount>>} get iroha account promise.
   */
  async apiIrohaAccountGet (): Promise<ApiError | AxiosResponse<IrohaAccount>> {
    return ApiService.apiGet(
      '/qc/account',
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Get iroha account.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<Record<string, unknown>>>} get iroha account promise.
   */
  async getIrohaAccount (): Promise<ApiError | IrohaAccount> {
    const dispatch: AsyncDispatch<IrohaAccount | ApiError> = storeDispatch;

    return dispatch(actions.getIrohaAccountRequest());
  }

  /**
   * Transfer transaction in iroha api.
   *
   * @author Ihar Kazlouski
   * @param {TransferIrohaTransactionRequestPayload} payload request payload.
   * @return {Promise<ApiError | AxiosResponse<IrohaAccount>>} transfer transaction in iroha promise.
   */
  async apiTransferIrohaTransactionPost (
    payload: TransferIrohaTransactionRequestPayload,
  ): Promise<ApiError | AxiosResponse<Record<string, unknown>>> {
    return ApiService.apiPost(
      '/qc/transaction/user-btc-to-bank',
      { ...payload },
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Transfer transaction in iroha.
   *
   * @author Ihar Kazlouski
   * @param {TransferIrohaTransactionRequestPayload} payload request payload.
   * @return {Promise<ApiError | AxiosResponse<Record<string, unknown>>>} transfer transaction in iroha promise.
   */
  async transferIrohaTransaction (
    payload: TransferIrohaTransactionRequestPayload,
  ): Promise<ApiError | Record<string, unknown>> {
    const dispatch: AsyncDispatch<Record<string, unknown> | ApiError> =
      storeDispatch;

    return dispatch(actions.transferIrohaTransactionRequest({ ...payload }));
  }

  /**
   * Get iroha balance api.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<QuantumBalance[]>>} get iroha balance promise.
   */
  async apiIrohaBalanceGet (): Promise<
  ApiError | AxiosResponse<QuantumBalance[]>
  > {
    return ApiService.apiGet(
      '/qc/account/balance',
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Get iroha qc transaction parameters api.
   *
   * @author Ihar Kazlouski
   * @param {GetIrohaWithdrawTransactionParamsRequestPayload} payload payload.
   * @return {Promise<ApiError | AxiosResponse<IrohaWitdrawQcTransactionParams>>} get iroha transaction params promise.
   */
  async apiIrohaWitdrawQcParamsPost (
    payload: GetIrohaWithdrawTransactionParamsRequestPayload,
  ): Promise<ApiError | AxiosResponse<IrohaWitdrawQcTransactionParams>> {
    return ApiService.apiPost(
      '/qc/transaction/withdraw-transaction-parameters',
      { ...payload },
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Get iroha qc transaction parameters.
   *
   * @author Ihar Kazlouski
   * @param {GetIrohaWithdrawTransactionParamsRequestPayload} payload payload.
   * @return {Promise<ApiError | IrohaWitdrawQcTransactionParams>>} get iroha transaction params promise.
   */
  async getIrohaWitdrawQcParams (
    payload: GetIrohaWithdrawTransactionParamsRequestPayload,
  ): Promise<ApiError | IrohaWitdrawQcTransactionParams> {
    const dispatch: AsyncDispatch<IrohaWitdrawQcTransactionParams | ApiError> =
      storeDispatch;

    return dispatch(
      actions.getIrohaWithdrawTransactionParamsRequest({ ...payload }),
    );
  }

  /**
   * Withdraw transaction in iroha api.
   *
   * @author Ihar Kazlouski
   * @param {WithdrawIrohaTransactionRequestPayload} payload request payload.
   * @return {Promise<ApiError | AxiosResponse<Record<string, unknown>>} withdraw transaction in iroha promise.
   */
  async apiWithdrawIrohaTransactionPost (
    payload: WithdrawIrohaTransactionRequestPayload,
  ): Promise<ApiError | AxiosResponse<Record<string, unknown>>> {
    return ApiService.apiPost(
      '/qc/transaction/withdraw',
      { ...payload },
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Withdraw transaction in iroha.
   *
   * @author Ihar Kazlouski
   * @param {WithdrawIrohaTransactionRequestPayload} payload request payload.
   * @return {Promise<ApiError | AxiosResponse<IrohaWithdrawTransactionResult>>} withdraw transaction in iroha promise.
   */
  async withdrawIrohaTransaction (
    payload: WithdrawIrohaTransactionRequestPayload,
  ): Promise<ApiError | IrohaWithdrawTransactionResult> {
    const dispatch: AsyncDispatch<IrohaWithdrawTransactionResult | ApiError> =
      storeDispatch;

    return dispatch(actions.withdrawIrohaTransactionRequest({ ...payload }));
  }

  /**
   * Get iroha transfer to another account parameters api.
   *
   * @author Ihar Kazlouski
   * @param {GetIrohaTransferToAnotherAccountParamsRequestPayload} payload payload.
   * @return {Promise<ApiError | AxiosResponse<IrohaWitdrawQcTransactionParams>>} get iroha transfer to another account promise.
   */
  async apiIrohaTransferToAnotherAccountParamsPost (
    payload: GetIrohaTransferToAnotherAccountParamsRequestPayload,
  ): Promise<
    ApiError | AxiosResponse<IrohaTransferToAnotherAccountTransactionParams>
    > {
    return ApiService.apiPost(
      '/qc/transaction/transfer-transaction-parameters',
      { ...payload },
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Get iroha transfer to another account parameters.
   *
   * @author Ihar Kazlouski
   * @param {GetIrohaTransferToAnotherAccountParamsRequestPayload} payload payload.
   * @return {Promise<ApiError | IrohaTransferToAnotherAccountTransactionParams>>} get iroha transaction params promise.
   */
  async getTransferToAnotherAccountParams (
    payload: GetIrohaTransferToAnotherAccountParamsRequestPayload,
  ): Promise<ApiError | IrohaTransferToAnotherAccountTransactionParams> {
    const dispatch: AsyncDispatch<
    IrohaTransferToAnotherAccountTransactionParams | ApiError
    > = storeDispatch;

    return dispatch(
      actions.getIrohaTransferToAnotherAccountParamsRequest({ ...payload }),
    );
  }

  /**
   * Transfer transaction to another account in iroha api.
   *
   * @author Ihar Kazlouski
   * @param {TransferIrohaTransactionToAnotherAccountRequestPayload} payload request payload.
   * @return {Promise<ApiError | AxiosResponse<IrohaTransferTransactionToAnotherAccountResult>>} transfer transaction to another account in iroha promise.
   */
  async apiTransferIrohaTransactionToAnotherAccountPost (
    payload: TransferIrohaTransactionToAnotherAccountRequestPayload,
  ): Promise<ApiError | AxiosResponse<IrohaTransferTransactionToAnotherAccountResult>> {
    return ApiService.apiPost(
      '/qc/transaction/transfer',
      { ...payload },
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Transfer transaction to another account in iroha.
   *
   * @author Ihar Kazlouski
   * @param {TransferIrohaTransactionToAnotherAccountRequestPayload} payload request payload.
   * @return {Promise<ApiError | AxiosResponse<IrohaTransferTransactionToAnotherAccountResult>>} transfer transaction to another account in iroha promise.
   */
  async transferIrohaTransactionToAnotherAccount (
    payload: TransferIrohaTransactionToAnotherAccountRequestPayload,
  ): Promise<ApiError | Record<string, unknown>> {
    const dispatch: AsyncDispatch<IrohaTransferTransactionToAnotherAccountResult | ApiError> =
      storeDispatch;

    return dispatch(
      actions.transferIrohaTransactionToAnotherAccountRequest({ ...payload }),
    );
  }
}

const IrohaService = new IrohaServiceClass();

export { IrohaService };
