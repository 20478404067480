import { FC } from 'react';

import {
  AddMoneyModal,
  AddressCreatedModal,
  CreatedWalletModal,
  DeleteWalletAddressModal,
  DeleteWalletModal,
  DownloadedPrivateKeysModal,
  DownloadIrohaKeyModal,
  DownloadKeysModal,
  DownloadKeysNotification,
  ErrorModal,
  FailedIrohaAccountCreation,
  InvalidSignatureModal,
  IsWalletBalanceActualModal,
  PasswordModal,
  PrivateKeyModal, SuccessfulTransferModal,
  SuccessfulWithdrawModal,
  TooMuchWalletsModal,
  TransactionModal,
  TransferToAnotherUserModal,
  TransferToAnotherUserTransactionModal,
  UploadKeysModal,
  UploadWalletModal,
  WalletUpdatedModal,
  WithdrawQcModal,
  WithdrawTransactionModal,
} from '@modals';

import { Modals } from '@enums';

export const modalVariants: Record<string, FC> = {
  [Modals.DownloadKeys]:                     DownloadKeysModal,
  [Modals.UploadKeys]:                       UploadKeysModal,
  [Modals.DownloadKeysNotification]:         DownloadKeysNotification,
  [Modals.Error]:                            ErrorModal,
  [Modals.DeleteWallet]:                     DeleteWalletModal,
  [Modals.CreatedWallet]:                    CreatedWalletModal,
  [Modals.TooMuchWallets]:                   TooMuchWalletsModal,
  [Modals.DeleteWalletAddress]:              DeleteWalletAddressModal,
  [Modals.UpdatedWallet]:                    WalletUpdatedModal,
  [Modals.DownloadIrohaKey]:                 DownloadIrohaKeyModal,
  [Modals.FailedIrohaAccountCreation]:       FailedIrohaAccountCreation,
  [Modals.IsWalletBalanceActual]:            IsWalletBalanceActualModal,
  [Modals.Transaction]:                      TransactionModal,
  [Modals.AddMoney]:                         AddMoneyModal,
  [Modals.Password]:                         PasswordModal,
  [Modals.UploadWallet]:                     UploadWalletModal,
  [Modals.AddressCreated]:                   AddressCreatedModal,
  [Modals.PrivateKey]:                       PrivateKeyModal,
  [Modals.DownloadedPrivateKeys]:            DownloadedPrivateKeysModal,
  [Modals.InvalidSignature]:                 InvalidSignatureModal,
  [Modals.WithdrawQc]:                       WithdrawQcModal,
  [Modals.WithdrawTransaction]:              WithdrawTransactionModal,
  [Modals.SuccessfulWithdraw]:               SuccessfulWithdrawModal,
  [Modals.TransferToAnotherUser]:            TransferToAnotherUserModal,
  [Modals.TransferToAnotherUserTransaction]: TransferToAnotherUserTransactionModal,
  [Modals.SuccessfulTransfer]:               SuccessfulTransferModal,
};
