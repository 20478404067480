export const SET_IS_ITEM_IN_LOCAL_STORAGE = 'SET_IS_ITEM_IN_LOCAL_STORAGE';
export const SET_DECRYPTED_USER_DATA = 'SET_DECRYPTED_USER_DATA';
export const SEND_PHONE_REQUEST = 'SEND_PHONE_REQUEST';
export const SEND_PHONE_SUCCESS = 'SEND_PHONE_SUCCESS';
export const SEND_PHONE_FAILURE = 'SEND_PHONE_FAILURE';
export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const EMAIL_VERIFICATION_REQUEST = 'EMAIL_VERIFICATION_REQUEST';
export const EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS';
export const EMAIL_VERIFICATION_FAILURE = 'EMAIL_VERIFICATION_FAILURE';
export const EMAIL_RESEND_REQUEST = 'EMAIL_RESEND_REQUEST';
export const EMAIL_RESEND_SUCCESS = 'EMAIL_RESEND_SUCCESS';
export const EMAIL_RESEND_FAILURE = 'EMAIL_RESEND_FAILURE';
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';
export const GET_CONTAINER_FROM_SERVER_REQUEST = 'GET_CONTAINER_FROM_SERVER_REQUEST';
export const GET_CONTAINER_FROM_SERVER_SUCCESS = 'GET_CONTAINER_FROM_SERVER_SUCCESS';
export const GET_CONTAINER_FROM_SERVER_FAILURE = 'GET_CONTAINER_FROM_SERVER_FAILURE';
