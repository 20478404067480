import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { db } from '@db';

import { Box, Button, Typography } from '@mui/material';

import { getModalSelector } from '@store/modals';

import { GridsService, ModalsService, WalletService } from '@services';
import { useDeviceSize } from '@hooks';

import { Grids, Modals } from '@enums';
import { BitcoinWallets } from '@types';

import { Modal } from '@elements';

/**
 * Delete wallet address modal component.
 *
 * @author Ihar Kazlouski
 * @function DeleteWalletAddressModal
 * @category Modals
 * @return {FC} delete wallet address modal component.
 */
const DeleteWalletAddressModal: FC = () => {
  const deviceSize = useDeviceSize();
  const modal = useSelector(getModalSelector(Modals.DeleteWalletAddress));
  const { t } = useTranslation();

  /**
   * Api requests for api delete address.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const apiRequests = async (
    transformResponse: (request: BitcoinWallets) => BitcoinWallets[],
  ): Promise<void> => {
    await WalletService.deleteWalletAddress(
      (modal?.data as { addressId: string }).addressId,
    );

    await GridsService.update<BitcoinWallets[], BitcoinWallets>(
      Grids.WalletMore,
      WalletService.apiWalletGet.bind(
        WalletService,
        (modal?.data as { id: string }).id || '',
      ),
      transformResponse,
    );
  };

  /**
   * Delete wallet address acceptation.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const deleteWallet = (): void => {
    const transformResponse = (request: BitcoinWallets): BitcoinWallets[] => {
      return [request];
    };

    void db.wallets
      .where('name')
      .equals((modal?.data as { walletName: string }).walletName)
      .modify((x) => {
        x.addresses = x.addresses.filter(
          (address) =>
            address.address !== (modal?.data as { address: string }).address,
        );
      });

    void apiRequests(transformResponse);

    ModalsService.close(Modals.DeleteWalletAddress);
  };

  return (
    <Modal maxWidth='sm' fullWidth>
      <Modal.Title>
        {t('pages.walletMore.areYouSure')}{' '}
        {(modal?.data as { address: string }).address}?
      </Modal.Title>
      <Modal.Content>
        <Typography textAlign='center'>
          {t('pages.bitcoinHoldings.revertAfterDeleting')}
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Box
          pt={4}
          pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
          display='flex'
          justifyContent='space-around'
        >
          <Button variant='contained' onClick={deleteWallet}>
            {t('pages.bitcoinHoldings.delete')}
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};

export { DeleteWalletAddressModal };
