import { produce } from 'immer';

import { ApiError } from '@types';

import {
  ADD_NEW_ADDRESS_FAILURE,
  ADD_NEW_ADDRESS_REQUEST, ADD_NEW_ADDRESS_SUCCESS,
  ADD_NEW_WALLET_FAILURE,
  ADD_NEW_WALLET_REQUEST,
  ADD_NEW_WALLET_SUCCESS,
  CHECK_WALLET_NAME_FAILURE,
  CHECK_WALLET_NAME_REQUEST,
  CHECK_WALLET_NAME_SUCCESS,
  DELETE_WALLET_ADDRESS_FAILURE,
  DELETE_WALLET_ADDRESS_REQUEST,
  DELETE_WALLET_ADDRESS_SUCCESS,
  DELETE_WALLET_FAILURE,
  DELETE_WALLET_REQUEST,
  DELETE_WALLET_SUCCESS,
  GET_ADDRESS_OUTPUTS_FAILURE,
  GET_ADDRESS_OUTPUTS_REQUEST,
  GET_ADDRESS_OUTPUTS_SUCCESS,
  REFRESH_WALLET_FAILURE,
  REFRESH_WALLET_REQUEST,
  REFRESH_WALLET_SUCCESS,
} from './wallet.action-types';
import { ActionsTypes, WalletState } from './wallet.types';

const initialState: WalletState = {
  pending: false,
  errors:  [] as ApiError[],
  wallets: [],
};

/**
 * Wallet reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Wallet
 * @param {BitcoinHoldingsState} state state.
 * @param {ActionsTypes} action action.
 * @return {BitcoinHoldingsState} Wallet state.
 */
export default (state = initialState, action: ActionsTypes): WalletState => {
  switch (action.type) {
    case DELETE_WALLET_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case DELETE_WALLET_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = [];
      });
    }

    case DELETE_WALLET_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    case CHECK_WALLET_NAME_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case CHECK_WALLET_NAME_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = [];
      });
    }

    case CHECK_WALLET_NAME_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    case ADD_NEW_WALLET_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case ADD_NEW_WALLET_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.wallets.push(action.payload);
        draft.errors = [];
      });
    }

    case ADD_NEW_WALLET_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    case DELETE_WALLET_ADDRESS_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case DELETE_WALLET_ADDRESS_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = [];
      });
    }

    case DELETE_WALLET_ADDRESS_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    case REFRESH_WALLET_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case REFRESH_WALLET_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = [];
      });
    }

    case REFRESH_WALLET_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    case GET_ADDRESS_OUTPUTS_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
        draft.errors = [];
      });
    }

    case GET_ADDRESS_OUTPUTS_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
      });
    }

    case GET_ADDRESS_OUTPUTS_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    case ADD_NEW_ADDRESS_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
        draft.errors = [];
      });
    }

    case ADD_NEW_ADDRESS_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
      });
    }

    case ADD_NEW_ADDRESS_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
