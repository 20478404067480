import { createSelector } from 'reselect';

import { AppState } from '@store/root.reducer';

import { FormState, Selector } from './forms.types';

/**
 * Get forms from state.
 *
 * @author Ihar Kazlouski
 * @function getForms
 * @category Selectors
 * @subcategory Forms
 * @param {AppState} state state.
 * @return {FormState[]} modals state.
 */
const getForms = (state: AppState): FormState[] => {
  return state.forms;
};

/**
 * Get forms array.
 *
 * @author Ihar Kazlouski
 * @function getFormsSelector
 * @category Selectors
 * @subcategory Forms
 * @param {AppState} state state.
 * @return {FormState[]} modals state.
 */
export const getFormsSelector = createSelector(getForms, (forms) => forms);

/**
 * Get form.
 *
 * @author Ihar Kazlouski
 * @function getFormSelector
 * @category Selectors
 * @subcategory Forms
 * @param {AppState} state state.
 * @return {FormState | undefined} modals state.
 */
export const getFormSelector = (
  formId: FormState['formId'],
): Selector<FormState | undefined> => {
  return createSelector(getFormsSelector, (forms) => {
    return forms.find((form) => form.formId === formId);
  });
};

/**
 * Get form id.
 *
 * @author Ihar Kazlouski
 * @function getFormIdSelector
 * @category Selectors
 * @subcategory Forms
 * @param {AppState} state state.
 * @return {FormState['formId'] | undefined} modals state.
 */
export const getFormIdSelector = (
  formId: FormState['formId'],
): Selector<FormState['formId'] | undefined> => {
  return createSelector([getFormSelector(formId)], (form) => {
    return form?.formId;
  });
};

/**
 * Get form values.
 *
 * @author Ihar Kazlouski
 * @function getFormValuesSelector
 * @category Selectors
 * @subcategory Forms
 * @param {AppState} state state.
 * @return {FormState['values'] | undefined} modals state.
 */
export const getFormValuesSelector = (
  formId: FormState['formId'],
): Selector<FormState['values'] | undefined> => {
  return createSelector([getFormSelector(formId)], (form) => {
    return form?.values;
  });
};
