import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import { ConstantsUtil } from '@utils';

import {
  EMAIL_RESEND_FAILURE,
  EMAIL_RESEND_REQUEST,
  EMAIL_RESEND_SUCCESS,
  EMAIL_VERIFICATION_FAILURE,
  EMAIL_VERIFICATION_REQUEST,
  EMAIL_VERIFICATION_SUCCESS,
  GET_CONTAINER_FROM_SERVER_FAILURE,
  GET_CONTAINER_FROM_SERVER_REQUEST,
  GET_CONTAINER_FROM_SERVER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  SEND_PHONE_FAILURE,
  SEND_PHONE_REQUEST,
  SEND_PHONE_SUCCESS,
  SET_DECRYPTED_USER_DATA,
  SET_IS_ITEM_IN_LOCAL_STORAGE,
} from './auth.action-types';
import {
  DecryptedUserDataPayload,
  EmailResendFailurePayload,
  EmailResendRequestPayload,
  EmailResendSuccessPayload,
  EmailVerificationFailurePayload,
  EmailVerificationRequestPayload,
  GetContainerFromServerFailurePayload,
  GetContainerFromServerRequestPayload,
  GetContainerFromServerSuccessPayload,
  IsItemInLocalStoragePayload,
  LoginUserFailurePayload,
  LoginUserRequestPayload,
  LogoutUserFailurePayload,
  RegisterFailurePayload,
  RegisterRequestPayload,
  SendPhoneFailurePayload,
  SendPhoneRequestPayload,
  SendPhoneSuccessPayload,
} from './auth.types';

export const actions = {
  /**
   * Set is item in local storage action.
   *
   * @author Ihar Kazlouski
   * @function setIsItemInLocalStorage
   * @category Actions
   * @subcategory Auth
   * @param {IsItemInLocalStoragePayload} payload payload.
   * @return {IsItemInLocalStoragePayload} action object.
   */
  setIsItemInLocalStorage: (payload: IsItemInLocalStoragePayload) =>
    ({
      type: SET_IS_ITEM_IN_LOCAL_STORAGE,
      payload,
    } as const),

  /**
   * Set decrypted user data.
   *
   * @author Ihar Kazlouski
   * @function setDecryptedUserData
   * @category Actions
   * @subcategory Auth
   * @param {DecryptedUserDataPayload} payload payload.
   * @return {DecryptedUserDataPayload} action object.
   */
  setDecryptedUserData: (payload: DecryptedUserDataPayload) =>
    ({
      type: SET_DECRYPTED_USER_DATA,
      payload,
    } as const),

  /**
   * Send phone request action.
   *
   * @author Ihar Kazlouski
   * @function sendPhoneRequest
   * @param {SendPhoneRequestPayload} payload payload.
   * @category Actions
   * @subcategory Auth
   * @return {SendPhoneRequest} send phone request.
   */
  sendPhoneRequest: (payload: SendPhoneRequestPayload) =>
    ({
      type:              SEND_PHONE_REQUEST,
      [WAIT_FOR_ACTION]: `${SEND_PHONE_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${SEND_PHONE_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Send phone success action.
   *
   * @author Ihar Kazlouski
   * @function sendPhoneSuccess
   * @param {FetchTodoSuccessPayload} payload payload.
   * @category Actions
   * @subcategory Auth
   * @return {SendPhoneRequest} send phone success.
   */
  sendPhoneSuccess: (payload: SendPhoneSuccessPayload) =>
    ({
      type: SEND_PHONE_SUCCESS,
      payload,
    } as const),

  /**
   * Send phone failure action.
   *
   * @author Ihar Kazlouski
   * @function sendPhoneFailure
   * @param {FetchTodoFailurePayload} payload payload.
   * @category Actions
   * @subcategory Auth
   * @return {SendPhoneRequest} send phone failure.
   */
  sendPhoneFailure: (payload: SendPhoneFailurePayload) =>
    ({
      type: SEND_PHONE_FAILURE,
      payload,
    } as const),

  /**
   * User register request action.
   *
   * @author Ihar Kazlouski
   * @function registerUserRequest
   * @param {RegisterRequestPayload} payload payload.
   * @category Actions
   * @subcategory Auth
   * @return {REGISTER_USER_REQUEST} user register request.
   */
  registerUserRequest: (payload: RegisterRequestPayload) =>
    ({
      type:              REGISTER_USER_REQUEST,
      [WAIT_FOR_ACTION]: `${REGISTER_USER_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${REGISTER_USER_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * User register success action.
   *
   * @author Ihar Kazlouski
   * @function registerUserSuccess
   * @category Actions
   * @subcategory Auth
   * @return {REGISTER_USER_SUCCESS} user register success.
   */
  registerUserSuccess: () =>
    ({
      type: REGISTER_USER_SUCCESS,
    } as const),

  /**
   * User register failure action.
   *
   * @author Ihar Kazlouski
   * @function registerUserFailure
   * @param {RegisterFailurePayload} payload payload.
   * @category Actions
   * @subcategory Auth
   * @return {REGISTER_USER_FAILURE} user register failure.
   */
  registerUserFailure: (payload: RegisterFailurePayload) =>
    ({
      type: REGISTER_USER_FAILURE,
      payload,
    } as const),

  /**
   * Email verification request action.
   *
   * @author Ihar Kazlouski
   * @function emailVerificationRequest
   * @param {RegisterRequestPayload} payload payload.
   * @category Actions
   * @subcategory Auth
   * @return {EMAIL_VERIFICATION_REQUEST} email verification request.
   */
  emailVerificationRequest: (payload: EmailVerificationRequestPayload) =>
    ({
      type:              EMAIL_VERIFICATION_REQUEST,
      [WAIT_FOR_ACTION]: `${EMAIL_VERIFICATION_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${EMAIL_VERIFICATION_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Email verification success action.
   *
   * @author Ihar Kazlouski
   * @function emailVerificationSuccess
   * @category Actions
   * @subcategory Auth
   * @return {EMAIL_VERIFICATION_SUCCESS} send user register success.
   */
  emailVerificationSuccess: () =>
    ({
      type: EMAIL_VERIFICATION_SUCCESS,
    } as const),

  /**
   * Email verification failure action.
   *
   * @author Ihar Kazlouski
   * @function registerUserFailure
   * @param {EmailVerificationFailurePayload} payload payload.
   * @category Actions
   * @subcategory Auth
   * @return {EMAIL_VERIFICATION_FAILURE} email verification failure.
   */
  emailVerificationFailure: (payload: EmailVerificationFailurePayload) =>
    ({
      type: EMAIL_VERIFICATION_FAILURE,
      payload,
    } as const),

  /**
   * Email resend request action.
   *
   * @author Ihar Kazlouski
   * @function emailResendRequest
   * @param {EmailResendRequestPayload} payload payload.
   * @category Actions
   * @subcategory Auth
   * @return {EMAIL_RESEND_REQUEST} email resend request.
   */
  emailResendRequest: (payload: EmailResendRequestPayload) =>
    ({
      type:              EMAIL_RESEND_REQUEST,
      [WAIT_FOR_ACTION]: `${EMAIL_RESEND_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${EMAIL_RESEND_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Email resend success action.
   *
   * @author Ihar Kazlouski
   * @function emailResendSuccess
   * @category Actions
   * @subcategory Auth
   * @return {EMAIL_RESEND_SUCCESS} email resend success.
   */
  emailResendSuccess: (payload: EmailResendSuccessPayload) =>
    ({
      type: EMAIL_RESEND_SUCCESS,
      payload,
    } as const),

  /**
   * Email resend failure action.
   *
   * @author Ihar Kazlouski
   * @function emailResendFailure
   * @param {EmailResendFailurePayload} payload payload.
   * @category Actions
   * @subcategory Auth
   * @return {EMAIL_RESEND_FAILURE} email resend failure.
   */
  emailResendFailure: (payload: EmailResendFailurePayload) =>
    ({
      type: EMAIL_RESEND_FAILURE,
      payload,
    } as const),

  /**
   * Login user request action.
   *
   * @author Ihar Kazlouski
   * @function loginUserRequest
   * @param {LoginUserRequestPayload} payload payload.
   * @category Actions
   * @subcategory Auth
   * @return {LOGIN_USER_REQUEST} login user request.
   */
  loginUserRequest: (payload: LoginUserRequestPayload) =>
    ({
      type:              LOGIN_USER_REQUEST,
      [WAIT_FOR_ACTION]: `${LOGIN_USER_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${LOGIN_USER_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Login user success action.
   *
   * @author Ihar Kazlouski
   * @function loginUserSuccess
   * @category Actions
   * @subcategory Auth
   * @return {LOGIN_USER_SUCCESS} login user success.
   */
  loginUserSuccess: () =>
    ({
      type: LOGIN_USER_SUCCESS,
    } as const),

  /**
   * Login user failure action.
   *
   * @author Ihar Kazlouski
   * @function loginUserFailure
   * @param {LoginUserFailurePayload} payload payload.
   * @category Actions
   * @subcategory Auth
   * @return {EMAIL_RESEND_FAILURE} login user failure.
   */
  loginUserFailure: (payload: LoginUserFailurePayload) =>
    ({
      type: LOGIN_USER_FAILURE,
      payload,
    } as const),

  /**
   * Logout user request action.
   *
   * @author Ihar Kazlouski
   * @function logoutUserRequest
   * @category Actions
   * @subcategory Auth
   * @return {LOGOUT_USER_REQUEST} logout user request.
   */
  logoutUserRequest: () =>
    ({
      type:              LOGOUT_USER_REQUEST,
      [WAIT_FOR_ACTION]: `${LOGOUT_USER_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${LOGOUT_USER_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
    } as const),

  /**
   * Logout user success action.
   *
   * @author Ihar Kazlouski
   * @function logoutUserSuccess
   * @category Actions
   * @subcategory Auth
   * @return {LOGOUT_USER_SUCCESS} logout user success.
   */
  logoutUserSuccess: () =>
    ({
      type: LOGOUT_USER_SUCCESS,
    } as const),

  /**
   * Logout user failure action.
   *
   * @author Ihar Kazlouski
   * @function logoutUserFailure
   * @param {LoginUserFailurePayload} payload payload.
   * @category Actions
   * @subcategory Auth
   * @return {LOGIN_USER_FAILURE} logout user failure.
   */
  logoutUserFailure: (payload: LogoutUserFailurePayload) =>
    ({
      type: LOGOUT_USER_FAILURE,
      payload,
    } as const),

  /**
   * Get container from server request action.
   *
   * @author Ihar Kazlouski
   * @function getContainerFromServerRequest
   * @category Actions
   * @subcategory Auth
   * @return {GET_CONTAINER_FROM_SERVER_REQUEST} Get container from server request.
   */
  getContainerFromServerRequest: (
    payload: GetContainerFromServerRequestPayload,
  ) =>
    ({
      type:              GET_CONTAINER_FROM_SERVER_REQUEST,
      payload,
      [WAIT_FOR_ACTION]: `${GET_CONTAINER_FROM_SERVER_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${GET_CONTAINER_FROM_SERVER_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
    } as const),

  /**
   * Get container from server success action.
   *
   * @author Ihar Kazlouski
   * @function getContainerFromServerSuccess
   * @category Actions
   * @subcategory Auth
   * @return {GET_CONTAINER_FROM_SERVER_SUCCESS} Get container from server success.
   */
  getContainerFromServerSuccess: (
    payload: GetContainerFromServerSuccessPayload,
  ) =>
    ({
      type: GET_CONTAINER_FROM_SERVER_SUCCESS,
      payload,
    } as const),

  /**
   * Get container from server failure action.
   *
   * @author Ihar Kazlouski
   * @function getContainerFromServerFailure
   * @param {GetContainerFromServerFailurePayload} payload payload.
   * @category Actions
   * @subcategory Auth
   * @return {GET_CONTAINER_FROM_SERVER_FAILURE} Get container from server failure.
   */
  getContainerFromServerFailure: (
    payload: GetContainerFromServerFailurePayload,
  ) =>
    ({
      type: GET_CONTAINER_FROM_SERVER_FAILURE,
      payload,
    } as const),
};
