// source: commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var primitive_pb = require('./primitive_pb.js');
goog.object.extend(proto, primitive_pb);
goog.exportSymbol('proto.iroha.protocol.AddAssetQuantity', null, global);
goog.exportSymbol('proto.iroha.protocol.AddPeer', null, global);
goog.exportSymbol('proto.iroha.protocol.AddSignatory', null, global);
goog.exportSymbol('proto.iroha.protocol.AppendRole', null, global);
goog.exportSymbol('proto.iroha.protocol.CallEngine', null, global);
goog.exportSymbol('proto.iroha.protocol.CallEngine.EngineType', null, global);
goog.exportSymbol('proto.iroha.protocol.CallEngine.OptCalleeCase', null, global);
goog.exportSymbol('proto.iroha.protocol.Command', null, global);
goog.exportSymbol('proto.iroha.protocol.Command.CommandCase', null, global);
goog.exportSymbol('proto.iroha.protocol.CompareAndSetAccountDetail', null, global);
goog.exportSymbol('proto.iroha.protocol.CompareAndSetAccountDetail.OptOldValueCase', null, global);
goog.exportSymbol('proto.iroha.protocol.CreateAccount', null, global);
goog.exportSymbol('proto.iroha.protocol.CreateAsset', null, global);
goog.exportSymbol('proto.iroha.protocol.CreateDomain', null, global);
goog.exportSymbol('proto.iroha.protocol.CreateRole', null, global);
goog.exportSymbol('proto.iroha.protocol.DetachRole', null, global);
goog.exportSymbol('proto.iroha.protocol.GrantPermission', null, global);
goog.exportSymbol('proto.iroha.protocol.RemovePeer', null, global);
goog.exportSymbol('proto.iroha.protocol.RemoveSignatory', null, global);
goog.exportSymbol('proto.iroha.protocol.RevokePermission', null, global);
goog.exportSymbol('proto.iroha.protocol.SetAccountDetail', null, global);
goog.exportSymbol('proto.iroha.protocol.SetAccountQuorum', null, global);
goog.exportSymbol('proto.iroha.protocol.SetSettingValue', null, global);
goog.exportSymbol('proto.iroha.protocol.SubtractAssetQuantity', null, global);
goog.exportSymbol('proto.iroha.protocol.TransferAsset', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.AddAssetQuantity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.AddAssetQuantity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.AddAssetQuantity.displayName = 'proto.iroha.protocol.AddAssetQuantity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.AddPeer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.AddPeer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.AddPeer.displayName = 'proto.iroha.protocol.AddPeer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.RemovePeer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.RemovePeer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.RemovePeer.displayName = 'proto.iroha.protocol.RemovePeer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.AddSignatory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.AddSignatory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.AddSignatory.displayName = 'proto.iroha.protocol.AddSignatory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.CreateAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.CreateAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.CreateAsset.displayName = 'proto.iroha.protocol.CreateAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.CreateAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.CreateAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.CreateAccount.displayName = 'proto.iroha.protocol.CreateAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.SetAccountDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.SetAccountDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.SetAccountDetail.displayName = 'proto.iroha.protocol.SetAccountDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.CreateDomain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.CreateDomain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.CreateDomain.displayName = 'proto.iroha.protocol.CreateDomain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.RemoveSignatory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.RemoveSignatory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.RemoveSignatory.displayName = 'proto.iroha.protocol.RemoveSignatory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.SetAccountQuorum = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.SetAccountQuorum, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.SetAccountQuorum.displayName = 'proto.iroha.protocol.SetAccountQuorum';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.TransferAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.TransferAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.TransferAsset.displayName = 'proto.iroha.protocol.TransferAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.AppendRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.AppendRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.AppendRole.displayName = 'proto.iroha.protocol.AppendRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.DetachRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.DetachRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.DetachRole.displayName = 'proto.iroha.protocol.DetachRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.CreateRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iroha.protocol.CreateRole.repeatedFields_, null);
};
goog.inherits(proto.iroha.protocol.CreateRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.CreateRole.displayName = 'proto.iroha.protocol.CreateRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.GrantPermission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.GrantPermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.GrantPermission.displayName = 'proto.iroha.protocol.GrantPermission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.RevokePermission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.RevokePermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.RevokePermission.displayName = 'proto.iroha.protocol.RevokePermission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.SubtractAssetQuantity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.SubtractAssetQuantity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.SubtractAssetQuantity.displayName = 'proto.iroha.protocol.SubtractAssetQuantity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.CompareAndSetAccountDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.iroha.protocol.CompareAndSetAccountDetail.oneofGroups_);
};
goog.inherits(proto.iroha.protocol.CompareAndSetAccountDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.CompareAndSetAccountDetail.displayName = 'proto.iroha.protocol.CompareAndSetAccountDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.SetSettingValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.SetSettingValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.SetSettingValue.displayName = 'proto.iroha.protocol.SetSettingValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.CallEngine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.iroha.protocol.CallEngine.oneofGroups_);
};
goog.inherits(proto.iroha.protocol.CallEngine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.CallEngine.displayName = 'proto.iroha.protocol.CallEngine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.Command = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.iroha.protocol.Command.oneofGroups_);
};
goog.inherits(proto.iroha.protocol.Command, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.Command.displayName = 'proto.iroha.protocol.Command';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.AddAssetQuantity.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.AddAssetQuantity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.AddAssetQuantity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AddAssetQuantity.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.AddAssetQuantity}
 */
proto.iroha.protocol.AddAssetQuantity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.AddAssetQuantity;
  return proto.iroha.protocol.AddAssetQuantity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.AddAssetQuantity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.AddAssetQuantity}
 */
proto.iroha.protocol.AddAssetQuantity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.AddAssetQuantity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.AddAssetQuantity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.AddAssetQuantity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AddAssetQuantity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string asset_id = 1;
 * @return {string}
 */
proto.iroha.protocol.AddAssetQuantity.prototype.getAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.AddAssetQuantity} returns this
 */
proto.iroha.protocol.AddAssetQuantity.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string amount = 2;
 * @return {string}
 */
proto.iroha.protocol.AddAssetQuantity.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.AddAssetQuantity} returns this
 */
proto.iroha.protocol.AddAssetQuantity.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.AddPeer.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.AddPeer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.AddPeer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AddPeer.toObject = function(includeInstance, msg) {
  var f, obj = {
    peer: (f = msg.getPeer()) && primitive_pb.Peer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.AddPeer}
 */
proto.iroha.protocol.AddPeer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.AddPeer;
  return proto.iroha.protocol.AddPeer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.AddPeer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.AddPeer}
 */
proto.iroha.protocol.AddPeer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new primitive_pb.Peer;
      reader.readMessage(value,primitive_pb.Peer.deserializeBinaryFromReader);
      msg.setPeer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.AddPeer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.AddPeer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.AddPeer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AddPeer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      primitive_pb.Peer.serializeBinaryToWriter
    );
  }
};


/**
 * optional Peer peer = 1;
 * @return {?proto.iroha.protocol.Peer}
 */
proto.iroha.protocol.AddPeer.prototype.getPeer = function() {
  return /** @type{?proto.iroha.protocol.Peer} */ (
    jspb.Message.getWrapperField(this, primitive_pb.Peer, 1));
};


/**
 * @param {?proto.iroha.protocol.Peer|undefined} value
 * @return {!proto.iroha.protocol.AddPeer} returns this
*/
proto.iroha.protocol.AddPeer.prototype.setPeer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.AddPeer} returns this
 */
proto.iroha.protocol.AddPeer.prototype.clearPeer = function() {
  return this.setPeer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.AddPeer.prototype.hasPeer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.RemovePeer.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.RemovePeer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.RemovePeer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.RemovePeer.toObject = function(includeInstance, msg) {
  var f, obj = {
    publicKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.RemovePeer}
 */
proto.iroha.protocol.RemovePeer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.RemovePeer;
  return proto.iroha.protocol.RemovePeer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.RemovePeer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.RemovePeer}
 */
proto.iroha.protocol.RemovePeer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.RemovePeer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.RemovePeer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.RemovePeer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.RemovePeer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublicKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string public_key = 1;
 * @return {string}
 */
proto.iroha.protocol.RemovePeer.prototype.getPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.RemovePeer} returns this
 */
proto.iroha.protocol.RemovePeer.prototype.setPublicKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.AddSignatory.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.AddSignatory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.AddSignatory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AddSignatory.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    publicKey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.AddSignatory}
 */
proto.iroha.protocol.AddSignatory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.AddSignatory;
  return proto.iroha.protocol.AddSignatory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.AddSignatory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.AddSignatory}
 */
proto.iroha.protocol.AddSignatory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.AddSignatory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.AddSignatory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.AddSignatory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AddSignatory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublicKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.AddSignatory.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.AddSignatory} returns this
 */
proto.iroha.protocol.AddSignatory.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string public_key = 2;
 * @return {string}
 */
proto.iroha.protocol.AddSignatory.prototype.getPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.AddSignatory} returns this
 */
proto.iroha.protocol.AddSignatory.prototype.setPublicKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.CreateAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.CreateAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.CreateAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.CreateAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    domainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    precision: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.CreateAsset}
 */
proto.iroha.protocol.CreateAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.CreateAsset;
  return proto.iroha.protocol.CreateAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.CreateAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.CreateAsset}
 */
proto.iroha.protocol.CreateAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrecision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.CreateAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.CreateAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.CreateAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.CreateAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrecision();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string asset_name = 1;
 * @return {string}
 */
proto.iroha.protocol.CreateAsset.prototype.getAssetName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CreateAsset} returns this
 */
proto.iroha.protocol.CreateAsset.prototype.setAssetName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string domain_id = 2;
 * @return {string}
 */
proto.iroha.protocol.CreateAsset.prototype.getDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CreateAsset} returns this
 */
proto.iroha.protocol.CreateAsset.prototype.setDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 precision = 3;
 * @return {number}
 */
proto.iroha.protocol.CreateAsset.prototype.getPrecision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.CreateAsset} returns this
 */
proto.iroha.protocol.CreateAsset.prototype.setPrecision = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.CreateAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.CreateAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.CreateAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.CreateAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    domainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    publicKey: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.CreateAccount}
 */
proto.iroha.protocol.CreateAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.CreateAccount;
  return proto.iroha.protocol.CreateAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.CreateAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.CreateAccount}
 */
proto.iroha.protocol.CreateAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.CreateAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.CreateAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.CreateAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.CreateAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPublicKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string account_name = 1;
 * @return {string}
 */
proto.iroha.protocol.CreateAccount.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CreateAccount} returns this
 */
proto.iroha.protocol.CreateAccount.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string domain_id = 2;
 * @return {string}
 */
proto.iroha.protocol.CreateAccount.prototype.getDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CreateAccount} returns this
 */
proto.iroha.protocol.CreateAccount.prototype.setDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string public_key = 3;
 * @return {string}
 */
proto.iroha.protocol.CreateAccount.prototype.getPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CreateAccount} returns this
 */
proto.iroha.protocol.CreateAccount.prototype.setPublicKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.SetAccountDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.SetAccountDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.SetAccountDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.SetAccountDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.SetAccountDetail}
 */
proto.iroha.protocol.SetAccountDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.SetAccountDetail;
  return proto.iroha.protocol.SetAccountDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.SetAccountDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.SetAccountDetail}
 */
proto.iroha.protocol.SetAccountDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.SetAccountDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.SetAccountDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.SetAccountDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.SetAccountDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.SetAccountDetail.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.SetAccountDetail} returns this
 */
proto.iroha.protocol.SetAccountDetail.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.iroha.protocol.SetAccountDetail.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.SetAccountDetail} returns this
 */
proto.iroha.protocol.SetAccountDetail.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.iroha.protocol.SetAccountDetail.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.SetAccountDetail} returns this
 */
proto.iroha.protocol.SetAccountDetail.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.CreateDomain.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.CreateDomain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.CreateDomain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.CreateDomain.toObject = function(includeInstance, msg) {
  var f, obj = {
    domainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    defaultRole: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.CreateDomain}
 */
proto.iroha.protocol.CreateDomain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.CreateDomain;
  return proto.iroha.protocol.CreateDomain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.CreateDomain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.CreateDomain}
 */
proto.iroha.protocol.CreateDomain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.CreateDomain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.CreateDomain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.CreateDomain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.CreateDomain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDefaultRole();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string domain_id = 1;
 * @return {string}
 */
proto.iroha.protocol.CreateDomain.prototype.getDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CreateDomain} returns this
 */
proto.iroha.protocol.CreateDomain.prototype.setDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string default_role = 2;
 * @return {string}
 */
proto.iroha.protocol.CreateDomain.prototype.getDefaultRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CreateDomain} returns this
 */
proto.iroha.protocol.CreateDomain.prototype.setDefaultRole = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.RemoveSignatory.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.RemoveSignatory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.RemoveSignatory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.RemoveSignatory.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    publicKey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.RemoveSignatory}
 */
proto.iroha.protocol.RemoveSignatory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.RemoveSignatory;
  return proto.iroha.protocol.RemoveSignatory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.RemoveSignatory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.RemoveSignatory}
 */
proto.iroha.protocol.RemoveSignatory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.RemoveSignatory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.RemoveSignatory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.RemoveSignatory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.RemoveSignatory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublicKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.RemoveSignatory.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.RemoveSignatory} returns this
 */
proto.iroha.protocol.RemoveSignatory.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string public_key = 2;
 * @return {string}
 */
proto.iroha.protocol.RemoveSignatory.prototype.getPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.RemoveSignatory} returns this
 */
proto.iroha.protocol.RemoveSignatory.prototype.setPublicKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.SetAccountQuorum.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.SetAccountQuorum.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.SetAccountQuorum} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.SetAccountQuorum.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quorum: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.SetAccountQuorum}
 */
proto.iroha.protocol.SetAccountQuorum.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.SetAccountQuorum;
  return proto.iroha.protocol.SetAccountQuorum.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.SetAccountQuorum} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.SetAccountQuorum}
 */
proto.iroha.protocol.SetAccountQuorum.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuorum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.SetAccountQuorum.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.SetAccountQuorum.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.SetAccountQuorum} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.SetAccountQuorum.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuorum();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.SetAccountQuorum.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.SetAccountQuorum} returns this
 */
proto.iroha.protocol.SetAccountQuorum.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 quorum = 2;
 * @return {number}
 */
proto.iroha.protocol.SetAccountQuorum.prototype.getQuorum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.SetAccountQuorum} returns this
 */
proto.iroha.protocol.SetAccountQuorum.prototype.setQuorum = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.TransferAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.TransferAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.TransferAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.TransferAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    srcAccountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    destAccountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assetId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.TransferAsset}
 */
proto.iroha.protocol.TransferAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.TransferAsset;
  return proto.iroha.protocol.TransferAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.TransferAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.TransferAsset}
 */
proto.iroha.protocol.TransferAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.TransferAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.TransferAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.TransferAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.TransferAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSrcAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDestAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string src_account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.TransferAsset.prototype.getSrcAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.TransferAsset} returns this
 */
proto.iroha.protocol.TransferAsset.prototype.setSrcAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dest_account_id = 2;
 * @return {string}
 */
proto.iroha.protocol.TransferAsset.prototype.getDestAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.TransferAsset} returns this
 */
proto.iroha.protocol.TransferAsset.prototype.setDestAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string asset_id = 3;
 * @return {string}
 */
proto.iroha.protocol.TransferAsset.prototype.getAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.TransferAsset} returns this
 */
proto.iroha.protocol.TransferAsset.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.iroha.protocol.TransferAsset.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.TransferAsset} returns this
 */
proto.iroha.protocol.TransferAsset.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string amount = 5;
 * @return {string}
 */
proto.iroha.protocol.TransferAsset.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.TransferAsset} returns this
 */
proto.iroha.protocol.TransferAsset.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.AppendRole.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.AppendRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.AppendRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AppendRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roleName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.AppendRole}
 */
proto.iroha.protocol.AppendRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.AppendRole;
  return proto.iroha.protocol.AppendRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.AppendRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.AppendRole}
 */
proto.iroha.protocol.AppendRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.AppendRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.AppendRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.AppendRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AppendRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoleName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.AppendRole.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.AppendRole} returns this
 */
proto.iroha.protocol.AppendRole.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string role_name = 2;
 * @return {string}
 */
proto.iroha.protocol.AppendRole.prototype.getRoleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.AppendRole} returns this
 */
proto.iroha.protocol.AppendRole.prototype.setRoleName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.DetachRole.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.DetachRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.DetachRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.DetachRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roleName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.DetachRole}
 */
proto.iroha.protocol.DetachRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.DetachRole;
  return proto.iroha.protocol.DetachRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.DetachRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.DetachRole}
 */
proto.iroha.protocol.DetachRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.DetachRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.DetachRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.DetachRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.DetachRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoleName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.DetachRole.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.DetachRole} returns this
 */
proto.iroha.protocol.DetachRole.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string role_name = 2;
 * @return {string}
 */
proto.iroha.protocol.DetachRole.prototype.getRoleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.DetachRole} returns this
 */
proto.iroha.protocol.DetachRole.prototype.setRoleName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iroha.protocol.CreateRole.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.CreateRole.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.CreateRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.CreateRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.CreateRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    roleName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.CreateRole}
 */
proto.iroha.protocol.CreateRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.CreateRole;
  return proto.iroha.protocol.CreateRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.CreateRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.CreateRole}
 */
proto.iroha.protocol.CreateRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleName(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.iroha.protocol.RolePermission>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPermissions(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.CreateRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.CreateRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.CreateRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.CreateRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoleName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional string role_name = 1;
 * @return {string}
 */
proto.iroha.protocol.CreateRole.prototype.getRoleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CreateRole} returns this
 */
proto.iroha.protocol.CreateRole.prototype.setRoleName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RolePermission permissions = 2;
 * @return {!Array<!proto.iroha.protocol.RolePermission>}
 */
proto.iroha.protocol.CreateRole.prototype.getPermissionsList = function() {
  return /** @type {!Array<!proto.iroha.protocol.RolePermission>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.iroha.protocol.RolePermission>} value
 * @return {!proto.iroha.protocol.CreateRole} returns this
 */
proto.iroha.protocol.CreateRole.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.iroha.protocol.RolePermission} value
 * @param {number=} opt_index
 * @return {!proto.iroha.protocol.CreateRole} returns this
 */
proto.iroha.protocol.CreateRole.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iroha.protocol.CreateRole} returns this
 */
proto.iroha.protocol.CreateRole.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.GrantPermission.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.GrantPermission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.GrantPermission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GrantPermission.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    permission: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.GrantPermission}
 */
proto.iroha.protocol.GrantPermission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.GrantPermission;
  return proto.iroha.protocol.GrantPermission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.GrantPermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.GrantPermission}
 */
proto.iroha.protocol.GrantPermission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {!proto.iroha.protocol.GrantablePermission} */ (reader.readEnum());
      msg.setPermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.GrantPermission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.GrantPermission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.GrantPermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GrantPermission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.GrantPermission.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.GrantPermission} returns this
 */
proto.iroha.protocol.GrantPermission.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GrantablePermission permission = 2;
 * @return {!proto.iroha.protocol.GrantablePermission}
 */
proto.iroha.protocol.GrantPermission.prototype.getPermission = function() {
  return /** @type {!proto.iroha.protocol.GrantablePermission} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.iroha.protocol.GrantablePermission} value
 * @return {!proto.iroha.protocol.GrantPermission} returns this
 */
proto.iroha.protocol.GrantPermission.prototype.setPermission = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.RevokePermission.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.RevokePermission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.RevokePermission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.RevokePermission.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    permission: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.RevokePermission}
 */
proto.iroha.protocol.RevokePermission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.RevokePermission;
  return proto.iroha.protocol.RevokePermission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.RevokePermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.RevokePermission}
 */
proto.iroha.protocol.RevokePermission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {!proto.iroha.protocol.GrantablePermission} */ (reader.readEnum());
      msg.setPermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.RevokePermission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.RevokePermission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.RevokePermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.RevokePermission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.RevokePermission.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.RevokePermission} returns this
 */
proto.iroha.protocol.RevokePermission.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GrantablePermission permission = 2;
 * @return {!proto.iroha.protocol.GrantablePermission}
 */
proto.iroha.protocol.RevokePermission.prototype.getPermission = function() {
  return /** @type {!proto.iroha.protocol.GrantablePermission} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.iroha.protocol.GrantablePermission} value
 * @return {!proto.iroha.protocol.RevokePermission} returns this
 */
proto.iroha.protocol.RevokePermission.prototype.setPermission = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.SubtractAssetQuantity.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.SubtractAssetQuantity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.SubtractAssetQuantity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.SubtractAssetQuantity.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.SubtractAssetQuantity}
 */
proto.iroha.protocol.SubtractAssetQuantity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.SubtractAssetQuantity;
  return proto.iroha.protocol.SubtractAssetQuantity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.SubtractAssetQuantity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.SubtractAssetQuantity}
 */
proto.iroha.protocol.SubtractAssetQuantity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.SubtractAssetQuantity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.SubtractAssetQuantity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.SubtractAssetQuantity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.SubtractAssetQuantity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string asset_id = 1;
 * @return {string}
 */
proto.iroha.protocol.SubtractAssetQuantity.prototype.getAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.SubtractAssetQuantity} returns this
 */
proto.iroha.protocol.SubtractAssetQuantity.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string amount = 2;
 * @return {string}
 */
proto.iroha.protocol.SubtractAssetQuantity.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.SubtractAssetQuantity} returns this
 */
proto.iroha.protocol.SubtractAssetQuantity.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.iroha.protocol.CompareAndSetAccountDetail.oneofGroups_ = [[4]];

/**
 * @enum {number}
 */
proto.iroha.protocol.CompareAndSetAccountDetail.OptOldValueCase = {
  OPT_OLD_VALUE_NOT_SET: 0,
  OLD_VALUE: 4
};

/**
 * @return {proto.iroha.protocol.CompareAndSetAccountDetail.OptOldValueCase}
 */
proto.iroha.protocol.CompareAndSetAccountDetail.prototype.getOptOldValueCase = function() {
  return /** @type {proto.iroha.protocol.CompareAndSetAccountDetail.OptOldValueCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.CompareAndSetAccountDetail.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.CompareAndSetAccountDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.CompareAndSetAccountDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.CompareAndSetAccountDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.CompareAndSetAccountDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    oldValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    checkEmpty: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.CompareAndSetAccountDetail}
 */
proto.iroha.protocol.CompareAndSetAccountDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.CompareAndSetAccountDetail;
  return proto.iroha.protocol.CompareAndSetAccountDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.CompareAndSetAccountDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.CompareAndSetAccountDetail}
 */
proto.iroha.protocol.CompareAndSetAccountDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldValue(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.CompareAndSetAccountDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.CompareAndSetAccountDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.CompareAndSetAccountDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.CompareAndSetAccountDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCheckEmpty();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.CompareAndSetAccountDetail.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CompareAndSetAccountDetail} returns this
 */
proto.iroha.protocol.CompareAndSetAccountDetail.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.iroha.protocol.CompareAndSetAccountDetail.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CompareAndSetAccountDetail} returns this
 */
proto.iroha.protocol.CompareAndSetAccountDetail.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.iroha.protocol.CompareAndSetAccountDetail.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CompareAndSetAccountDetail} returns this
 */
proto.iroha.protocol.CompareAndSetAccountDetail.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string old_value = 4;
 * @return {string}
 */
proto.iroha.protocol.CompareAndSetAccountDetail.prototype.getOldValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CompareAndSetAccountDetail} returns this
 */
proto.iroha.protocol.CompareAndSetAccountDetail.prototype.setOldValue = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.iroha.protocol.CompareAndSetAccountDetail.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.CompareAndSetAccountDetail} returns this
 */
proto.iroha.protocol.CompareAndSetAccountDetail.prototype.clearOldValue = function() {
  return jspb.Message.setOneofField(this, 4, proto.iroha.protocol.CompareAndSetAccountDetail.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.CompareAndSetAccountDetail.prototype.hasOldValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool check_empty = 5;
 * @return {boolean}
 */
proto.iroha.protocol.CompareAndSetAccountDetail.prototype.getCheckEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.iroha.protocol.CompareAndSetAccountDetail} returns this
 */
proto.iroha.protocol.CompareAndSetAccountDetail.prototype.setCheckEmpty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.SetSettingValue.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.SetSettingValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.SetSettingValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.SetSettingValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.SetSettingValue}
 */
proto.iroha.protocol.SetSettingValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.SetSettingValue;
  return proto.iroha.protocol.SetSettingValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.SetSettingValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.SetSettingValue}
 */
proto.iroha.protocol.SetSettingValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.SetSettingValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.SetSettingValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.SetSettingValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.SetSettingValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.iroha.protocol.SetSettingValue.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.SetSettingValue} returns this
 */
proto.iroha.protocol.SetSettingValue.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.iroha.protocol.SetSettingValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.SetSettingValue} returns this
 */
proto.iroha.protocol.SetSettingValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.iroha.protocol.CallEngine.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.iroha.protocol.CallEngine.OptCalleeCase = {
  OPT_CALLEE_NOT_SET: 0,
  CALLEE: 3
};

/**
 * @return {proto.iroha.protocol.CallEngine.OptCalleeCase}
 */
proto.iroha.protocol.CallEngine.prototype.getOptCalleeCase = function() {
  return /** @type {proto.iroha.protocol.CallEngine.OptCalleeCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.CallEngine.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.CallEngine.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.CallEngine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.CallEngine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.CallEngine.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    caller: jspb.Message.getFieldWithDefault(msg, 2, ""),
    callee: jspb.Message.getFieldWithDefault(msg, 3, ""),
    input: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.CallEngine}
 */
proto.iroha.protocol.CallEngine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.CallEngine;
  return proto.iroha.protocol.CallEngine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.CallEngine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.CallEngine}
 */
proto.iroha.protocol.CallEngine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.iroha.protocol.CallEngine.EngineType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaller(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallee(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInput(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.CallEngine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.CallEngine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.CallEngine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.CallEngine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCaller();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInput();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.iroha.protocol.CallEngine.EngineType = {
  KSOLIDITY: 0
};

/**
 * optional EngineType type = 1;
 * @return {!proto.iroha.protocol.CallEngine.EngineType}
 */
proto.iroha.protocol.CallEngine.prototype.getType = function() {
  return /** @type {!proto.iroha.protocol.CallEngine.EngineType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.iroha.protocol.CallEngine.EngineType} value
 * @return {!proto.iroha.protocol.CallEngine} returns this
 */
proto.iroha.protocol.CallEngine.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string caller = 2;
 * @return {string}
 */
proto.iroha.protocol.CallEngine.prototype.getCaller = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CallEngine} returns this
 */
proto.iroha.protocol.CallEngine.prototype.setCaller = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string callee = 3;
 * @return {string}
 */
proto.iroha.protocol.CallEngine.prototype.getCallee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CallEngine} returns this
 */
proto.iroha.protocol.CallEngine.prototype.setCallee = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.iroha.protocol.CallEngine.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.CallEngine} returns this
 */
proto.iroha.protocol.CallEngine.prototype.clearCallee = function() {
  return jspb.Message.setOneofField(this, 3, proto.iroha.protocol.CallEngine.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.CallEngine.prototype.hasCallee = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string input = 4;
 * @return {string}
 */
proto.iroha.protocol.CallEngine.prototype.getInput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CallEngine} returns this
 */
proto.iroha.protocol.CallEngine.prototype.setInput = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.iroha.protocol.Command.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]];

/**
 * @enum {number}
 */
proto.iroha.protocol.Command.CommandCase = {
  COMMAND_NOT_SET: 0,
  ADD_ASSET_QUANTITY: 1,
  ADD_PEER: 2,
  ADD_SIGNATORY: 3,
  APPEND_ROLE: 4,
  CREATE_ACCOUNT: 5,
  CREATE_ASSET: 6,
  CREATE_DOMAIN: 7,
  CREATE_ROLE: 8,
  DETACH_ROLE: 9,
  GRANT_PERMISSION: 10,
  REMOVE_SIGNATORY: 11,
  REVOKE_PERMISSION: 12,
  SET_ACCOUNT_DETAIL: 13,
  SET_ACCOUNT_QUORUM: 14,
  SUBTRACT_ASSET_QUANTITY: 15,
  TRANSFER_ASSET: 16,
  REMOVE_PEER: 17,
  COMPARE_AND_SET_ACCOUNT_DETAIL: 18,
  SET_SETTING_VALUE: 19,
  CALL_ENGINE: 20
};

/**
 * @return {proto.iroha.protocol.Command.CommandCase}
 */
proto.iroha.protocol.Command.prototype.getCommandCase = function() {
  return /** @type {proto.iroha.protocol.Command.CommandCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.Command.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.Command.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.Command.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.Command} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Command.toObject = function(includeInstance, msg) {
  var f, obj = {
    addAssetQuantity: (f = msg.getAddAssetQuantity()) && proto.iroha.protocol.AddAssetQuantity.toObject(includeInstance, f),
    addPeer: (f = msg.getAddPeer()) && proto.iroha.protocol.AddPeer.toObject(includeInstance, f),
    addSignatory: (f = msg.getAddSignatory()) && proto.iroha.protocol.AddSignatory.toObject(includeInstance, f),
    appendRole: (f = msg.getAppendRole()) && proto.iroha.protocol.AppendRole.toObject(includeInstance, f),
    createAccount: (f = msg.getCreateAccount()) && proto.iroha.protocol.CreateAccount.toObject(includeInstance, f),
    createAsset: (f = msg.getCreateAsset()) && proto.iroha.protocol.CreateAsset.toObject(includeInstance, f),
    createDomain: (f = msg.getCreateDomain()) && proto.iroha.protocol.CreateDomain.toObject(includeInstance, f),
    createRole: (f = msg.getCreateRole()) && proto.iroha.protocol.CreateRole.toObject(includeInstance, f),
    detachRole: (f = msg.getDetachRole()) && proto.iroha.protocol.DetachRole.toObject(includeInstance, f),
    grantPermission: (f = msg.getGrantPermission()) && proto.iroha.protocol.GrantPermission.toObject(includeInstance, f),
    removeSignatory: (f = msg.getRemoveSignatory()) && proto.iroha.protocol.RemoveSignatory.toObject(includeInstance, f),
    revokePermission: (f = msg.getRevokePermission()) && proto.iroha.protocol.RevokePermission.toObject(includeInstance, f),
    setAccountDetail: (f = msg.getSetAccountDetail()) && proto.iroha.protocol.SetAccountDetail.toObject(includeInstance, f),
    setAccountQuorum: (f = msg.getSetAccountQuorum()) && proto.iroha.protocol.SetAccountQuorum.toObject(includeInstance, f),
    subtractAssetQuantity: (f = msg.getSubtractAssetQuantity()) && proto.iroha.protocol.SubtractAssetQuantity.toObject(includeInstance, f),
    transferAsset: (f = msg.getTransferAsset()) && proto.iroha.protocol.TransferAsset.toObject(includeInstance, f),
    removePeer: (f = msg.getRemovePeer()) && proto.iroha.protocol.RemovePeer.toObject(includeInstance, f),
    compareAndSetAccountDetail: (f = msg.getCompareAndSetAccountDetail()) && proto.iroha.protocol.CompareAndSetAccountDetail.toObject(includeInstance, f),
    setSettingValue: (f = msg.getSetSettingValue()) && proto.iroha.protocol.SetSettingValue.toObject(includeInstance, f),
    callEngine: (f = msg.getCallEngine()) && proto.iroha.protocol.CallEngine.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.Command}
 */
proto.iroha.protocol.Command.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.Command;
  return proto.iroha.protocol.Command.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.Command} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.Command}
 */
proto.iroha.protocol.Command.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.iroha.protocol.AddAssetQuantity;
      reader.readMessage(value,proto.iroha.protocol.AddAssetQuantity.deserializeBinaryFromReader);
      msg.setAddAssetQuantity(value);
      break;
    case 2:
      var value = new proto.iroha.protocol.AddPeer;
      reader.readMessage(value,proto.iroha.protocol.AddPeer.deserializeBinaryFromReader);
      msg.setAddPeer(value);
      break;
    case 3:
      var value = new proto.iroha.protocol.AddSignatory;
      reader.readMessage(value,proto.iroha.protocol.AddSignatory.deserializeBinaryFromReader);
      msg.setAddSignatory(value);
      break;
    case 4:
      var value = new proto.iroha.protocol.AppendRole;
      reader.readMessage(value,proto.iroha.protocol.AppendRole.deserializeBinaryFromReader);
      msg.setAppendRole(value);
      break;
    case 5:
      var value = new proto.iroha.protocol.CreateAccount;
      reader.readMessage(value,proto.iroha.protocol.CreateAccount.deserializeBinaryFromReader);
      msg.setCreateAccount(value);
      break;
    case 6:
      var value = new proto.iroha.protocol.CreateAsset;
      reader.readMessage(value,proto.iroha.protocol.CreateAsset.deserializeBinaryFromReader);
      msg.setCreateAsset(value);
      break;
    case 7:
      var value = new proto.iroha.protocol.CreateDomain;
      reader.readMessage(value,proto.iroha.protocol.CreateDomain.deserializeBinaryFromReader);
      msg.setCreateDomain(value);
      break;
    case 8:
      var value = new proto.iroha.protocol.CreateRole;
      reader.readMessage(value,proto.iroha.protocol.CreateRole.deserializeBinaryFromReader);
      msg.setCreateRole(value);
      break;
    case 9:
      var value = new proto.iroha.protocol.DetachRole;
      reader.readMessage(value,proto.iroha.protocol.DetachRole.deserializeBinaryFromReader);
      msg.setDetachRole(value);
      break;
    case 10:
      var value = new proto.iroha.protocol.GrantPermission;
      reader.readMessage(value,proto.iroha.protocol.GrantPermission.deserializeBinaryFromReader);
      msg.setGrantPermission(value);
      break;
    case 11:
      var value = new proto.iroha.protocol.RemoveSignatory;
      reader.readMessage(value,proto.iroha.protocol.RemoveSignatory.deserializeBinaryFromReader);
      msg.setRemoveSignatory(value);
      break;
    case 12:
      var value = new proto.iroha.protocol.RevokePermission;
      reader.readMessage(value,proto.iroha.protocol.RevokePermission.deserializeBinaryFromReader);
      msg.setRevokePermission(value);
      break;
    case 13:
      var value = new proto.iroha.protocol.SetAccountDetail;
      reader.readMessage(value,proto.iroha.protocol.SetAccountDetail.deserializeBinaryFromReader);
      msg.setSetAccountDetail(value);
      break;
    case 14:
      var value = new proto.iroha.protocol.SetAccountQuorum;
      reader.readMessage(value,proto.iroha.protocol.SetAccountQuorum.deserializeBinaryFromReader);
      msg.setSetAccountQuorum(value);
      break;
    case 15:
      var value = new proto.iroha.protocol.SubtractAssetQuantity;
      reader.readMessage(value,proto.iroha.protocol.SubtractAssetQuantity.deserializeBinaryFromReader);
      msg.setSubtractAssetQuantity(value);
      break;
    case 16:
      var value = new proto.iroha.protocol.TransferAsset;
      reader.readMessage(value,proto.iroha.protocol.TransferAsset.deserializeBinaryFromReader);
      msg.setTransferAsset(value);
      break;
    case 17:
      var value = new proto.iroha.protocol.RemovePeer;
      reader.readMessage(value,proto.iroha.protocol.RemovePeer.deserializeBinaryFromReader);
      msg.setRemovePeer(value);
      break;
    case 18:
      var value = new proto.iroha.protocol.CompareAndSetAccountDetail;
      reader.readMessage(value,proto.iroha.protocol.CompareAndSetAccountDetail.deserializeBinaryFromReader);
      msg.setCompareAndSetAccountDetail(value);
      break;
    case 19:
      var value = new proto.iroha.protocol.SetSettingValue;
      reader.readMessage(value,proto.iroha.protocol.SetSettingValue.deserializeBinaryFromReader);
      msg.setSetSettingValue(value);
      break;
    case 20:
      var value = new proto.iroha.protocol.CallEngine;
      reader.readMessage(value,proto.iroha.protocol.CallEngine.deserializeBinaryFromReader);
      msg.setCallEngine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.Command.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.Command.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.Command} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Command.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddAssetQuantity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.iroha.protocol.AddAssetQuantity.serializeBinaryToWriter
    );
  }
  f = message.getAddPeer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.iroha.protocol.AddPeer.serializeBinaryToWriter
    );
  }
  f = message.getAddSignatory();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.iroha.protocol.AddSignatory.serializeBinaryToWriter
    );
  }
  f = message.getAppendRole();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.iroha.protocol.AppendRole.serializeBinaryToWriter
    );
  }
  f = message.getCreateAccount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.iroha.protocol.CreateAccount.serializeBinaryToWriter
    );
  }
  f = message.getCreateAsset();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.iroha.protocol.CreateAsset.serializeBinaryToWriter
    );
  }
  f = message.getCreateDomain();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.iroha.protocol.CreateDomain.serializeBinaryToWriter
    );
  }
  f = message.getCreateRole();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.iroha.protocol.CreateRole.serializeBinaryToWriter
    );
  }
  f = message.getDetachRole();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.iroha.protocol.DetachRole.serializeBinaryToWriter
    );
  }
  f = message.getGrantPermission();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.iroha.protocol.GrantPermission.serializeBinaryToWriter
    );
  }
  f = message.getRemoveSignatory();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.iroha.protocol.RemoveSignatory.serializeBinaryToWriter
    );
  }
  f = message.getRevokePermission();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.iroha.protocol.RevokePermission.serializeBinaryToWriter
    );
  }
  f = message.getSetAccountDetail();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.iroha.protocol.SetAccountDetail.serializeBinaryToWriter
    );
  }
  f = message.getSetAccountQuorum();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.iroha.protocol.SetAccountQuorum.serializeBinaryToWriter
    );
  }
  f = message.getSubtractAssetQuantity();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.iroha.protocol.SubtractAssetQuantity.serializeBinaryToWriter
    );
  }
  f = message.getTransferAsset();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.iroha.protocol.TransferAsset.serializeBinaryToWriter
    );
  }
  f = message.getRemovePeer();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.iroha.protocol.RemovePeer.serializeBinaryToWriter
    );
  }
  f = message.getCompareAndSetAccountDetail();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.iroha.protocol.CompareAndSetAccountDetail.serializeBinaryToWriter
    );
  }
  f = message.getSetSettingValue();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.iroha.protocol.SetSettingValue.serializeBinaryToWriter
    );
  }
  f = message.getCallEngine();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.iroha.protocol.CallEngine.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddAssetQuantity add_asset_quantity = 1;
 * @return {?proto.iroha.protocol.AddAssetQuantity}
 */
proto.iroha.protocol.Command.prototype.getAddAssetQuantity = function() {
  return /** @type{?proto.iroha.protocol.AddAssetQuantity} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.AddAssetQuantity, 1));
};


/**
 * @param {?proto.iroha.protocol.AddAssetQuantity|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setAddAssetQuantity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearAddAssetQuantity = function() {
  return this.setAddAssetQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasAddAssetQuantity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AddPeer add_peer = 2;
 * @return {?proto.iroha.protocol.AddPeer}
 */
proto.iroha.protocol.Command.prototype.getAddPeer = function() {
  return /** @type{?proto.iroha.protocol.AddPeer} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.AddPeer, 2));
};


/**
 * @param {?proto.iroha.protocol.AddPeer|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setAddPeer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearAddPeer = function() {
  return this.setAddPeer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasAddPeer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AddSignatory add_signatory = 3;
 * @return {?proto.iroha.protocol.AddSignatory}
 */
proto.iroha.protocol.Command.prototype.getAddSignatory = function() {
  return /** @type{?proto.iroha.protocol.AddSignatory} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.AddSignatory, 3));
};


/**
 * @param {?proto.iroha.protocol.AddSignatory|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setAddSignatory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearAddSignatory = function() {
  return this.setAddSignatory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasAddSignatory = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AppendRole append_role = 4;
 * @return {?proto.iroha.protocol.AppendRole}
 */
proto.iroha.protocol.Command.prototype.getAppendRole = function() {
  return /** @type{?proto.iroha.protocol.AppendRole} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.AppendRole, 4));
};


/**
 * @param {?proto.iroha.protocol.AppendRole|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setAppendRole = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearAppendRole = function() {
  return this.setAppendRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasAppendRole = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CreateAccount create_account = 5;
 * @return {?proto.iroha.protocol.CreateAccount}
 */
proto.iroha.protocol.Command.prototype.getCreateAccount = function() {
  return /** @type{?proto.iroha.protocol.CreateAccount} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.CreateAccount, 5));
};


/**
 * @param {?proto.iroha.protocol.CreateAccount|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setCreateAccount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearCreateAccount = function() {
  return this.setCreateAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasCreateAccount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CreateAsset create_asset = 6;
 * @return {?proto.iroha.protocol.CreateAsset}
 */
proto.iroha.protocol.Command.prototype.getCreateAsset = function() {
  return /** @type{?proto.iroha.protocol.CreateAsset} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.CreateAsset, 6));
};


/**
 * @param {?proto.iroha.protocol.CreateAsset|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setCreateAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearCreateAsset = function() {
  return this.setCreateAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasCreateAsset = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional CreateDomain create_domain = 7;
 * @return {?proto.iroha.protocol.CreateDomain}
 */
proto.iroha.protocol.Command.prototype.getCreateDomain = function() {
  return /** @type{?proto.iroha.protocol.CreateDomain} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.CreateDomain, 7));
};


/**
 * @param {?proto.iroha.protocol.CreateDomain|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setCreateDomain = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearCreateDomain = function() {
  return this.setCreateDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasCreateDomain = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional CreateRole create_role = 8;
 * @return {?proto.iroha.protocol.CreateRole}
 */
proto.iroha.protocol.Command.prototype.getCreateRole = function() {
  return /** @type{?proto.iroha.protocol.CreateRole} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.CreateRole, 8));
};


/**
 * @param {?proto.iroha.protocol.CreateRole|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setCreateRole = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearCreateRole = function() {
  return this.setCreateRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasCreateRole = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional DetachRole detach_role = 9;
 * @return {?proto.iroha.protocol.DetachRole}
 */
proto.iroha.protocol.Command.prototype.getDetachRole = function() {
  return /** @type{?proto.iroha.protocol.DetachRole} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.DetachRole, 9));
};


/**
 * @param {?proto.iroha.protocol.DetachRole|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setDetachRole = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearDetachRole = function() {
  return this.setDetachRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasDetachRole = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional GrantPermission grant_permission = 10;
 * @return {?proto.iroha.protocol.GrantPermission}
 */
proto.iroha.protocol.Command.prototype.getGrantPermission = function() {
  return /** @type{?proto.iroha.protocol.GrantPermission} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.GrantPermission, 10));
};


/**
 * @param {?proto.iroha.protocol.GrantPermission|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setGrantPermission = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearGrantPermission = function() {
  return this.setGrantPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasGrantPermission = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional RemoveSignatory remove_signatory = 11;
 * @return {?proto.iroha.protocol.RemoveSignatory}
 */
proto.iroha.protocol.Command.prototype.getRemoveSignatory = function() {
  return /** @type{?proto.iroha.protocol.RemoveSignatory} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.RemoveSignatory, 11));
};


/**
 * @param {?proto.iroha.protocol.RemoveSignatory|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setRemoveSignatory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearRemoveSignatory = function() {
  return this.setRemoveSignatory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasRemoveSignatory = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional RevokePermission revoke_permission = 12;
 * @return {?proto.iroha.protocol.RevokePermission}
 */
proto.iroha.protocol.Command.prototype.getRevokePermission = function() {
  return /** @type{?proto.iroha.protocol.RevokePermission} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.RevokePermission, 12));
};


/**
 * @param {?proto.iroha.protocol.RevokePermission|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setRevokePermission = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearRevokePermission = function() {
  return this.setRevokePermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasRevokePermission = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional SetAccountDetail set_account_detail = 13;
 * @return {?proto.iroha.protocol.SetAccountDetail}
 */
proto.iroha.protocol.Command.prototype.getSetAccountDetail = function() {
  return /** @type{?proto.iroha.protocol.SetAccountDetail} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.SetAccountDetail, 13));
};


/**
 * @param {?proto.iroha.protocol.SetAccountDetail|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setSetAccountDetail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearSetAccountDetail = function() {
  return this.setSetAccountDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasSetAccountDetail = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional SetAccountQuorum set_account_quorum = 14;
 * @return {?proto.iroha.protocol.SetAccountQuorum}
 */
proto.iroha.protocol.Command.prototype.getSetAccountQuorum = function() {
  return /** @type{?proto.iroha.protocol.SetAccountQuorum} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.SetAccountQuorum, 14));
};


/**
 * @param {?proto.iroha.protocol.SetAccountQuorum|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setSetAccountQuorum = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearSetAccountQuorum = function() {
  return this.setSetAccountQuorum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasSetAccountQuorum = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional SubtractAssetQuantity subtract_asset_quantity = 15;
 * @return {?proto.iroha.protocol.SubtractAssetQuantity}
 */
proto.iroha.protocol.Command.prototype.getSubtractAssetQuantity = function() {
  return /** @type{?proto.iroha.protocol.SubtractAssetQuantity} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.SubtractAssetQuantity, 15));
};


/**
 * @param {?proto.iroha.protocol.SubtractAssetQuantity|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setSubtractAssetQuantity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearSubtractAssetQuantity = function() {
  return this.setSubtractAssetQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasSubtractAssetQuantity = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional TransferAsset transfer_asset = 16;
 * @return {?proto.iroha.protocol.TransferAsset}
 */
proto.iroha.protocol.Command.prototype.getTransferAsset = function() {
  return /** @type{?proto.iroha.protocol.TransferAsset} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.TransferAsset, 16));
};


/**
 * @param {?proto.iroha.protocol.TransferAsset|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setTransferAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearTransferAsset = function() {
  return this.setTransferAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasTransferAsset = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional RemovePeer remove_peer = 17;
 * @return {?proto.iroha.protocol.RemovePeer}
 */
proto.iroha.protocol.Command.prototype.getRemovePeer = function() {
  return /** @type{?proto.iroha.protocol.RemovePeer} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.RemovePeer, 17));
};


/**
 * @param {?proto.iroha.protocol.RemovePeer|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setRemovePeer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearRemovePeer = function() {
  return this.setRemovePeer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasRemovePeer = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional CompareAndSetAccountDetail compare_and_set_account_detail = 18;
 * @return {?proto.iroha.protocol.CompareAndSetAccountDetail}
 */
proto.iroha.protocol.Command.prototype.getCompareAndSetAccountDetail = function() {
  return /** @type{?proto.iroha.protocol.CompareAndSetAccountDetail} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.CompareAndSetAccountDetail, 18));
};


/**
 * @param {?proto.iroha.protocol.CompareAndSetAccountDetail|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setCompareAndSetAccountDetail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearCompareAndSetAccountDetail = function() {
  return this.setCompareAndSetAccountDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasCompareAndSetAccountDetail = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional SetSettingValue set_setting_value = 19;
 * @return {?proto.iroha.protocol.SetSettingValue}
 */
proto.iroha.protocol.Command.prototype.getSetSettingValue = function() {
  return /** @type{?proto.iroha.protocol.SetSettingValue} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.SetSettingValue, 19));
};


/**
 * @param {?proto.iroha.protocol.SetSettingValue|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setSetSettingValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearSetSettingValue = function() {
  return this.setSetSettingValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasSetSettingValue = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional CallEngine call_engine = 20;
 * @return {?proto.iroha.protocol.CallEngine}
 */
proto.iroha.protocol.Command.prototype.getCallEngine = function() {
  return /** @type{?proto.iroha.protocol.CallEngine} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.CallEngine, 20));
};


/**
 * @param {?proto.iroha.protocol.CallEngine|undefined} value
 * @return {!proto.iroha.protocol.Command} returns this
*/
proto.iroha.protocol.Command.prototype.setCallEngine = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.iroha.protocol.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Command} returns this
 */
proto.iroha.protocol.Command.prototype.clearCallEngine = function() {
  return this.setCallEngine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Command.prototype.hasCallEngine = function() {
  return jspb.Message.getField(this, 20) != null;
};


goog.object.extend(exports, proto.iroha.protocol);
