import { produce } from 'immer';

import { ApiError } from '@types';

import {
  EMAIL_RESEND_FAILURE,
  EMAIL_RESEND_REQUEST,
  EMAIL_RESEND_SUCCESS,
  EMAIL_VERIFICATION_FAILURE,
  EMAIL_VERIFICATION_REQUEST,
  EMAIL_VERIFICATION_SUCCESS,
  GET_CONTAINER_FROM_SERVER_FAILURE,
  GET_CONTAINER_FROM_SERVER_REQUEST,
  GET_CONTAINER_FROM_SERVER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  SEND_PHONE_FAILURE,
  SEND_PHONE_REQUEST,
  SEND_PHONE_SUCCESS,
  SET_DECRYPTED_USER_DATA,
  SET_IS_ITEM_IN_LOCAL_STORAGE,
} from './auth.action-types';
import { ActionsTypes, AuthState } from './auth.types';

const initialState = {
  isItemInLocalStorage:         false,
  errors:                       [] as ApiError[],
  keyContainer:                 '',
  phoneNumber:                  '',
  isUserSuccessfullyRegistered: false,
  isEmailConfirmed:             false,
  keyNumber:                    '',
  clientNumber:                 '',
  password:                     '',
  pending:                      false,
  smsData:                      {
    attemptId:     '',
    initialVector: '',
    salt:          '',
  },
};

/**
 * Auth reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Auth
 * @param {AuthState} state auth state.
 * @param {ActionsTypes} action auth actions.
 * @return {AuthState} auth state.
 */
export default (state = initialState, action: ActionsTypes): AuthState => {
  switch (action.type) {
    case SET_IS_ITEM_IN_LOCAL_STORAGE: {
      return produce(state, (draft) => {
        draft.isItemInLocalStorage = action.payload.isItemInLocalStorage;
      });
    }

    case SET_DECRYPTED_USER_DATA: {
      return produce(state, (draft) => {
        draft.keyNumber = action.payload.keyNumber;
        draft.clientNumber = action.payload.clientNumber;
        draft.password = action.payload.password;
      });
    }

    case SEND_PHONE_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case SEND_PHONE_SUCCESS: {
      return produce(state, (draft) => {
        draft.smsData = action.payload.smsData;
        draft.phoneNumber = action.payload.phoneNumber;
        draft.errors = [];
        draft.pending = false;
      });
    }

    case SEND_PHONE_FAILURE: {
      return produce(state, (draft) => {
        draft.errors = action.payload.errors;
        draft.pending = false;
      });
    }

    case REGISTER_USER_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case REGISTER_USER_SUCCESS: {
      return produce(state, (draft) => {
        draft.isUserSuccessfullyRegistered = true;
        draft.errors = [];
        draft.pending = false;
      });
    }

    case REGISTER_USER_FAILURE: {
      return produce(state, (draft) => {
        draft.isUserSuccessfullyRegistered = false;
        draft.errors = action.payload.errors;
        draft.pending = false;
      });
    }

    case EMAIL_VERIFICATION_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case EMAIL_VERIFICATION_SUCCESS: {
      return produce(state, (draft) => {
        draft.isEmailConfirmed = true;
        draft.errors = [];
        draft.pending = false;
      });
    }

    case EMAIL_VERIFICATION_FAILURE: {
      return produce(state, (draft) => {
        draft.isEmailConfirmed = false;
        draft.errors = action.payload.errors;
        draft.pending = false;
      });
    }

    case EMAIL_RESEND_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case EMAIL_RESEND_SUCCESS: {
      return produce(state, (draft) => {
        draft.phoneNumber = action.payload.phoneNumber;
        draft.errors = [];
        draft.pending = false;
      });
    }

    case EMAIL_RESEND_FAILURE: {
      return produce(state, (draft) => {
        draft.errors = action.payload.errors;
        draft.pending = false;
      });
    }

    case LOGIN_USER_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case LOGIN_USER_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = [];
      });
    }

    case LOGIN_USER_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.errors;
      });
    }

    case LOGOUT_USER_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case LOGOUT_USER_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = [];
      });
    }

    case LOGOUT_USER_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.errors;
      });
    }

    case GET_CONTAINER_FROM_SERVER_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case GET_CONTAINER_FROM_SERVER_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.keyContainer = action.payload.keyContainer;
        draft.errors = [];
      });
    }

    case GET_CONTAINER_FROM_SERVER_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.errors;
      });
    }

    default: {
      return state;
    }
  }
};
