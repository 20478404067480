import Dexie, { Table } from 'dexie';

import { BitcoinDbWallet } from '@types';

export class MySubClassedDexie extends Dexie {
  wallets!: Table<BitcoinDbWallet>;

  constructor () {
    super('QuantumChain');
    this.version(1).stores({
      wallets: '++id, name, seed, type, addresses',
    });
  }
}

export const db = new MySubClassedDexie();
