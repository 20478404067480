import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import { ConstantsUtil } from '@utils';

import {
  GET_LAMPORT_KEYS_FAILURE,
  GET_LAMPORT_KEYS_REQUEST,
  GET_LAMPORT_KEYS_SUCCESS,
  SEND_LAMPORT_PUB_KEY_FAILURE,
  SEND_LAMPORT_PUB_KEY_REQUEST,
  SEND_LAMPORT_PUB_KEY_SUCCESS,
} from './lamport.action-types';
import {
  GetLamportKeysFailurePayload, GetLamportKeysRequestPayload,
  GetLamportKeysSuccessPayload,
  SendLamportPubKeyFailurePayload,
  SendLamportPubKeyRequestPayload,
} from './lamport.types';

export const actions = {
  /**
   * Send lamport public key request action.
   *
   * @author Ihar Kazlouski
   * @function sendLamportPubKeyRequest
   * @param {SendLamportPubKeyRequestPayload} payload payload.
   * @category Actions
   * @subcategory Lamport
   * @return {SendLamportPubKeyRequestPayload} send lamport public key request.
   */
  sendLamportPubKeyRequest: (payload: SendLamportPubKeyRequestPayload) =>
    ({
      type:              SEND_LAMPORT_PUB_KEY_REQUEST,
      [WAIT_FOR_ACTION]: `${SEND_LAMPORT_PUB_KEY_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${SEND_LAMPORT_PUB_KEY_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Send lamport public key success action.
   *
   * @author Ihar Kazlouski
   * @function sendLamportPubKeySuccess
   * @param {CalculateTransactionParamsRequestPayload} payload payload.
   * @category Actions
   * @subcategory Lamport
   * @return {CalculateTransactionParamsSuccessPayload} send lamport public key success.
   */
  sendLamportPubKeySuccess: () =>
    ({
      type: SEND_LAMPORT_PUB_KEY_SUCCESS,
    } as const),

  /**
   * Send lamport public key failure action.
   *
   * @author Ihar Kazlouski
   * @function sendLamportPubKeyFailure
   * @category Actions
   * @subcategory Lamport
   * @param {SendLamportPubKeyFailurePayload} payload payload.
   * @return {SendLamportPubKeyFailurePayload} send lamport public key failure.
   */
  sendLamportPubKeyFailure: (payload: SendLamportPubKeyFailurePayload) =>
    ({
      type: SEND_LAMPORT_PUB_KEY_FAILURE,
      payload,
    } as const),

  /**
   * Get lamport keys request action.
   *
   * @author Ihar Kazlouski
   * @function getLamportKeysRequest
   * @category Actions
   * @subcategory Lamport
   */
  getLamportKeysRequest: (payload: GetLamportKeysRequestPayload) =>
    ({
      type:              GET_LAMPORT_KEYS_REQUEST,
      payload,
      [WAIT_FOR_ACTION]: `${GET_LAMPORT_KEYS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${GET_LAMPORT_KEYS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
    } as const),

  /**
   * Get lamport keys success action.
   *
   * @author Ihar Kazlouski
   * @function getLamportKeysSuccess
   * @param {GetLamportKeysSuccessPayload} payload payload.
   * @category Actions
   * @subcategory Lamport
   * @return {GetLamportKeysSuccessPayload} get lamport keys success.
   */
  getLamportKeysSuccess: (payload: GetLamportKeysSuccessPayload) =>
    ({
      type: GET_LAMPORT_KEYS_SUCCESS,
      payload,
    } as const),

  /**
   * Get lamport keys failure action.
   *
   * @author Ihar Kazlouski
   * @function getLamportKeysFailure
   * @category Actions
   * @subcategory Lamport
   * @param {GetLamportKeysFailurePayload} payload payload.
   * @return {GetLamportKeysFailurePayload} get lamport keys failure.
   */
  getLamportKeysFailure: (payload: GetLamportKeysFailurePayload) =>
    ({
      type: GET_LAMPORT_KEYS_FAILURE,
      payload,
    } as const),
};
