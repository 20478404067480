import { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';

import { history } from '@history';

import { AuthService } from '@services';
import { ConstantsUtil } from '@utils';

import { Routes } from '@enums';
import {
  ApiError,
  KeyContainer,
  PayloadAction,
  PhoneNumberResponse,
} from '@types';

import {
  EMAIL_RESEND_REQUEST,
  EMAIL_VERIFICATION_REQUEST,
  GET_CONTAINER_FROM_SERVER_REQUEST,
  LOGIN_USER_REQUEST,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  REGISTER_USER_REQUEST,
  SEND_PHONE_REQUEST,
} from './auth.action-types';
import { actions } from './auth.actions';
import {
  EmailResendRequestPayload,
  EmailVerificationRequestPayload,
  GetContainerFromServerRequestPayload,
  LoginUserRequestPayload,
  RegisterRequestPayload,
  SendPhoneRequestPayload,
} from './auth.types';

/**
 * Set phone number saga worker.
 *
 * @author Ihar Kazlouski
 * @function setPhoneNumberSagaWorker
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<KeyContainer>>} data.
 */
function* setPhoneNumberSagaWorker (
  action: PayloadAction<SendPhoneRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<PhoneNumberResponse>
  > {
  try {
    const { data } = yield call(AuthService.setPhoneNumberApi, {
      ...action.payload,
    });

    yield put(
      actions.sendPhoneSuccess({
        smsData:     data,
        phoneNumber: action.payload.phoneNumber,
      }),
    );

    yield put({
      type:    `${SEND_PHONE_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: data,
    });
  } catch (error) {
    yield put(
      actions.sendPhoneFailure({
        errors: error as ApiError[],
      }),
    );

    yield put({
      type:  `${SEND_PHONE_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError,
    });
  }
}

/**
 * Set phone number saga watcher.
 *
 * @author Ihar Kazlouski
 * @function setPhoneNumberSagaWatcher
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* setPhoneNumberSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(SEND_PHONE_REQUEST, setPhoneNumberSagaWorker)]);
}

/**
 * User register saga worker.
 *
 * @author Ihar Kazlouski
 * @function userRegisterSagaWorker
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<Record<string, unknown>>>} data.
 */
function* userRegisterSagaWorker (
  action: PayloadAction<RegisterRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<Record<string, unknown>>
  > {
  try {
    yield call(AuthService.apiPostUserRegister, action.payload);

    yield put(actions.registerUserSuccess());

    yield put({
      type: `${REGISTER_USER_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
    });
  } catch (error) {
    yield put(
      actions.registerUserFailure({
        errors: error as ApiError[],
      }),
    );

    yield put({
      type:  `${REGISTER_USER_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError,
    });
  }
}

/**
 * User register saga watcher.
 *
 * @author Ihar Kazlouski
 * @function userRegisterSagaWatcher
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* userRegisterSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(REGISTER_USER_REQUEST, userRegisterSagaWorker)]);
}

/**
 * Email verification saga worker.
 *
 * @author Ihar Kazlouski
 * @function emailVerificationSagaWorker
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<Record<string, unknown>>>} data.
 */
function* emailVerificationSagaWorker (
  action: PayloadAction<EmailVerificationRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<Record<string, unknown>>
  > {
  try {
    yield call(
      AuthService.apiPostEmailConfirmation,
      action.payload.token,
      action.payload.salt,
      action.payload.irohaPublicKey,
    );

    yield put(actions.emailVerificationSuccess());

    yield put({
      type: `${EMAIL_VERIFICATION_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
    });
  } catch (error) {
    yield put(
      actions.registerUserFailure({
        errors: error as ApiError[],
      }),
    );

    yield put({
      type:  `${EMAIL_VERIFICATION_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError,
    });
  }
}

/**
 * Email verification saga watcher.
 *
 * @author Ihar Kazlouski
 * @function emailVerificationSagaWatcher
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* emailVerificationSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([
    takeLatest(EMAIL_VERIFICATION_REQUEST, emailVerificationSagaWorker),
  ]);
}

/**
 * Email resend saga worker.
 *
 * @author Ihar Kazlouski
 * @function emailVerificationSagaWorker
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<Record<string, unknown>>>} data.
 */
function* emailResendSagaWorker (
  action: PayloadAction<EmailResendRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<Record<string, unknown>>
  > {
  try {
    yield call(AuthService.apiPostResendEmail, action.payload.phoneNumber);

    yield put(
      actions.emailResendSuccess({
        phoneNumber: action.payload.phoneNumber || '',
      }),
    );

    yield put({
      type: `${EMAIL_RESEND_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
    });
  } catch (error) {
    yield put(
      actions.emailResendFailure({
        errors: error as ApiError[],
      }),
    );

    yield put({
      type:  `${EMAIL_RESEND_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError,
    });
  }
}

/**
 * Email resend saga watcher.
 *
 * @author Ihar Kazlouski
 * @function emailResendSagaWatcher
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* emailResendSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(EMAIL_RESEND_REQUEST, emailResendSagaWorker)]);
}

/**
 * Login user saga worker.
 *
 * @author Ihar Kazlouski
 * @function loginUserSagaWorker
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<Record<string, unknown>>>} data.
 */
function* loginUserSagaWorker (
  action: PayloadAction<LoginUserRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<Record<string, unknown>>
  > {
  try {
    yield call(AuthService.apiPostLogin, action.payload);

    yield put(actions.loginUserSuccess());

    yield put({
      type: `${LOGIN_USER_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
    });
  } catch (error) {
    yield put(
      actions.loginUserFailure({
        errors: error as ApiError[],
      }),
    );

    yield put({
      type:  `${LOGIN_USER_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError,
    });
  }
}

/**
 * Login user saga watcher.
 *
 * @author Ihar Kazlouski
 * @function loginUserSagaWatcher
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* loginUserSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(LOGIN_USER_REQUEST, loginUserSagaWorker)]);
}

/**
 * Logout user saga worker.
 *
 * @author Ihar Kazlouski
 * @function logoutUserSagaWorker
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<Record<string, unknown>>>} data.
 */
function* logoutUserSagaWorker (): Generator<
PutEffect | CallEffect,
void,
ApiError & AxiosResponse<Record<string, unknown>>
> {
  try {
    yield call(AuthService.apiPostLogout);

    yield put(actions.logoutUserSuccess());

    history.push(Routes.Login);

    yield put({
      type: `${LOGOUT_USER_SUCCESS}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
    });
  } catch (error) {
    yield put(
      actions.logoutUserFailure({
        errors: error as ApiError[],
      }),
    );

    yield put({
      type:  `${LOGOUT_USER_SUCCESS}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError,
    });
  }
}

/**
 * Logout user saga watcher.
 *
 * @author Ihar Kazlouski
 * @function logoutUserSagaWatcher
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* logoutUserSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(LOGOUT_USER_REQUEST, logoutUserSagaWorker)]);
}

/**
 * Get container from server saga worker.
 *
 * @author Ihar Kazlouski
 * @function getContainerFromServerSagaWorker
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<Record<string, unknown>>>} data.
 */
function* getContainerFromServerSagaWorker (
  action: PayloadAction<GetContainerFromServerRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<KeyContainer>
  > {
  try {
    const { data } = yield call(
      AuthService.apiPostGetContainerFromServer,
      action.payload,
    );

    yield put(
      actions.getContainerFromServerSuccess({
        keyContainer: data.container,
      }),
    );

    yield put({
      type:    `${GET_CONTAINER_FROM_SERVER_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: data.container,
    });
  } catch (error) {
    yield put(
      actions.getContainerFromServerFailure({
        errors: error as ApiError[],
      }),
    );

    yield put({
      type:  `${GET_CONTAINER_FROM_SERVER_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError,
    });
  }
}

/**
 * Get container from server saga watcher.
 *
 * @author Ihar Kazlouski
 * @function getContainerFromServerSagaWatcher
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* getContainerFromServerSagaWatcher (): Generator<
AllEffect<ForkEffect>
> {
  yield all([
    takeLatest(
      GET_CONTAINER_FROM_SERVER_REQUEST,
      getContainerFromServerSagaWorker,
    ),
  ]);
}

/**
 * Auth saga.
 *
 * @author Ihar Kazlouski
 * @function authSaga
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* authSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(setPhoneNumberSagaWatcher),
    fork(userRegisterSagaWatcher),
    fork(emailVerificationSagaWatcher),
    fork(emailResendSagaWatcher),
    fork(loginUserSagaWatcher),
    fork(logoutUserSagaWatcher),
    fork(getContainerFromServerSagaWatcher),
  ]);
}
