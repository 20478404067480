import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { db } from '@db';

import { Box, Button, Typography } from '@mui/material';

import { getModalOpenStateSelector, getModalSelector } from '@store/modals';

import { ModalsService } from '@services';
import { useDeviceSize } from '@hooks';

import { Modals } from '@enums';

import { Modal } from '@elements';

/**
 * Upload wallet modal component.
 *
 * @author Ihar Kazlouski
 * @function UploadKeysModal
 * @category Modals
 * @return {FC} upload keys modal component.
 */
const UploadWalletModal: FC = () => {
  const deviceSize = useDeviceSize();
  const { t } = useTranslation();
  const [isSizeError, setIsSizeError] = useState(false);
  const size = 1048576;
  const isOpen = useSelector(getModalOpenStateSelector(Modals.UploadWallet));
  const modal = useSelector(getModalSelector(Modals.UploadWallet));

  useEffect(() => {
    if (isSizeError) {
      setIsSizeError(!isSizeError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  /**
   * Upload keys.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files) {
      if (event.target.files?.[0].size > size) {
        setIsSizeError(!isSizeError);
      } else {
        if (isSizeError) {
          setIsSizeError(!isSizeError);
        }

        void event.target.files?.[0]
          ?.text()
          .then(async (file) => {
            await db.wallets.add(JSON.parse(file));
          })
          .then(() => ModalsService.close(Modals.UploadWallet))
          .catch((error) => {
            void ModalsService.open(Modals.Error, {
              content: t('modals.uploadWallet.incorrectSize'),
            });
          });
      }
    }
  };

  return (
    <Modal maxWidth='sm' fullWidth>
      <Modal.Title>
        {isSizeError ? (
          <Typography textAlign='center' color='error'>
            {t('modals.uploadWallet.incorrectSize')}
          </Typography>
        ) : (
          ''
        )}
      </Modal.Title>
      <Modal.Content>
        <Typography textAlign='center'>
          {(modal?.data as { content: string })?.content || t('modals.uploadWallet.content')}
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Box
          pt={4}
          pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
          display='flex'
          justifyContent='space-around'
        >
          <Button variant='contained' component='label'>
            {t('modals.uploadWallet.upload')}
            <input onChange={handleChange} accept='.json' type='file' hidden />
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};

export { UploadWalletModal };
