import React, { FC } from 'react';

import { Box, Drawer, useMediaQuery } from '@mui/material';

import { DefaultTheme } from '@types';

import { NavItem } from '@components/nav-item';
import { sideBarItems } from '@components/side-bar/side-bar.helpers';
import { Icon } from '@elements/icon';

import { colors } from '@themes';
import { StyledContentContainer, StyledDivider } from './side-bar.styles';

import logo from '@images/logo.svg';

import { SideBarProps } from './side-bar.types';

/**
 * Sidebar component.
 *
 * @author Ihar Kazlouski
 * @function SideBarComponent
 * @category components
 * @param {function} onClose side bar close function.
 * @param {boolean} open is sidebar open.
 * @return {FC} side bar component.
 */
const SideBarComponent: FC<SideBarProps> = ({ onClose, open }) => {
  const lgUp = useMediaQuery(
    (theme: DefaultTheme) => theme.breakpoints.up('lg'),
    {
      defaultMatches: true,
    },
  );

  const content = (
    <>
      <StyledContentContainer>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Box p={3}>
            <Icon src={logo} width={120} height={95} color='amazon' />
          </Box>
        </Box>
        <StyledDivider />
        <Box sx={{ flexGrow: 1 }}>
          {sideBarItems.map((item) => (
            <NavItem
              key={item.id}
              href={item.href}
              icon={item.icon}
              title={item.title}
            />
          ))}
        </Box>
      </StyledContentContainer>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor='left'
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color:           colors.white,
            width:           280,
          },
        }}
        variant='permanent'
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor='left'
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color:           colors.white,
          width:           280,
        },
      }}
      sx={{ zIndex: (theme): number => theme.zIndex.appBar + 100 }}
      variant='temporary'
    >
      {content}
    </Drawer>
  );
};

export { SideBarComponent };
