/**
 * Get list children array.
 *
 * @author Ihar Kazlouski
 * @param {ReactNode | ReactNode[]) => JSX.Element)} children children list.
 * @param {string} name children name.
 * @return {(ReactChild | ReactFragment | ReactPortal)[] | null | undefined} return list children array.
 */
import { Children, ReactChild, ReactFragment, ReactNode, ReactPortal } from 'react';

function getListChildren (
  children: ReactNode | ReactNode[],
  name: string,
): (ReactChild | ReactFragment | ReactPortal)[] | null | undefined {
  return Children.map(children, (child): ReactNode => {
    const childElement = child as ReactNode & {
      type: { displayName?: string };
    };

    return childElement.type.displayName === name ? childElement : undefined;
  });
}

export { getListChildren };
