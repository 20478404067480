import { ReactElement } from 'react';

import { GridApiErrorComponentProps } from './api-grid-error.types';

/**
 * Api grid error component.
 *
 * @author Ihar Kazlouski
 * @function ApiGridError
 * @category components
 * @return {ReactNode} children.
 */
function ApiGridError ({
  children,
}: GridApiErrorComponentProps): ReactElement<unknown> {
  return <>{children}</>;
}

ApiGridError.displayName = 'ApiGridError';

export { ApiGridError };
