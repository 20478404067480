import { produce } from 'immer';

import {
  GET_LAMPORT_KEYS_FAILURE,
  GET_LAMPORT_KEYS_REQUEST,
  GET_LAMPORT_KEYS_SUCCESS,
  SEND_LAMPORT_PUB_KEY_FAILURE,
  SEND_LAMPORT_PUB_KEY_REQUEST,
  SEND_LAMPORT_PUB_KEY_SUCCESS,
} from '@store/lamport/lamport.action-types';

import { ApiError } from '@types';

import { ActionsTypes, LamportState } from './lamport.types';

const initialState: LamportState = {
  pending:     false,
  lamportKeys: null,
  errors:      [] as ApiError[],
};

/**
 * Lamport reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Lamport
 * @param {LamportState} state state.
 * @param {ActionsTypes} action action.
 * @return {LamportState} transaction state.
 */
export default (state = initialState, action: ActionsTypes): LamportState => {
  switch (action.type) {
    case SEND_LAMPORT_PUB_KEY_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case SEND_LAMPORT_PUB_KEY_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = [];
      });
    }

    case SEND_LAMPORT_PUB_KEY_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    case GET_LAMPORT_KEYS_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case GET_LAMPORT_KEYS_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.lamportKeys = action.payload.lamportKeys;
        draft.errors = [];
      });
    }

    case GET_LAMPORT_KEYS_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
