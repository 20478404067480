import { produce } from 'immer';

import { ApiError } from '@types';

import {
  GET_IROHA_ACCOUNT_FAILURE,
  GET_IROHA_ACCOUNT_REQUEST,
  GET_IROHA_ACCOUNT_SUCCESS,
  GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_FAILURE,
  GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_REQUEST,
  GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_SUCCESS,
  GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_FAILURE,
  GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_REQUEST,
  GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_SUCCESS,
  TRANSFER_IROHA_TRANSACTION_FAILURE,
  TRANSFER_IROHA_TRANSACTION_REQUEST,
  TRANSFER_IROHA_TRANSACTION_SUCCESS,
  TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_FAILURE,
  TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_REQUEST,
  TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_SUCCESS,
  WITHDRAW_IROHA_TRANSACTION_FAILURE,
  WITHDRAW_IROHA_TRANSACTION_REQUEST,
  WITHDRAW_IROHA_TRANSACTION_SUCCESS,
} from './iroha.action-types';
import { ActionsTypes, IrohaState } from './iroha.types';

const initialState: IrohaState = {
  pending:      false,
  irohaAccount: null,
  errors:       [] as ApiError[],
};

/**
 * Iroha reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Iroha
 * @param {IrohaState} state state.
 * @param {ActionsTypes} action action.
 * @return {IrohaState} transaction state.
 */
export default (state = initialState, action: ActionsTypes): IrohaState => {
  switch (action.type) {
    case GET_IROHA_ACCOUNT_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case GET_IROHA_ACCOUNT_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.irohaAccount = action.payload.irohaAccount;
        draft.errors = [];
      });
    }

    case GET_IROHA_ACCOUNT_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    case TRANSFER_IROHA_TRANSACTION_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case TRANSFER_IROHA_TRANSACTION_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = [];
      });
    }

    case TRANSFER_IROHA_TRANSACTION_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    case GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = [];
      });
    }

    case GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    case WITHDRAW_IROHA_TRANSACTION_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case WITHDRAW_IROHA_TRANSACTION_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = [];
      });
    }

    case WITHDRAW_IROHA_TRANSACTION_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    case GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = [];
      });
    }

    case GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    case TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = [];
      });
    }

    case TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.errors = action.payload.error;
      });
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
