import { alpha } from '@mui/material';

const colors = {
  white:       '#FFFFFF',
  oxfordBlue:  '#2D3748',
  successMain: '#14B8A6',
  warningMain: '#FFB020',

  dodgerblueLight: alpha('#18A0FB', 0.15),
  lightGray:       alpha('#ffffff', 0.08),
};

export { colors };
