import { Dialog, Paper, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledDialog = styled(Dialog)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    [theme.breakpoints.down('md')]: {
      zIndex: theme.zIndex.drawer,
    },
  };

  return styles;
});

const StyledPaper = styled(Paper)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    borderRadius: pxToRem(12),
  };

  return styles;
});

export { StyledDialog, StyledPaper };
