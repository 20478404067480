import { i18n } from '@localization';

export enum Address {
  Receiving = 0,
  Change = 1,
}

export const getAddressType = (): Record<number, string> => ({
  0: i18n.t('pages.walletCreation.receiving'),
  1: i18n.t('pages.walletCreation.change'),
});

export enum AddressUpdateStatus {
  Updated = 'Updated',
  InProgress = 'ProcessUpdating',
}
