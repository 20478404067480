import { useEffect, useRef } from 'react';

/**
 * Helps to get previous state.
 *
 * @author Ihar Kazlouski
 * @function usePrevious
 * @param {T} value value.
 * @return {T | undefined} previous value.
 */
function usePrevious<T> (value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    ref.current = value;
  }, [value]);

  return ref.current;
}

export { usePrevious };
