import React, { createElement, FC } from 'react';

import { StyledModalContentContainer } from './modal-content.styled';
import { ModalContentProps } from './modal-content.types';

/**
 * ModalContentSlot component.
 *
 * @author Ihar Kazlouski
 * @function ModalContent
 * @category components
 * @param {ModalContentProps} content props.
 * @return {JSX.Element} ModalContentSlot component.
 */
const ModalContent: FC<ModalContentProps> = ({
  children,
  ...restProps
}): JSX.Element => {
  const content =
    typeof children === 'function'
      ? createElement(children, { ...restProps })
      : children;

  return <StyledModalContentContainer>{content}</StyledModalContentContainer>;
};

ModalContent.displayName = 'ModalContent';

export { ModalContent };
