import * as bitcoin from 'bitcoinjs-lib';
import { Network } from 'bitcoinjs-lib/src/networks';

import { ConstantsUtil } from '@utils/constants';

/**
 * Get bitcoin address util.
 *
 * @author Ihar Kazlouski
 * @param {string} node bitcoin node.
 * @param {Network} network bitcoin network.
 * @return {string} address.
 */
const getAddress = (publicKey: Buffer, network: Network = ConstantsUtil.network): string => {
  return bitcoin.payments.p2pkh({ pubkey: publicKey, network }).address!;
};

export { getAddress };
