import BigInteger from 'big-integer';
const zero = BigInteger(0);
const n256 = BigInteger(256);

const toLittleEndian = (bigNumber: any): BigInteger => {
  const result = new Uint8Array(32);
  let i = 0;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  while (bigNumber.greater(zero)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    result[i] = bigNumber.mod(n256);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    bigNumber = bigNumber.divide(n256);
    i += 1;
  }
  return result;
};

export const toBigEndian = (bytes: BigInteger): BigInteger => {
  return toLittleEndian(bytes).reverse();
};
