import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import SupportIcon from '@mui/icons-material/Support';

import { Routes } from '@enums';

import { SideBarItemsType } from './side-bar.types';

const sideBarItems: SideBarItemsType[] = [
  {
    id:    '1',
    href:  Routes.BitcoinHoldings,
    icon:  (<CurrencyBitcoinIcon />),
    title: 'Bitcoin Holdings',
  },
  {
    id:    '2',
    href:  Routes.QuantumVault,
    icon:  (<SafetyCheckIcon />),
    title: 'Quantum Vault',
  },
  {
    id:    '3',
    href:  Routes.History,
    icon:  (<ReceiptLongIcon />),
    title: 'History',
  },
  {
    id:    '4',
    href:  Routes.Support,
    icon:  (<SupportIcon />),
    title: 'Support',
  },
];

export { sideBarItems };
