import React, { FC, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { appPrivateRoutes, appPublicRoutes } from '@routers';

import { InitService } from '@services';
import { PrivateLayout, PublicLayout, WithSuspense } from '@hocs';

import { AppRoutes } from '@types';

import { Loader, Modals, SnackBarComponent } from './components';

/**
 * App component.
 *
 * @author Ihar Kazlouski
 * @function AppComponent
 * @category Components
 * @component
 * @return {FC} app.
 */
const AppComponent: FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    InitService.init()
      .then(() => {
        // Do something
      })
      .catch(() => {
        // Handle app init error
      });
  }, []);

  return (
    <>
      <Loader />
      <Routes>
        {appPublicRoutes.map((route: AppRoutes) => (
          <Route
            key={route.name}
            path='/'
            element={<PublicLayout inline={route.inline} />}
          >
            <Route
              path={route.path}
              element={WithSuspense(route.element)}
              index={route.index}
            />
          </Route>
        ))}
        {appPrivateRoutes.map((route: AppRoutes) => (
          <Route
            key={route.name}
            path='/'
            element={<PrivateLayout inline={route.inline} />}
          >
            <Route
              path={route.path}
              element={WithSuspense(route.element)}
              index={route.index}
            />
          </Route>
        ))}
      </Routes>
      <SnackBarComponent description={t('snackBar.description')} />
      <Modals />
    </>
  );
};

export default withTranslation()(AppComponent);
