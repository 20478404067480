import { Button, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledButton = styled(Button)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
  };

  return styles;
});

export { StyledButton };
