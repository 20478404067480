import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, Grid, Typography } from '@mui/material';

import { getModalSelector } from '@store/modals';

import { ModalsService } from '@services';
import { useDeviceSize } from '@hooks';

import { Modals } from '@enums';

import { Modal } from '@elements';

/**
 * Is wallet balance actual modal component.
 *
 * @author Ihar Kazlouski
 * @function IsWalletBalanceActualModal
 * @category Modals
 * @return {FC} is wallet balance actual modal component.
 */
const IsWalletBalanceActualModal: FC = () => {
  const deviceSize = useDeviceSize();
  const { t } = useTranslation();
  const modal = useSelector(getModalSelector(Modals.IsWalletBalanceActual));

  /**
   * Open btc transfer modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const openBtcTransferModal = (): void => {
    ModalsService.close(Modals.IsWalletBalanceActual);
    void ModalsService.open(Modals.AddMoney, {
      addresses: (modal?.data as { addresses: string[] })?.addresses,
      name:      (modal?.data as { name: string }).name,
    });
  };

  /**
   * Close modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const closeModal = (): void => {
    ModalsService.close(Modals.IsWalletBalanceActual);
  };

  return (
    <Modal maxWidth='sm' fullWidth>
      <Modal.Title>{t('modals.isBalanceActual.attention')}</Modal.Title>
      <Modal.Content>
        <Typography textAlign='center'>
          {t('modals.isBalanceActual.content')}
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Grid
          container
          pt={4}
          pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
          display='flex'
          justifyContent='space-around'
        >
          <Grid item xs={12} md={2} lg={3}>
            <Button
              variant='contained'
              onClick={openBtcTransferModal}
              fullWidth
            >
              {t('modals.isBalanceActual.continue')}
            </Button>
          </Grid>
          <Grid item xs={3} display={deviceSize.desktop ? 'flex' : 'none'}>
            <Button variant='outlined' onClick={closeModal} fullWidth>
              {t('modals.isBalanceActual.close')}
            </Button>
          </Grid>
        </Grid>
      </Modal.Footer>
    </Modal>
  );
};

export { IsWalletBalanceActualModal };
