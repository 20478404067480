import { Navigate } from 'react-router-dom';

import { LazyPage } from '@hocs';

import { Routes } from '@enums';
import { AppRoutes } from '@types';

const BitcoinHoldingsPage = LazyPage('bitcoin-holdings', 'BitcoinHoldings');
const QuantumVaultPage = LazyPage('quantum-vault', 'QuantumVault');
const SupportPage = LazyPage('support', 'Support');
const WalletCreationPage = LazyPage('wallet-creation', 'WalletCreation');
const WalletTypePage = LazyPage('wallet-type', 'WalletType');
const SeedPhraseCreationPage = LazyPage('seed-phrase-creation', 'SeedPhraseCreation');
const SeedPhraseConfirmPage = LazyPage('seed-phrase-confirm', 'SeedPhraseConfirm');
const WalletImportPage = LazyPage('wallet-import', 'WalletImport');
const ImportPrivateKeysPage = LazyPage('import-private-keys', 'ImportPrivateKeys');
const WalletMorePage = LazyPage('wallet-more', 'WalletMore');
const HistoryPage = LazyPage('history', 'HistoryPage');

const appPrivateRoutes: AppRoutes[] = [
  {
    name:    Routes.Main,
    path:    Routes.Main,
    element: <Navigate to={Routes.BitcoinHoldings} replace />,
    index:   false,
  },
  {
    name:    Routes.BitcoinHoldings,
    path:    Routes.BitcoinHoldings,
    element: <BitcoinHoldingsPage />,
    index:   false,
  },
  {
    name:    Routes.QuantumVault,
    path:    Routes.QuantumVault,
    element: <QuantumVaultPage />,
    index:   false,
  },
  {
    name:    Routes.Support,
    path:    Routes.Support,
    element: <SupportPage />,
    index:   false,
  },
  {
    name:    Routes.WalletCreation,
    path:    Routes.WalletCreation,
    element: <WalletCreationPage />,
    index:   false,
  },
  {
    name:    Routes.WalletType,
    path:    Routes.WalletType,
    element: <WalletTypePage />,
    index:   false,
  },
  {
    name:    Routes.SeedPhraseCreation,
    path:    Routes.SeedPhraseCreation,
    element: <SeedPhraseCreationPage />,
    index:   false,
  },
  {
    name:    Routes.SeedPhraseConfirm,
    path:    Routes.SeedPhraseConfirm,
    element: <SeedPhraseConfirmPage />,
    index:   false,
  },
  {
    name:    Routes.WalletImportSeed,
    path:    Routes.WalletImportSeed,
    element: <WalletImportPage />,
    index:   false,
  },
  {
    name:    Routes.KeysImport,
    path:    Routes.KeysImport,
    element: <ImportPrivateKeysPage />,
    index:   false,
  },
  {
    name:    Routes.WalletMore,
    path:    Routes.WalletMore,
    element: <WalletMorePage />,
    index:   false,
  },
  {
    name:    Routes.History,
    path:    Routes.History,
    element: <HistoryPage />,
    index:   false,
  },
];

export { appPrivateRoutes };
