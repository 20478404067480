import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import { ModalsService } from '@services';
import { useDeviceSize } from '@hooks';

import { Modals } from '@enums';

import { Modal, ModalProps } from '@elements';

/**
 * Address created modal component.
 *
 * @author Ihar Kazlouski
 * @function AddressCreatedModal
 * @category Modals
 * @return {FC} address created modal component.
 */
const AddressCreatedModal: FC<ModalProps> = () => {
  const deviceSize = useDeviceSize();
  const { t } = useTranslation();

  /**
   * Close modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const closeModal = (): void => {
    ModalsService.close(Modals.AddressCreated);
  };

  return (
    <Modal maxWidth='sm' fullWidth>
      <Modal.Title>{t('modals.addressCreated.addressCreated')}</Modal.Title>
      <Modal.Content>
        <Box pb={4}>
          <Typography textAlign='center'>
            {t('modals.addressCreated.fileDownloaded')}
          </Typography>
          <Typography textAlign='center' mt={1.5}>
            {t('modals.addressCreated.dontTellAnyone')}
          </Typography>
        </Box>
      </Modal.Content>
      <Modal.Footer>
        <Box
          pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
          sx={{
            display: {
              xs: 'none',
              lg: 'flex',
            },
          }}
          justifyContent='space-around'
        >
          <Button variant='contained' onClick={closeModal}>
            {t('modals.addressCreated.close')}
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};

export { AddressCreatedModal };
