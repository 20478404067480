/**
 * Encrypt and decrypt XOR util.
 *
 * @author Ihar Kazlouski
 * @param {string} inpString input string.
 * @param {string} inpString xorKey key.
 * @return {string} value.
 */
const encryptDecrypt = (inpString: string, xorKey: string): string => {
  const splitedInpString = inpString.split('');
  const len = inpString.length;

  for (let i = 0; i < len; i++) {
    splitedInpString[i] = (String.fromCharCode((inpString[i].charCodeAt(0)) ^ xorKey.charCodeAt(0)));
  }

  return splitedInpString.join('');
};

export { encryptDecrypt };
