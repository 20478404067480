import { randomBytes } from 'crypto';
import { db } from '@db';

import { ConstantsUtil, cryptoSimpleEncryption, encryptDecrypt } from '@utils';

/**
 * Reencrypt private keys and set them to db.
 *
 * @author Ihar Kazlouski
 * @param {string} inputAddress input address.
 * @param {string} decryptedPrivateKey decrypted private key.
 * @return {void}
 */
const reEncryptPrivateKey = (
  inputAddress: string,
  decryptedPrivateKey: string,
  walletName: string,
): void => {
  void db.wallets
    .where('name')
    .equals(walletName)
    .modify((x) => {
      x.addresses.map((address) => {
        if (address.address === inputAddress) {
          const salt = randomBytes(32).toString('hex');
          const encryptedKey = cryptoSimpleEncryption(
            decryptedPrivateKey,
            encryptDecrypt(
              localStorage.getItem(ConstantsUtil.localStorage.cipher) || '',
              localStorage.getItem(ConstantsUtil.localStorage.salt) || '',
            ),
            salt,
          );

          address.salt = salt;
          address.key = encryptedKey;
          return address;
        } else {
          return address;
        }
      });
    });
};

export { reEncryptPrivateKey };
