import React, { FC } from 'react';

import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Toolbar, Typography } from '@mui/material';

import { AuthService, ModalsService } from '@services';
import { ConstantsUtil, errorsHandlerUtil } from '@utils';

import { Modals } from '@enums';
import { ApiErrorObject } from '@types';

import { StyledAppBar, StyledContainer } from './header.styles';

import { HeaderProps } from './header.types';

/**
 * Header component.
 *
 * @author Ihar Kazlouski
 * @function Header
 * @category components
 * @param {function} onSideBarOpen side bar handler func.
 * @return {FC} header component.
 */
const Header: FC<HeaderProps> = ({ onSideBarOpen }) => {
  /**
   * Logout button click handler.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const onLogoutClick = async (): Promise<void> => {
    try {
      localStorage.removeItem(ConstantsUtil.localStorage.cipher);
      await AuthService.logout();
    } catch (error) {
      void ModalsService.open(Modals.Error, {
        content: errorsHandlerUtil(error as ApiErrorObject[]),
      });
    }
  };

  return (
    <StyledAppBar
      sx={{
        left: {
          lg: 280,
        },
        width: {
          lg: 'calc(100% - 280px)',
        },
      }}
      elevation={0}
    >
      <Toolbar disableGutters>
        <StyledContainer fixed>
          <Box
            alignItems='center'
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none',
              },
            }}
          >
            <IconButton data-testid='sidebar-button' onClick={onSideBarOpen}>
              <MenuIcon />
            </IconButton>
            <Typography
              color='textSecondary'
              component='h5'
              variant='h5'
              ml={2}
            >
              Quantum Chain
            </Typography>
          </Box>
          <Box
            sx={{
              display: {
                xs: 'none',
                lg: 'inline-flex',
              },
            }}
          >
            <Typography
              color='textSecondary'
              component='h4'
              variant='h4'
              ml={2}
            >
              Quantum Chain
            </Typography>
          </Box>
          <IconButton data-testid='logout-button' onClick={onLogoutClick}>
            <LogoutIcon />
          </IconButton>
        </StyledContainer>
      </Toolbar>
    </StyledAppBar>
  );
};

export { Header };
