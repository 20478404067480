import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  GET_IROHA_ACCOUNT_FAILURE,
  GET_IROHA_ACCOUNT_REQUEST,
  GET_IROHA_ACCOUNT_SUCCESS,
  GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_FAILURE,
  GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_REQUEST,
  GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_SUCCESS,
  GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_FAILURE,
  GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_REQUEST,
  GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_SUCCESS,
  TRANSFER_IROHA_TRANSACTION_FAILURE,
  TRANSFER_IROHA_TRANSACTION_REQUEST,
  TRANSFER_IROHA_TRANSACTION_SUCCESS,
  TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_FAILURE,
  TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_REQUEST,
  TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_SUCCESS,
  WITHDRAW_IROHA_TRANSACTION_FAILURE,
  WITHDRAW_IROHA_TRANSACTION_REQUEST,
  WITHDRAW_IROHA_TRANSACTION_SUCCESS,
} from '@store/iroha/iroha.action-types';
import {
  GetIrohaAccountFailurePayload,
  GetIrohaAccountSuccessPayload,
  GetIrohaTransferToAnotherAccountParamsFailurePayload,
  GetIrohaTransferToAnotherAccountParamsRequestPayload,
  GetIrohaWithdrawTransactionParamsRequestPayload,
  TransferIrohaTransactionFailurePayload,
  TransferIrohaTransactionRequestPayload,
  TransferIrohaTransactionToAnotherAccountFailurePayload,
  TransferIrohaTransactionToAnotherAccountRequestPayload,
  WithdrawIrohaTransactionFailurePayload,
  WithdrawIrohaTransactionRequestPayload,
} from '@store/iroha/iroha.types';

import { ConstantsUtil } from '@utils';

export const actions = {
  /**
   * Get iroha account request action.
   *
   * @author Ihar Kazlouski
   * @function getIrohaAccountRequest
   * @category Actions
   * @subcategory Iroha
   * @return {SendLamportPubKeyRequestPayload} get iroha account request.
   */
  getIrohaAccountRequest: () =>
    ({
      type:              GET_IROHA_ACCOUNT_REQUEST,
      [WAIT_FOR_ACTION]: `${GET_IROHA_ACCOUNT_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${GET_IROHA_ACCOUNT_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
    } as const),

  /**
   * Get iroha account success action.
   *
   * @author Ihar Kazlouski
   * @function getIrohaSuccess
   * @param {GetIrohaAccountSuccessPayload} payload payload.
   * @category Actions
   * @subcategory Iroha
   * @return {GetIrohaAccountSuccessPayload} get iroha account success.
   */
  getIrohaAccountSuccess: (payload: GetIrohaAccountSuccessPayload) =>
    ({
      type: GET_IROHA_ACCOUNT_SUCCESS,
      payload,
    } as const),

  /**
   * Get iroha account failure action.
   *
   * @author Ihar Kazlouski
   * @function getIrohaAccountFailure
   * @category Actions
   * @subcategory Iroha
   * @param {GetIrohaAccountFailurePayload} payload payload.
   * @return {GetIrohaAccountFailurePayload} get iroha account key failure.
   */
  getIrohaAccountFailure: (payload: GetIrohaAccountFailurePayload) =>
    ({
      type: GET_IROHA_ACCOUNT_FAILURE,
      payload,
    } as const),

  /**
   * Transfer iroha transaction request action.
   *
   * @author Ihar Kazlouski
   * @function transferIrohaTransactionRequest
   * @param {TransferIrohaTransactionRequestPayload} payload payload.
   * @category Actions
   * @subcategory Iroha
   * @return {TransferIrohaTransactionRequestPayload} transfer iroha transaction request.
   */
  transferIrohaTransactionRequest: (
    payload: TransferIrohaTransactionRequestPayload,
  ) =>
    ({
      type:              TRANSFER_IROHA_TRANSACTION_REQUEST,
      [WAIT_FOR_ACTION]: `${TRANSFER_IROHA_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${TRANSFER_IROHA_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Transfer iroha transaction success action.
   *
   * @author Ihar Kazlouski
   * @function transferIrohaTransactionSuccess
   * @category Actions
   * @subcategory Iroha
   * @return {GetIrohaAccountSuccessPayload} transfer iroha transaction success.
   */
  transferIrohaTransactionSuccess: () =>
    ({
      type: TRANSFER_IROHA_TRANSACTION_SUCCESS,
    } as const),

  /**
   * Transfer iroha transaction failure action.
   *
   * @author Ihar Kazlouski
   * @function transferIrohaTransactionFailure
   * @category Actions
   * @subcategory Iroha
   * @param {TransferIrohaTransactionFailurePayload} payload payload.
   * @return {TransferIrohaTransactionFailurePayload} transfer iroha transaction failure.
   */
  transferIrohaTransactionFailure: (
    payload: TransferIrohaTransactionFailurePayload,
  ) =>
    ({
      type: TRANSFER_IROHA_TRANSACTION_FAILURE,
      payload,
    } as const),

  /**
   * Get iroha withdraw transaction params request action.
   *
   * @author Ihar Kazlouski
   * @function getIrohaWithdrawTransactionParams
   * @param {GetIrohaWithdrawTransactionParamsRequestPayload} payload payload.
   * @category Actions
   * @subcategory Iroha
   * @return {SendLamportPubKeyRequestPayload} get iroha withdraw transaction params request.
   */
  getIrohaWithdrawTransactionParamsRequest: (
    payload: GetIrohaWithdrawTransactionParamsRequestPayload,
  ) =>
    ({
      type:              GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_REQUEST,
      [WAIT_FOR_ACTION]: `${GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Get iroha withdraw transaction params success action.
   *
   * @author Ihar Kazlouski
   * @function getIrohaWithdrawTransactionParamsSuccess
   * @category Actions
   * @subcategory Iroha
   * @return {GetIrohaAccountSuccessPayload} get iroha withdraw transaction params success.
   */
  getIrohaWithdrawTransactionParamsSuccess: () =>
    ({
      type: GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_SUCCESS,
    } as const),

  /**
   * Get iroha withdraw transaction params failure action.
   *
   * @author Ihar Kazlouski
   * @function getIrohaWithdrawTransactionParamsFailure
   * @category Actions
   * @subcategory Iroha
   * @param {GetIrohaWithdrawTransactionParamsFailurePayload} payload payload.
   * @return {GetIrohaWithdrawTransactionParamsFailurePayload} get iroha withdraw transaction params failure.
   */
  getIrohaWithdrawTransactionParamsFailure: (
    payload: GetIrohaAccountFailurePayload,
  ) =>
    ({
      type: GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_FAILURE,
      payload,
    } as const),

  /**
   * Withdraw iroha transaction request action.
   *
   * @author Ihar Kazlouski
   * @function withdrawIrohaTransactionRequest
   * @param {WithdrawIrohaTransactionRequestPayload} payload payload.
   * @category Actions
   * @subcategory Iroha
   * @return {WithdrawIrohaTransactionRequestPayload} withdraw iroha transaction request.
   */
  withdrawIrohaTransactionRequest: (
    payload: WithdrawIrohaTransactionRequestPayload,
  ) =>
    ({
      type:              WITHDRAW_IROHA_TRANSACTION_REQUEST,
      [WAIT_FOR_ACTION]: `${WITHDRAW_IROHA_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${WITHDRAW_IROHA_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Withdraw iroha transaction success action.
   *
   * @author Ihar Kazlouski
   * @function withdrawIrohaTransactionSuccess
   * @category Actions
   * @subcategory Iroha
   * @return {} withdraw iroha transaction success.
   */
  withdrawIrohaTransactionSuccess: () =>
    ({
      type: WITHDRAW_IROHA_TRANSACTION_SUCCESS,
    } as const),

  /**
   * Withdraw iroha transaction failure action.
   *
   * @author Ihar Kazlouski
   * @function withdrawIrohaTransactionFailure
   * @category Actions
   * @subcategory Iroha
   * @param {WithdrawIrohaTransactionFailurePayload} payload payload.
   * @return {WithdrawIrohaTransactionFailurePayload} withdraw iroha transaction failure.
   */
  withdrawIrohaTransactionFailure: (
    payload: WithdrawIrohaTransactionFailurePayload,
  ) =>
    ({
      type: WITHDRAW_IROHA_TRANSACTION_FAILURE,
      payload,
    } as const),

  /**
   * Get iroha transfer to another account params request action.
   *
   * @author Ihar Kazlouski
   * @function getIrohaTransferToAnotherAccountParamsRequest
   * @param {GetIrohaTransferToAnotherAccountParamsRequestPayload} payload payload.
   * @category Actions
   * @subcategory Iroha
   * @return {GetIrohaTransferToAnotherAccountParamsRequestPayload} get iroha transfer to another account params request.
   */
  getIrohaTransferToAnotherAccountParamsRequest: (
    payload: GetIrohaTransferToAnotherAccountParamsRequestPayload,
  ) =>
    ({
      type:              GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_REQUEST,
      [WAIT_FOR_ACTION]: `${GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Get iroha transfer to another account params success action.
   *
   * @author Ihar Kazlouski
   * @function getIrohaTransferToAnotherAccountParamsSuccess
   * @category Actions
   * @subcategory Iroha
   * @return {}
   */
  getIrohaTransferToAnotherAccountParamsSuccess: () =>
    ({
      type: GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_SUCCESS,
    } as const),

  /**
   * Get iroha transfer to another account params failure action.
   *
   * @author Ihar Kazlouski
   * @function getIrohaTransferToAnotherAccountParamsFailure
   * @category Actions
   * @subcategory Iroha
   * @param {GetIrohaTransferToAnotherAccountParamsFailurePayload} payload payload.
   * @return {GetIrohaTransferToAnotherAccountParamsFailurePayload} get iroha transfer to another account params failure.
   */
  getIrohaTransferToAnotherAccountParamsFailure: (
    payload: GetIrohaTransferToAnotherAccountParamsFailurePayload,
  ) =>
    ({
      type: GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_FAILURE,
      payload,
    } as const),

  /**
   * Transfer iroha transaction to another account request action.
   *
   * @author Ihar Kazlouski
   * @function transferIrohaTransactionToAnotherAccountRequest
   * @param {TransferIrohaTransactionToAnotherAccountRequestPayload} payload payload.
   * @category Actions
   * @subcategory Iroha
   * @return {TransferIrohaTransactionToAnotherAccountRequestPayload} transfer iroha transaction to another account request.
   */
  transferIrohaTransactionToAnotherAccountRequest: (
    payload: TransferIrohaTransactionToAnotherAccountRequestPayload,
  ) =>
    ({
      type:              TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_REQUEST,
      [WAIT_FOR_ACTION]: `${TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Transfer iroha transaction to another account success action.
   *
   * @author Ihar Kazlouski
   * @function transferIrohaTransactionToAnotherAccountSuccess
   * @category Actions
   * @subcategory Iroha
   * @return {}
   */
  transferIrohaTransactionToAnotherAccountSuccess: () =>
    ({
      type: TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_SUCCESS,
    } as const),

  /**
   * Transfer iroha transaction to another account failure action.
   *
   * @author Ihar Kazlouski
   * @function transferIrohaTransactionToAnotherAccountFailure
   * @category Actions
   * @subcategory Iroha
   * @param {TransferIrohaTransactionToAnotherAccountFailurePayload} payload payload.
   * @return {TransferIrohaTransactionToAnotherAccountFailurePayload} transfer iroha transaction to another account failure.
   */
  transferIrohaTransactionToAnotherAccountFailure: (
    payload: TransferIrohaTransactionToAnotherAccountFailurePayload,
  ) =>
    ({
      type: TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_FAILURE,
      payload,
    } as const),
};
