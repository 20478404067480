import { AxiosResponse } from 'axios';
import { SignUpValues } from '@views/pages/sign-up/sign-up.types';

import {
  actions,
  GetContainerFromServerRequestPayload,
  LoginUserRequestPayload,
  SendPhoneRequestPayload,
} from '@store/auth';
import { dispatch as storeDispatch } from '@store/index';

import { ApiService } from '@services/api';
import { InitService } from '@services/init';
import { ConstantsUtil } from '@utils';

import {
  ApiError,
  AsyncDispatch,
  IrohaKeyObj,
  KeyContainer,
  ParsedContainer,
  PhoneNumberResponse,
} from '@types';

/**
 * Auth service.
 *
 * @author Ihar Kazlouski
 * @class AuthServiceClass
 * @category Services
 */
class AuthServiceClass {
  /**
   * Get container from LS.
   *
   * @author Ihar Kazlouski
   */
  getContainerFromLs (): string | null {
    return localStorage.getItem(ConstantsUtil.localStorage.keysContainer);
  }

  /**
   * Get key from container.
   *
   * @param {number} keyNumber number of key.
   * @author Ihar Kazlouski
   */
  getKeyFromContainer (
    keyNumber: number,
    password: string,
    currentUser: string,
    salt: string,
    format = 'hex',
  ): void {
    InitService.worker.postMessage({
      containers:    AuthService.getContainerFromLs(),
      password,
      isReEncrypted: true,
      currentUser,
      keyNumber,
      format,
    });

    InitService.worker.onmessage = ({ data }: { data: string }): void => {
      document.dispatchEvent(new CustomEvent('webWorker', { detail: data }));
    };
  }

  /**
   * Decrypt container in webworker.
   *
   * @param {number} keyNumber number of key.
   * @author Ihar Kazlouski
   */
  getDecryptedContainer (
    container: string,
    isReEncrypted = true,
    password?: string,
    isNeedToEncrypt = false,
    newPassword?: string,
  ): void {
    const currentUser = localStorage.getItem(
      ConstantsUtil.localStorage.currentUser,
    );
    const containers = [container];

    InitService.worker.postMessage({
      containers,
      password,
      isReEncrypted,
      currentUser,
      isNeedToEncrypt,
      newPassword,
    });

    InitService.worker.onmessage = ({ data }: { data: string }): void => {
      document.dispatchEvent(new CustomEvent('webWorker', { detail: data }));
    };
  }

  /**
   * Get unfinished container from LS.
   *
   * @author Ihar Kazlouski
   */
  getUnfinishedContainerFromLs (): string | null {
    return localStorage.getItem(ConstantsUtil.localStorage.unFinishedContainer);
  }

  /**
   * Set unfinished container to LS.
   *
   * @author Ihar Kazlouski
   */
  setUnfinishedToLs (container: string): void {
    localStorage.setItem(
      ConstantsUtil.localStorage.unFinishedContainer,
      container,
    );
  }

  /**
   * Check is container same or not.
   * @param {ParsedContainer} el container.
   * @param {ParsedContainer[]} arr array of containers.
   *
   * @author Ihar Kazlouski
   */
  checkIsSameContainer (el: ParsedContainer, arr: ParsedContainer[]): boolean {
    return arr.map((el) => el.login).indexOf(el.login) > -1;
  }

  /**
   * Set container to LS.
   * @param {string} container container.
   *
   * @author Ihar Kazlouski
   */
  setNewItemToLS (container: string): void {
    const newContainer = [];
    const newParsedContainer = JSON.parse(container || '') as ParsedContainer;

    if (this.getContainerFromLs()) {
      const parsedLsContainer =
        (JSON.parse(this.getContainerFromLs() || '') as ParsedContainer[]) ||
        [];

      if (!this.checkIsSameContainer(newParsedContainer, parsedLsContainer)) {
        parsedLsContainer.push(newParsedContainer);
      }

      storeDispatch(
        actions.setIsItemInLocalStorage({ isItemInLocalStorage: true }),
      );

      localStorage.setItem(
        ConstantsUtil.localStorage.keysContainer,
        JSON.stringify(parsedLsContainer),
      );
    } else {
      newContainer.push(newParsedContainer);

      storeDispatch(
        actions.setIsItemInLocalStorage({ isItemInLocalStorage: false }),
      );

      localStorage.setItem(
        ConstantsUtil.localStorage.keysContainer,
        JSON.stringify(newContainer),
      );
    }
  }

  /**
   * Check is iroha user same or not.
   * @param {IrohaKeyObj} el container.
   * @param {IrohaKeyObj[]} arr array of containers.
   *
   * @author Ihar Kazlouski
   */
  checkIsSameIrohaUser (el: IrohaKeyObj, arr: IrohaKeyObj[]): boolean {
    return arr.map((el) => el.currentUser).indexOf(el.currentUser) > -1;
  }

  /**
   * Set iroha key to LS.
   * @param {string} data salt, current user, iroha key.
   *
   * @author Ihar Kazlouski
   */
  setIrohaKeyToLS (data: string): void {
    const newContainer = [];
    const newParsedContainer = JSON.parse(data || '') as IrohaKeyObj;

    if (localStorage.getItem(ConstantsUtil.localStorage.irohaKey)) {
      const parsedLsContainer =
        (JSON.parse(
          localStorage.getItem(ConstantsUtil.localStorage.irohaKey) || '',
        ) as IrohaKeyObj[]) || [];

      if (!this.checkIsSameIrohaUser(newParsedContainer, parsedLsContainer)) {
        parsedLsContainer.push(newParsedContainer);
      }

      localStorage.setItem(
        ConstantsUtil.localStorage.irohaKey,
        JSON.stringify(parsedLsContainer),
      );
    } else {
      newContainer.push(newParsedContainer);

      localStorage.setItem(
        ConstantsUtil.localStorage.irohaKey,
        JSON.stringify(newContainer),
      );
    }
  }

  /**
   * Set iroha key to LS.
   * @param {string} irohaKey iroha key.
   * @author Ihar Kazlouski
   */
  setIrohaKeyToLs (irohaKey: string): void {
    localStorage.setItem(ConstantsUtil.localStorage.irohaKey, irohaKey);
  }

  /**
   * Send phone number api.
   * @param {string} phoneNumber phone number.
   *
   * @author Ihar Kazlouski
   */
  setPhoneNumberApi (
    payload: SendPhoneRequestPayload,
  ): Promise<ApiError | AxiosResponse<PhoneNumberResponse>> {
    return ApiService.apiPost('/auth/registration/phone', {
      phoneNumber:     payload.phoneNumber,
      parentAttemptId: payload.parentAttemptId,
    });
  }

  /**
   * Send phone number.
   * @param {string} phoneNumber phone number.
   *
   * @author Ihar Kazlouski
   * @return {Promise<KeyContainer | ApiError>} key container response promise.
   */
  async setPhoneNumber (
    phoneNumber: string,
    parentAttemptId: string | null,
  ): Promise<PhoneNumberResponse | ApiError> {
    const dispatch: AsyncDispatch<PhoneNumberResponse | ApiError> =
      storeDispatch;

    return dispatch(actions.sendPhoneRequest({ phoneNumber, parentAttemptId }));
  }

  /**
   * Send decrypted user key and client number.
   * @param {string} keyNumber key number.
   * @param {string} clientNumber client number.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  async setDecryptedUserData (
    keyNumber: string,
    clientNumber: string,
    password: string,
  ): Promise<void> {
    const dispatch: AsyncDispatch<void> = storeDispatch;

    return dispatch(
      actions.setDecryptedUserData({ keyNumber, clientNumber, password }),
    );
  }

  /**
   * User register api.
   * @param {SignUpValues} values sign up values.
   *
   * @author Ihar Kazlouski
   */
  apiPostUserRegister (
    values: SignUpValues,
  ): Promise<ApiError | AxiosResponse<Record<string, unknown>>> {
    return ApiService.apiPost('/auth/registration', { ...values });
  }

  /**
   * User registration.
   * @param {SignUpValues} values sign up values.
   *
   * @author Ihar Kazlouski
   * @return {Promise<Record<string, unknown> | ApiError>} empty response promise.
   */
  async registerUser (
    values: SignUpValues & { keyNumber: string; clientNumber: string },
  ): Promise<Record<string, unknown> | ApiError> {
    const dispatch: AsyncDispatch<Record<string, unknown> | ApiError> =
      storeDispatch;

    return dispatch(
      actions.registerUserRequest({
        phoneNumber:         values.phoneNumber,
        login:               values.login,
        email:               values.email,
        qkeyContainerNumber: values.keyNumber,
        qkeyContainerClient: values.clientNumber,
      }),
    );
  }

  /**
   * Email confirmation api.
   * @param {string} token token.
   * @param {string} salt salt.
   * @param {string} irohaPublicKey iroha public key.
   * @author Ihar Kazlouski
   */
  apiPostEmailConfirmation (
    token: string | null,
    salt: string,
    irohaPublicKey: string,
  ): Promise<ApiError | AxiosResponse<Record<string, unknown>>> {
    return ApiService.apiPost('/auth/registration/confirm-email', {
      token,
      salt,
      pubKey: irohaPublicKey,
    });
  }

  /**
   * Email confirmation.
   * @param {string} token token.
   * @param {string} salt salt.
   * @param {string} irohaPublicKey iroha public key.
   * @author Ihar Kazlouski
   * @return {Promise<Record<string, unknown> | ApiError>} empty response promise.
   */
  async emailConfirmation (
    token: string | null,
    salt: string,
    irohaPublicKey: string,
  ): Promise<Record<string, unknown> | ApiError> {
    const dispatch: AsyncDispatch<Record<string, unknown> | ApiError> =
      storeDispatch;
    return dispatch(
      actions.emailVerificationRequest({
        token,
        salt,
        irohaPublicKey,
      }),
    );
  }

  /**
   * Email resend api.
   * @param {string} token token.
   *
   * @author Ihar Kazlouski
   */
  apiPostResendEmail (
    phoneNumber: string | null,
  ): Promise<ApiError | AxiosResponse<Record<string, unknown>>> {
    return ApiService.apiPost('/auth/registration/resend-email', {
      phoneNumber,
    });
  }

  /**
   * Email resend.
   * @param {string} token token.
   *
   * @author Ihar Kazlouski
   * @return {Promise<Record<string, unknown> | ApiError>} empty response promise.
   */
  async resendEmail (
    phoneNumber: string | null,
  ): Promise<Record<string, unknown> | ApiError> {
    const dispatch: AsyncDispatch<Record<string, unknown> | ApiError> =
      storeDispatch;

    return dispatch(
      actions.emailResendRequest({
        phoneNumber,
      }),
    );
  }

  /**
   * Login api.
   * @param {LoginUserRequestPayload} values values.
   *
   * @author Ihar Kazlouski
   */
  apiPostLogin (
    values: LoginUserRequestPayload,
  ): Promise<ApiError | AxiosResponse<Record<string, unknown>>> {
    return ApiService.apiPost('/auth/login', { ...values });
  }

  /**
   * Login.
   * @param {LoginUserRequestPayload} values values.
   *
   * @author Ihar Kazlouski
   * @return {Promise<Record<string, unknown> | ApiError>} empty response promise.
   */
  async login (
    values: LoginUserRequestPayload,
  ): Promise<Record<string, unknown> | ApiError> {
    const dispatch: AsyncDispatch<Record<string, unknown> | ApiError> =
      storeDispatch;

    return dispatch(
      actions.loginUserRequest({
        ...values,
      }),
    );
  }

  /**
   * Logout api.
   *
   * @author Ihar Kazlouski
   * @return {Promise<Record<string, unknown> | ApiError>} empty response promise.
   */
  apiPostLogout (): Promise<ApiError | AxiosResponse<Record<string, unknown>>> {
    return ApiService.apiPost('/auth/logout', {});
  }

  /**
   * Logout.
   *
   * @author Ihar Kazlouski
   * @return {Promise<Record<string, unknown> | ApiError>} empty response promise.
   */
  async logout (): Promise<Record<string, unknown> | ApiError> {
    const dispatch: AsyncDispatch<Record<string, unknown> | ApiError> =
      storeDispatch;

    return dispatch(actions.logoutUserRequest());
  }

  /**
   * Get container from server api.
   *
   * @author Ihar Kazlouski
   * @param {GetContainerFromServerRequestPayload} payload payload.
   * @return {Promise<Record<string, unknown> | ApiError>} empty response promise.
   */
  apiPostGetContainerFromServer (
    payload: GetContainerFromServerRequestPayload,
  ): Promise<ApiError | AxiosResponse<KeyContainer>> {
    return ApiService.apiPost('auth/registration/qkey-container', {
      ...payload,
    });
  }

  /**
   * Logout.
   *
   * @author Ihar Kazlouski
   * @param {GetContainerFromServerRequestPayload} payload payload.
   * @return {Promise<Record<string, unknown> | ApiError>} empty response promise.
   */
  async getContainerFromServer (
    payload: GetContainerFromServerRequestPayload,
  ): Promise<string | ApiError> {
    const dispatch: AsyncDispatch<string | ApiError> = storeDispatch;

    return dispatch(actions.getContainerFromServerRequest(payload));
  }
}

const AuthService = new AuthServiceClass();

export { AuthService };
