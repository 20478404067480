import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { cryptoHelper } from 'iroha-helpers';
import Crypto from 'iroha-helpers/lib/cryptoHelper';
import sha, { Message } from 'js-sha3';
import SimpleLamport from 'simple-lamport';

import { Button, Grid, TextField, Typography } from '@mui/material';

import { getModalSelector, ModalPayload } from '@store/modals';

import {
  GridsService,
  IrohaService,
  LamportService, LoaderService,
  ModalsService,
} from '@services';
import {
  ConstantsUtil,
  cryptoSimpleDecryption,
  encryptDecrypt,
  errorsHandlerUtil,
  exportTxtData,
  hexToBytes,
} from '@utils';
import { useDeviceSize } from '@hooks';

import { Grids, Modals } from '@enums';
import {
  ApiErrorObject,
  IrohaAccount,
  IrohaKeyObj,
  IrohaWitdrawQcTransactionParams,
  IrohaWithdrawTransactionResult,
  LamportKeys,
  QuantumBalance,
} from '@types';

import { Form, FormField, Modal } from '@elements';

import { TxBuilder } from '../../../libs/lib/chain';
import { WithdrawTransactionValues } from './withdraw-transaction.types';

/**
 * Withdraw transaction modal component.
 *
 * @author Ihar Kazlouski
 * @function WithdrawTransactionModal
 * @category Modals
 * @return {FC} withdraw transaction modal component.
 */
const WithdrawTransactionModal: FC = () => {
  const { t } = useTranslation();
  const deviceSize = useDeviceSize();
  const modal = useSelector(getModalSelector(Modals.WithdrawTransaction));
  const transaction = (
    modal?.data as { transaction: IrohaWitdrawQcTransactionParams }
  ).transaction;

  /**
   * Get lamportk keys.
   *
   * @author Ihar Kazlouski
   * @return {Promise<void>}
   */
  const getLamportKeys = async (): Promise<LamportKeys> => {
    const { data } = (await ModalsService.open(Modals.Password, {
      isCheckLamport: true,
    })) as ModalPayload;

    return data as LamportKeys;
  };

  /**
   * Create iroha transaction.
   *
   * @author Ihar Kazlouski
   * @param {LamportKeys} keys lamport keys.
   * @return {Promise<{transferSignature: string; extractSignature: string; userAccountId: string; description: string; signTime: number}>} return iroha signature and encrypted description.
   */
  const createIrohaTransaction = async (
    keys: LamportKeys,
  ): Promise<{
    transferSignature: string;
    extractSignature: string;
    userAccountId: string;
    description: string;
    signTime: number;
    subtractAssetQuantitySignTime: number;
  }> => {
    const account = (await IrohaService.getIrohaAccount()) as IrohaAccount;

    const irohaPrivateKeysArray = JSON.parse(
      localStorage.getItem(ConstantsUtil.localStorage.irohaKey) || '',
    ) as IrohaKeyObj[];
    const irohaPrivateKey = irohaPrivateKeysArray.find(
      (item) =>
        item.currentUser ===
          localStorage.getItem(ConstantsUtil.localStorage.currentUser) || '',
    ) as IrohaKeyObj;

    const salt = localStorage.getItem(ConstantsUtil.localStorage.salt) || '';
    const decryptedIrohaKey = cryptoSimpleDecryption(
      irohaPrivateKey.encryptedIrohaPrivateKey,
      encryptDecrypt(
        localStorage.getItem(ConstantsUtil.localStorage.cipher) || '',
        salt,
      ),
      irohaPrivateKey.salt,
    );

    const lamport = new SimpleLamport();
    const message = JSON.stringify({
      login:   account.accountId,
      bank:    ConstantsUtil.iroha.destAccountId,
      assetId: ConstantsUtil.iroha.qcAsset,
    });

    const signature = lamport.sign(message, keys.privateKey);
    const description = JSON.stringify({ payload: message, signature });

    const irohaTransferObject = {
      amount:        transaction.qcAmount.toString(),
      assetId:       ConstantsUtil.iroha.qcAsset,
      description:   description,
      destAccountId: ConstantsUtil.iroha.destAccountId,
      srcAccountId:  account.accountId,
    };

    const irohaTransaction = new TxBuilder()
      .transferAsset(irohaTransferObject)
      .addMeta(account.accountId, 1)
      .sign([decryptedIrohaKey]).tx;

    const irohaExtractTransaction = new TxBuilder()
      .subtractAssetQuantity({
        assetId: ConstantsUtil.iroha.toQcAsset,
        amount:  transaction.qcAmount.toString(),
      })
      .addMeta(account.accountId, 1)
      .sign([decryptedIrohaKey]).tx;

    const payloadBuffer = Buffer.from(
      sha.sha3_256.array(
        irohaTransaction?.getPayload()?.serializeBinary() as Message,
      ),
    );
    const payloadExtractBuffer = Buffer.from(
      sha.sha3_256.array(
        irohaExtractTransaction?.getPayload()?.serializeBinary() as Message,
      ),
    );

    const signBuffer = cryptoHelper.sign(
      payloadBuffer,
      Buffer.from(Crypto.derivePublicKey(decryptedIrohaKey)),
      Buffer.from(hexToBytes(decryptedIrohaKey)),
    );
    const signExtractBuffer = cryptoHelper.sign(
      payloadExtractBuffer,
      Buffer.from(Crypto.derivePublicKey(decryptedIrohaKey)),
      Buffer.from(hexToBytes(decryptedIrohaKey)),
    );

    return {
      transferSignature: signBuffer.toString('hex'),
      extractSignature:  signExtractBuffer.toString('hex'),
      userAccountId:     account.accountId,
      description,
      signTime:          irohaTransaction
        ?.getPayload()
        ?.getReducedPayload()
        ?.getCreatedTime() as number,
      subtractAssetQuantitySignTime: irohaExtractTransaction
        ?.getPayload()
        ?.getReducedPayload()
        ?.getCreatedTime() as number,
    };
  };

  /**
   * Send transaction to iroha.
   *
   * @author Ihar Kazlouski
   * @param {LamportKeys} keys lamport keys.
   * @return {Promise<void>}
   */
  const sendTransactionToIroha = async (keys: LamportKeys): Promise<void> => {
    const irohaTransaction = await createIrohaTransaction(keys);

    const resp = (await IrohaService.withdrawIrohaTransaction({
      amount:                          transaction.qcAmount,
      qcAssetId:                       ConstantsUtil.iroha.qcAsset,
      btcAssetId:                      ConstantsUtil.iroha.toQcAsset,
      qcToIrohaTransactionDescription: irohaTransaction.description,
      bankAccountId:                   ConstantsUtil.iroha.destAccountId,
      userAccountId:                   irohaTransaction.userAccountId,
      qcToIrohaSignature:              irohaTransaction.transferSignature,
      subtractAssetQuantitySignature:  irohaTransaction.extractSignature,
      signTime:                        irohaTransaction.signTime,
      subtractAssetQuantitySignTime:   irohaTransaction.subtractAssetQuantitySignTime,
    })) as IrohaWithdrawTransactionResult;

    if (resp.error) {
      let errorData;

      if (
        errorsHandlerUtil(resp.error) ===
        ConstantsUtil.errors.LamportSignatureInvalid
      ) {
        errorData = {
          id:          resp.transactionHash,
          letterTopic: t('modals.error.lamportSignatureInvalidTopic'),
          content:     ConstantsUtil.errors.LamportSignatureInvalid,
          error:       resp.error,
        };
      } else if (
        errorsHandlerUtil(resp.error) ===
          ConstantsUtil.errors.QcToIrohaTransactionRejected ||
        errorsHandlerUtil(resp.error) ===
          ConstantsUtil.errors.QcToIrohaTransactionFailed
      ) {
        errorData = {
          id:          resp.transactionHash,
          letterTopic: t('modals.error.rejectedQcTransactionTopic'),
          content:     `${ConstantsUtil.errors.QcToIrohaTransactionRejected} ${resp.transactionHash}`,
          error:       resp.error,
        };
      } else {
        errorData = {
          id:          resp.transactionHash,
          letterTopic: t('modals.error.unfinishedQcTransactionTopic'),
          content:     `${ConstantsUtil.errors.SubtractAssetQuantityTransactionFailed} ${resp?.transactionHash}`,
          error:       resp.error,
        };
      }

      void ModalsService.open(Modals.InvalidSignature, {
        ...errorData,
      });
    } else {
      exportTxtData(
        resp?.systemWalletInfos.map((key) => {
          return Object.entries(key)
            .map(([key, value]) => `${key}: ${value}`)
            .join(', ');
        }),
        'private-keys',
      );

      void ModalsService.open(Modals.SuccessfulWithdraw);
    }
  };

  /**
   * Grid update.
   *
   * @author Ihar Kazlouski
   * @return {Promise<void>}
   */
  const updateGrid = async (): Promise<void> => {
    const transformResponse = (request: QuantumBalance[]): QuantumBalance[] => {
      const qtcItem = request?.find(
        (item) => item.assetId === ConstantsUtil.iroha.qcAsset,
      ) as QuantumBalance;

      return [qtcItem];
    };

    await GridsService.update<QuantumBalance[], QuantumBalance[]>(
      Grids.QuantumVault,
      IrohaService.apiIrohaBalanceGet,
      transformResponse,
    );
  };

  /**
   * Submit form.
   *
   * @author Ihar Kazlouski
   * @param {WithdrawTransactionValues} values values.
   * @return {Promise<void>}
   */
  const handleSubmit =
    (values: WithdrawTransactionValues) => async (): Promise<void> => {
      try {
        const keys = await getLamportKeys();

        await sendTransactionToIroha(keys);
        await updateGrid();

        ModalsService.close(Modals.WithdrawTransaction);
        LoaderService.decRequestCounter();
      } catch (error) {
        LoaderService.decRequestCounter();
        void ModalsService.open(Modals.Error, {
          content: errorsHandlerUtil(error as ApiErrorObject[]),
        });
      }
    };

  /**
   * Close modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const closeModal = (): void => {
    ModalsService.close(Modals.WithdrawTransaction);
  };

  return (
    <Modal maxWidth='sm' fullWidth>
      <Modal.Title>
        <>
          <Typography variant='body1' fontWeight='700'>
            {t('modals.withdrawQcTransaction.transaction')}
          </Typography>
        </>
      </Modal.Title>
      <Modal.Content>
        <Form
          onSubmit={(values): (() => void) =>
            handleSubmit(values as WithdrawTransactionValues)
          }
          subscription={{
            pristine:   true,
            submitting: true,
            invalid:    true,
            values:     true,
          }}
          initialValues={{
            qcAmount:  transaction?.qcAmount.toString(),
            btcAmount: transaction?.btcAmount.toString(),
          }}
          render={({ values, form }): JSX.Element => {
            return (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormField
                      variant='outlined'
                      name='qcAmount'
                      disabled
                      size='small'
                      component={TextField}
                      fullWidth
                      label={t('modals.withdrawQcTransaction.qcToWithdraw')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      variant='outlined'
                      name='btcAmount'
                      disabled
                      size='small'
                      component={TextField}
                      fullWidth
                      label={t('modals.withdrawQcTransaction.btcToWithdraw')}
                    />
                  </Grid>
                  <Grid
                    container
                    pt={4}
                    pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
                    display='flex'
                    justifyContent='space-around'
                  >
                    <Grid item xs={4} md={2} lg={3}>
                      <Button
                        variant='contained'
                        fullWidth
                        onClick={handleSubmit(
                          values as WithdrawTransactionValues,
                        )}
                        sx={{
                          marginLeft: {
                            xs: 1,
                            lg: 0,
                          },
                        }}
                      >
                        {t('modals.withdrawQcTransaction.sign')}
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      display={deviceSize.desktop ? 'flex' : 'none'}
                    >
                      <Button variant='outlined' fullWidth onClick={closeModal}>
                        {t('modals.withdrawQcTransaction.close')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            );
          }}
        />
      </Modal.Content>
    </Modal>
  );
};

export { WithdrawTransactionModal };
