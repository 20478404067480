import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, Grid } from '@mui/material';

import { getGridSelector } from '@store/grids';
import { getModalSelector } from '@store/modals';

import { ModalsService, TransactionService } from '@services';
import { useDeviceSize } from '@hooks';

import { Address, Grids, Modals } from '@enums';
import { BitcoinWallets, PaymentAddress } from '@types';

import { Form, FormField, Modal, NumberFormatInput } from '@elements';

import { addMoneySchema } from './add-money.schema';
import { AddMoneyValues } from './add-money.types';

/**
 * Add money to transaction modal component.
 *
 * @author Ihar Kazlouski
 * @function AddMoneyModal
 * @category Modals
 * @return {FC} add money to transaction modal component.
 */
const AddMoneyModal: FC = () => {
  const deviceSize = useDeviceSize();
  const { t } = useTranslation();
  const modal = useSelector(getModalSelector(Modals.AddMoney));
  const addresses = useSelector(getGridSelector(Grids.WalletMore));

  /**
   * Find address for change.
   *
   * @author Ihar Kazlouski
   * @return {string} address.
   */
  const findChangeAddress = (): string => {
    return (
      (addresses?.items as BitcoinWallets[])?.[0]?.addresses.find(
        (gridAddress) => gridAddress.type === Address.Change,
      )?.address ||
      (addresses?.items as BitcoinWallets[])?.[0]?.addresses?.[0]?.address
    );
  };

  /**
   * Request for transaction calculating.
   *
   * @author Ihar Kazlouski
   * @param {string} btcAmount amount of btc.
   * @return {Promise<void>}
   */
  const calculateTransactionRequest = async (
    btcAmount: string,
  ): Promise<void> => {
    const paymentAddresses: PaymentAddress[] = [];
    (modal?.data as { addresses: string[] })?.addresses.forEach((address) => {
      (addresses?.items as BitcoinWallets[])?.[0]?.addresses?.forEach(
        (gridAddress) => {
          if (gridAddress.address === address) {
            paymentAddresses.push({
              amount:  `${gridAddress?.balance}`,
              address: gridAddress?.address,
            });
          }
        },
      );
    });

    await TransactionService.calculateTransaction({
      paymentAddresses,
      btcAmount,
      changeAddress: findChangeAddress(),
    });
  };

  /**
   * Submit form.
   *
   * @author Ihar Kazlouski
   * @param {AddMoneyValues} values values.
   * @return {Promise<void>}
   */
  const handleSubmit = (values: AddMoneyValues) => async (): Promise<void> => {
    try {
      await calculateTransactionRequest(values.btcAmount);

      ModalsService.close(Modals.AddMoney);

      void ModalsService.open(Modals.Transaction, {
        name:          (modal?.data as { name: string }).name,
        addresses:     (modal?.data as { addresses: string[] })?.addresses,
        changeAddress: findChangeAddress(),
      });
    } catch (error) {
      void ModalsService.open(Modals.Error, {
        content: t('modals.error.operationFailed'),
      });
    }
  };

  /**
   * Close modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const closeModal = (): void => {
    ModalsService.close(Modals.AddMoney);
  };

  return (
    <Modal maxWidth='sm' fullWidth>
      <Modal.Title>{t('modals.addMoney.addMoney')}</Modal.Title>
      <Modal.Content>
        <Form
          onSubmit={(values): (() => void) =>
            handleSubmit(values as AddMoneyValues)
          }
          subscription={{
            pristine:   true,
            submitting: true,
            invalid:    true,
            values:     true,
          }}
          validateSchema={addMoneySchema()}
          render={({ pristine, submitting, invalid, values }): JSX.Element => {
            return (
              <Grid container>
                <Grid item xs={12}>
                  <FormField
                    variant='outlined'
                    name='btcAmount'
                    size='small'
                    component={NumberFormatInput}
                    fullWidth
                    decimalScale={8}
                    label={t('modals.addMoney.enterBtcSum')}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  pt={4}
                  pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
                  display='flex'
                  justifyContent='space-around'
                >
                  <Grid item xs={12} md={2} lg={3}>
                    <Button
                      variant='contained'
                      disabled={pristine || submitting || invalid}
                      fullWidth
                      onClick={handleSubmit(values as AddMoneyValues)}
                    >
                      {t('modals.addMoney.further')}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    display={deviceSize.desktop ? 'flex' : 'none'}
                  >
                    <Button variant='outlined' fullWidth onClick={closeModal}>
                      {t('modals.addMoney.close')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        />
      </Modal.Content>
    </Modal>
  );
};

export { AddMoneyModal };
