import { FC, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FormApi } from 'final-form';

import { Button, Grid, TextField } from '@mui/material';

import { getModalSelector } from '@store/modals';

import { IrohaService, ModalsService } from '@services';
import { ConstantsUtil, errorsHandlerUtil } from '@utils';
import { useDeviceSize } from '@hooks';

import { Modals } from '@enums';
import { IrohaTransferToAnotherAccountTransactionParams } from '@types';

import { Form, FormField, Modal, NumberFormatInput } from '@elements';

import { transferToAnotherUserSchema } from './transfer-to-another-user.schema';
import { TransferToAnotherUserValues } from './transfer-to-another-user.types';

/**
 * Transfer to another user modal component.
 *
 * @author Ihar Kazlouski
 * @function TransferToAnotherUserModal
 * @category Modals
 * @return {FC} transfer to another user modal component.
 */
const TransferToAnotherUserModal: FC = () => {
  const deviceSize = useDeviceSize();
  const { t } = useTranslation();
  const modal = useSelector(getModalSelector(Modals.TransferToAnotherUser));

  /**
   * Submit form.
   *
   * @author Ihar Kazlouski
   * @param {TransferToAnotherUserValues} values values.
   * @return {Promise<void>}
   */
  const handleSubmit =
    (values: TransferToAnotherUserValues) => async (): Promise<void> => {
      try {
        const transaction = await IrohaService.getTransferToAnotherAccountParams({
          accountRecepient: values.accountRecipient,
          qcAmount:         +values.qtcAmount,
        }) as IrohaTransferToAnotherAccountTransactionParams;

        if (errorsHandlerUtil(transaction.error) === ConstantsUtil.errors.InvalidIrohaAccount) {
          void ModalsService.open(Modals.Error, {
            content: ConstantsUtil.errors.InvalidIrohaAccount,
          });
        } else {
          ModalsService.close(Modals.TransferToAnotherUser);
          void ModalsService.open(Modals.TransferToAnotherUserTransaction, {
            transaction,
            recipient: values.accountRecipient,
          });
        }
      } catch (error) {
        void ModalsService.open(Modals.Error, {
          content: t('modals.error.operationFailed'),
        });
      }
    };

  /**
   * Finds nearest multiple.
   *
   * @author Ihar Kazlouski
   * @param {number} num number.
   * @return {number} number.
   */
  const nearestMultiple = (num: number): number => {
    return Math.floor(num / 0.001) * 0.001;
  };

  /**
   * Handle change function.
   *
   * @author Ihar Kazlouski
   * @param {FormApi} form
   * @return {FocusEvent<HTMLInputElement>} event.
   */
  const handleChange =
    (form: FormApi) =>
      (e: FocusEvent<HTMLInputElement>): void => {
        form.change('qtcAmount', nearestMultiple(+e.target.value).toString());
      };

  /**
   * Close modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const closeModal = (): void => {
    ModalsService.close(Modals.TransferToAnotherUser);
  };

  return (
    <Modal maxWidth='sm' fullWidth>
      <Modal.Title>{t('modals.transferToAnotherAccount.transfer')}</Modal.Title>
      <Modal.Content>
        <Form
          onSubmit={(values): (() => void) =>
            handleSubmit(values as TransferToAnotherUserValues)
          }
          subscription={{
            pristine:   true,
            submitting: true,
            invalid:    true,
            values:     true,
          }}
          validateSchema={transferToAnotherUserSchema(
            (modal?.data as { total: number }).total,
          )}
          render={({
            pristine,
            submitting,
            invalid,
            form,
            values,
          }): JSX.Element => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormField
                    variant='outlined'
                    name='accountRecipient'
                    size='small'
                    component={TextField}
                    fullWidth
                    label={t('modals.transferToAnotherAccount.enterAccount')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    variant='outlined'
                    name='qtcAmount'
                    size='small'
                    component={NumberFormatInput}
                    onBlur={handleChange(form)}
                    fullWidth
                    decimalScale={8}
                    label={t('modals.transferToAnotherAccount.enterQcSum')}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  pt={4}
                  pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
                  display='flex'
                  justifyContent='space-around'
                >
                  <Grid item xs={12} md={2} lg={3}>
                    <Button
                      variant='contained'
                      disabled={pristine || submitting || invalid}
                      fullWidth
                      onClick={handleSubmit(
                        values as TransferToAnotherUserValues,
                      )}
                    >
                      {t('modals.transferToAnotherAccount.further')}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    display={deviceSize.desktop ? 'flex' : 'none'}
                  >
                    <Button variant='outlined' fullWidth onClick={closeModal}>
                      {t('modals.transferToAnotherAccount.close')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        />
      </Modal.Content>
    </Modal>
  );
};

export { TransferToAnotherUserModal };
