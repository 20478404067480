import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Button, Typography } from '@mui/material';

import { getModalSelector } from '@store/modals';

import { ModalsService } from '@services';
import { useDeviceSize } from '@hooks';

import { Modals } from '@enums';

import { Modal } from '@elements';

/**
 * Failed iroha account creation modal component.
 *
 * @author Ihar Kazlouski
 * @function FailedIrohaAccountCreation
 * @category Modals
 * @return {FC} Failed iroha account creation modal component.
 */
const FailedIrohaAccountCreation: FC = () => {
  const deviceSize = useDeviceSize();
  const modal = useSelector(
    getModalSelector(Modals.FailedIrohaAccountCreation),
  );
  const { t } = useTranslation();

  /**
   * Send email to support.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const writeToSupport = (): void => {
    window.location.href = `mailto:quantumchain@gmail.com?subject=${t(
      'modals.failedIrohaAccountCreation.emailSubject',
    )} ${(modal?.data as { id: string }).id}&body=${
      (modal?.data as { content: string }).content
    }`;

    ModalsService.close(Modals.FailedIrohaAccountCreation);
  };

  return (
    <Modal maxWidth='sm' fullWidth>
      <Modal.Title>&nbsp;</Modal.Title>
      <Modal.Content>
        <Typography textAlign='center'>
          {t('modals.failedIrohaAccountCreation.content')}
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Box
          pt={4}
          pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
          display='flex'
          justifyContent='space-around'
        >
          <Button variant='contained' onClick={writeToSupport}>
            {t('modals.failedIrohaAccountCreation.writeToSupport')}
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};

export { FailedIrohaAccountCreation };
