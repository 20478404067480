import { FC, useState } from 'react';

import { TextField } from '@mui/material';

import { FormField } from '@elements';
import { Icon } from '@elements/icon';

import { StyledFormInputIconWrapper } from './password-input.styles';

import closeEye from '@images/close-eye.image.svg';
import openEye from '@images/open-eye.image.svg';

import { PasswordInputProps } from './password-input.types';

/**
 * Password input view component.
 *
 * @author Ihar Kazlouski
 * @function PasswordInputView
 * @category Views
 * @param {string} label input label.
 * @param {string} name name of input.
 * @param {boolean} pristine is input value are the same as the initial values.
 * @return {FC} password input view component.
 */
const PasswordInputView: FC<PasswordInputProps> = ({
  label,
  name,
  pristine,
  parse,
  ...restProps
}) => {
  const [isShowPassword, setShowPassword] = useState(false);

  /**
   * Toggle user password.
   *
   * @author Ihar Kazlouski
   * @function togglePassword
   * @return {void}
   */
  const togglePassword = (): void => {
    setShowPassword(!isShowPassword);
  };

  return (
    <FormField
      data-testid={`${name}-password-input`}
      name={name}
      component={TextField}
      fullWidth
      label={label}
      type={isShowPassword ? 'text' : 'password'}
      parse={parse}
      InputProps={{
        endAdornment: (
          <StyledFormInputIconWrapper
            onClick={togglePassword}
            disabled={pristine}
          >
            <Icon
              src={isShowPassword ? closeEye : openEye}
              width={24}
              height={24}
            />
          </StyledFormInputIconWrapper>
        ),
      }}
      {...restProps}
    />
  );
};

export { PasswordInputView };
