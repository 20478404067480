import { createSelector } from 'reselect';

import { LamportState } from '@store/lamport/lamport.types';
import { AppState } from '@store/root.reducer';

import { LamportKeys } from '@types';

/**
 * Get lamport state.
 *
 * @author Ihar Kazlouski
 * @function getLamportState
 * @category Selectors
 * @subcategory Lamport
 * @param {AppState} state state.
 * @return {LamportState} lamport state.
 */
const getLamportState = (state: AppState): LamportState => state.lamport;

/**
 * Get lamport keys.
 *
 * @author Ihar Kazlouski
 * @function getLamportKeys
 * @category Selectors
 * @subcategory Lamport
 * @param {AppState} state state.
 * @return {LamportKeys | null} lamport keys.
 */
const getLamportKeys = (state: AppState): LamportKeys | null => state.lamport.lamportKeys;

export const getLamportStateSelector = createSelector(
  getLamportState,
  (lamportState: LamportState) => lamportState,
);

export const getLamportKeysSelector = createSelector(
  getLamportKeys,
  (lamportKeys: LamportKeys | null) => lamportKeys,
);
