import { Box, Modal, styled } from '@mui/material';

const LoaderContainer = styled(Box)`
  position: absolute;
  left: 50%;
  top: 50%;
`;

const StyledModal = styled(Modal)`
  z-index: 10000000;
`;

export { LoaderContainer, StyledModal };
