import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { CssBaseline, ThemeProvider } from '@mui/material';

import { getThemeSelector } from '@store/theme/theme.selectors';

import { Theme } from '@enums';

import { darkTheme, lightTheme } from '@themes';

import { WithThemeProps } from './with-theme.types';

/**
 * Material UI theme wrapper component.
 *
 * @author Ihar Kazlouski
 * @function WithTheme
 * @category Hocs
 * @component
 * @param {ReactElement} children wrapped element.
 * @return {ReactElement} wrapped in theme provider element.
 */
const WithTheme: FC<WithThemeProps> = ({ children }): ReactElement => {
  const theme = useSelector(getThemeSelector);

  return (
    <ThemeProvider theme={theme === Theme.Light ? lightTheme : darkTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export { WithTheme };
