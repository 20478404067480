export const GET_IROHA_ACCOUNT_REQUEST = 'GET_IROHA_ACCOUNT_REQUEST';
export const GET_IROHA_ACCOUNT_SUCCESS = 'GET_IROHA_ACCOUNT_SUCCESS';
export const GET_IROHA_ACCOUNT_FAILURE = 'GET_IROHA_ACCOUNT_FAILURE';
export const TRANSFER_IROHA_TRANSACTION_REQUEST = 'TRANSFER_IROHA_TRANSACTION_REQUEST';
export const TRANSFER_IROHA_TRANSACTION_SUCCESS = 'TRANSFER_IROHA_TRANSACTION_SUCCESS';
export const TRANSFER_IROHA_TRANSACTION_FAILURE = 'TRANSFER_IROHA_TRANSACTION_FAILURE';
export const GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_REQUEST = 'GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_REQUEST';
export const GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_SUCCESS = 'GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_SUCCESS';
export const GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_FAILURE = 'GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_FAILURE';
export const WITHDRAW_IROHA_TRANSACTION_REQUEST = 'WITHDRAW_IROHA_TRANSACTION_REQUEST';
export const WITHDRAW_IROHA_TRANSACTION_SUCCESS = 'WITHDRAW_IROHA_TRANSACTION_SUCCESS';
export const WITHDRAW_IROHA_TRANSACTION_FAILURE = 'WITHDRAW_IROHA_TRANSACTION_FAILURE';
export const GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_REQUEST = 'GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_REQUEST';
export const GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_SUCCESS = 'GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_SUCCESS';
export const GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_FAILURE = 'GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_FAILURE';
export const TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_REQUEST = 'TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_REQUEST';
export const TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_SUCCESS = 'TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_SUCCESS';
export const TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_FAILURE = 'TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_FAILURE';
