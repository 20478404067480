import {
  all,
  AllEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';

import { ConstantsUtil } from '@utils';

import { PayloadAction } from '@types';

import {
  INIT_CLOSE_ALL_MODAL,
  INIT_CLOSE_MODAL,
  INIT_MODAL_CLOSING_WITH_ANSWER,
  MODAL_INIT,
} from './modals.action-types';
import { actions } from './modals.actions';
import {
  ModalClosePayload,
  ModalCloseWithAnswerPayload,
  ModalPayload,
} from './modals.types';

/**
 * Open modal saga worker.
 *
 * @author Ihar Kazlouski
 * @function modalOpenSagaWorker
 * @category Sagas
 * @subcategory Modals
 * @return {Generator<PutEffect>} action data.
 */
function* modalOpenSagaWorker (
  action: PayloadAction<ModalPayload>,
): Generator<PutEffect> {
  yield put(
    actions.setModal({
      modalId: action.payload.modalId,
      isOpen:  action.payload.isOpen,
      data:    action.payload.data,
    }),
  );
}

/**
 * Set new modal to saga watcher.
 *
 * @author Ihar Kazlouski
 * @function modalOpenSagaWatcher
 * @category Sagas
 * @subcategory Modals
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* modalOpenSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(MODAL_INIT, modalOpenSagaWorker)]);
}

/**
 * Close modal saga worker.
 *
 * @author Ihar Kazlouski
 * @function modalCloseSagaWorker
 * @category Sagas
 * @subcategory Modals
 * @return {Generator<PutEffect>} action data.
 */
function* modalCloseSagaWorker (
  action: PayloadAction<ModalClosePayload>,
): Generator<PutEffect> {
  yield put(
    actions.modalClose({
      modalId: action.payload.modalId,
    }),
  );

  yield put({
    type:    `${MODAL_INIT}/${ConstantsUtil.actions.ASYNC_SUCCESS}/${action.payload.modalId}`,
    payload: {
      modalId: action.payload.modalId,
    },
  });
}

/**
 * Close modal.
 *
 * @author Ihar Kazlouski
 * @function modalCloseSagaWatcher
 * @category Sagas
 * @subcategory Modals
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* modalCloseSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(INIT_CLOSE_MODAL, modalCloseSagaWorker)]);
}

/**
 * Close all modals saga worker.
 *
 * @author Ihar Kazlouski
 * @function modalCloseSagaWorker
 * @category Sagas
 * @subcategory Modals
 * @return {Generator<PutEffect>} action data.
 */
function* modalCloseAllSagaWorker (
): Generator<PutEffect> {
  yield put(
    actions.modalAllClose(),
  );

  yield put({
    type: `${MODAL_INIT}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
  });
}

/**
 * Close modals.
 *
 * @author Ihar Kazlouski
 * @function modalCloseAllSagaWatcher
 * @category Sagas
 * @subcategory Modals
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* modalCloseAllSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(INIT_CLOSE_ALL_MODAL, modalCloseAllSagaWorker)]);
}

/**
 * Close modal with answer saga worker.
 *
 * @author Ihar Kazlouski
 * @function modalCloseWithAnswerSagaWorker
 * @category Sagas
 * @subcategory Modals
 * @return {Generator<PutEffect>} action data.
 */
function* modalCloseWithAnswerSagaWorker (
  action: PayloadAction<ModalCloseWithAnswerPayload>,
): Generator<PutEffect> {
  yield put(
    actions.closeWithAnswer({
      modalId:  action.payload.modalId,
      accepted: action.payload.accepted,
      rejected: action.payload.rejected,
    }),
  );

  if (action.payload.accepted) {
    yield put({
      type:    `${MODAL_INIT}/${ConstantsUtil.actions.ASYNC_SUCCESS}/${action.payload.modalId}`,
      payload: {
        modalId:  action.payload.modalId,
        accepted: action.payload.accepted,
        rejected: action.payload.rejected,
        data:     action.payload.data,
      },
    });
  } else {
    yield put({
      type:  `${MODAL_INIT}/${ConstantsUtil.actions.ASYNC_FAILED}/${action.payload.modalId}`,
      error: {
        modalId:  action.payload.modalId,
        accepted: action.payload.accepted,
        rejected: action.payload.rejected,
      },
    });
  }
}

/**
 * Close modal with answer.
 *
 * @author Ihar Kazlouski
 * @function modalCloseWithAnswerSagaWatcher
 * @category Sagas
 * @subcategory Modals
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* modalCloseWithAnswerSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([
    takeLatest(INIT_MODAL_CLOSING_WITH_ANSWER, modalCloseWithAnswerSagaWorker),
  ]);
}

/**
 * Modals saga.
 *
 * @author Ihar Kazlouski
 * @function modalsSaga
 * @category Sagas
 * @subcategory Modals
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* modalsSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(modalOpenSagaWatcher),
    fork(modalCloseSagaWatcher),
    fork(modalCloseWithAnswerSagaWatcher),
    fork(modalCloseAllSagaWatcher),
  ]);
}
