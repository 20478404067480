import { createSelector } from 'reselect';

import { AppState } from '@store/root.reducer';
import { TransactionState } from '@store/transaction/transaction.types';

import { TransactionParams } from '@types';

/**
 * Get transaction state.
 *
 * @author Ihar Kazlouski
 * @function getTransactionState
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {TransactionState} transaction state.
 */
const getTransactionState = (state: AppState): TransactionState => state.transaction;

/**
 * Get transaction params.
 *
 * @author Ihar Kazlouski
 * @function getTransactionParams
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {TransactionState} transaction params.
 */
const getTransactionParams = (state: AppState): TransactionParams => state.transaction.transactionParams;

export const getTransactionStateSelector = createSelector(
  getTransactionState,
  (transactionState: TransactionState) => transactionState,
);

export const getTransactionParamsSelector = createSelector(
  getTransactionParams,
  (transactionParams: TransactionParams) => transactionParams,
);
