import { ChangeEvent, forwardRef, ReactElement } from 'react';
import { InputAttributes, NumericFormat } from 'react-number-format';

import { TextField } from '@mui/material';

import { NumberFormatInputProps } from './number-format-input.types';

/**
 * Number format input component.
 *
 * @author Ihar Kazlouski
 * @function NumberFormatInput
 * @category components
 * @param {NumericFormatProps} restProps field rest props.
 * @return {FC} number format input component.
 */
const NumberFormatInput = forwardRef<
  typeof NumericFormat<InputAttributes>,
NumberFormatInputProps
>((props, ref): ReactElement => {
  const { onChange, valueIsNumericString, ...other } = props;

  return (
    <>
      <NumericFormat
        {...other}
        customInput={TextField}
        getInputRef={ref}
        onValueChange={(values): void => {
          if (onChange && other.name) {
            const newValue = {
              target: {
                name:  other.name,
                value: valueIsNumericString
                  ? values.floatValue || values.value
                  : values.formattedValue,
              },
            } as unknown as ChangeEvent<HTMLInputElement>;
            onChange(newValue);
          }
        }}
      />
    </>
  );
});

NumberFormatInput.displayName = 'NumberFormatInput';

export { NumberFormatInput };
