import * as yup from 'yup';

import { i18n } from '@localization';

const withdrawQcSchema = (total: number): yup.BaseSchema => {
  return yup.object().shape({
    qtcAmount: yup
      .number()
      .required(i18n.t('validation.required') || '')
      .max(total, i18n.t('validation.notEnoughFunds') || '')
      .min(0.001, i18n.t('validation.minSum') || ''),
  });
};

export { withdrawQcSchema };
