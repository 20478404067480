import { produce } from 'immer';

import { CLOSE_ALL_MODAL, CLOSE_MODAL, CLOSING_MODAL_WITH_ANSWER, SET_MODAL } from './modals.action-types';
import { ActionsTypes, ModalsState } from './modals.types';

const initialState: ModalsState[] = [];

/**
 * Modals reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Modals
 * @param {ModalsState} state state.
 * @param {ActionsTypes} action action.
 * @return {ModalsState[]} modals state.
 */
const modalsReducer = (
  state = initialState,
  action: ActionsTypes,
): ModalsState[] => {
  switch (action.type) {
    case SET_MODAL: {
      return produce(state, (draft) => {
        const idx = state.findIndex(
          (modal) => modal.modalId === action.payload.modalId,
        );
        if (idx >= 0) {
          draft[idx] = {
            modalId:  action.payload.modalId,
            isOpen:   action.payload.isOpen,
            data:     action.payload.data,
            accepted: false,
            rejected: false,
          };
        } else {
          draft.push({
            modalId:  action.payload.modalId,
            isOpen:   action.payload.isOpen,
            data:     action.payload.data,
            accepted: false,
            rejected: false,
          });
        }
      });
    }

    case CLOSE_MODAL: {
      return produce(state, (draft) => {
        const modal = draft.find(
          (modal) => modal.modalId === action.payload.modalId,
        );

        if (modal) {
          modal.isOpen = false;
        }
      });
    }

    case CLOSE_ALL_MODAL: {
      return [];
    }

    case CLOSING_MODAL_WITH_ANSWER: {
      return produce(state, (draft) => {
        const modal = draft.find(
          (modal) => modal.modalId === action.payload.modalId,
        );

        if (modal) {
          modal.accepted = action.payload.accepted;
          modal.rejected = action.payload.rejected;
          modal.isOpen = false;
        }
      });
    }

    default: {
      return state;
    }
  }
};

export default modalsReducer;
