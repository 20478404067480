export enum Environment {
  Dev = 'dev',
  Qa = 'qa',
  Preprod = 'preprod',
  Master = 'master',
}

export enum Feature {
  ServiceWorker = 'serviceWorker',
  PayloadEncryption = 'payloadEncryption',
}
