import {
  all,
  AllEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';

import {
  INIT_LOADER_HIDING,
  INIT_LOADER_SHOWING,
  INIT_REQUEST_COUNTER_DECREASING,
  INIT_REQUEST_COUNTER_INCREASING,
} from './loader.action-types';
import { actions } from './loader.actions';

/**
 * Show loader saga worker.
 *
 * @author Ihar Kazlouski
 * @function showLoaderSagaWorker
 * @category Sagas
 * @subcategory Loader
 * @return {Generator<PutEffect>} action data.
 */
function* showLoaderSagaWorker (): Generator<PutEffect> {
  yield put(actions.showLoader());
}

/**
 * Show loader saga watcher.
 *
 * @author Ihar Kazlouski
 * @function showLoaderSagaWatcher
 * @category Sagas
 * @subcategory Loader
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* showLoaderSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(INIT_LOADER_SHOWING, showLoaderSagaWorker)]);
}

/**
 * Hide loader saga worker.
 *
 * @author Ihar Kazlouski
 * @function hideLoaderSagaWorker
 * @category Sagas
 * @subcategory Loader
 * @return {Generator<PutEffect>} action data.
 */
function* hideLoaderSagaWorker (): Generator<PutEffect> {
  yield put(actions.hideLoader());
}

/**
 * Hide laoder saga watcher.
 *
 * @author Ihar Kazlouski
 * @function hideLoaderSagaWatcher
 * @category Sagas
 * @subcategory Loader
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* hideLoaderSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(INIT_LOADER_HIDING, hideLoaderSagaWorker)]);
}

/**
 * Increase request counter saga worker.
 *
 * @author Ihar Kazlouski
 * @function hideLoaderSagaWorker
 * @category Sagas
 * @subcategory Loader
 * @return {Generator<PutEffect>} action data.
 */
function* increaseRequestCounterSagaWorker (): Generator<PutEffect> {
  yield put(actions.increaseRequestCounter());
}

/**
 * Increase request counter saga watcher.
 *
 * @author Ihar Kazlouski
 * @function increaseRequestCounterSagaWatcher
 * @category Sagas
 * @subcategory Loader
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* increaseRequestCounterSagaWatcher (): Generator<
AllEffect<ForkEffect>
> {
  yield all([
    takeLatest(
      INIT_REQUEST_COUNTER_INCREASING,
      increaseRequestCounterSagaWorker,
    ),
  ]);
}

/**
 * Decrease request counter saga worker.
 *
 * @author Ihar Kazlouski
 * @function decreaseRequestCounterSagaWorker
 * @category Sagas
 * @subcategory Loader
 * @return {Generator<PutEffect>} action data.
 */
function* decreaseRequestCounterSagaWorker (): Generator<PutEffect> {
  yield put(actions.decreaseRequestCounter());
}

/**
 * Decrease request counter saga watcher.
 *
 * @author Ihar Kazlouski
 * @function increaseRequestCounterSagaWatcher
 * @category Sagas
 * @subcategory Loader
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* decreaseRequestCounterSagaWatcher (): Generator<
AllEffect<ForkEffect>
> {
  yield all([
    takeLatest(
      INIT_REQUEST_COUNTER_DECREASING,
      decreaseRequestCounterSagaWorker,
    ),
  ]);
}

/**
 * Loader saga.
 *
 * @author Ihar Kazlouski
 * @function loaderSaga
 * @category Sagas
 * @subcategory Loader
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* loaderSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(showLoaderSagaWatcher),
    fork(hideLoaderSagaWatcher),
    fork(increaseRequestCounterSagaWatcher),
    fork(decreaseRequestCounterSagaWatcher),
  ]);
}
