import { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';

import { IrohaService } from '@services';
import { ConstantsUtil } from '@utils';

import {
  ApiError,
  IrohaAccount,
  IrohaTransferToAnotherAccountTransactionParams,
  IrohaTransferTransactionToAnotherAccountResult,
  IrohaWitdrawQcTransactionParams,
  IrohaWithdrawTransactionResult,
  PayloadAction,
} from '@types';

import {
  GET_IROHA_ACCOUNT_REQUEST,
  GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_REQUEST,
  GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_REQUEST,
  TRANSFER_IROHA_TRANSACTION_REQUEST,
  TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_REQUEST,
  WITHDRAW_IROHA_TRANSACTION_REQUEST,
} from './iroha.action-types';
import { actions } from './iroha.actions';
import {
  GetIrohaTransferToAnotherAccountParamsRequestPayload,
  GetIrohaWithdrawTransactionParamsRequestPayload,
  TransferIrohaTransactionRequestPayload,
  TransferIrohaTransactionToAnotherAccountRequestPayload,
  WithdrawIrohaTransactionRequestPayload,
} from './iroha.types';

/**
 * Get iroha account saga worker.
 *
 * @author Ihar Kazlouski
 * @function getIrohaAccountSagaWorker
 * @category Sagas
 * @subcategory Iroha
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<IrohaAccount>>} data.
 */
function* getIrohaAccountSagaWorker (): Generator<
PutEffect | CallEffect,
void,
ApiError & AxiosResponse<IrohaAccount>
> {
  try {
    const { data } = yield call(IrohaService.apiIrohaAccountGet);

    yield put(
      actions.getIrohaAccountSuccess({
        irohaAccount: data,
      }),
    );

    yield put({
      type:    `${GET_IROHA_ACCOUNT_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: data,
    });
  } catch (error) {
    yield put(
      actions.getIrohaAccountFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${GET_IROHA_ACCOUNT_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Get iroha account saga watcher.
 *
 * @author Ihar Kazlouski
 * @function getIrohaAccountSagaWatcher
 * @category Sagas
 * @subcategory Iroha
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* getIrohaAccountSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(GET_IROHA_ACCOUNT_REQUEST, getIrohaAccountSagaWorker)]);
}

/**
 * Transfer iroha transaction saga worker.
 *
 * @author Ihar Kazlouski
 * @function transferIrohaTransactionSagaWorker
 * @category Sagas
 * @subcategory Iroha
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<Record<string, unknown>>>} data.
 */
function* transferIrohaTransactionSagaWorker (
  action: PayloadAction<TransferIrohaTransactionRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<Record<string, unknown>>
  > {
  try {
    yield call(IrohaService.apiTransferIrohaTransactionPost, action.payload);

    yield put(actions.transferIrohaTransactionSuccess());

    yield put({
      type: `${TRANSFER_IROHA_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
    });
  } catch (error) {
    yield put(
      actions.transferIrohaTransactionFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${TRANSFER_IROHA_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Transfer iroha transaction saga watcher.
 *
 * @author Ihar Kazlouski
 * @function transferIrohaTransactionSagaWatcher
 * @category Sagas
 * @subcategory Iroha
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* transferIrohaTransactionSagaWatcher (): Generator<
AllEffect<ForkEffect>
> {
  yield all([
    takeLatest(
      TRANSFER_IROHA_TRANSACTION_REQUEST,
      transferIrohaTransactionSagaWorker,
    ),
  ]);
}

/**
 * Get iroha withdraw transaction params saga worker.
 *
 * @author Ihar Kazlouski
 * @function getIrohaWithdrawTransactionParamsSagaWorker
 * @category Sagas
 * @subcategory Iroha
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<IrohaWitdrawQcTransactionParams>>} data.
 */
function* getIrohaWithdrawTransactionParamsSagaWorker (
  action: PayloadAction<GetIrohaWithdrawTransactionParamsRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<IrohaWitdrawQcTransactionParams>
  > {
  try {
    const { data } = yield call(
      IrohaService.apiIrohaWitdrawQcParamsPost,
      action.payload,
    );

    yield put(actions.getIrohaWithdrawTransactionParamsSuccess());

    yield put({
      type:    `${GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: data,
    });
  } catch (error) {
    yield put(
      actions.getIrohaWithdrawTransactionParamsFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Get iroha withdraw transaction params saga watcher.
 *
 * @author Ihar Kazlouski
 * @function getIrohaWithdrawTransactionParamsSagaWatcher
 * @category Sagas
 * @subcategory Iroha
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* getIrohaWithdrawTransactionParamsSagaWatcher (): Generator<
AllEffect<ForkEffect>
> {
  yield all([
    takeLatest(
      GET_IROHA_WITHDRAW_TRANSACTION_PARAMS_REQUEST,
      getIrohaWithdrawTransactionParamsSagaWorker,
    ),
  ]);
}

/**
 * Withdraw iroha transaction saga worker.
 *
 * @author Ihar Kazlouski
 * @function withdrawIrohaTransactionSagaWorker
 * @category Sagas
 * @subcategory Iroha
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<IrohaWithdrawTransactionResult>>} data.
 */
function* withdrawIrohaTransactionSagaWorker (
  action: PayloadAction<WithdrawIrohaTransactionRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<IrohaWithdrawTransactionResult>
  > {
  try {
    const { data } = yield call(
      IrohaService.apiWithdrawIrohaTransactionPost,
      action.payload,
    );

    yield put(actions.withdrawIrohaTransactionSuccess());

    yield put({
      type:    `${WITHDRAW_IROHA_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: data,
    });
  } catch (error) {
    yield put(
      actions.withdrawIrohaTransactionFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${WITHDRAW_IROHA_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Withdraw iroha transaction saga watcher.
 *
 * @author Ihar Kazlouski
 * @function withdrawIrohaTransactionSagaWatcher
 * @category Sagas
 * @subcategory Iroha
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* withdrawIrohaTransactionSagaWatcher (): Generator<
AllEffect<ForkEffect>
> {
  yield all([
    takeLatest(
      WITHDRAW_IROHA_TRANSACTION_REQUEST,
      withdrawIrohaTransactionSagaWorker,
    ),
  ]);
}

/**
 * Get iroha transfer to another account params saga worker.
 *
 * @author Ihar Kazlouski
 * @function getIrohaTransferToAnotherAccountParamsSagaWorker
 * @category Sagas
 * @subcategory Iroha
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<IrohaTransferToAnotherAccountTransactionParams>>} data.
 */
function* getIrohaTransferToAnotherAccountParamsSagaWorker (
  action: PayloadAction<GetIrohaTransferToAnotherAccountParamsRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<IrohaTransferToAnotherAccountTransactionParams>
  > {
  try {
    const { data } = yield call(
      IrohaService.apiIrohaTransferToAnotherAccountParamsPost,
      action.payload,
    );

    yield put(actions.getIrohaTransferToAnotherAccountParamsSuccess());

    yield put({
      type:    `${GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: data,
    });
  } catch (error) {
    yield put(
      actions.getIrohaTransferToAnotherAccountParamsFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Get iroha transfer to another account params saga watcher.
 *
 * @author Ihar Kazlouski
 * @function getIrohaTransferToAnotherAccountParamsSagaWatcher
 * @category Sagas
 * @subcategory Iroha
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* getIrohaTransferToAnotherAccountParamsSagaWatcher (): Generator<
AllEffect<ForkEffect>
> {
  yield all([
    takeLatest(
      GET_IROHA_TRANSFER_TO_ANOTHER_ACCOUNT_PARAMS_REQUEST,
      getIrohaTransferToAnotherAccountParamsSagaWorker,
    ),
  ]);
}

/**
 * Transfer iroha transaction to another account saga worker.
 *
 * @author Ihar Kazlouski
 * @function transferIrohaTransactionToAnotherAccountSagaWorker
 * @category Sagas
 * @subcategory Iroha
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<Record<string, unknown>>>} data.
 */
function* transferIrohaTransactionToAnotherAccountSagaWorker (
  action: PayloadAction<TransferIrohaTransactionToAnotherAccountRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<IrohaTransferTransactionToAnotherAccountResult>
  > {
  try {
    yield call(
      IrohaService.apiTransferIrohaTransactionToAnotherAccountPost,
      action.payload,
    );

    yield put(actions.transferIrohaTransactionToAnotherAccountSuccess());

    yield put({
      type: `${TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
    });
  } catch (error) {
    yield put(
      actions.transferIrohaTransactionToAnotherAccountFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Transfer iroha transaction to another account saga watcher.
 *
 * @author Ihar Kazlouski
 * @function transferIrohaTransactionToAnotherAccountSagaWatcher
 * @category Sagas
 * @subcategory Iroha
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* transferIrohaTransactionToAnotherAccountSagaWatcher (): Generator<
AllEffect<ForkEffect>
> {
  yield all([
    takeLatest(
      TRANSFER_IROHA_TRANSACTION_TO_ANOTHER_ACCOUNT_REQUEST,
      transferIrohaTransactionToAnotherAccountSagaWorker,
    ),
  ]);
}

/**
 * Iroha saga.
 *
 * @author Ihar Kazlouski
 * @function irohaSaga
 * @category Sagas
 * @subcategory Iroha
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* irohaSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(getIrohaAccountSagaWatcher),
    fork(transferIrohaTransactionSagaWatcher),
    fork(getIrohaWithdrawTransactionParamsSagaWatcher),
    fork(withdrawIrohaTransactionSagaWatcher),
    fork(getIrohaTransferToAnotherAccountParamsSagaWatcher),
    fork(transferIrohaTransactionToAnotherAccountSagaWatcher),
  ]);
}
