import React, {
  ReactChild,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { getGridSelector, GridState } from '@store/grids';

import { GridsService } from '@services';
import { getListChildren } from '@utils';

import { GridApiComponentProps } from './api-grid.types';
import { ApiGridComplete } from './api-grid-complete.component';
import { ApiGridError } from './api-grid-error.component';
import { ApiGridLoading } from './api-grid-loading.component';

/**
 * ApiGrid component.
 *
 * @author Ihar Kazlouski
 * @function ApiGrid
 * @category components
 * @return {FC} api grid component.
 */
function ApiGrid<T = unknown, F = T, R = T> ({
  id,
  api,
  transformResponse,
  children,
  fakeGenerator,
}: GridApiComponentProps<T, F, R>): ReactElement {
  const grid = useSelector(getGridSelector(id)) as GridState<T>;
  const gridState: GridState<T> & { fake: T } = useMemo(() => {
    const state: GridState<T> & { fake: T } = {
      ...grid,
      fake: [],
    } as unknown as GridState<T> & { fake: T };

    if (fakeGenerator) {
      if (typeof fakeGenerator.model === 'function') {
        const res = [];

        for (let i = 0; i < fakeGenerator.count; i += 1) {
          res.push((fakeGenerator.model as (index: number) => F)(i));
        }

        state.fake = res as unknown as T;
      } else {
        state.fake = new Array(fakeGenerator.count).fill(
          fakeGenerator.model,
        ) as unknown as T;
      }
    }

    return state;
  }, [grid, fakeGenerator]);

  const loading = useMemo(
    () => getListChildren(children as ReactNode, 'ApiGridLoading'),
    [children],
  );
  const complete = useMemo(() => {
    let completeWithoutProps = getListChildren(
      children as ReactNode,
      'ApiGridComplete',
    );

    if (completeWithoutProps) {
      completeWithoutProps = completeWithoutProps.map((child): ReactChild => {
        const childObj = child as { props: Record<string, unknown> };

        const childWithProps = {
          ...childObj,
          props: {
            ...childObj.props,
            gridState,
          },
        };

        return childWithProps as ReactChild;
      });
    }

    return completeWithoutProps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, grid]);
  const error = useMemo(
    () => getListChildren(children as ReactNode, 'ApiGridError'),
    [children],
  );

  useEffect(() => {
    GridsService.api<T, R>(id, api, transformResponse).catch(() => {
      // Handle error
    });

    return (): void => {
      GridsService.close(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (grid) {
    return typeof children === 'function' ? (
      (children(gridState) as ReactElement)
    ) : grid?.isLoading ? (
      <>{loading}</>
    ) : grid?.isError ? (
      <>{error}</>
    ) : (
      <>{complete}</>
    );
  }

  return <></>;
}

ApiGrid.Loading = ApiGridLoading;
ApiGrid.Complete = ApiGridComplete;
ApiGrid.Error = ApiGridError;

export { ApiGrid };
