import React, { FC } from 'react';

import { Stack, Typography } from '@mui/material';

import { Icon } from '@elements/icon';

import { AuthPageWrapper } from './auth-pages-layout.styles';

import logo from '@images/logo.svg';

import { AuthPagesLayoutProps } from './auth-pages-layout.types';

/**
 * Auth page layout hoc component.
 *
 * @author Ihar Kazlouski
 * @function AuthPagesLayoutHoc
 * @category Hocs
 * @param {ReactNode} children children.
 * @param {string} pageText text of the page.
 * @param {number} spacing spacing.
 * @param {number | undefined} headerBottomMargin bottom margin of header.
 * @return {JSX.Element} wrapped component.
 */
const AuthPagesLayoutHoc: FC<AuthPagesLayoutProps> = ({
  children,
  pageText,
  spacing,
  headerBottomMargin,
}) => {
  return (
    <AuthPageWrapper>
      <Stack width='100%' spacing={spacing} alignItems='center'>
        <Stack mb={headerBottomMargin || 0} alignItems='center'>
          <Icon src={logo} width={120} height={95} color='amazon' />
          <Typography mt={2.5} variant='h5' textAlign='center'>
            {pageText}
          </Typography>
        </Stack>
        {children}
      </Stack>
    </AuthPageWrapper>
  );
};

export { AuthPagesLayoutHoc };
