import { produce } from 'immer';

import { ActionsTypes } from '@store/loader';

import {
  HIDE_LOADER,
  LOADER_SHOW,
  REQUEST_COUNTER_DECREASE,
  REQUEST_COUNTER_INCREASE,
} from './loader.action-types';
import { LoaderState } from './loader.types';

const initialState: LoaderState = {
  isShowLoader:   false,
  requestCounter: 0,
};

/**
 * Loader reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Loader
 * @param {LoaderState} state state.
 * @param {ActionsTypes} action action.
 * @return {LoaderState} modals state.
 */
const loaderReducer = (
  state = initialState,
  action: ActionsTypes,
): LoaderState => {
  switch (action.type) {
    case LOADER_SHOW: {
      return produce(state, (draft) => {
        draft.isShowLoader = true;
      });
    }

    case HIDE_LOADER: {
      return produce(state, (draft) => {
        draft.isShowLoader = false;
      });
    }

    case REQUEST_COUNTER_INCREASE: {
      return produce(state, (draft) => {
        draft.requestCounter += 1;
      });
    }

    case REQUEST_COUNTER_DECREASE: {
      return produce(state, (draft) => {
        draft.requestCounter -= 1;
      });
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default loaderReducer;
