import { FC, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FormApi } from 'final-form';
import { withdrawQcSchema } from '@modals/withdraw-money/withdraw-qc.schema';
import { WithdrawQcValues } from '@modals/withdraw-money/withdraw-qc.types';

import { Button, Grid } from '@mui/material';

import { getModalSelector } from '@store/modals';

import { IrohaService, ModalsService } from '@services';
import { useDeviceSize } from '@hooks';

import { Modals } from '@enums';

import { Form, FormField, Modal, NumberFormatInput } from '@elements';

/**
 * Withdraw qc modal component.
 *
 * @author Ihar Kazlouski
 * @function WithdrawMoneyModal
 * @category Modals
 * @return {FC} withdraw qc modal component.
 */
const WithdrawQcModal: FC = () => {
  const deviceSize = useDeviceSize();
  const { t } = useTranslation();
  const modal = useSelector(getModalSelector(Modals.WithdrawQc));

  /**
   * Submit form.
   *
   * @author Ihar Kazlouski
   * @param {WithdrawQcValues} values values.
   * @return {Promise<void>}
   */
  const handleSubmit = (values: WithdrawQcValues) => async (): Promise<void> => {
    try {
      const transaction = await IrohaService.getIrohaWitdrawQcParams({ qcAmount: +values.qtcAmount });

      ModalsService.close(Modals.WithdrawQc);
      void ModalsService.open(Modals.WithdrawTransaction, { transaction });
    } catch (error) {
      void ModalsService.open(Modals.Error, {
        content: t('modals.error.operationFailed'),
      });
    }
  };

  /**
   * Finds nearest multiple.
   *
   * @author Ihar Kazlouski
   * @param {number} num number.
   * @return {number} number.
   */
  const nearestMultiple = (num: number): number => {
    return Math.floor(num / 0.001) * 0.001;
  };

  /**
   * Handle change function.
   *
   * @author Ihar Kazlouski
   * @param {FormApi} form
   * @return {FocusEvent<HTMLInputElement>} event.
   */
  const handleChange =
    (form: FormApi) =>
      (e: FocusEvent<HTMLInputElement>): void => {
        form.change('qtcAmount', nearestMultiple(+e.target.value).toString());
      };

  /**
   * Close modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const closeModal = (): void => {
    ModalsService.close(Modals.WithdrawQc);
  };

  return (
    <Modal maxWidth='sm' fullWidth>
      <Modal.Title>{t('modals.withdrawQc.withdraw')}</Modal.Title>
      <Modal.Content>
        <Form
          onSubmit={(values): (() => void) =>
            handleSubmit(values as WithdrawQcValues)
          }
          subscription={{
            pristine:   true,
            submitting: true,
            invalid:    true,
            values:     true,
          }}
          validateSchema={withdrawQcSchema(
            (modal?.data as { total: number }).total,
          )}
          render={({
            pristine,
            submitting,
            invalid,
            form,
            values,
          }): JSX.Element => {
            return (
              <Grid container>
                <Grid item xs={12}>
                  <FormField
                    variant='outlined'
                    name='qtcAmount'
                    size='small'
                    component={NumberFormatInput}
                    onBlur={handleChange(form)}
                    fullWidth
                    decimalScale={8}
                    label={t('modals.withdrawQc.enterQcSum')}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  pt={4}
                  pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
                  display='flex'
                  justifyContent='space-around'
                >
                  <Grid item xs={12} md={2} lg={3}>
                    <Button
                      variant='contained'
                      disabled={pristine || submitting || invalid}
                      fullWidth
                      onClick={handleSubmit(values as WithdrawQcValues)}
                    >
                      {t('modals.withdrawQc.further')}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    display={deviceSize.desktop ? 'flex' : 'none'}
                  >
                    <Button variant='outlined' fullWidth onClick={closeModal}>
                      {t('modals.withdrawQc.close')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        />
      </Modal.Content>
    </Modal>
  );
};

export { WithdrawQcModal };
