export const GRID_INIT_REQUEST = 'GRID_INIT_REQUEST';
export const GRID_INIT = 'GRID_INIT';
export const UPDATE_GRID_REQUEST = 'UPDATE_GRID_REQUEST';
export const UPDATE_GRID = 'UPDATE_GRID';
export const CLOSE_GRID_REQUEST = 'CLOSE_GRID_REQUEST';
export const CLOSE_GRID = 'CLOSE_GRID';
export const CLOSE_ALL_GRIDS_REQUEST = 'CLOSE_ALL_GRIDS_REQUEST';
export const CLOSE_ALL_GRIDS = 'CLOSE_ALL_GRIDS';
export const API_GRID_FETCH = 'API_GRID_FETCH';
export const API_GRID_SUCCESS = 'API_GRID_SUCCESS';
export const API_GRID_FAILURE = 'API_GRID_FAILURE';
export const API_GRID_UPDATE_FETCH = 'API_GRID_UPDATE_FETCH';
