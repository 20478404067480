import { Paper, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledPaper = styled(Paper)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:       'flex',
    flexDirection: 'column',
    borderRadius:  pxToRem(12),
    padding:       pxToRem(20),
  };

  return styles;
});

export { StyledPaper };
