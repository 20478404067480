import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { logger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import createReduxWaitForMiddleware from 'redux-wait-for-action';

import rootReducer from './root.reducer';
import { rootSaga } from './root.saga';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();
// Create the waitFor middleware
const waitForMiddleware = createReduxWaitForMiddleware();

// Mount it on the Store
const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware, waitForMiddleware, logger),
  ),
);

// Run the saga
sagaMiddleware.run(rootSaga);

const { dispatch } = store;

export { dispatch };

export default store;
