import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import { StyledBox, StyledTableHead } from './table.styles';

import { TableProps } from './table.types';

/**
 * Table component.
 *
 * @author Ihar Kazlouski
 * @function TableComponent
 * @category components
 * @param {[]} columns table columns.
 * @param {[]} items table items.
 * @return {FC} table component.
 */
function TableComponent<T> ({ columns, items }: TableProps<T>): JSX.Element {
  return (
    <Card>
      <StyledBox>
        <Table>
          <StyledTableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.columnId} width={column.width}>
                  <Box display='flex' alignItems='center'>
                    <Typography variant='caption' whiteSpace='nowrap'>
                      {column.title}
                    </Typography>
                    {column.headerColumnTooltip && column.headerColumnTooltip}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {items.map((item, index) => {
              const rowKey = (item as { id: string }).id;

              return (
                <TableRow hover key={rowKey || index}>
                  {columns.map((column) => (
                    <TableCell key={column.columnId} width={column.width}>
                      {column.itemFormatter
                        ? column.itemFormatter(item)
                        : column.actionButton
                          ? column.actionButton(item)
                          : null}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledBox>
    </Card>
  );
}

export { TableComponent };
