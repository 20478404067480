import { useSelector } from 'react-redux';

import { getModalsSelector } from '@store/modals/modals.selectors';

import { ModalComponent } from './modal.component';

/**
 * Modals component.
 *
 * @author Ihar Kazlouski
 * @function Modals
 * @category components
 * @return {JSX.Element} modals component.
 */
const Modals = (): JSX.Element => {
  const modals = useSelector(getModalsSelector);

  return (
    <>
      {modals?.map((modal) => (
        <ModalComponent key={modal.modalId} modalId={modal.modalId} />
      ))}
    </>
  );
};

export { Modals };
