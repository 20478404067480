// source: primitive.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.iroha.protocol.AccountDetailRecordId', null, global);
goog.exportSymbol('proto.iroha.protocol.CallResult', null, global);
goog.exportSymbol('proto.iroha.protocol.EngineLog', null, global);
goog.exportSymbol('proto.iroha.protocol.EngineReceipt', null, global);
goog.exportSymbol('proto.iroha.protocol.EngineReceipt.ResultOrContractAddressCase', null, global);
goog.exportSymbol('proto.iroha.protocol.GrantablePermission', null, global);
goog.exportSymbol('proto.iroha.protocol.Peer', null, global);
goog.exportSymbol('proto.iroha.protocol.Peer.CertificateCase', null, global);
goog.exportSymbol('proto.iroha.protocol.RolePermission', null, global);
goog.exportSymbol('proto.iroha.protocol.Signature', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.Signature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.Signature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.Signature.displayName = 'proto.iroha.protocol.Signature';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.Peer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.iroha.protocol.Peer.oneofGroups_);
};
goog.inherits(proto.iroha.protocol.Peer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.Peer.displayName = 'proto.iroha.protocol.Peer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.AccountDetailRecordId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.AccountDetailRecordId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.AccountDetailRecordId.displayName = 'proto.iroha.protocol.AccountDetailRecordId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.EngineLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iroha.protocol.EngineLog.repeatedFields_, null);
};
goog.inherits(proto.iroha.protocol.EngineLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.EngineLog.displayName = 'proto.iroha.protocol.EngineLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.CallResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.CallResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.CallResult.displayName = 'proto.iroha.protocol.CallResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.EngineReceipt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iroha.protocol.EngineReceipt.repeatedFields_, proto.iroha.protocol.EngineReceipt.oneofGroups_);
};
goog.inherits(proto.iroha.protocol.EngineReceipt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.EngineReceipt.displayName = 'proto.iroha.protocol.EngineReceipt';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.Signature.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.Signature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.Signature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Signature.toObject = function(includeInstance, msg) {
  var f, obj = {
    publicKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    signature: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.Signature}
 */
proto.iroha.protocol.Signature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.Signature;
  return proto.iroha.protocol.Signature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.Signature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.Signature}
 */
proto.iroha.protocol.Signature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.Signature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.Signature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.Signature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Signature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublicKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string public_key = 1;
 * @return {string}
 */
proto.iroha.protocol.Signature.prototype.getPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.Signature} returns this
 */
proto.iroha.protocol.Signature.prototype.setPublicKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string signature = 2;
 * @return {string}
 */
proto.iroha.protocol.Signature.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.Signature} returns this
 */
proto.iroha.protocol.Signature.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.iroha.protocol.Peer.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.iroha.protocol.Peer.CertificateCase = {
  CERTIFICATE_NOT_SET: 0,
  TLS_CERTIFICATE: 3
};

/**
 * @return {proto.iroha.protocol.Peer.CertificateCase}
 */
proto.iroha.protocol.Peer.prototype.getCertificateCase = function() {
  return /** @type {proto.iroha.protocol.Peer.CertificateCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.Peer.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.Peer.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.Peer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.Peer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Peer.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    peerKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tlsCertificate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    syncingPeer: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.Peer}
 */
proto.iroha.protocol.Peer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.Peer;
  return proto.iroha.protocol.Peer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.Peer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.Peer}
 */
proto.iroha.protocol.Peer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeerKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTlsCertificate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSyncingPeer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.Peer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.Peer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.Peer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Peer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPeerKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSyncingPeer();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.iroha.protocol.Peer.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.Peer} returns this
 */
proto.iroha.protocol.Peer.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string peer_key = 2;
 * @return {string}
 */
proto.iroha.protocol.Peer.prototype.getPeerKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.Peer} returns this
 */
proto.iroha.protocol.Peer.prototype.setPeerKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tls_certificate = 3;
 * @return {string}
 */
proto.iroha.protocol.Peer.prototype.getTlsCertificate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.Peer} returns this
 */
proto.iroha.protocol.Peer.prototype.setTlsCertificate = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.iroha.protocol.Peer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.Peer} returns this
 */
proto.iroha.protocol.Peer.prototype.clearTlsCertificate = function() {
  return jspb.Message.setOneofField(this, 3, proto.iroha.protocol.Peer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Peer.prototype.hasTlsCertificate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool syncing_peer = 4;
 * @return {boolean}
 */
proto.iroha.protocol.Peer.prototype.getSyncingPeer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.iroha.protocol.Peer} returns this
 */
proto.iroha.protocol.Peer.prototype.setSyncingPeer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.AccountDetailRecordId.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.AccountDetailRecordId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.AccountDetailRecordId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AccountDetailRecordId.toObject = function(includeInstance, msg) {
  var f, obj = {
    writer: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.AccountDetailRecordId}
 */
proto.iroha.protocol.AccountDetailRecordId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.AccountDetailRecordId;
  return proto.iroha.protocol.AccountDetailRecordId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.AccountDetailRecordId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.AccountDetailRecordId}
 */
proto.iroha.protocol.AccountDetailRecordId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWriter(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.AccountDetailRecordId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.AccountDetailRecordId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.AccountDetailRecordId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AccountDetailRecordId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWriter();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string writer = 1;
 * @return {string}
 */
proto.iroha.protocol.AccountDetailRecordId.prototype.getWriter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.AccountDetailRecordId} returns this
 */
proto.iroha.protocol.AccountDetailRecordId.prototype.setWriter = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.iroha.protocol.AccountDetailRecordId.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.AccountDetailRecordId} returns this
 */
proto.iroha.protocol.AccountDetailRecordId.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iroha.protocol.EngineLog.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.EngineLog.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.EngineLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.EngineLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.EngineLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    topicsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.EngineLog}
 */
proto.iroha.protocol.EngineLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.EngineLog;
  return proto.iroha.protocol.EngineLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.EngineLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.EngineLog}
 */
proto.iroha.protocol.EngineLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addTopics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.EngineLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.EngineLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.EngineLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.EngineLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.iroha.protocol.EngineLog.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.EngineLog} returns this
 */
proto.iroha.protocol.EngineLog.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.iroha.protocol.EngineLog.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.EngineLog} returns this
 */
proto.iroha.protocol.EngineLog.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string topics = 3;
 * @return {!Array<string>}
 */
proto.iroha.protocol.EngineLog.prototype.getTopicsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.iroha.protocol.EngineLog} returns this
 */
proto.iroha.protocol.EngineLog.prototype.setTopicsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.iroha.protocol.EngineLog} returns this
 */
proto.iroha.protocol.EngineLog.prototype.addTopics = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iroha.protocol.EngineLog} returns this
 */
proto.iroha.protocol.EngineLog.prototype.clearTopicsList = function() {
  return this.setTopicsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.CallResult.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.CallResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.CallResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.CallResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    callee: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resultData: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.CallResult}
 */
proto.iroha.protocol.CallResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.CallResult;
  return proto.iroha.protocol.CallResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.CallResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.CallResult}
 */
proto.iroha.protocol.CallResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallee(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.CallResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.CallResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.CallResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.CallResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCallee();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResultData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string callee = 1;
 * @return {string}
 */
proto.iroha.protocol.CallResult.prototype.getCallee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CallResult} returns this
 */
proto.iroha.protocol.CallResult.prototype.setCallee = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string result_data = 2;
 * @return {string}
 */
proto.iroha.protocol.CallResult.prototype.getResultData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.CallResult} returns this
 */
proto.iroha.protocol.CallResult.prototype.setResultData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iroha.protocol.EngineReceipt.repeatedFields_ = [5];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.iroha.protocol.EngineReceipt.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.iroha.protocol.EngineReceipt.ResultOrContractAddressCase = {
  RESULT_OR_CONTRACT_ADDRESS_NOT_SET: 0,
  CALL_RESULT: 3,
  CONTRACT_ADDRESS: 4
};

/**
 * @return {proto.iroha.protocol.EngineReceipt.ResultOrContractAddressCase}
 */
proto.iroha.protocol.EngineReceipt.prototype.getResultOrContractAddressCase = function() {
  return /** @type {proto.iroha.protocol.EngineReceipt.ResultOrContractAddressCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.EngineReceipt.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.EngineReceipt.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.EngineReceipt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.EngineReceipt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.EngineReceipt.toObject = function(includeInstance, msg) {
  var f, obj = {
    commandIndex: jspb.Message.getFieldWithDefault(msg, 1, 0),
    caller: jspb.Message.getFieldWithDefault(msg, 2, ""),
    callResult: (f = msg.getCallResult()) && proto.iroha.protocol.CallResult.toObject(includeInstance, f),
    contractAddress: jspb.Message.getFieldWithDefault(msg, 4, ""),
    logsList: jspb.Message.toObjectList(msg.getLogsList(),
    proto.iroha.protocol.EngineLog.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.EngineReceipt}
 */
proto.iroha.protocol.EngineReceipt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.EngineReceipt;
  return proto.iroha.protocol.EngineReceipt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.EngineReceipt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.EngineReceipt}
 */
proto.iroha.protocol.EngineReceipt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCommandIndex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaller(value);
      break;
    case 3:
      var value = new proto.iroha.protocol.CallResult;
      reader.readMessage(value,proto.iroha.protocol.CallResult.deserializeBinaryFromReader);
      msg.setCallResult(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractAddress(value);
      break;
    case 5:
      var value = new proto.iroha.protocol.EngineLog;
      reader.readMessage(value,proto.iroha.protocol.EngineLog.deserializeBinaryFromReader);
      msg.addLogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.EngineReceipt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.EngineReceipt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.EngineReceipt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.EngineReceipt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommandIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCaller();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCallResult();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.iroha.protocol.CallResult.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.iroha.protocol.EngineLog.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 command_index = 1;
 * @return {number}
 */
proto.iroha.protocol.EngineReceipt.prototype.getCommandIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.EngineReceipt} returns this
 */
proto.iroha.protocol.EngineReceipt.prototype.setCommandIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string caller = 2;
 * @return {string}
 */
proto.iroha.protocol.EngineReceipt.prototype.getCaller = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.EngineReceipt} returns this
 */
proto.iroha.protocol.EngineReceipt.prototype.setCaller = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CallResult call_result = 3;
 * @return {?proto.iroha.protocol.CallResult}
 */
proto.iroha.protocol.EngineReceipt.prototype.getCallResult = function() {
  return /** @type{?proto.iroha.protocol.CallResult} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.CallResult, 3));
};


/**
 * @param {?proto.iroha.protocol.CallResult|undefined} value
 * @return {!proto.iroha.protocol.EngineReceipt} returns this
*/
proto.iroha.protocol.EngineReceipt.prototype.setCallResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.iroha.protocol.EngineReceipt.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.EngineReceipt} returns this
 */
proto.iroha.protocol.EngineReceipt.prototype.clearCallResult = function() {
  return this.setCallResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.EngineReceipt.prototype.hasCallResult = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string contract_address = 4;
 * @return {string}
 */
proto.iroha.protocol.EngineReceipt.prototype.getContractAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.EngineReceipt} returns this
 */
proto.iroha.protocol.EngineReceipt.prototype.setContractAddress = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.iroha.protocol.EngineReceipt.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.EngineReceipt} returns this
 */
proto.iroha.protocol.EngineReceipt.prototype.clearContractAddress = function() {
  return jspb.Message.setOneofField(this, 4, proto.iroha.protocol.EngineReceipt.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.EngineReceipt.prototype.hasContractAddress = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated EngineLog logs = 5;
 * @return {!Array<!proto.iroha.protocol.EngineLog>}
 */
proto.iroha.protocol.EngineReceipt.prototype.getLogsList = function() {
  return /** @type{!Array<!proto.iroha.protocol.EngineLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.iroha.protocol.EngineLog, 5));
};


/**
 * @param {!Array<!proto.iroha.protocol.EngineLog>} value
 * @return {!proto.iroha.protocol.EngineReceipt} returns this
*/
proto.iroha.protocol.EngineReceipt.prototype.setLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.iroha.protocol.EngineLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.iroha.protocol.EngineLog}
 */
proto.iroha.protocol.EngineReceipt.prototype.addLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.iroha.protocol.EngineLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iroha.protocol.EngineReceipt} returns this
 */
proto.iroha.protocol.EngineReceipt.prototype.clearLogsList = function() {
  return this.setLogsList([]);
};


/**
 * @enum {number}
 */
proto.iroha.protocol.RolePermission = {
  CAN_APPEND_ROLE: 0,
  CAN_CREATE_ROLE: 1,
  CAN_DETACH_ROLE: 2,
  CAN_ADD_ASSET_QTY: 3,
  CAN_SUBTRACT_ASSET_QTY: 4,
  CAN_ADD_PEER: 5,
  CAN_REMOVE_PEER: 46,
  CAN_ADD_SIGNATORY: 6,
  CAN_REMOVE_SIGNATORY: 7,
  CAN_SET_QUORUM: 8,
  CAN_CREATE_ACCOUNT: 9,
  CAN_SET_DETAIL: 10,
  CAN_CREATE_ASSET: 11,
  CAN_TRANSFER: 12,
  CAN_RECEIVE: 13,
  CAN_CREATE_DOMAIN: 14,
  CAN_ADD_DOMAIN_ASSET_QTY: 43,
  CAN_SUBTRACT_DOMAIN_ASSET_QTY: 44,
  CAN_CALL_ENGINE: 48,
  CAN_READ_ASSETS: 15,
  CAN_GET_ROLES: 16,
  CAN_GET_MY_ACCOUNT: 17,
  CAN_GET_ALL_ACCOUNTS: 18,
  CAN_GET_DOMAIN_ACCOUNTS: 19,
  CAN_GET_MY_SIGNATORIES: 20,
  CAN_GET_ALL_SIGNATORIES: 21,
  CAN_GET_DOMAIN_SIGNATORIES: 22,
  CAN_GET_MY_ACC_AST: 23,
  CAN_GET_ALL_ACC_AST: 24,
  CAN_GET_DOMAIN_ACC_AST: 25,
  CAN_GET_MY_ACC_DETAIL: 26,
  CAN_GET_ALL_ACC_DETAIL: 27,
  CAN_GET_DOMAIN_ACC_DETAIL: 28,
  CAN_GET_MY_ACC_TXS: 29,
  CAN_GET_ALL_ACC_TXS: 30,
  CAN_GET_DOMAIN_ACC_TXS: 31,
  CAN_GET_MY_ACC_AST_TXS: 32,
  CAN_GET_ALL_ACC_AST_TXS: 33,
  CAN_GET_DOMAIN_ACC_AST_TXS: 34,
  CAN_GET_MY_TXS: 35,
  CAN_GET_ALL_TXS: 36,
  CAN_GET_BLOCKS: 42,
  CAN_GET_PEERS: 45,
  CAN_GET_MY_ENGINE_RECEIPTS: 50,
  CAN_GET_DOMAIN_ENGINE_RECEIPTS: 51,
  CAN_GET_ALL_ENGINE_RECEIPTS: 52,
  CAN_GRANT_CAN_SET_MY_QUORUM: 37,
  CAN_GRANT_CAN_ADD_MY_SIGNATORY: 38,
  CAN_GRANT_CAN_REMOVE_MY_SIGNATORY: 39,
  CAN_GRANT_CAN_TRANSFER_MY_ASSETS: 40,
  CAN_GRANT_CAN_SET_MY_ACCOUNT_DETAIL: 41,
  CAN_GRANT_CAN_CALL_ENGINE_ON_MY_BEHALF: 49,
  ROOT: 47
};

/**
 * @enum {number}
 */
proto.iroha.protocol.GrantablePermission = {
  CAN_ADD_MY_SIGNATORY: 0,
  CAN_REMOVE_MY_SIGNATORY: 1,
  CAN_SET_MY_QUORUM: 2,
  CAN_SET_MY_ACCOUNT_DETAIL: 3,
  CAN_TRANSFER_MY_ASSETS: 4,
  CAN_CALL_ENGINE_ON_MY_BEHALF: 5
};

goog.object.extend(exports, proto.iroha.protocol);
