import { ReactElement } from 'react';

import { GridApiCompleteComponentProps } from '@components/api-grid/api-grid-complete.types';

/**
 * Api grid complete component.
 *
 * @author Ihar Kazlouski
 * @function ApiGridComplete
 * @category components
 * @param {GridApiCompleteComponentProps<T>} props grid complete component props.
 * @return {ReactNode} children.
 */
function ApiGridComplete<T> ({
  children,
  gridState,
}: GridApiCompleteComponentProps<T>): ReactElement {
  if (gridState) {
    return <>{children(gridState)}</>;
  }

  return <></>;
}

ApiGridComplete.displayName = 'ApiGridComplete';

export { ApiGridComplete };
