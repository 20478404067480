import { Navigate } from 'react-router-dom';

import { LazyPage } from '@hocs';

import { Routes } from '@enums';
import { AppRoutes } from '@types';

const LoginPage = LazyPage('login', 'LoginPage');
const PhoneNumberPage = LazyPage('phone-number', 'PhoneNumberPage');
const SmsPasswordPage = LazyPage('sms-password', 'SmsPasswordPage');
const SignUpPage = LazyPage('sign-up', 'SignUpPage');
const EmailConfirmationPage = LazyPage(
  'email-confirmation',
  'EmailConfirmationPage',
);
const SuccessSignUpPage = LazyPage('success-sign-up', 'SuccessSignUpPage');
const ChangePasswordPage = LazyPage('change-password', 'ChangePasswordPage');
const ErrorPage = LazyPage('error', 'ErrorPage');

const appPublicRoutes: AppRoutes[] = [
  {
    name:    Routes.Main,
    path:    Routes.Main,
    element: <Navigate to={Routes.Login} replace />,
    index:   false,
  },
  {
    name:    Routes.All,
    path:    Routes.All,
    element: <Navigate to={Routes.Login} replace />,
    index:   false,
  },
  {
    name:    Routes.Login,
    path:    Routes.Login,
    element: <LoginPage />,
    index:   false,
  },
  {
    name:    Routes.Phone,
    path:    Routes.Phone,
    element: <PhoneNumberPage />,
    index:   false,
  },
  {
    name:    Routes.Sms,
    path:    Routes.Sms,
    element: <SmsPasswordPage />,
    index:   false,
  },
  {
    name:    Routes.SignUp,
    path:    Routes.SignUp,
    element: <SignUpPage />,
    index:   false,
  },
  {
    name:    Routes.EmailConfirm,
    path:    Routes.EmailConfirm,
    element: <EmailConfirmationPage />,
    index:   false,
  },
  {
    name:    Routes.SuccessSignUp,
    path:    Routes.SuccessSignUp,
    element: <SuccessSignUpPage />,
    index:   false,
  },
  {
    name:    Routes.ChangePassword,
    path:    Routes.ChangePassword,
    element: <ChangePasswordPage />,
    index:   false,
  },
  {
    name:    Routes.Error,
    path:    Routes.Error,
    element: <ErrorPage />,
    index:   false,
  },
];

export { appPublicRoutes };
