import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Button, Typography } from '@mui/material';

import { getModalSelector } from '@store/modals';

import { ModalsService } from '@services';
import { useDeviceSize } from '@hooks';

import { Modals } from '@enums';

import { Modal, ModalProps } from '@elements';

/**
 * Successful transfer modal component.
 *
 * @author Ihar Kazlouski
 * @function SuccessfulTransferModal
 * @category Modals
 * @return {FC} successful transfer modal component.
 */
const SuccessfulTransferModal: FC<ModalProps> = () => {
  const deviceSize = useDeviceSize();
  const { t } = useTranslation();
  const modal = useSelector(getModalSelector(Modals.SuccessfulTransfer));
  const transferData = modal?.data as { account: string; amount: string };

  /**
     * Close modal.
     *
     * @author Ihar Kazlouski
     * @return {void}
     */
  const closeModal = (): void => {
    ModalsService.close(Modals.SuccessfulTransfer);
  };

  return (
        <Modal maxWidth='sm' fullWidth>
            <Modal.Title>
                {t('modals.successfulTransfer.transactionSuccess')}
            </Modal.Title>
            <Modal.Content>
                <Box pb={4}>
                    <Typography textAlign='center'>
                        {t('modals.successfulTransfer.youTransfer')}
                        &nbsp;
                        {transferData?.amount}
                        &nbsp;
                        {t('modals.successfulTransfer.onAccount')}
                        &nbsp;
                        {transferData?.account}!
                    </Typography>
                </Box>
            </Modal.Content>
            <Modal.Footer>
                <Box
                    pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
                    sx={{
                      display: {
                        xs: 'none',
                        lg: 'flex',
                      },
                    }}
                    justifyContent='space-around'
                >
                    <Button variant='contained' onClick={closeModal}>
                        {t('modals.successfulTransfer.close')}
                    </Button>
                </Box>
            </Modal.Footer>
        </Modal>
  );
};

export { SuccessfulTransferModal };
