import React, { Children, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import { getValidChild } from '@utils';
import { useDeviceSize } from '@hooks';

import {
  StyledGrid,
  StyledModalButton,
  StyledModalContainer,
} from './modal.styles';

import { ModalContent, ModalContentProps } from './slots/modal-content';
import { ModalFooter, ModalFooterProps } from './slots/modal-footer';
import { ModalTitle, ModalTitleProps } from './slots/modal-title';
import { ModalProps } from './modal.types';

/**
 * ModalSlot component.
 *
 * @author Ihar Kazlouski
 * @function Modal
 * @category components
 * @param {ModalProps} modal props.
 * @return {JSX.Element} modal component.
 */
const Modal = ({
  children,
  handleClose,
  acceptModal,
  rejectModal,
  modalId,
  customAsyncClose,
}: ModalProps): JSX.Element => {
  const childrenArray = Children.toArray(children);
  const device = useDeviceSize();
  const { t } = useTranslation();

  const title = useMemo(
    () => childrenArray.find(getValidChild<ModalTitleProps>(ModalTitle)),
    [childrenArray],
  );
  const content = useMemo(
    () => childrenArray.find(getValidChild<ModalContentProps>(ModalContent)),
    [childrenArray],
  );
  const footer = useMemo(
    () => childrenArray.find(getValidChild<ModalFooterProps>(ModalFooter)),
    [childrenArray],
  );

  /**
   * Reject modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const handleRejectModal = (): void => {
    rejectModal && rejectModal();
  };

  /**
   * Reject modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const customAsyncRejectModal = async (): Promise<void> => {
    customAsyncClose && await customAsyncClose();
    rejectModal && rejectModal();
  };

  return (
    <StyledModalContainer>
      {title && (
        <ModalTitle
          acceptModal={acceptModal}
          rejectModal={rejectModal}
          handleClose={handleClose}
          modalId={modalId}
        >
          {title.props.children}
        </ModalTitle>
      )}
      {content && (
        <ModalContent
          acceptModal={acceptModal}
          rejectModal={rejectModal}
          handleClose={handleClose}
          modalId={modalId}
        >
          {content.props.children}
        </ModalContent>
      )}
      {footer && (
        <ModalFooter
          acceptModal={acceptModal}
          rejectModal={rejectModal}
          handleClose={handleClose}
          modalId={modalId}
        >
          {footer.props.children}
        </ModalFooter>
      )}
      {!device.desktop && (
        <StyledGrid
          container
          justifyContent='center'
          alignItems='center'
          direction='column'
        >
          <Grid item xs={12}>
            <StyledModalButton
              variant='text'
              color='primary'
              onClick={customAsyncRejectModal || handleRejectModal}
            >
              {t('modals.close')}
            </StyledModalButton>
          </Grid>
        </StyledGrid>
      )}
    </StyledModalContainer>
  );
};

Modal.Title = ModalTitle;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;

export { Modal };
