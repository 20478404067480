import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as bitcoin from 'bitcoinjs-lib';
import { ECPairFactory } from 'ecpair';
import { ECPairInterface } from 'ecpair/src/ecpair';
import { FormApi } from 'final-form';
import { cryptoHelper } from 'iroha-helpers';
import Crypto from 'iroha-helpers/lib/cryptoHelper';
import sha, { Message } from 'js-sha3';
import SimpleLamport from 'simple-lamport';
import * as ecc from 'tiny-secp256k1';
import { db } from '@db';
import { TransactionValues } from '@modals/transaction/transaction.types';

import { Button, Grid, TextField, Typography } from '@mui/material';

import { getGridSelector } from '@store/grids';
import { getModalSelector, ModalPayload } from '@store/modals';
import { getTransactionParamsSelector } from '@store/transaction';

import {
  AuthService,
  GridsService,
  IrohaService,
  LoaderService,
  ModalsService,
  TransactionService,
  WalletService,
} from '@services';
import {
  ConstantsUtil,
  cryptoSimpleDecryption,
  encryptDecrypt,
  errorsHandlerUtil,
  hexToBytes,
  reEncryptPrivateKey,
} from '@utils';
import { useDeviceSize } from '@hooks';

import { Grids, Modals } from '@enums';
import {
  AddressOutputs,
  ApiErrorObject,
  BitcoinWallets,
  IrohaAccount,
  IrohaKeyObj,
  IrohaTransferAsset,
  LamportKeys,
  OutputsForNewTransaction,
  OutputsForTransaction,
} from '@types';

import { Form, FormField, Modal, NumberFormatInput } from '@elements';

import { TxBuilder } from '../../../libs/lib/chain';

/**
 * Transaction creating modal component.
 *
 * @author Ihar Kazlouski
 * @function TransactionModal
 * @category Modals
 * @return {FC} transaction creating modal component.
 */
const TransactionModal: FC = () => {
  const deviceSize = useDeviceSize();
  const { t } = useTranslation();
  const [recalculate, setRecalculate] = useState(false);
  const transaction = useSelector(getTransactionParamsSelector);
  const currentGrid = useSelector(getGridSelector(Grids.WalletMore));
  const modal = useSelector(getModalSelector(Modals.Transaction));
  const ECPair = ECPairFactory(ecc);

  /**
   * Get Private keys from db and reencrypt them.
   *
   * @author Ihar Kazlouski
   * @return {Promise<string[]>} private keys.
   */
  const getPrivateKeyFromDb = async (): Promise<string[]> => {
    const wallets = await db.wallets.toArray();
    const wallet = wallets.find(
      (wallet) => wallet.name === (modal?.data as { name: string }).name,
    );
    const privateKeys: string[] = [];

    wallet?.addresses.forEach((address) => {
      (modal?.data as { addresses: string[] })?.addresses.forEach(
        (chosenAddress) => {
          if (address.address === chosenAddress) {
            const decryptedPrivateKey = cryptoSimpleDecryption(
              address.key,
              encryptDecrypt(
                localStorage.getItem(ConstantsUtil.localStorage.cipher) || '',
                localStorage.getItem(ConstantsUtil.localStorage.salt) || '',
              ),
              address.salt,
            );
            privateKeys.push(decryptedPrivateKey);

            reEncryptPrivateKey(
              address.address,
              decryptedPrivateKey,
              (modal?.data as { name: string }).name,
            );
          }
        },
      );
    });

    return privateKeys;
  };

  /**
   * Cancel operation.
   *
   * @author Ihar Kazlouski
   * @return {Promise<void>}
   */
  const cancelOperation = async (): Promise<void> => {
    try {
      await TransactionService.cancelTransaction(transaction.transactionId);
      void ModalsService.close(Modals.Transaction);
    } catch (error) {
      void ModalsService.open(Modals.Error, {
        content: errorsHandlerUtil(error as ApiErrorObject[]),
      });
    }
  };

  /**
   * Transform outputs of previous transactions.
   *
   * @author Ihar Kazlouski
   * @return {Promise<OutputsForTransaction[]>} previous outputs.
   */
  const transformOutputs = async (
    address: string,
  ): Promise<OutputsForTransaction[]> => {
    const outputsPromise = new Promise((resolve, reject) => {
      const outputs: OutputsForTransaction[] = [];

      void (async (): Promise<void> => {
        try {
          const resp = (await WalletService.getAddressOutputs(
            address,
          )) as AddressOutputs;

          resp.outputs.forEach((output) => {
            outputs.push({
              hash:           output.transactionId,
              index:          +output.outputNumber,
              nonWitnessUtxo: Buffer.from(output.hex, 'hex'),
            });
          });

          resolve(outputs);
        } catch (error) {
          reject(error);
        }
      })();
    });

    const outputsPromiseResult =
      (await outputsPromise) as OutputsForTransaction[];
    return outputsPromiseResult;
  };

  /**
   * Create outputs for new transactions.
   *
   * @author Ihar Kazlouski
   * @param {string} btcAmount amount of btc.
   * @return {OutputsForNewTransaction[]} outputs.
   */
  const createOutputsForNewTransaction = (): OutputsForNewTransaction[] => {
    return transaction.wallets.map((address) => {
      return {
        address: address.address,
        value:   Math.floor(+address.amount * 1e8),
      };
    });
  };

  /**
   * Recalculate transaction.
   *
   * @author Ihar Kazlouski
   * @param {string} commissionByteBTC commission for byte.
   * @return {Promise<void>}
   */
  const reCalculateTransaction =
    (commissionByteBTC: string) => async (): Promise<void> => {
      await TransactionService.reCalculateTransaction({
        paymentAddresses: transaction.paymentAddresses,
        btcAmount:        transaction.btcAmount.toString(),
        comissionByteBtc: commissionByteBTC,
        changeAddress:    (modal?.data as { changeAddress: string }).changeAddress,
        transactionId:    transaction.transactionId,
      });

      setRecalculate(false);
    };

  /**
   * Send transaction to bitcoin network.
   *
   * @author Ihar Kazlouski
   * @return {Promise<void>}
   */
  const sendTransactionToBitcoinNetwork = async (): Promise<void> => {
    const psbt = new bitcoin.Psbt({ network: ConstantsUtil.network });
    const privateKeys = await getPrivateKeyFromDb();

    if (privateKeys.length === 0) {
      LoaderService.decRequestCounter();
      await ModalsService.open(Modals.UploadWallet);
      LoaderService.incRequestCounter();
      void ModalsService.close(Modals.Password);
    } else if (!localStorage.getItem(ConstantsUtil.localStorage.irohaKey)) {
      LoaderService.decRequestCounter();
      await ModalsService.open(Modals.UploadKeys, {
        content:  t('modals.uploadKeys.irohaKeys'),
        loadFunc: AuthService.setIrohaKeyToLS,
      });
      LoaderService.incRequestCounter();
      void ModalsService.close(Modals.Password);
    } else {
      for (const address of transaction.paymentAddresses) {
        const previousOutputs = await transformOutputs(address.address);
        psbt.addInputs(previousOutputs);
      }

      const newOutputs = createOutputsForNewTransaction();
      psbt.addOutputs(newOutputs);

      privateKeys.forEach((key, index) => {
        let alice: ECPairInterface;

        if (key.length === 51 || key.length === 52) {
          alice = ECPair.fromWIF(key);
        } else if (key.trim().length === 44) {
          alice = ECPair.fromPrivateKey(Buffer.from(key, 'base64'));
        } else {
          alice = ECPair.fromPrivateKey(Buffer.from(key, 'hex'));
        }

        psbt.signAllInputs(alice);
      });

      psbt.finalizeAllInputs();

      await TransactionService.updateHex({
        transactionId: transaction.transactionId,
        hex:           psbt.extractTransaction(true).toHex(),
      });
    }
  };

  /**
   * Get lamport session keys.
   *
   * @author Ihar Kazlouski
   * @return {Promise<void>}
   */
  const getSessionKeys = async (): Promise<LamportKeys | undefined> => {
    const { data } = (await ModalsService.open(Modals.Password, {
      isCheckLamport: true,
    })) as ModalPayload;

    return data as LamportKeys;
  };

  /**
   * Grid update.
   *
   * @author Ihar Kazlouski
   * @return {Promise<void>}
   */
  const updateGrid = async (): Promise<void> => {
    const transformResponse = (request: BitcoinWallets): BitcoinWallets[] => {
      return [request];
    };
    const id = (currentGrid?.items as BitcoinWallets[])?.[0]?.id;

    await GridsService.update<BitcoinWallets[], BitcoinWallets>(
      Grids.WalletMore,
      WalletService.apiWalletGet.bind(WalletService, id || ''),
      transformResponse,
    );
  };

  /**
   * Create iroha transaction.
   *
   * @author Ihar Kazlouski
   * @param {LamportKeys} keys lamport keys.
   * @return {Promise<{signature: string; description: string}>} return iroha signature and encrypted description.
   */
  const createIrohaTransaction = async (
    keys: LamportKeys,
  ): Promise<{
    signature: string;
    irohaTransferObject: IrohaTransferAsset;
    signTime: number;
    message: string;
  }> => {
    const account = (await IrohaService.getIrohaAccount()) as IrohaAccount;

    const irohaPrivateKeysArray = JSON.parse(
      localStorage.getItem(ConstantsUtil.localStorage.irohaKey) || '',
    ) as IrohaKeyObj[];
    const irohaPrivateKey = irohaPrivateKeysArray.find(
      (item) =>
        item.currentUser ===
          localStorage.getItem(ConstantsUtil.localStorage.currentUser) || '',
    ) as IrohaKeyObj;

    const salt = localStorage.getItem(ConstantsUtil.localStorage.salt) || '';
    const decryptedIrohaKey = cryptoSimpleDecryption(
      irohaPrivateKey.encryptedIrohaPrivateKey,
      encryptDecrypt(
        localStorage.getItem(ConstantsUtil.localStorage.cipher) || '',
        salt,
      ),
      irohaPrivateKey.salt,
    );

    const lamport = new SimpleLamport();
    const message = JSON.stringify({
      login:   account.accountId,
      bank:    ConstantsUtil.iroha.destAccountId,
      assetId: ConstantsUtil.iroha.toQcAsset,
    });

    const signature = lamport.sign(message, keys.privateKey);
    const description = JSON.stringify({ payload: message, signature });

    const irohaTransferObject = {
      amount:        transaction.totalBtc.toString(),
      assetId:       ConstantsUtil.iroha.toQcAsset,
      description:   description,
      destAccountId: ConstantsUtil.iroha.destAccountId,
      srcAccountId:  account.accountId,
    };

    const irohaTransaction = new TxBuilder()
      .transferAsset(irohaTransferObject)
      .addMeta(account.accountId, 1)
      .sign([decryptedIrohaKey]).tx;

    const payloadBuffer = Buffer.from(
      sha.sha3_256.array(
        irohaTransaction?.getPayload()?.serializeBinary() as Message,
      ),
    );
    const signBuffer = cryptoHelper.sign(
      payloadBuffer,
      Buffer.from(Crypto.derivePublicKey(decryptedIrohaKey)),
      Buffer.from(hexToBytes(decryptedIrohaKey)),
    );

    return {
      signature: signBuffer.toString('hex'),
      irohaTransferObject,
      signTime:  irohaTransaction
        ?.getPayload()
        ?.getReducedPayload()
        ?.getCreatedTime() as number,
      message,
    };
  };

  /**
   * Send transaction to iroha.
   *
   * @author Ihar Kazlouski
   * @param {LamportKeys} keys lamport keys.
   * @return {Promise<void>}
   */
  const sendTransactionToIroha = async (keys: LamportKeys): Promise<void> => {
    const irohaTransaction = await createIrohaTransaction(keys);

    await IrohaService.transferIrohaTransaction({
      amount:        transaction.totalBtc,
      description:   irohaTransaction.irohaTransferObject.description,
      assetId:       irohaTransaction.irohaTransferObject.assetId,
      destAccountId: irohaTransaction.irohaTransferObject.destAccountId,
      srcAccountId:  irohaTransaction.irohaTransferObject.srcAccountId,
      signature:     irohaTransaction.signature,
      signTime:      irohaTransaction.signTime,
      transactionId: transaction.transactionId,
    });
  };

  /**
   * Submit form.
   *
   * @author Ihar Kazlouski
   * @param {TransactionValues} values values.
   * @return {void}
   */
  const handleSubmit =
    (values: TransactionValues) => async (): Promise<void> => {
      try {
        const keys = await getSessionKeys();

        await sendTransactionToBitcoinNetwork();
        await sendTransactionToIroha(keys as LamportKeys);

        ModalsService.close(Modals.Transaction);

        await updateGrid();
        LoaderService.decRequestCounter();
      } catch (error) {
        console.log(error);

        LoaderService.decRequestCounter();

        const errorDescription = (error as ApiErrorObject[])?.[0]?.parameters?.[0];

        if (errorsHandlerUtil(error as ApiErrorObject[]) === ConstantsUtil.errors.LamportSignatureInvalid) {
          void ModalsService.open(Modals.InvalidSignature, {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            id:          JSON.parse(errorDescription)?.[0]?.txHash || '',
            letterTopic: t('modals.error.lamportSignatureInvalidTopic'),
            content:     ConstantsUtil.errors.LamportSignatureInvalid,
            error:       errorDescription,
          });
        } else if (errorsHandlerUtil(error as ApiErrorObject[]) === ConstantsUtil.errors.QcToIrohaTransactionRejected) {
          void ModalsService.open(Modals.InvalidSignature, {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            id:          JSON.parse(errorDescription)?.[0]?.txHash || '',
            letterTopic: t('modals.invalidSignature.qcTransactionFailedTopic'),
            content:     ConstantsUtil.errors.QcToIrohaTransactionRejected,
            error:       errorDescription,
          });
        } else {
          void ModalsService.open(Modals.Error, {
            content: t('modals.error.operationFailed'),
          });
        }
      }
    };

  /**
   * Open form for recalculating.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const recalculateSatoshi =
    (values: TransactionValues, form: FormApi, name: string) =>
      (e: ChangeEvent<HTMLInputElement>): void => {
        if (!recalculate) {
          setRecalculate(true);
        }

        if (name === 'commissionByteBTC') {
          form.change(
            'commissionRecommended',
            ((+values.transactionSize * +e.target.value) / 1e8)
              .toFixed(8)
              .toString(),
          );
        }

        if (name === 'commissionRecommended') {
          form.change(
            'commissionByteBTC',
            ((+e.target.value / +values.transactionSize) * 1e8)
              .toFixed(8)
              .toString(),
          );
        }
      };

  /**
   * Close form for recalculating.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const cancelRecalculate = (form: FormApi) => (): void => {
    form.change('commissionByteBTC', transaction.comissionByteBtc.toString());
    form.change(
      'commissionRecommended',
      transaction.comissionRecommBtc.toString(),
    );
    setRecalculate(false);
  };

  return (
    <Modal maxWidth='sm' fullWidth customAsyncClose={cancelOperation}>
      <Modal.Title>
        <>
          <Typography variant='body1' fontWeight='700'>
            {t('modals.transaction.transaction')}
          </Typography>
          {transaction.changeAmount < 0 ? (
            <Typography color='error'>
              {' '}
              {t('modals.transaction.notEnoughMoney')}
            </Typography>
          ) : null}
        </>
      </Modal.Title>
      <Modal.Content>
        <Form
          onSubmit={(values): (() => void) =>
            handleSubmit(values as TransactionValues)
          }
          subscription={{
            pristine:   true,
            submitting: true,
            invalid:    true,
            values:     true,
          }}
          initialValues={{
            totalBtc:              transaction.totalAmount.toString(),
            btcToChange:           transaction.btcAmount.toString(),
            commission:            transaction.comissionAmount.toString(),
            inputs:                transaction.paymentAddresses.length.toString(),
            outputs:               transaction.wallets.length.toString(),
            transactionSize:       transaction.transactionSize.toString(),
            commissionByteBTC:     transaction.comissionByteBtc.toString(),
            commissionRecommended: transaction.comissionRecommBtc.toString(),
            totalBtcToWithdraw:    transaction.totalBtc.toString(),
            change:                transaction.changeAmount.toString(),
          }}
          render={({ values, form }): JSX.Element => {
            return (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormField
                      variant='outlined'
                      name='totalBtc'
                      disabled
                      size='small'
                      component={TextField}
                      fullWidth
                      label={t('modals.transaction.totalBtcOnAddresses')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      variant='outlined'
                      name='btcToChange'
                      disabled
                      size='small'
                      component={TextField}
                      fullWidth
                      label={t('modals.transaction.btcToChange')}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormField
                      variant='outlined'
                      name='commission'
                      disabled
                      size='small'
                      component={TextField}
                      fullWidth
                      label={`${t('modals.transaction.serviceCommission')} (${
                        transaction.comissionSize
                      }%)`}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormField
                      variant='outlined'
                      name='inputs'
                      disabled
                      size='small'
                      component={TextField}
                      fullWidth
                      label={t('modals.transaction.inputs')}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormField
                      variant='outlined'
                      name='outputs'
                      disabled
                      size='small'
                      component={TextField}
                      fullWidth
                      label={t('modals.transaction.outputs')}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormField
                      variant='outlined'
                      name='transactionSize'
                      disabled
                      size='small'
                      component={TextField}
                      fullWidth
                      label={t('modals.transaction.transactionSize')}
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    justifyContent='space-between'
                    alignItems='center'
                    xs={12}
                  >
                    <Grid item xs={5}>
                      <FormField
                        variant='outlined'
                        name='commissionByteBTC'
                        size='small'
                        component={NumberFormatInput}
                        fullWidth
                        label={t('modals.transaction.commissionByteBTC')}
                        onChange={recalculateSatoshi(
                          values as TransactionValues,
                          form,
                          'commissionByteBTC',
                        )}
                      />
                    </Grid>

                    <Grid item xs={1} alignItems='center'>
                      <Typography textAlign='center' variant='body2'>
                        =
                      </Typography>
                    </Grid>

                    <Grid item xs={5}>
                      <FormField
                        variant='outlined'
                        name='commissionRecommended'
                        size='small'
                        component={NumberFormatInput}
                        fullWidth
                        label={t('modals.transaction.commissionForTransaction')}
                        onChange={recalculateSatoshi(
                          values as TransactionValues,
                          form,
                          'commissionRecommended',
                        )}
                      />
                    </Grid>
                  </Grid>

                  {recalculate ? (
                    <Grid
                      container
                      pt={4}
                      pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
                      display='flex'
                      justifyContent='space-around'
                      spacing={2}
                    >
                      <Grid item xs={4} sm={3}>
                        <Button
                          variant='contained'
                          size='small'
                          fullWidth
                          onClick={reCalculateTransaction(
                            (values as TransactionValues).commissionByteBTC,
                          )}
                        >
                          {t('modals.transaction.recalculate')}
                        </Button>
                      </Grid>
                      <Grid item xs={1} sm={2} />
                      <Grid item xs={4} sm={3}>
                        <Button
                          variant='outlined'
                          size='small'
                          fullWidth
                          onClick={cancelRecalculate(form)}
                        >
                          {t('modals.transaction.close')}
                        </Button>
                      </Grid>
                    </Grid>
                  ) : null}
                  {!recalculate ? (
                    <>
                      <Grid item xs={12}>
                        <FormField
                          variant='outlined'
                          name='totalBtcToWithdraw'
                          disabled
                          size='small'
                          component={TextField}
                          fullWidth
                          label={t('modals.transaction.btcToWithdraw')}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormField
                          variant='outlined'
                          name='change'
                          disabled
                          size='small'
                          component={TextField}
                          fullWidth
                          label={t('modals.transaction.change')}
                        />
                      </Grid>

                      <Grid
                        container
                        pt={4}
                        pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
                        display='flex'
                        justifyContent='space-around'
                      >
                        <Grid item xs={4} md={2} lg={3}>
                          <Button
                            variant='contained'
                            fullWidth
                            disabled={transaction.changeAmount < 0}
                            onClick={handleSubmit(values as TransactionValues)}
                            sx={{
                              marginLeft: {
                                xs: 1,
                                lg: 0,
                              },
                            }}
                          >
                            {t('modals.transaction.sign')}
                          </Button>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          display={deviceSize.desktop ? 'flex' : 'none'}
                        >
                          <Button
                            variant='outlined'
                            fullWidth
                            onClick={cancelOperation}
                          >
                            {t('modals.transaction.close')}
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </>
            );
          }}
        />
      </Modal.Content>
    </Modal>
  );
};

export { TransactionModal };
