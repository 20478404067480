export const CALCULATE_TRANSACTION_REQUEST = 'CALCULATE_TRANSACTION_REQUEST';
export const CALCULATE_TRANSACTION_SUCCESS = 'CALCULATE_TRANSACTION_SUCCESS';
export const CALCULATE_TRANSACTION_FAILURE = 'CALCULATE_TRANSACTION_FAILURE';
export const CANCEL_TRANSACTION_REQUEST = 'CANCEL_TRANSACTION_REQUEST';
export const CANCEL_TRANSACTION_SUCCESS = 'CANCEL_TRANSACTION_SUCCESS';
export const CANCEL_TRANSACTION_FAILURE = 'CANCEL_TRANSACTION_FAILURE';
export const RE_CALCULATE_TRANSACTION_REQUEST = 'RE_CALCULATE_TRANSACTION_REQUEST';
export const RE_CALCULATE_TRANSACTION_SUCCESS = 'RE_CALCULATE_TRANSACTION_SUCCESS';
export const RE_CALCULATE_TRANSACTION_FAILURE = 'RE_CALCULATE_TRANSACTION_FAILURE';
export const HEX_UPDATE_REQUEST = 'HEX_UPDATE_REQUEST';
export const HEX_UPDATE_SUCCESS = 'HEX_UPDATE_SUCCESS';
export const HEX_UPDATE_FAILURE = 'HEX_UPDATE_FAILURE';
