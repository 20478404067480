import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Snackbar, Typography } from '@mui/material';

import { ConstantsUtil } from '@utils';

import { StyledPaper } from './snack-bar.styles';

import { SnackBarProps } from './snack-bar.types';

/**
 * Snack bar component.
 *
 * @author Ihar Kazlouski
 * @function SnackBarComponent
 * @category components
 * @param {string} description description.
 * @return {FC} snack bar component.
 */
const SnackBarComponent: FC<SnackBarProps> = ({ description }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsOpen(!localStorage.getItem(ConstantsUtil.localStorage.cookie));
  }, []);

  /**
   * Accept cookie.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const acceptCookie = (): void => {
    setIsOpen(false);
    localStorage.setItem(ConstantsUtil.localStorage.cookie, 'accepted');
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{ width: 260 }}
      open={isOpen}
      data-testid='snackbar'
    >
      <StyledPaper>
        <Typography textAlign='center' variant='subtitle2' component='p'>
          {description}
        </Typography>
        <Box pt={1.5} display='flex' justifyContent='space-around'>
          <Button variant='text' data-testid='snackbar-ok-button' onClick={acceptCookie}>
            <Typography variant='button'>{t('snackBar.ok')}</Typography>
          </Button>
        </Box>
      </StyledPaper>
    </Snackbar>
  );
};

export { SnackBarComponent };
