import { styled, Typography } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledModalTitleTypography = styled(Typography)<{
  theme?: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    [theme.breakpoints.down('md')]: {
      fontSize:   pxToRem(17),
      lineHeight: pxToRem(20),
      fontWeight: 500,
    },
  };

  return styles;
});

export { StyledModalTitleTypography };
