import { Box, BoxProps, styled } from '@mui/material';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledMain = styled(Box)<BoxProps & { inline?: boolean }>(
  ({ theme, inline }) => {
    const styles: OverridesStyleRules = {
      display:       'flex',
      flex:          '1 1 auto',
      flexDirection: 'column',
      width:         '100%',
      paddingTop:    inline ? 0 : theme.spacing(3),
      paddingBottom: inline ? 0 : theme.spacing(3),
    };
    return styles;
  },
);

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display:                      'flex',
  flex:                         '1 1 auto',
  maxWidth:                     '100%',
  paddingTop:                   64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280,
  },
}));

export { DashboardLayoutRoot, StyledMain };
