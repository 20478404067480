import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import { ConstantsUtil } from '@utils';

import {
  CALCULATE_TRANSACTION_FAILURE,
  CALCULATE_TRANSACTION_REQUEST,
  CALCULATE_TRANSACTION_SUCCESS,
  CANCEL_TRANSACTION_FAILURE,
  CANCEL_TRANSACTION_REQUEST,
  CANCEL_TRANSACTION_SUCCESS,
  HEX_UPDATE_FAILURE,
  HEX_UPDATE_REQUEST,
  HEX_UPDATE_SUCCESS,
  RE_CALCULATE_TRANSACTION_FAILURE,
  RE_CALCULATE_TRANSACTION_REQUEST,
  RE_CALCULATE_TRANSACTION_SUCCESS,
} from './transaction.action-types';
import {
  CalculateTransactionParamsFailurePayload,
  CalculateTransactionParamsRequestPayload,
  CalculateTransactionParamsSuccessPayload,
  CancelTransactionFailurePayload,
  CancelTransactionRequestPayload,
  HexUpdateFailurePayload,
  HexUpdateRequestPayload,
  ReCalculateTransactionParamsRequestPayload,
} from './transaction.types';

export const actions = {
  /**
   * Calculate transaction params request action.
   *
   * @author Ihar Kazlouski
   * @function calculateTransactionRequest
   * @param {CalculateTransactionParamsRequestPayload} payload payload.
   * @category Actions
   * @subcategory Transaction
   * @return {CalculateTransactionParamsRequestPayload} calculate transaction params request.
   */
  calculateTransactionRequest: (
    payload: CalculateTransactionParamsRequestPayload,
  ) =>
    ({
      type:              CALCULATE_TRANSACTION_REQUEST,
      [WAIT_FOR_ACTION]: `${CALCULATE_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${CALCULATE_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Calculate transaction params success action.
   *
   * @author Ihar Kazlouski
   * @function calculateTransactionSuccess
   * @param {CalculateTransactionParamsRequestPayload} payload payload.
   * @category Actions
   * @subcategory Transaction
   * @return {CalculateTransactionParamsSuccessPayload} calculate transaction params success.
   */
  calculateTransactionSuccess: (
    payload: CalculateTransactionParamsSuccessPayload,
  ) =>
    ({
      type: CALCULATE_TRANSACTION_SUCCESS,
      payload,
    } as const),

  /**
   * Calculate transaction params failure action.
   *
   * @author Ihar Kazlouski
   * @function calculateTransactionFailure
   * @category Actions
   * @subcategory Transaction
   * @param {CalculateTransactionParamsFailurePayload} payload payload.
   * @return {CalculateTransactionParamsFailurePayload} calculate transaction params failure.
   */
  calculateTransactionFailure: (
    payload: CalculateTransactionParamsFailurePayload,
  ) =>
    ({
      type: CALCULATE_TRANSACTION_FAILURE,
      payload,
    } as const),

  /**
   * Cancel transaction request action.
   *
   * @author Ihar Kazlouski
   * @function cancelTransactionRequest
   * @param {CancelTransactionRequestPayload} payload payload.
   * @category Actions
   * @subcategory Transaction
   * @return {CancelTransactionRequestPayload} cancel transaction request.
   */
  cancelTransactionRequest: (payload: CancelTransactionRequestPayload) =>
    ({
      type:              CANCEL_TRANSACTION_REQUEST,
      [WAIT_FOR_ACTION]: `${CANCEL_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${CANCEL_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Cancel transaction success action.
   *
   * @author Ihar Kazlouski
   * @function cancelTransactionSuccess
   * @category Actions
   * @subcategory Transaction
   * @return {void} cancel transaction success.
   */
  cancelTransactionSuccess: () =>
    ({
      type: CANCEL_TRANSACTION_SUCCESS,
    } as const),

  /**
   * Cancel transaction failure action.
   *
   * @author Ihar Kazlouski
   * @function cancelTransactionFailure
   * @category Actions
   * @subcategory Transaction
   * @param {CancelTransactionFailurePayload} payload payload.
   * @return {CancelTransactionFailurePayload} cancel transaction failure.
   */
  cancelTransactionFailure: (payload: CancelTransactionFailurePayload) =>
    ({
      type: CANCEL_TRANSACTION_FAILURE,
      payload,
    } as const),

  /**
   * Recalculate transaction params request action.
   *
   * @author Ihar Kazlouski
   * @function reCalculateTransactionRequest
   * @param {CalculateTransactionParamsRequestPayload} payload payload.
   * @category Actions
   * @subcategory Transaction
   * @return {CalculateTransactionParamsRequestPayload} recalculate transaction params request.
   */
  reCalculateTransactionRequest: (
    payload: ReCalculateTransactionParamsRequestPayload,
  ) =>
    ({
      type:              RE_CALCULATE_TRANSACTION_REQUEST,
      [WAIT_FOR_ACTION]: `${RE_CALCULATE_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${RE_CALCULATE_TRANSACTION_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Recalculate transaction params success action.
   *
   * @author Ihar Kazlouski
   * @function reCalculateTransactionSuccess
   * @param {RE_CALCULATE_TRANSACTION_SUCCESS} payload payload.
   * @category Actions
   * @subcategory Transaction
   * @return {RE_CALCULATE_TRANSACTION_SUCCESS} recalculate transaction params success.
   */
  reCalculateTransactionSuccess: (
    payload: CalculateTransactionParamsSuccessPayload,
  ) =>
    ({
      type: RE_CALCULATE_TRANSACTION_SUCCESS,
      payload,
    } as const),

  /**
   * Recalculate transaction params failure action.
   *
   * @author Ihar Kazlouski
   * @function reCalculateTransactionFailure
   * @category Actions
   * @subcategory Transaction
   * @param {CalculateTransactionParamsFailurePayload} payload payload.
   * @return {CalculateTransactionParamsFailurePayload} recalculate transaction params failure.
   */
  reCalculateTransactionFailure: (
    payload: CalculateTransactionParamsFailurePayload,
  ) =>
    ({
      type: RE_CALCULATE_TRANSACTION_FAILURE,
      payload,
    } as const),

  /**
   * Hex update request action.
   *
   * @author Ihar Kazlouski
   * @function hexUpdateRequest
   * @param {HexUpdateRequestPayload} payload payload.
   * @category Actions
   * @subcategory Transaction
   * @return {HexUpdateRequestPayload} hex update request.
   */
  hexUpdateRequest: (payload: HexUpdateRequestPayload) =>
    ({
      type:              HEX_UPDATE_REQUEST,
      [WAIT_FOR_ACTION]: `${HEX_UPDATE_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${HEX_UPDATE_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload,
    } as const),

  /**
   * Hex update success action.
   *
   * @author Ihar Kazlouski
   * @function hexUpdateSuccess
   * @category Actions
   * @subcategory Transaction
   * @return {void}
   */
  hexUpdateSuccess: () =>
    ({
      type: HEX_UPDATE_SUCCESS,
    } as const),

  /**
   * Hex update failure action.
   *
   * @author Ihar Kazlouski
   * @function hexUpdateFailure
   * @category Actions
   * @subcategory Transaction
   * @param {HexUpdateFailurePayload} payload payload.
   * @return {HexUpdateFailurePayload} hex update failure.
   */
  hexUpdateFailure: (payload: HexUpdateFailurePayload) =>
    ({
      type: HEX_UPDATE_FAILURE,
      payload,
    } as const),
};
