import {
  CREATE_FORM_STATE,
  CREATE_FORM_STATE_ASYNC,
  DELETE_FORM_STATE,
  DELETE_FORM_STATE_ASYNC,
  UPDATE_FORM_STATE,
  UPDATE_FORM_STATE_ASYNC,
} from './forms.action-types';
import {
  FormState,
  FormStateDeletePayload,
  FormStateInitPayload,
} from './forms.types';

export const actions = {
  /**
   * create form async action.
   *
   * @author Ihar Kazlouski
   * @function createFormAsync
   * @category Actions
   */
  createFormAsync: (payload: FormStateInitPayload) =>
    ({
      type: CREATE_FORM_STATE_ASYNC,
      payload,
    } as const),
  /**
   * create form action.
   *
   * @author Ihar Kazlouski
   * @function createForm
   * @category Actions
   */
  createForm: (payload: FormStateInitPayload) =>
    ({
      type: CREATE_FORM_STATE,
      payload,
    } as const),
  /**
   * update form async action.
   *
   * @author Ihar Kazlouski
   * @function updateFormStateAsync
   * @category Actions
   */
  updateFormStateAsync: (payload: FormState) =>
    ({
      type: UPDATE_FORM_STATE_ASYNC,
      payload,
    } as const),
  /**
   * update form action.
   *
   * @author Ihar Kazlouski
   * @function updateFormState
   * @category Actions
   */
  updateFormState: (payload: FormState) =>
    ({
      type: UPDATE_FORM_STATE,
      payload,
    } as const),
  /**
   * delete form async action.
   *
   * @author Ihar Kazlouski
   * @function deleteFormStateAsync
   * @category Actions
   */
  deleteFormStateAsync: (payload: FormStateDeletePayload) =>
    ({
      type: DELETE_FORM_STATE_ASYNC,
      payload,
    } as const),
  /**
   * delete form action.
   *
   * @author Ihar Kazlouski
   * @function deleteFormState
   * @category Actions
   */
  deleteFormState: (payload: FormStateDeletePayload) =>
    ({
      type: DELETE_FORM_STATE,
      payload,
    } as const),
};
