import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Button, IconButton, Typography } from '@mui/material';

import { getModalSelector } from '@store/modals';

import { ModalsService } from '@services';
import { useDeviceSize } from '@hooks';

import { Modals } from '@enums';

import { Modal, ModalProps } from '@elements';

/**
 * Private key modal component.
 *
 * @author Ihar Kazlouski
 * @function TooMuchWalletsModal
 * @category Modals
 * @return {FC} private key modal component.
 */
const PrivateKeyModal: FC<ModalProps> = () => {
  const deviceSize = useDeviceSize();
  const { t } = useTranslation();
  const modal = useSelector(getModalSelector(Modals.PrivateKey));

  /**
   * Close modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const closeModal = (): void => {
    ModalsService.close(Modals.PrivateKey);
  };

  return (
    <Modal maxWidth='sm' fullWidth>
      <Modal.Title>{t('modals.privateKey.dontTellPrivateKey')}</Modal.Title>
      <Modal.Content>
        <Box pb={4}>
          <Box>
            <Typography>
              <b>{t('modals.privateKey.address')}</b>{' '}
              {(modal?.data as { gridAddress: string })?.gridAddress}
            </Typography>
          </Box>
          <Box pt={2} display='flex' alignItems='center'>
            <Typography sx={{ wordBreak: 'break-word' }}>
              <b>{t('modals.privateKey.privateKey')}</b>{' '}
              {
                (modal?.data as { decryptedPrivateKey: string })
                  ?.decryptedPrivateKey
              }
            </Typography>
            <IconButton
              onClick={(
                modal?.data as {
                  copyToClipboardHintHandler: (
                    address: string
                  ) => () => Promise<void>;
                }
              )?.copyToClipboardHintHandler(
                (modal?.data as { decryptedPrivateKey: string })
                  ?.decryptedPrivateKey,
              )}
            >
              <ContentCopyIcon />
            </IconButton>
          </Box>
        </Box>
      </Modal.Content>
      <Modal.Footer>
        <Box
          pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
          sx={{
            display: {
              xs: 'none',
              lg: 'flex',
            },
          }}
          justifyContent='space-around'
        >
          <Button variant='contained' onClick={closeModal}>
            {t('modals.privateKey.close')}
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};

export { PrivateKeyModal };
