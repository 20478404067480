import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { LoaderService } from '@services';

import {
  ApiError,
} from '@types';

/**
 * Loader interceptor, catch all requests and responses
 *
 * @author Ihar Kazlouski
 * @function LoaderInterceptor
 * @category Interceptors
 * @return {AxiosResponse | AxiosRequestConfig | AxiosError<ApiError> | Promise<AxiosError<ApiError>> | undefined} data.
 */
const LoaderInterceptor = ({
  response,
  request,
  error,
}: {
  response?: AxiosResponse;
  request?: AxiosRequestConfig;
  error?: AxiosError<ApiError>;
}):
| AxiosResponse
| AxiosRequestConfig
| AxiosError<ApiError>
| Promise<AxiosError<ApiError>>
| undefined => {
  if (request) {
    LoaderService.incRequestCounter();
  }

  if (response) {
    LoaderService.decRequestCounter();
  }

  if (error) {
    LoaderService.decRequestCounter();
  }

  return request || response || (error && Promise.reject(error));
};

export { LoaderInterceptor };
