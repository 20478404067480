// source: queries.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var primitive_pb = require('./primitive_pb.js');
goog.object.extend(proto, primitive_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.iroha.protocol.AccountDetailPaginationMeta', null, global);
goog.exportSymbol('proto.iroha.protocol.AssetPaginationMeta', null, global);
goog.exportSymbol('proto.iroha.protocol.AssetPaginationMeta.OptFirstAssetIdCase', null, global);
goog.exportSymbol('proto.iroha.protocol.BlocksQuery', null, global);
goog.exportSymbol('proto.iroha.protocol.Direction', null, global);
goog.exportSymbol('proto.iroha.protocol.Field', null, global);
goog.exportSymbol('proto.iroha.protocol.GetAccount', null, global);
goog.exportSymbol('proto.iroha.protocol.GetAccountAssetTransactions', null, global);
goog.exportSymbol('proto.iroha.protocol.GetAccountAssets', null, global);
goog.exportSymbol('proto.iroha.protocol.GetAccountDetail', null, global);
goog.exportSymbol('proto.iroha.protocol.GetAccountDetail.OptAccountIdCase', null, global);
goog.exportSymbol('proto.iroha.protocol.GetAccountDetail.OptKeyCase', null, global);
goog.exportSymbol('proto.iroha.protocol.GetAccountDetail.OptWriterCase', null, global);
goog.exportSymbol('proto.iroha.protocol.GetAccountTransactions', null, global);
goog.exportSymbol('proto.iroha.protocol.GetAssetInfo', null, global);
goog.exportSymbol('proto.iroha.protocol.GetBlock', null, global);
goog.exportSymbol('proto.iroha.protocol.GetEngineReceipts', null, global);
goog.exportSymbol('proto.iroha.protocol.GetPeers', null, global);
goog.exportSymbol('proto.iroha.protocol.GetPendingTransactions', null, global);
goog.exportSymbol('proto.iroha.protocol.GetRolePermissions', null, global);
goog.exportSymbol('proto.iroha.protocol.GetRoles', null, global);
goog.exportSymbol('proto.iroha.protocol.GetSignatories', null, global);
goog.exportSymbol('proto.iroha.protocol.GetTransactions', null, global);
goog.exportSymbol('proto.iroha.protocol.Ordering', null, global);
goog.exportSymbol('proto.iroha.protocol.Ordering.FieldOrdering', null, global);
goog.exportSymbol('proto.iroha.protocol.Query', null, global);
goog.exportSymbol('proto.iroha.protocol.Query.Payload', null, global);
goog.exportSymbol('proto.iroha.protocol.Query.Payload.QueryCase', null, global);
goog.exportSymbol('proto.iroha.protocol.QueryPayloadMeta', null, global);
goog.exportSymbol('proto.iroha.protocol.TxPaginationMeta', null, global);
goog.exportSymbol('proto.iroha.protocol.TxPaginationMeta.OptFirstTxHashCase', null, global);
goog.exportSymbol('proto.iroha.protocol.TxPaginationMeta.OptFirstTxHeightCase', null, global);
goog.exportSymbol('proto.iroha.protocol.TxPaginationMeta.OptFirstTxTimeCase', null, global);
goog.exportSymbol('proto.iroha.protocol.TxPaginationMeta.OptLastTxHeightCase', null, global);
goog.exportSymbol('proto.iroha.protocol.TxPaginationMeta.OptLastTxTimeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.Ordering = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iroha.protocol.Ordering.repeatedFields_, null);
};
goog.inherits(proto.iroha.protocol.Ordering, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.Ordering.displayName = 'proto.iroha.protocol.Ordering';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.Ordering.FieldOrdering = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.Ordering.FieldOrdering, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.Ordering.FieldOrdering.displayName = 'proto.iroha.protocol.Ordering.FieldOrdering';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.TxPaginationMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.iroha.protocol.TxPaginationMeta.oneofGroups_);
};
goog.inherits(proto.iroha.protocol.TxPaginationMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.TxPaginationMeta.displayName = 'proto.iroha.protocol.TxPaginationMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.AssetPaginationMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.iroha.protocol.AssetPaginationMeta.oneofGroups_);
};
goog.inherits(proto.iroha.protocol.AssetPaginationMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.AssetPaginationMeta.displayName = 'proto.iroha.protocol.AssetPaginationMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.GetAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.GetAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.GetAccount.displayName = 'proto.iroha.protocol.GetAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.GetBlock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.GetBlock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.GetBlock.displayName = 'proto.iroha.protocol.GetBlock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.GetSignatories = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.GetSignatories, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.GetSignatories.displayName = 'proto.iroha.protocol.GetSignatories';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.GetAccountTransactions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.GetAccountTransactions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.GetAccountTransactions.displayName = 'proto.iroha.protocol.GetAccountTransactions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.GetAccountAssetTransactions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.GetAccountAssetTransactions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.GetAccountAssetTransactions.displayName = 'proto.iroha.protocol.GetAccountAssetTransactions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.GetTransactions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iroha.protocol.GetTransactions.repeatedFields_, null);
};
goog.inherits(proto.iroha.protocol.GetTransactions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.GetTransactions.displayName = 'proto.iroha.protocol.GetTransactions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.GetAccountAssets = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.GetAccountAssets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.GetAccountAssets.displayName = 'proto.iroha.protocol.GetAccountAssets';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.AccountDetailPaginationMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.AccountDetailPaginationMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.AccountDetailPaginationMeta.displayName = 'proto.iroha.protocol.AccountDetailPaginationMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.GetAccountDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.iroha.protocol.GetAccountDetail.oneofGroups_);
};
goog.inherits(proto.iroha.protocol.GetAccountDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.GetAccountDetail.displayName = 'proto.iroha.protocol.GetAccountDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.GetAssetInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.GetAssetInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.GetAssetInfo.displayName = 'proto.iroha.protocol.GetAssetInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.GetRoles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.GetRoles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.GetRoles.displayName = 'proto.iroha.protocol.GetRoles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.GetRolePermissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.GetRolePermissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.GetRolePermissions.displayName = 'proto.iroha.protocol.GetRolePermissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.GetPendingTransactions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.GetPendingTransactions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.GetPendingTransactions.displayName = 'proto.iroha.protocol.GetPendingTransactions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.GetPeers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.GetPeers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.GetPeers.displayName = 'proto.iroha.protocol.GetPeers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.QueryPayloadMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.QueryPayloadMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.QueryPayloadMeta.displayName = 'proto.iroha.protocol.QueryPayloadMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.GetEngineReceipts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.GetEngineReceipts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.GetEngineReceipts.displayName = 'proto.iroha.protocol.GetEngineReceipts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.Query = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.Query, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.Query.displayName = 'proto.iroha.protocol.Query';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.Query.Payload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.iroha.protocol.Query.Payload.oneofGroups_);
};
goog.inherits(proto.iroha.protocol.Query.Payload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.Query.Payload.displayName = 'proto.iroha.protocol.Query.Payload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.BlocksQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.BlocksQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.BlocksQuery.displayName = 'proto.iroha.protocol.BlocksQuery';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iroha.protocol.Ordering.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.Ordering.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.Ordering.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.Ordering} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Ordering.toObject = function(includeInstance, msg) {
  var f, obj = {
    sequenceList: jspb.Message.toObjectList(msg.getSequenceList(),
    proto.iroha.protocol.Ordering.FieldOrdering.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.Ordering}
 */
proto.iroha.protocol.Ordering.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.Ordering;
  return proto.iroha.protocol.Ordering.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.Ordering} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.Ordering}
 */
proto.iroha.protocol.Ordering.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.iroha.protocol.Ordering.FieldOrdering;
      reader.readMessage(value,proto.iroha.protocol.Ordering.FieldOrdering.deserializeBinaryFromReader);
      msg.addSequence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.Ordering.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.Ordering.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.Ordering} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Ordering.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSequenceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.iroha.protocol.Ordering.FieldOrdering.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.Ordering.FieldOrdering.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.Ordering.FieldOrdering.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.Ordering.FieldOrdering} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Ordering.FieldOrdering.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.Ordering.FieldOrdering}
 */
proto.iroha.protocol.Ordering.FieldOrdering.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.Ordering.FieldOrdering;
  return proto.iroha.protocol.Ordering.FieldOrdering.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.Ordering.FieldOrdering} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.Ordering.FieldOrdering}
 */
proto.iroha.protocol.Ordering.FieldOrdering.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.iroha.protocol.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.iroha.protocol.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.Ordering.FieldOrdering.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.Ordering.FieldOrdering.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.Ordering.FieldOrdering} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Ordering.FieldOrdering.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional Field field = 1;
 * @return {!proto.iroha.protocol.Field}
 */
proto.iroha.protocol.Ordering.FieldOrdering.prototype.getField = function() {
  return /** @type {!proto.iroha.protocol.Field} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.iroha.protocol.Field} value
 * @return {!proto.iroha.protocol.Ordering.FieldOrdering} returns this
 */
proto.iroha.protocol.Ordering.FieldOrdering.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Direction direction = 2;
 * @return {!proto.iroha.protocol.Direction}
 */
proto.iroha.protocol.Ordering.FieldOrdering.prototype.getDirection = function() {
  return /** @type {!proto.iroha.protocol.Direction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.iroha.protocol.Direction} value
 * @return {!proto.iroha.protocol.Ordering.FieldOrdering} returns this
 */
proto.iroha.protocol.Ordering.FieldOrdering.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated FieldOrdering sequence = 1;
 * @return {!Array<!proto.iroha.protocol.Ordering.FieldOrdering>}
 */
proto.iroha.protocol.Ordering.prototype.getSequenceList = function() {
  return /** @type{!Array<!proto.iroha.protocol.Ordering.FieldOrdering>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.iroha.protocol.Ordering.FieldOrdering, 1));
};


/**
 * @param {!Array<!proto.iroha.protocol.Ordering.FieldOrdering>} value
 * @return {!proto.iroha.protocol.Ordering} returns this
*/
proto.iroha.protocol.Ordering.prototype.setSequenceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.iroha.protocol.Ordering.FieldOrdering=} opt_value
 * @param {number=} opt_index
 * @return {!proto.iroha.protocol.Ordering.FieldOrdering}
 */
proto.iroha.protocol.Ordering.prototype.addSequence = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.iroha.protocol.Ordering.FieldOrdering, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iroha.protocol.Ordering} returns this
 */
proto.iroha.protocol.Ordering.prototype.clearSequenceList = function() {
  return this.setSequenceList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.iroha.protocol.TxPaginationMeta.oneofGroups_ = [[2],[4],[5],[6],[7]];

/**
 * @enum {number}
 */
proto.iroha.protocol.TxPaginationMeta.OptFirstTxHashCase = {
  OPT_FIRST_TX_HASH_NOT_SET: 0,
  FIRST_TX_HASH: 2
};

/**
 * @return {proto.iroha.protocol.TxPaginationMeta.OptFirstTxHashCase}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.getOptFirstTxHashCase = function() {
  return /** @type {proto.iroha.protocol.TxPaginationMeta.OptFirstTxHashCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.TxPaginationMeta.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.iroha.protocol.TxPaginationMeta.OptFirstTxTimeCase = {
  OPT_FIRST_TX_TIME_NOT_SET: 0,
  FIRST_TX_TIME: 4
};

/**
 * @return {proto.iroha.protocol.TxPaginationMeta.OptFirstTxTimeCase}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.getOptFirstTxTimeCase = function() {
  return /** @type {proto.iroha.protocol.TxPaginationMeta.OptFirstTxTimeCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.TxPaginationMeta.oneofGroups_[1]));
};

/**
 * @enum {number}
 */
proto.iroha.protocol.TxPaginationMeta.OptLastTxTimeCase = {
  OPT_LAST_TX_TIME_NOT_SET: 0,
  LAST_TX_TIME: 5
};

/**
 * @return {proto.iroha.protocol.TxPaginationMeta.OptLastTxTimeCase}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.getOptLastTxTimeCase = function() {
  return /** @type {proto.iroha.protocol.TxPaginationMeta.OptLastTxTimeCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.TxPaginationMeta.oneofGroups_[2]));
};

/**
 * @enum {number}
 */
proto.iroha.protocol.TxPaginationMeta.OptFirstTxHeightCase = {
  OPT_FIRST_TX_HEIGHT_NOT_SET: 0,
  FIRST_TX_HEIGHT: 6
};

/**
 * @return {proto.iroha.protocol.TxPaginationMeta.OptFirstTxHeightCase}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.getOptFirstTxHeightCase = function() {
  return /** @type {proto.iroha.protocol.TxPaginationMeta.OptFirstTxHeightCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.TxPaginationMeta.oneofGroups_[3]));
};

/**
 * @enum {number}
 */
proto.iroha.protocol.TxPaginationMeta.OptLastTxHeightCase = {
  OPT_LAST_TX_HEIGHT_NOT_SET: 0,
  LAST_TX_HEIGHT: 7
};

/**
 * @return {proto.iroha.protocol.TxPaginationMeta.OptLastTxHeightCase}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.getOptLastTxHeightCase = function() {
  return /** @type {proto.iroha.protocol.TxPaginationMeta.OptLastTxHeightCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.TxPaginationMeta.oneofGroups_[4]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.TxPaginationMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.TxPaginationMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.TxPaginationMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstTxHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ordering: (f = msg.getOrdering()) && proto.iroha.protocol.Ordering.toObject(includeInstance, f),
    firstTxTime: (f = msg.getFirstTxTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastTxTime: (f = msg.getLastTxTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    firstTxHeight: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lastTxHeight: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.TxPaginationMeta}
 */
proto.iroha.protocol.TxPaginationMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.TxPaginationMeta;
  return proto.iroha.protocol.TxPaginationMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.TxPaginationMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.TxPaginationMeta}
 */
proto.iroha.protocol.TxPaginationMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstTxHash(value);
      break;
    case 3:
      var value = new proto.iroha.protocol.Ordering;
      reader.readMessage(value,proto.iroha.protocol.Ordering.deserializeBinaryFromReader);
      msg.setOrdering(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFirstTxTime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastTxTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFirstTxHeight(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastTxHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.TxPaginationMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.TxPaginationMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.TxPaginationMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrdering();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.iroha.protocol.Ordering.serializeBinaryToWriter
    );
  }
  f = message.getFirstTxTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastTxTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeUint64(
      7,
      f
    );
  }
};


/**
 * optional uint32 page_size = 1;
 * @return {number}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.TxPaginationMeta} returns this
 */
proto.iroha.protocol.TxPaginationMeta.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_tx_hash = 2;
 * @return {string}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.getFirstTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.TxPaginationMeta} returns this
 */
proto.iroha.protocol.TxPaginationMeta.prototype.setFirstTxHash = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.iroha.protocol.TxPaginationMeta.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.TxPaginationMeta} returns this
 */
proto.iroha.protocol.TxPaginationMeta.prototype.clearFirstTxHash = function() {
  return jspb.Message.setOneofField(this, 2, proto.iroha.protocol.TxPaginationMeta.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.hasFirstTxHash = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Ordering ordering = 3;
 * @return {?proto.iroha.protocol.Ordering}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.getOrdering = function() {
  return /** @type{?proto.iroha.protocol.Ordering} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.Ordering, 3));
};


/**
 * @param {?proto.iroha.protocol.Ordering|undefined} value
 * @return {!proto.iroha.protocol.TxPaginationMeta} returns this
*/
proto.iroha.protocol.TxPaginationMeta.prototype.setOrdering = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.TxPaginationMeta} returns this
 */
proto.iroha.protocol.TxPaginationMeta.prototype.clearOrdering = function() {
  return this.setOrdering(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.hasOrdering = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp first_tx_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.getFirstTxTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.iroha.protocol.TxPaginationMeta} returns this
*/
proto.iroha.protocol.TxPaginationMeta.prototype.setFirstTxTime = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.iroha.protocol.TxPaginationMeta.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.TxPaginationMeta} returns this
 */
proto.iroha.protocol.TxPaginationMeta.prototype.clearFirstTxTime = function() {
  return this.setFirstTxTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.hasFirstTxTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp last_tx_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.getLastTxTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.iroha.protocol.TxPaginationMeta} returns this
*/
proto.iroha.protocol.TxPaginationMeta.prototype.setLastTxTime = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.iroha.protocol.TxPaginationMeta.oneofGroups_[2], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.TxPaginationMeta} returns this
 */
proto.iroha.protocol.TxPaginationMeta.prototype.clearLastTxTime = function() {
  return this.setLastTxTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.hasLastTxTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint64 first_tx_height = 6;
 * @return {number}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.getFirstTxHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.TxPaginationMeta} returns this
 */
proto.iroha.protocol.TxPaginationMeta.prototype.setFirstTxHeight = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.iroha.protocol.TxPaginationMeta.oneofGroups_[3], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.TxPaginationMeta} returns this
 */
proto.iroha.protocol.TxPaginationMeta.prototype.clearFirstTxHeight = function() {
  return jspb.Message.setOneofField(this, 6, proto.iroha.protocol.TxPaginationMeta.oneofGroups_[3], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.hasFirstTxHeight = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint64 last_tx_height = 7;
 * @return {number}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.getLastTxHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.TxPaginationMeta} returns this
 */
proto.iroha.protocol.TxPaginationMeta.prototype.setLastTxHeight = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.iroha.protocol.TxPaginationMeta.oneofGroups_[4], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.TxPaginationMeta} returns this
 */
proto.iroha.protocol.TxPaginationMeta.prototype.clearLastTxHeight = function() {
  return jspb.Message.setOneofField(this, 7, proto.iroha.protocol.TxPaginationMeta.oneofGroups_[4], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.TxPaginationMeta.prototype.hasLastTxHeight = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.iroha.protocol.AssetPaginationMeta.oneofGroups_ = [[2]];

/**
 * @enum {number}
 */
proto.iroha.protocol.AssetPaginationMeta.OptFirstAssetIdCase = {
  OPT_FIRST_ASSET_ID_NOT_SET: 0,
  FIRST_ASSET_ID: 2
};

/**
 * @return {proto.iroha.protocol.AssetPaginationMeta.OptFirstAssetIdCase}
 */
proto.iroha.protocol.AssetPaginationMeta.prototype.getOptFirstAssetIdCase = function() {
  return /** @type {proto.iroha.protocol.AssetPaginationMeta.OptFirstAssetIdCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.AssetPaginationMeta.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.AssetPaginationMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.AssetPaginationMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.AssetPaginationMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AssetPaginationMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstAssetId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.AssetPaginationMeta}
 */
proto.iroha.protocol.AssetPaginationMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.AssetPaginationMeta;
  return proto.iroha.protocol.AssetPaginationMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.AssetPaginationMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.AssetPaginationMeta}
 */
proto.iroha.protocol.AssetPaginationMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstAssetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.AssetPaginationMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.AssetPaginationMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.AssetPaginationMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AssetPaginationMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 page_size = 1;
 * @return {number}
 */
proto.iroha.protocol.AssetPaginationMeta.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.AssetPaginationMeta} returns this
 */
proto.iroha.protocol.AssetPaginationMeta.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_asset_id = 2;
 * @return {string}
 */
proto.iroha.protocol.AssetPaginationMeta.prototype.getFirstAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.AssetPaginationMeta} returns this
 */
proto.iroha.protocol.AssetPaginationMeta.prototype.setFirstAssetId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.iroha.protocol.AssetPaginationMeta.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.AssetPaginationMeta} returns this
 */
proto.iroha.protocol.AssetPaginationMeta.prototype.clearFirstAssetId = function() {
  return jspb.Message.setOneofField(this, 2, proto.iroha.protocol.AssetPaginationMeta.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.AssetPaginationMeta.prototype.hasFirstAssetId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.GetAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.GetAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.GetAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.GetAccount}
 */
proto.iroha.protocol.GetAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.GetAccount;
  return proto.iroha.protocol.GetAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.GetAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.GetAccount}
 */
proto.iroha.protocol.GetAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.GetAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.GetAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.GetAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.GetAccount.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.GetAccount} returns this
 */
proto.iroha.protocol.GetAccount.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.GetBlock.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.GetBlock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.GetBlock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetBlock.toObject = function(includeInstance, msg) {
  var f, obj = {
    height: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.GetBlock}
 */
proto.iroha.protocol.GetBlock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.GetBlock;
  return proto.iroha.protocol.GetBlock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.GetBlock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.GetBlock}
 */
proto.iroha.protocol.GetBlock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.GetBlock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.GetBlock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.GetBlock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetBlock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 height = 1;
 * @return {number}
 */
proto.iroha.protocol.GetBlock.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.GetBlock} returns this
 */
proto.iroha.protocol.GetBlock.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.GetSignatories.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.GetSignatories.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.GetSignatories} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetSignatories.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.GetSignatories}
 */
proto.iroha.protocol.GetSignatories.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.GetSignatories;
  return proto.iroha.protocol.GetSignatories.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.GetSignatories} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.GetSignatories}
 */
proto.iroha.protocol.GetSignatories.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.GetSignatories.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.GetSignatories.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.GetSignatories} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetSignatories.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.GetSignatories.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.GetSignatories} returns this
 */
proto.iroha.protocol.GetSignatories.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.GetAccountTransactions.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.GetAccountTransactions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.GetAccountTransactions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetAccountTransactions.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paginationMeta: (f = msg.getPaginationMeta()) && proto.iroha.protocol.TxPaginationMeta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.GetAccountTransactions}
 */
proto.iroha.protocol.GetAccountTransactions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.GetAccountTransactions;
  return proto.iroha.protocol.GetAccountTransactions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.GetAccountTransactions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.GetAccountTransactions}
 */
proto.iroha.protocol.GetAccountTransactions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = new proto.iroha.protocol.TxPaginationMeta;
      reader.readMessage(value,proto.iroha.protocol.TxPaginationMeta.deserializeBinaryFromReader);
      msg.setPaginationMeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.GetAccountTransactions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.GetAccountTransactions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.GetAccountTransactions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetAccountTransactions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaginationMeta();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.iroha.protocol.TxPaginationMeta.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.GetAccountTransactions.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.GetAccountTransactions} returns this
 */
proto.iroha.protocol.GetAccountTransactions.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TxPaginationMeta pagination_meta = 2;
 * @return {?proto.iroha.protocol.TxPaginationMeta}
 */
proto.iroha.protocol.GetAccountTransactions.prototype.getPaginationMeta = function() {
  return /** @type{?proto.iroha.protocol.TxPaginationMeta} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.TxPaginationMeta, 2));
};


/**
 * @param {?proto.iroha.protocol.TxPaginationMeta|undefined} value
 * @return {!proto.iroha.protocol.GetAccountTransactions} returns this
*/
proto.iroha.protocol.GetAccountTransactions.prototype.setPaginationMeta = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.GetAccountTransactions} returns this
 */
proto.iroha.protocol.GetAccountTransactions.prototype.clearPaginationMeta = function() {
  return this.setPaginationMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.GetAccountTransactions.prototype.hasPaginationMeta = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.GetAccountAssetTransactions.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.GetAccountAssetTransactions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.GetAccountAssetTransactions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetAccountAssetTransactions.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assetId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    paginationMeta: (f = msg.getPaginationMeta()) && proto.iroha.protocol.TxPaginationMeta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.GetAccountAssetTransactions}
 */
proto.iroha.protocol.GetAccountAssetTransactions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.GetAccountAssetTransactions;
  return proto.iroha.protocol.GetAccountAssetTransactions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.GetAccountAssetTransactions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.GetAccountAssetTransactions}
 */
proto.iroha.protocol.GetAccountAssetTransactions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetId(value);
      break;
    case 3:
      var value = new proto.iroha.protocol.TxPaginationMeta;
      reader.readMessage(value,proto.iroha.protocol.TxPaginationMeta.deserializeBinaryFromReader);
      msg.setPaginationMeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.GetAccountAssetTransactions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.GetAccountAssetTransactions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.GetAccountAssetTransactions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetAccountAssetTransactions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPaginationMeta();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.iroha.protocol.TxPaginationMeta.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.GetAccountAssetTransactions.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.GetAccountAssetTransactions} returns this
 */
proto.iroha.protocol.GetAccountAssetTransactions.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string asset_id = 2;
 * @return {string}
 */
proto.iroha.protocol.GetAccountAssetTransactions.prototype.getAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.GetAccountAssetTransactions} returns this
 */
proto.iroha.protocol.GetAccountAssetTransactions.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TxPaginationMeta pagination_meta = 3;
 * @return {?proto.iroha.protocol.TxPaginationMeta}
 */
proto.iroha.protocol.GetAccountAssetTransactions.prototype.getPaginationMeta = function() {
  return /** @type{?proto.iroha.protocol.TxPaginationMeta} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.TxPaginationMeta, 3));
};


/**
 * @param {?proto.iroha.protocol.TxPaginationMeta|undefined} value
 * @return {!proto.iroha.protocol.GetAccountAssetTransactions} returns this
*/
proto.iroha.protocol.GetAccountAssetTransactions.prototype.setPaginationMeta = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.GetAccountAssetTransactions} returns this
 */
proto.iroha.protocol.GetAccountAssetTransactions.prototype.clearPaginationMeta = function() {
  return this.setPaginationMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.GetAccountAssetTransactions.prototype.hasPaginationMeta = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iroha.protocol.GetTransactions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.GetTransactions.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.GetTransactions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.GetTransactions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetTransactions.toObject = function(includeInstance, msg) {
  var f, obj = {
    txHashesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.GetTransactions}
 */
proto.iroha.protocol.GetTransactions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.GetTransactions;
  return proto.iroha.protocol.GetTransactions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.GetTransactions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.GetTransactions}
 */
proto.iroha.protocol.GetTransactions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addTxHashes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.GetTransactions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.GetTransactions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.GetTransactions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetTransactions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTxHashesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string tx_hashes = 1;
 * @return {!Array<string>}
 */
proto.iroha.protocol.GetTransactions.prototype.getTxHashesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.iroha.protocol.GetTransactions} returns this
 */
proto.iroha.protocol.GetTransactions.prototype.setTxHashesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.iroha.protocol.GetTransactions} returns this
 */
proto.iroha.protocol.GetTransactions.prototype.addTxHashes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iroha.protocol.GetTransactions} returns this
 */
proto.iroha.protocol.GetTransactions.prototype.clearTxHashesList = function() {
  return this.setTxHashesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.GetAccountAssets.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.GetAccountAssets.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.GetAccountAssets} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetAccountAssets.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paginationMeta: (f = msg.getPaginationMeta()) && proto.iroha.protocol.AssetPaginationMeta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.GetAccountAssets}
 */
proto.iroha.protocol.GetAccountAssets.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.GetAccountAssets;
  return proto.iroha.protocol.GetAccountAssets.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.GetAccountAssets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.GetAccountAssets}
 */
proto.iroha.protocol.GetAccountAssets.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = new proto.iroha.protocol.AssetPaginationMeta;
      reader.readMessage(value,proto.iroha.protocol.AssetPaginationMeta.deserializeBinaryFromReader);
      msg.setPaginationMeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.GetAccountAssets.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.GetAccountAssets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.GetAccountAssets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetAccountAssets.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaginationMeta();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.iroha.protocol.AssetPaginationMeta.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.GetAccountAssets.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.GetAccountAssets} returns this
 */
proto.iroha.protocol.GetAccountAssets.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AssetPaginationMeta pagination_meta = 2;
 * @return {?proto.iroha.protocol.AssetPaginationMeta}
 */
proto.iroha.protocol.GetAccountAssets.prototype.getPaginationMeta = function() {
  return /** @type{?proto.iroha.protocol.AssetPaginationMeta} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.AssetPaginationMeta, 2));
};


/**
 * @param {?proto.iroha.protocol.AssetPaginationMeta|undefined} value
 * @return {!proto.iroha.protocol.GetAccountAssets} returns this
*/
proto.iroha.protocol.GetAccountAssets.prototype.setPaginationMeta = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.GetAccountAssets} returns this
 */
proto.iroha.protocol.GetAccountAssets.prototype.clearPaginationMeta = function() {
  return this.setPaginationMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.GetAccountAssets.prototype.hasPaginationMeta = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.AccountDetailPaginationMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.AccountDetailPaginationMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.AccountDetailPaginationMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AccountDetailPaginationMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstRecordId: (f = msg.getFirstRecordId()) && primitive_pb.AccountDetailRecordId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.AccountDetailPaginationMeta}
 */
proto.iroha.protocol.AccountDetailPaginationMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.AccountDetailPaginationMeta;
  return proto.iroha.protocol.AccountDetailPaginationMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.AccountDetailPaginationMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.AccountDetailPaginationMeta}
 */
proto.iroha.protocol.AccountDetailPaginationMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = new primitive_pb.AccountDetailRecordId;
      reader.readMessage(value,primitive_pb.AccountDetailRecordId.deserializeBinaryFromReader);
      msg.setFirstRecordId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.AccountDetailPaginationMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.AccountDetailPaginationMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.AccountDetailPaginationMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AccountDetailPaginationMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFirstRecordId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      primitive_pb.AccountDetailRecordId.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 page_size = 1;
 * @return {number}
 */
proto.iroha.protocol.AccountDetailPaginationMeta.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.AccountDetailPaginationMeta} returns this
 */
proto.iroha.protocol.AccountDetailPaginationMeta.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional AccountDetailRecordId first_record_id = 2;
 * @return {?proto.iroha.protocol.AccountDetailRecordId}
 */
proto.iroha.protocol.AccountDetailPaginationMeta.prototype.getFirstRecordId = function() {
  return /** @type{?proto.iroha.protocol.AccountDetailRecordId} */ (
    jspb.Message.getWrapperField(this, primitive_pb.AccountDetailRecordId, 2));
};


/**
 * @param {?proto.iroha.protocol.AccountDetailRecordId|undefined} value
 * @return {!proto.iroha.protocol.AccountDetailPaginationMeta} returns this
*/
proto.iroha.protocol.AccountDetailPaginationMeta.prototype.setFirstRecordId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.AccountDetailPaginationMeta} returns this
 */
proto.iroha.protocol.AccountDetailPaginationMeta.prototype.clearFirstRecordId = function() {
  return this.setFirstRecordId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.AccountDetailPaginationMeta.prototype.hasFirstRecordId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.iroha.protocol.GetAccountDetail.oneofGroups_ = [[1],[2],[3]];

/**
 * @enum {number}
 */
proto.iroha.protocol.GetAccountDetail.OptAccountIdCase = {
  OPT_ACCOUNT_ID_NOT_SET: 0,
  ACCOUNT_ID: 1
};

/**
 * @return {proto.iroha.protocol.GetAccountDetail.OptAccountIdCase}
 */
proto.iroha.protocol.GetAccountDetail.prototype.getOptAccountIdCase = function() {
  return /** @type {proto.iroha.protocol.GetAccountDetail.OptAccountIdCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.GetAccountDetail.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.iroha.protocol.GetAccountDetail.OptKeyCase = {
  OPT_KEY_NOT_SET: 0,
  KEY: 2
};

/**
 * @return {proto.iroha.protocol.GetAccountDetail.OptKeyCase}
 */
proto.iroha.protocol.GetAccountDetail.prototype.getOptKeyCase = function() {
  return /** @type {proto.iroha.protocol.GetAccountDetail.OptKeyCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.GetAccountDetail.oneofGroups_[1]));
};

/**
 * @enum {number}
 */
proto.iroha.protocol.GetAccountDetail.OptWriterCase = {
  OPT_WRITER_NOT_SET: 0,
  WRITER: 3
};

/**
 * @return {proto.iroha.protocol.GetAccountDetail.OptWriterCase}
 */
proto.iroha.protocol.GetAccountDetail.prototype.getOptWriterCase = function() {
  return /** @type {proto.iroha.protocol.GetAccountDetail.OptWriterCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.GetAccountDetail.oneofGroups_[2]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.GetAccountDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.GetAccountDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.GetAccountDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetAccountDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    writer: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paginationMeta: (f = msg.getPaginationMeta()) && proto.iroha.protocol.AccountDetailPaginationMeta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.GetAccountDetail}
 */
proto.iroha.protocol.GetAccountDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.GetAccountDetail;
  return proto.iroha.protocol.GetAccountDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.GetAccountDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.GetAccountDetail}
 */
proto.iroha.protocol.GetAccountDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWriter(value);
      break;
    case 4:
      var value = new proto.iroha.protocol.AccountDetailPaginationMeta;
      reader.readMessage(value,proto.iroha.protocol.AccountDetailPaginationMeta.deserializeBinaryFromReader);
      msg.setPaginationMeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.GetAccountDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.GetAccountDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.GetAccountDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetAccountDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPaginationMeta();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.iroha.protocol.AccountDetailPaginationMeta.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.GetAccountDetail.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.GetAccountDetail} returns this
 */
proto.iroha.protocol.GetAccountDetail.prototype.setAccountId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.iroha.protocol.GetAccountDetail.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.GetAccountDetail} returns this
 */
proto.iroha.protocol.GetAccountDetail.prototype.clearAccountId = function() {
  return jspb.Message.setOneofField(this, 1, proto.iroha.protocol.GetAccountDetail.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.GetAccountDetail.prototype.hasAccountId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.iroha.protocol.GetAccountDetail.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.GetAccountDetail} returns this
 */
proto.iroha.protocol.GetAccountDetail.prototype.setKey = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.iroha.protocol.GetAccountDetail.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.GetAccountDetail} returns this
 */
proto.iroha.protocol.GetAccountDetail.prototype.clearKey = function() {
  return jspb.Message.setOneofField(this, 2, proto.iroha.protocol.GetAccountDetail.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.GetAccountDetail.prototype.hasKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string writer = 3;
 * @return {string}
 */
proto.iroha.protocol.GetAccountDetail.prototype.getWriter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.GetAccountDetail} returns this
 */
proto.iroha.protocol.GetAccountDetail.prototype.setWriter = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.iroha.protocol.GetAccountDetail.oneofGroups_[2], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.GetAccountDetail} returns this
 */
proto.iroha.protocol.GetAccountDetail.prototype.clearWriter = function() {
  return jspb.Message.setOneofField(this, 3, proto.iroha.protocol.GetAccountDetail.oneofGroups_[2], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.GetAccountDetail.prototype.hasWriter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AccountDetailPaginationMeta pagination_meta = 4;
 * @return {?proto.iroha.protocol.AccountDetailPaginationMeta}
 */
proto.iroha.protocol.GetAccountDetail.prototype.getPaginationMeta = function() {
  return /** @type{?proto.iroha.protocol.AccountDetailPaginationMeta} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.AccountDetailPaginationMeta, 4));
};


/**
 * @param {?proto.iroha.protocol.AccountDetailPaginationMeta|undefined} value
 * @return {!proto.iroha.protocol.GetAccountDetail} returns this
*/
proto.iroha.protocol.GetAccountDetail.prototype.setPaginationMeta = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.GetAccountDetail} returns this
 */
proto.iroha.protocol.GetAccountDetail.prototype.clearPaginationMeta = function() {
  return this.setPaginationMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.GetAccountDetail.prototype.hasPaginationMeta = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.GetAssetInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.GetAssetInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.GetAssetInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetAssetInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.GetAssetInfo}
 */
proto.iroha.protocol.GetAssetInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.GetAssetInfo;
  return proto.iroha.protocol.GetAssetInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.GetAssetInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.GetAssetInfo}
 */
proto.iroha.protocol.GetAssetInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.GetAssetInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.GetAssetInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.GetAssetInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetAssetInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string asset_id = 1;
 * @return {string}
 */
proto.iroha.protocol.GetAssetInfo.prototype.getAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.GetAssetInfo} returns this
 */
proto.iroha.protocol.GetAssetInfo.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.GetRoles.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.GetRoles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.GetRoles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetRoles.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.GetRoles}
 */
proto.iroha.protocol.GetRoles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.GetRoles;
  return proto.iroha.protocol.GetRoles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.GetRoles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.GetRoles}
 */
proto.iroha.protocol.GetRoles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.GetRoles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.GetRoles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.GetRoles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetRoles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.GetRolePermissions.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.GetRolePermissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.GetRolePermissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetRolePermissions.toObject = function(includeInstance, msg) {
  var f, obj = {
    roleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.GetRolePermissions}
 */
proto.iroha.protocol.GetRolePermissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.GetRolePermissions;
  return proto.iroha.protocol.GetRolePermissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.GetRolePermissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.GetRolePermissions}
 */
proto.iroha.protocol.GetRolePermissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.GetRolePermissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.GetRolePermissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.GetRolePermissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetRolePermissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string role_id = 1;
 * @return {string}
 */
proto.iroha.protocol.GetRolePermissions.prototype.getRoleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.GetRolePermissions} returns this
 */
proto.iroha.protocol.GetRolePermissions.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.GetPendingTransactions.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.GetPendingTransactions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.GetPendingTransactions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetPendingTransactions.toObject = function(includeInstance, msg) {
  var f, obj = {
    paginationMeta: (f = msg.getPaginationMeta()) && proto.iroha.protocol.TxPaginationMeta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.GetPendingTransactions}
 */
proto.iroha.protocol.GetPendingTransactions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.GetPendingTransactions;
  return proto.iroha.protocol.GetPendingTransactions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.GetPendingTransactions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.GetPendingTransactions}
 */
proto.iroha.protocol.GetPendingTransactions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.iroha.protocol.TxPaginationMeta;
      reader.readMessage(value,proto.iroha.protocol.TxPaginationMeta.deserializeBinaryFromReader);
      msg.setPaginationMeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.GetPendingTransactions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.GetPendingTransactions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.GetPendingTransactions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetPendingTransactions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaginationMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.iroha.protocol.TxPaginationMeta.serializeBinaryToWriter
    );
  }
};


/**
 * optional TxPaginationMeta pagination_meta = 1;
 * @return {?proto.iroha.protocol.TxPaginationMeta}
 */
proto.iroha.protocol.GetPendingTransactions.prototype.getPaginationMeta = function() {
  return /** @type{?proto.iroha.protocol.TxPaginationMeta} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.TxPaginationMeta, 1));
};


/**
 * @param {?proto.iroha.protocol.TxPaginationMeta|undefined} value
 * @return {!proto.iroha.protocol.GetPendingTransactions} returns this
*/
proto.iroha.protocol.GetPendingTransactions.prototype.setPaginationMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.GetPendingTransactions} returns this
 */
proto.iroha.protocol.GetPendingTransactions.prototype.clearPaginationMeta = function() {
  return this.setPaginationMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.GetPendingTransactions.prototype.hasPaginationMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.GetPeers.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.GetPeers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.GetPeers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetPeers.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.GetPeers}
 */
proto.iroha.protocol.GetPeers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.GetPeers;
  return proto.iroha.protocol.GetPeers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.GetPeers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.GetPeers}
 */
proto.iroha.protocol.GetPeers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.GetPeers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.GetPeers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.GetPeers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetPeers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.QueryPayloadMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.QueryPayloadMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.QueryPayloadMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.QueryPayloadMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    creatorAccountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    queryCounter: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.QueryPayloadMeta}
 */
proto.iroha.protocol.QueryPayloadMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.QueryPayloadMeta;
  return proto.iroha.protocol.QueryPayloadMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.QueryPayloadMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.QueryPayloadMeta}
 */
proto.iroha.protocol.QueryPayloadMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCreatedTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatorAccountId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setQueryCounter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.QueryPayloadMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.QueryPayloadMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.QueryPayloadMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.QueryPayloadMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedTime();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getCreatorAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQueryCounter();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional uint64 created_time = 1;
 * @return {number}
 */
proto.iroha.protocol.QueryPayloadMeta.prototype.getCreatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.QueryPayloadMeta} returns this
 */
proto.iroha.protocol.QueryPayloadMeta.prototype.setCreatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string creator_account_id = 2;
 * @return {string}
 */
proto.iroha.protocol.QueryPayloadMeta.prototype.getCreatorAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.QueryPayloadMeta} returns this
 */
proto.iroha.protocol.QueryPayloadMeta.prototype.setCreatorAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 query_counter = 3;
 * @return {number}
 */
proto.iroha.protocol.QueryPayloadMeta.prototype.getQueryCounter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.QueryPayloadMeta} returns this
 */
proto.iroha.protocol.QueryPayloadMeta.prototype.setQueryCounter = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.GetEngineReceipts.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.GetEngineReceipts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.GetEngineReceipts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetEngineReceipts.toObject = function(includeInstance, msg) {
  var f, obj = {
    txHash: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.GetEngineReceipts}
 */
proto.iroha.protocol.GetEngineReceipts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.GetEngineReceipts;
  return proto.iroha.protocol.GetEngineReceipts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.GetEngineReceipts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.GetEngineReceipts}
 */
proto.iroha.protocol.GetEngineReceipts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.GetEngineReceipts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.GetEngineReceipts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.GetEngineReceipts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.GetEngineReceipts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTxHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tx_hash = 1;
 * @return {string}
 */
proto.iroha.protocol.GetEngineReceipts.prototype.getTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.GetEngineReceipts} returns this
 */
proto.iroha.protocol.GetEngineReceipts.prototype.setTxHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.Query.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.Query.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.Query} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Query.toObject = function(includeInstance, msg) {
  var f, obj = {
    payload: (f = msg.getPayload()) && proto.iroha.protocol.Query.Payload.toObject(includeInstance, f),
    signature: (f = msg.getSignature()) && primitive_pb.Signature.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.Query}
 */
proto.iroha.protocol.Query.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.Query;
  return proto.iroha.protocol.Query.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.Query} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.Query}
 */
proto.iroha.protocol.Query.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.iroha.protocol.Query.Payload;
      reader.readMessage(value,proto.iroha.protocol.Query.Payload.deserializeBinaryFromReader);
      msg.setPayload(value);
      break;
    case 2:
      var value = new primitive_pb.Signature;
      reader.readMessage(value,primitive_pb.Signature.deserializeBinaryFromReader);
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.Query.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.Query.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.Query} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Query.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayload();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.iroha.protocol.Query.Payload.serializeBinaryToWriter
    );
  }
  f = message.getSignature();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      primitive_pb.Signature.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.iroha.protocol.Query.Payload.oneofGroups_ = [[3,4,5,6,7,8,9,10,11,12,13,14,15,16]];

/**
 * @enum {number}
 */
proto.iroha.protocol.Query.Payload.QueryCase = {
  QUERY_NOT_SET: 0,
  GET_ACCOUNT: 3,
  GET_SIGNATORIES: 4,
  GET_ACCOUNT_TRANSACTIONS: 5,
  GET_ACCOUNT_ASSET_TRANSACTIONS: 6,
  GET_TRANSACTIONS: 7,
  GET_ACCOUNT_ASSETS: 8,
  GET_ACCOUNT_DETAIL: 9,
  GET_ROLES: 10,
  GET_ROLE_PERMISSIONS: 11,
  GET_ASSET_INFO: 12,
  GET_PENDING_TRANSACTIONS: 13,
  GET_BLOCK: 14,
  GET_PEERS: 15,
  GET_ENGINE_RECEIPTS: 16
};

/**
 * @return {proto.iroha.protocol.Query.Payload.QueryCase}
 */
proto.iroha.protocol.Query.Payload.prototype.getQueryCase = function() {
  return /** @type {proto.iroha.protocol.Query.Payload.QueryCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.Query.Payload.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.Query.Payload.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.Query.Payload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.Query.Payload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Query.Payload.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.iroha.protocol.QueryPayloadMeta.toObject(includeInstance, f),
    getAccount: (f = msg.getGetAccount()) && proto.iroha.protocol.GetAccount.toObject(includeInstance, f),
    getSignatories: (f = msg.getGetSignatories()) && proto.iroha.protocol.GetSignatories.toObject(includeInstance, f),
    getAccountTransactions: (f = msg.getGetAccountTransactions()) && proto.iroha.protocol.GetAccountTransactions.toObject(includeInstance, f),
    getAccountAssetTransactions: (f = msg.getGetAccountAssetTransactions()) && proto.iroha.protocol.GetAccountAssetTransactions.toObject(includeInstance, f),
    getTransactions: (f = msg.getGetTransactions()) && proto.iroha.protocol.GetTransactions.toObject(includeInstance, f),
    getAccountAssets: (f = msg.getGetAccountAssets()) && proto.iroha.protocol.GetAccountAssets.toObject(includeInstance, f),
    getAccountDetail: (f = msg.getGetAccountDetail()) && proto.iroha.protocol.GetAccountDetail.toObject(includeInstance, f),
    getRoles: (f = msg.getGetRoles()) && proto.iroha.protocol.GetRoles.toObject(includeInstance, f),
    getRolePermissions: (f = msg.getGetRolePermissions()) && proto.iroha.protocol.GetRolePermissions.toObject(includeInstance, f),
    getAssetInfo: (f = msg.getGetAssetInfo()) && proto.iroha.protocol.GetAssetInfo.toObject(includeInstance, f),
    getPendingTransactions: (f = msg.getGetPendingTransactions()) && proto.iroha.protocol.GetPendingTransactions.toObject(includeInstance, f),
    getBlock: (f = msg.getGetBlock()) && proto.iroha.protocol.GetBlock.toObject(includeInstance, f),
    getPeers: (f = msg.getGetPeers()) && proto.iroha.protocol.GetPeers.toObject(includeInstance, f),
    getEngineReceipts: (f = msg.getGetEngineReceipts()) && proto.iroha.protocol.GetEngineReceipts.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.Query.Payload}
 */
proto.iroha.protocol.Query.Payload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.Query.Payload;
  return proto.iroha.protocol.Query.Payload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.Query.Payload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.Query.Payload}
 */
proto.iroha.protocol.Query.Payload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.iroha.protocol.QueryPayloadMeta;
      reader.readMessage(value,proto.iroha.protocol.QueryPayloadMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 3:
      var value = new proto.iroha.protocol.GetAccount;
      reader.readMessage(value,proto.iroha.protocol.GetAccount.deserializeBinaryFromReader);
      msg.setGetAccount(value);
      break;
    case 4:
      var value = new proto.iroha.protocol.GetSignatories;
      reader.readMessage(value,proto.iroha.protocol.GetSignatories.deserializeBinaryFromReader);
      msg.setGetSignatories(value);
      break;
    case 5:
      var value = new proto.iroha.protocol.GetAccountTransactions;
      reader.readMessage(value,proto.iroha.protocol.GetAccountTransactions.deserializeBinaryFromReader);
      msg.setGetAccountTransactions(value);
      break;
    case 6:
      var value = new proto.iroha.protocol.GetAccountAssetTransactions;
      reader.readMessage(value,proto.iroha.protocol.GetAccountAssetTransactions.deserializeBinaryFromReader);
      msg.setGetAccountAssetTransactions(value);
      break;
    case 7:
      var value = new proto.iroha.protocol.GetTransactions;
      reader.readMessage(value,proto.iroha.protocol.GetTransactions.deserializeBinaryFromReader);
      msg.setGetTransactions(value);
      break;
    case 8:
      var value = new proto.iroha.protocol.GetAccountAssets;
      reader.readMessage(value,proto.iroha.protocol.GetAccountAssets.deserializeBinaryFromReader);
      msg.setGetAccountAssets(value);
      break;
    case 9:
      var value = new proto.iroha.protocol.GetAccountDetail;
      reader.readMessage(value,proto.iroha.protocol.GetAccountDetail.deserializeBinaryFromReader);
      msg.setGetAccountDetail(value);
      break;
    case 10:
      var value = new proto.iroha.protocol.GetRoles;
      reader.readMessage(value,proto.iroha.protocol.GetRoles.deserializeBinaryFromReader);
      msg.setGetRoles(value);
      break;
    case 11:
      var value = new proto.iroha.protocol.GetRolePermissions;
      reader.readMessage(value,proto.iroha.protocol.GetRolePermissions.deserializeBinaryFromReader);
      msg.setGetRolePermissions(value);
      break;
    case 12:
      var value = new proto.iroha.protocol.GetAssetInfo;
      reader.readMessage(value,proto.iroha.protocol.GetAssetInfo.deserializeBinaryFromReader);
      msg.setGetAssetInfo(value);
      break;
    case 13:
      var value = new proto.iroha.protocol.GetPendingTransactions;
      reader.readMessage(value,proto.iroha.protocol.GetPendingTransactions.deserializeBinaryFromReader);
      msg.setGetPendingTransactions(value);
      break;
    case 14:
      var value = new proto.iroha.protocol.GetBlock;
      reader.readMessage(value,proto.iroha.protocol.GetBlock.deserializeBinaryFromReader);
      msg.setGetBlock(value);
      break;
    case 15:
      var value = new proto.iroha.protocol.GetPeers;
      reader.readMessage(value,proto.iroha.protocol.GetPeers.deserializeBinaryFromReader);
      msg.setGetPeers(value);
      break;
    case 16:
      var value = new proto.iroha.protocol.GetEngineReceipts;
      reader.readMessage(value,proto.iroha.protocol.GetEngineReceipts.deserializeBinaryFromReader);
      msg.setGetEngineReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.Query.Payload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.Query.Payload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.Query.Payload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Query.Payload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.iroha.protocol.QueryPayloadMeta.serializeBinaryToWriter
    );
  }
  f = message.getGetAccount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.iroha.protocol.GetAccount.serializeBinaryToWriter
    );
  }
  f = message.getGetSignatories();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.iroha.protocol.GetSignatories.serializeBinaryToWriter
    );
  }
  f = message.getGetAccountTransactions();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.iroha.protocol.GetAccountTransactions.serializeBinaryToWriter
    );
  }
  f = message.getGetAccountAssetTransactions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.iroha.protocol.GetAccountAssetTransactions.serializeBinaryToWriter
    );
  }
  f = message.getGetTransactions();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.iroha.protocol.GetTransactions.serializeBinaryToWriter
    );
  }
  f = message.getGetAccountAssets();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.iroha.protocol.GetAccountAssets.serializeBinaryToWriter
    );
  }
  f = message.getGetAccountDetail();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.iroha.protocol.GetAccountDetail.serializeBinaryToWriter
    );
  }
  f = message.getGetRoles();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.iroha.protocol.GetRoles.serializeBinaryToWriter
    );
  }
  f = message.getGetRolePermissions();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.iroha.protocol.GetRolePermissions.serializeBinaryToWriter
    );
  }
  f = message.getGetAssetInfo();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.iroha.protocol.GetAssetInfo.serializeBinaryToWriter
    );
  }
  f = message.getGetPendingTransactions();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.iroha.protocol.GetPendingTransactions.serializeBinaryToWriter
    );
  }
  f = message.getGetBlock();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.iroha.protocol.GetBlock.serializeBinaryToWriter
    );
  }
  f = message.getGetPeers();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.iroha.protocol.GetPeers.serializeBinaryToWriter
    );
  }
  f = message.getGetEngineReceipts();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.iroha.protocol.GetEngineReceipts.serializeBinaryToWriter
    );
  }
};


/**
 * optional QueryPayloadMeta meta = 1;
 * @return {?proto.iroha.protocol.QueryPayloadMeta}
 */
proto.iroha.protocol.Query.Payload.prototype.getMeta = function() {
  return /** @type{?proto.iroha.protocol.QueryPayloadMeta} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.QueryPayloadMeta, 1));
};


/**
 * @param {?proto.iroha.protocol.QueryPayloadMeta|undefined} value
 * @return {!proto.iroha.protocol.Query.Payload} returns this
*/
proto.iroha.protocol.Query.Payload.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query.Payload} returns this
 */
proto.iroha.protocol.Query.Payload.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.Payload.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetAccount get_account = 3;
 * @return {?proto.iroha.protocol.GetAccount}
 */
proto.iroha.protocol.Query.Payload.prototype.getGetAccount = function() {
  return /** @type{?proto.iroha.protocol.GetAccount} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.GetAccount, 3));
};


/**
 * @param {?proto.iroha.protocol.GetAccount|undefined} value
 * @return {!proto.iroha.protocol.Query.Payload} returns this
*/
proto.iroha.protocol.Query.Payload.prototype.setGetAccount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.iroha.protocol.Query.Payload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query.Payload} returns this
 */
proto.iroha.protocol.Query.Payload.prototype.clearGetAccount = function() {
  return this.setGetAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.Payload.prototype.hasGetAccount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional GetSignatories get_signatories = 4;
 * @return {?proto.iroha.protocol.GetSignatories}
 */
proto.iroha.protocol.Query.Payload.prototype.getGetSignatories = function() {
  return /** @type{?proto.iroha.protocol.GetSignatories} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.GetSignatories, 4));
};


/**
 * @param {?proto.iroha.protocol.GetSignatories|undefined} value
 * @return {!proto.iroha.protocol.Query.Payload} returns this
*/
proto.iroha.protocol.Query.Payload.prototype.setGetSignatories = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.iroha.protocol.Query.Payload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query.Payload} returns this
 */
proto.iroha.protocol.Query.Payload.prototype.clearGetSignatories = function() {
  return this.setGetSignatories(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.Payload.prototype.hasGetSignatories = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional GetAccountTransactions get_account_transactions = 5;
 * @return {?proto.iroha.protocol.GetAccountTransactions}
 */
proto.iroha.protocol.Query.Payload.prototype.getGetAccountTransactions = function() {
  return /** @type{?proto.iroha.protocol.GetAccountTransactions} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.GetAccountTransactions, 5));
};


/**
 * @param {?proto.iroha.protocol.GetAccountTransactions|undefined} value
 * @return {!proto.iroha.protocol.Query.Payload} returns this
*/
proto.iroha.protocol.Query.Payload.prototype.setGetAccountTransactions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.iroha.protocol.Query.Payload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query.Payload} returns this
 */
proto.iroha.protocol.Query.Payload.prototype.clearGetAccountTransactions = function() {
  return this.setGetAccountTransactions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.Payload.prototype.hasGetAccountTransactions = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional GetAccountAssetTransactions get_account_asset_transactions = 6;
 * @return {?proto.iroha.protocol.GetAccountAssetTransactions}
 */
proto.iroha.protocol.Query.Payload.prototype.getGetAccountAssetTransactions = function() {
  return /** @type{?proto.iroha.protocol.GetAccountAssetTransactions} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.GetAccountAssetTransactions, 6));
};


/**
 * @param {?proto.iroha.protocol.GetAccountAssetTransactions|undefined} value
 * @return {!proto.iroha.protocol.Query.Payload} returns this
*/
proto.iroha.protocol.Query.Payload.prototype.setGetAccountAssetTransactions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.iroha.protocol.Query.Payload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query.Payload} returns this
 */
proto.iroha.protocol.Query.Payload.prototype.clearGetAccountAssetTransactions = function() {
  return this.setGetAccountAssetTransactions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.Payload.prototype.hasGetAccountAssetTransactions = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional GetTransactions get_transactions = 7;
 * @return {?proto.iroha.protocol.GetTransactions}
 */
proto.iroha.protocol.Query.Payload.prototype.getGetTransactions = function() {
  return /** @type{?proto.iroha.protocol.GetTransactions} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.GetTransactions, 7));
};


/**
 * @param {?proto.iroha.protocol.GetTransactions|undefined} value
 * @return {!proto.iroha.protocol.Query.Payload} returns this
*/
proto.iroha.protocol.Query.Payload.prototype.setGetTransactions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.iroha.protocol.Query.Payload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query.Payload} returns this
 */
proto.iroha.protocol.Query.Payload.prototype.clearGetTransactions = function() {
  return this.setGetTransactions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.Payload.prototype.hasGetTransactions = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional GetAccountAssets get_account_assets = 8;
 * @return {?proto.iroha.protocol.GetAccountAssets}
 */
proto.iroha.protocol.Query.Payload.prototype.getGetAccountAssets = function() {
  return /** @type{?proto.iroha.protocol.GetAccountAssets} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.GetAccountAssets, 8));
};


/**
 * @param {?proto.iroha.protocol.GetAccountAssets|undefined} value
 * @return {!proto.iroha.protocol.Query.Payload} returns this
*/
proto.iroha.protocol.Query.Payload.prototype.setGetAccountAssets = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.iroha.protocol.Query.Payload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query.Payload} returns this
 */
proto.iroha.protocol.Query.Payload.prototype.clearGetAccountAssets = function() {
  return this.setGetAccountAssets(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.Payload.prototype.hasGetAccountAssets = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional GetAccountDetail get_account_detail = 9;
 * @return {?proto.iroha.protocol.GetAccountDetail}
 */
proto.iroha.protocol.Query.Payload.prototype.getGetAccountDetail = function() {
  return /** @type{?proto.iroha.protocol.GetAccountDetail} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.GetAccountDetail, 9));
};


/**
 * @param {?proto.iroha.protocol.GetAccountDetail|undefined} value
 * @return {!proto.iroha.protocol.Query.Payload} returns this
*/
proto.iroha.protocol.Query.Payload.prototype.setGetAccountDetail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.iroha.protocol.Query.Payload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query.Payload} returns this
 */
proto.iroha.protocol.Query.Payload.prototype.clearGetAccountDetail = function() {
  return this.setGetAccountDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.Payload.prototype.hasGetAccountDetail = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional GetRoles get_roles = 10;
 * @return {?proto.iroha.protocol.GetRoles}
 */
proto.iroha.protocol.Query.Payload.prototype.getGetRoles = function() {
  return /** @type{?proto.iroha.protocol.GetRoles} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.GetRoles, 10));
};


/**
 * @param {?proto.iroha.protocol.GetRoles|undefined} value
 * @return {!proto.iroha.protocol.Query.Payload} returns this
*/
proto.iroha.protocol.Query.Payload.prototype.setGetRoles = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.iroha.protocol.Query.Payload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query.Payload} returns this
 */
proto.iroha.protocol.Query.Payload.prototype.clearGetRoles = function() {
  return this.setGetRoles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.Payload.prototype.hasGetRoles = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GetRolePermissions get_role_permissions = 11;
 * @return {?proto.iroha.protocol.GetRolePermissions}
 */
proto.iroha.protocol.Query.Payload.prototype.getGetRolePermissions = function() {
  return /** @type{?proto.iroha.protocol.GetRolePermissions} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.GetRolePermissions, 11));
};


/**
 * @param {?proto.iroha.protocol.GetRolePermissions|undefined} value
 * @return {!proto.iroha.protocol.Query.Payload} returns this
*/
proto.iroha.protocol.Query.Payload.prototype.setGetRolePermissions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.iroha.protocol.Query.Payload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query.Payload} returns this
 */
proto.iroha.protocol.Query.Payload.prototype.clearGetRolePermissions = function() {
  return this.setGetRolePermissions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.Payload.prototype.hasGetRolePermissions = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional GetAssetInfo get_asset_info = 12;
 * @return {?proto.iroha.protocol.GetAssetInfo}
 */
proto.iroha.protocol.Query.Payload.prototype.getGetAssetInfo = function() {
  return /** @type{?proto.iroha.protocol.GetAssetInfo} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.GetAssetInfo, 12));
};


/**
 * @param {?proto.iroha.protocol.GetAssetInfo|undefined} value
 * @return {!proto.iroha.protocol.Query.Payload} returns this
*/
proto.iroha.protocol.Query.Payload.prototype.setGetAssetInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.iroha.protocol.Query.Payload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query.Payload} returns this
 */
proto.iroha.protocol.Query.Payload.prototype.clearGetAssetInfo = function() {
  return this.setGetAssetInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.Payload.prototype.hasGetAssetInfo = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional GetPendingTransactions get_pending_transactions = 13;
 * @return {?proto.iroha.protocol.GetPendingTransactions}
 */
proto.iroha.protocol.Query.Payload.prototype.getGetPendingTransactions = function() {
  return /** @type{?proto.iroha.protocol.GetPendingTransactions} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.GetPendingTransactions, 13));
};


/**
 * @param {?proto.iroha.protocol.GetPendingTransactions|undefined} value
 * @return {!proto.iroha.protocol.Query.Payload} returns this
*/
proto.iroha.protocol.Query.Payload.prototype.setGetPendingTransactions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.iroha.protocol.Query.Payload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query.Payload} returns this
 */
proto.iroha.protocol.Query.Payload.prototype.clearGetPendingTransactions = function() {
  return this.setGetPendingTransactions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.Payload.prototype.hasGetPendingTransactions = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional GetBlock get_block = 14;
 * @return {?proto.iroha.protocol.GetBlock}
 */
proto.iroha.protocol.Query.Payload.prototype.getGetBlock = function() {
  return /** @type{?proto.iroha.protocol.GetBlock} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.GetBlock, 14));
};


/**
 * @param {?proto.iroha.protocol.GetBlock|undefined} value
 * @return {!proto.iroha.protocol.Query.Payload} returns this
*/
proto.iroha.protocol.Query.Payload.prototype.setGetBlock = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.iroha.protocol.Query.Payload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query.Payload} returns this
 */
proto.iroha.protocol.Query.Payload.prototype.clearGetBlock = function() {
  return this.setGetBlock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.Payload.prototype.hasGetBlock = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional GetPeers get_peers = 15;
 * @return {?proto.iroha.protocol.GetPeers}
 */
proto.iroha.protocol.Query.Payload.prototype.getGetPeers = function() {
  return /** @type{?proto.iroha.protocol.GetPeers} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.GetPeers, 15));
};


/**
 * @param {?proto.iroha.protocol.GetPeers|undefined} value
 * @return {!proto.iroha.protocol.Query.Payload} returns this
*/
proto.iroha.protocol.Query.Payload.prototype.setGetPeers = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.iroha.protocol.Query.Payload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query.Payload} returns this
 */
proto.iroha.protocol.Query.Payload.prototype.clearGetPeers = function() {
  return this.setGetPeers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.Payload.prototype.hasGetPeers = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional GetEngineReceipts get_engine_receipts = 16;
 * @return {?proto.iroha.protocol.GetEngineReceipts}
 */
proto.iroha.protocol.Query.Payload.prototype.getGetEngineReceipts = function() {
  return /** @type{?proto.iroha.protocol.GetEngineReceipts} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.GetEngineReceipts, 16));
};


/**
 * @param {?proto.iroha.protocol.GetEngineReceipts|undefined} value
 * @return {!proto.iroha.protocol.Query.Payload} returns this
*/
proto.iroha.protocol.Query.Payload.prototype.setGetEngineReceipts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.iroha.protocol.Query.Payload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query.Payload} returns this
 */
proto.iroha.protocol.Query.Payload.prototype.clearGetEngineReceipts = function() {
  return this.setGetEngineReceipts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.Payload.prototype.hasGetEngineReceipts = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Payload payload = 1;
 * @return {?proto.iroha.protocol.Query.Payload}
 */
proto.iroha.protocol.Query.prototype.getPayload = function() {
  return /** @type{?proto.iroha.protocol.Query.Payload} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.Query.Payload, 1));
};


/**
 * @param {?proto.iroha.protocol.Query.Payload|undefined} value
 * @return {!proto.iroha.protocol.Query} returns this
*/
proto.iroha.protocol.Query.prototype.setPayload = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query} returns this
 */
proto.iroha.protocol.Query.prototype.clearPayload = function() {
  return this.setPayload(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.prototype.hasPayload = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Signature signature = 2;
 * @return {?proto.iroha.protocol.Signature}
 */
proto.iroha.protocol.Query.prototype.getSignature = function() {
  return /** @type{?proto.iroha.protocol.Signature} */ (
    jspb.Message.getWrapperField(this, primitive_pb.Signature, 2));
};


/**
 * @param {?proto.iroha.protocol.Signature|undefined} value
 * @return {!proto.iroha.protocol.Query} returns this
*/
proto.iroha.protocol.Query.prototype.setSignature = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.Query} returns this
 */
proto.iroha.protocol.Query.prototype.clearSignature = function() {
  return this.setSignature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.Query.prototype.hasSignature = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.BlocksQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.BlocksQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.BlocksQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.BlocksQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.iroha.protocol.QueryPayloadMeta.toObject(includeInstance, f),
    signature: (f = msg.getSignature()) && primitive_pb.Signature.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.BlocksQuery}
 */
proto.iroha.protocol.BlocksQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.BlocksQuery;
  return proto.iroha.protocol.BlocksQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.BlocksQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.BlocksQuery}
 */
proto.iroha.protocol.BlocksQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.iroha.protocol.QueryPayloadMeta;
      reader.readMessage(value,proto.iroha.protocol.QueryPayloadMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new primitive_pb.Signature;
      reader.readMessage(value,primitive_pb.Signature.deserializeBinaryFromReader);
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.BlocksQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.BlocksQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.BlocksQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.BlocksQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.iroha.protocol.QueryPayloadMeta.serializeBinaryToWriter
    );
  }
  f = message.getSignature();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      primitive_pb.Signature.serializeBinaryToWriter
    );
  }
};


/**
 * optional QueryPayloadMeta meta = 1;
 * @return {?proto.iroha.protocol.QueryPayloadMeta}
 */
proto.iroha.protocol.BlocksQuery.prototype.getMeta = function() {
  return /** @type{?proto.iroha.protocol.QueryPayloadMeta} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.QueryPayloadMeta, 1));
};


/**
 * @param {?proto.iroha.protocol.QueryPayloadMeta|undefined} value
 * @return {!proto.iroha.protocol.BlocksQuery} returns this
*/
proto.iroha.protocol.BlocksQuery.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.BlocksQuery} returns this
 */
proto.iroha.protocol.BlocksQuery.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.BlocksQuery.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Signature signature = 2;
 * @return {?proto.iroha.protocol.Signature}
 */
proto.iroha.protocol.BlocksQuery.prototype.getSignature = function() {
  return /** @type{?proto.iroha.protocol.Signature} */ (
    jspb.Message.getWrapperField(this, primitive_pb.Signature, 2));
};


/**
 * @param {?proto.iroha.protocol.Signature|undefined} value
 * @return {!proto.iroha.protocol.BlocksQuery} returns this
*/
proto.iroha.protocol.BlocksQuery.prototype.setSignature = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.BlocksQuery} returns this
 */
proto.iroha.protocol.BlocksQuery.prototype.clearSignature = function() {
  return this.setSignature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.BlocksQuery.prototype.hasSignature = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.iroha.protocol.Field = {
  KCREATEDTIME: 0,
  KPOSITION: 1
};

/**
 * @enum {number}
 */
proto.iroha.protocol.Direction = {
  KASCENDING: 0,
  KDESCENDING: 1
};

goog.object.extend(exports, proto.iroha.protocol);
