import { i18n } from '@localization';

const getWalletType = (): Record<number, string> => ({
  0: i18n.t('pages.bitcoinHoldings.standard'),
  1: i18n.t('pages.bitcoinHoldings.imported'),
});

enum WalletType {
  Standard = 'standard',
  Imported = 'imported',
  StandardWithPhrase = 'standardWithPhrase',
  ImportedWithPhrase = 'importedWithPhrase',
}

enum WalletIndexType {
  Standard = 0,
  Imported = 1,
}

export { getWalletType, WalletIndexType, WalletType };
