export const CREATE_FORM_STATE_ASYNC = 'CREATE_FORM_STATE_ASYNC';

export const CREATE_FORM_STATE = 'CREATE_FORM_STATE';

export const UPDATE_FORM_STATE_ASYNC = 'UPDATE_FORM_STATE_ASYNC';

export const UPDATE_FORM_STATE = 'UPDATE_FORM_STATE';

export const DELETE_FORM_STATE_ASYNC = 'DELETE_FORM_STATE_ASYNC';

export const DELETE_FORM_STATE = 'DELETE_FORM_STATE';
