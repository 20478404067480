// source: qry_responses.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var block_pb = require('./block_pb.js');
goog.object.extend(proto, block_pb);
var transaction_pb = require('./transaction_pb.js');
goog.object.extend(proto, transaction_pb);
var primitive_pb = require('./primitive_pb.js');
goog.object.extend(proto, primitive_pb);
goog.exportSymbol('proto.iroha.protocol.Account', null, global);
goog.exportSymbol('proto.iroha.protocol.AccountAsset', null, global);
goog.exportSymbol('proto.iroha.protocol.AccountAssetResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.AccountAssetResponse.OptNextAssetIdCase', null, global);
goog.exportSymbol('proto.iroha.protocol.AccountDetailResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.AccountResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.Asset', null, global);
goog.exportSymbol('proto.iroha.protocol.AssetResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.BlockErrorResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.BlockQueryResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.BlockQueryResponse.ResponseCase', null, global);
goog.exportSymbol('proto.iroha.protocol.BlockResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.Domain', null, global);
goog.exportSymbol('proto.iroha.protocol.EngineReceiptsResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.ErrorResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.ErrorResponse.Reason', null, global);
goog.exportSymbol('proto.iroha.protocol.HealthcheckData', null, global);
goog.exportSymbol('proto.iroha.protocol.HealthcheckData.OptIsHealthyCase', null, global);
goog.exportSymbol('proto.iroha.protocol.HealthcheckData.OptIsSyncingCase', null, global);
goog.exportSymbol('proto.iroha.protocol.HealthcheckData.OptLastBlockHeightCase', null, global);
goog.exportSymbol('proto.iroha.protocol.HealthcheckData.OptLastBlockRejectCase', null, global);
goog.exportSymbol('proto.iroha.protocol.HealthcheckData.OptMemoryConsumptionCase', null, global);
goog.exportSymbol('proto.iroha.protocol.PeersResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.PendingTransactionsPageResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo', null, global);
goog.exportSymbol('proto.iroha.protocol.QueryResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.QueryResponse.ResponseCase', null, global);
goog.exportSymbol('proto.iroha.protocol.RolePermissionsResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.RolesResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.SignatoriesResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.TransactionsPageResponse', null, global);
goog.exportSymbol('proto.iroha.protocol.TransactionsPageResponse.NextPageTagCase', null, global);
goog.exportSymbol('proto.iroha.protocol.TransactionsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.Asset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.Asset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.Asset.displayName = 'proto.iroha.protocol.Asset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.Domain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.Domain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.Domain.displayName = 'proto.iroha.protocol.Domain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.Account.displayName = 'proto.iroha.protocol.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.AccountAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.AccountAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.AccountAsset.displayName = 'proto.iroha.protocol.AccountAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.AccountAssetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iroha.protocol.AccountAssetResponse.repeatedFields_, proto.iroha.protocol.AccountAssetResponse.oneofGroups_);
};
goog.inherits(proto.iroha.protocol.AccountAssetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.AccountAssetResponse.displayName = 'proto.iroha.protocol.AccountAssetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.AccountDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.AccountDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.AccountDetailResponse.displayName = 'proto.iroha.protocol.AccountDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.AccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iroha.protocol.AccountResponse.repeatedFields_, null);
};
goog.inherits(proto.iroha.protocol.AccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.AccountResponse.displayName = 'proto.iroha.protocol.AccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.AssetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.AssetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.AssetResponse.displayName = 'proto.iroha.protocol.AssetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.RolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iroha.protocol.RolesResponse.repeatedFields_, null);
};
goog.inherits(proto.iroha.protocol.RolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.RolesResponse.displayName = 'proto.iroha.protocol.RolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.RolePermissionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iroha.protocol.RolePermissionsResponse.repeatedFields_, null);
};
goog.inherits(proto.iroha.protocol.RolePermissionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.RolePermissionsResponse.displayName = 'proto.iroha.protocol.RolePermissionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.ErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.ErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.ErrorResponse.displayName = 'proto.iroha.protocol.ErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.SignatoriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iroha.protocol.SignatoriesResponse.repeatedFields_, null);
};
goog.inherits(proto.iroha.protocol.SignatoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.SignatoriesResponse.displayName = 'proto.iroha.protocol.SignatoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.TransactionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iroha.protocol.TransactionsResponse.repeatedFields_, null);
};
goog.inherits(proto.iroha.protocol.TransactionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.TransactionsResponse.displayName = 'proto.iroha.protocol.TransactionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.TransactionsPageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iroha.protocol.TransactionsPageResponse.repeatedFields_, proto.iroha.protocol.TransactionsPageResponse.oneofGroups_);
};
goog.inherits(proto.iroha.protocol.TransactionsPageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.TransactionsPageResponse.displayName = 'proto.iroha.protocol.TransactionsPageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.PendingTransactionsPageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iroha.protocol.PendingTransactionsPageResponse.repeatedFields_, null);
};
goog.inherits(proto.iroha.protocol.PendingTransactionsPageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.PendingTransactionsPageResponse.displayName = 'proto.iroha.protocol.PendingTransactionsPageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.displayName = 'proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.PeersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iroha.protocol.PeersResponse.repeatedFields_, null);
};
goog.inherits(proto.iroha.protocol.PeersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.PeersResponse.displayName = 'proto.iroha.protocol.PeersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.EngineReceiptsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iroha.protocol.EngineReceiptsResponse.repeatedFields_, null);
};
goog.inherits(proto.iroha.protocol.EngineReceiptsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.EngineReceiptsResponse.displayName = 'proto.iroha.protocol.EngineReceiptsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.QueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.iroha.protocol.QueryResponse.oneofGroups_);
};
goog.inherits(proto.iroha.protocol.QueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.QueryResponse.displayName = 'proto.iroha.protocol.QueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.BlockResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.BlockResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.BlockResponse.displayName = 'proto.iroha.protocol.BlockResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.BlockErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.iroha.protocol.BlockErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.BlockErrorResponse.displayName = 'proto.iroha.protocol.BlockErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.BlockQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.iroha.protocol.BlockQueryResponse.oneofGroups_);
};
goog.inherits(proto.iroha.protocol.BlockQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.BlockQueryResponse.displayName = 'proto.iroha.protocol.BlockQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iroha.protocol.HealthcheckData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.iroha.protocol.HealthcheckData.oneofGroups_);
};
goog.inherits(proto.iroha.protocol.HealthcheckData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iroha.protocol.HealthcheckData.displayName = 'proto.iroha.protocol.HealthcheckData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.Asset.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.Asset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.Asset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Asset.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    domainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    precision: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.Asset}
 */
proto.iroha.protocol.Asset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.Asset;
  return proto.iroha.protocol.Asset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.Asset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.Asset}
 */
proto.iroha.protocol.Asset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrecision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.Asset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.Asset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.Asset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Asset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrecision();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string asset_id = 1;
 * @return {string}
 */
proto.iroha.protocol.Asset.prototype.getAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.Asset} returns this
 */
proto.iroha.protocol.Asset.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string domain_id = 2;
 * @return {string}
 */
proto.iroha.protocol.Asset.prototype.getDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.Asset} returns this
 */
proto.iroha.protocol.Asset.prototype.setDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 precision = 3;
 * @return {number}
 */
proto.iroha.protocol.Asset.prototype.getPrecision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.Asset} returns this
 */
proto.iroha.protocol.Asset.prototype.setPrecision = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.Domain.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.Domain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.Domain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Domain.toObject = function(includeInstance, msg) {
  var f, obj = {
    domainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    defaultRole: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.Domain}
 */
proto.iroha.protocol.Domain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.Domain;
  return proto.iroha.protocol.Domain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.Domain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.Domain}
 */
proto.iroha.protocol.Domain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.Domain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.Domain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.Domain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Domain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDefaultRole();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string domain_id = 1;
 * @return {string}
 */
proto.iroha.protocol.Domain.prototype.getDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.Domain} returns this
 */
proto.iroha.protocol.Domain.prototype.setDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string default_role = 2;
 * @return {string}
 */
proto.iroha.protocol.Domain.prototype.getDefaultRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.Domain} returns this
 */
proto.iroha.protocol.Domain.prototype.setDefaultRole = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    domainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    quorum: jspb.Message.getFieldWithDefault(msg, 3, 0),
    jsonData: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.Account}
 */
proto.iroha.protocol.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.Account;
  return proto.iroha.protocol.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.Account}
 */
proto.iroha.protocol.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuorum(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setJsonData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuorum();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getJsonData();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.iroha.protocol.Account.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.Account} returns this
 */
proto.iroha.protocol.Account.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string domain_id = 2;
 * @return {string}
 */
proto.iroha.protocol.Account.prototype.getDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.Account} returns this
 */
proto.iroha.protocol.Account.prototype.setDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 quorum = 3;
 * @return {number}
 */
proto.iroha.protocol.Account.prototype.getQuorum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.Account} returns this
 */
proto.iroha.protocol.Account.prototype.setQuorum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string json_data = 4;
 * @return {string}
 */
proto.iroha.protocol.Account.prototype.getJsonData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.Account} returns this
 */
proto.iroha.protocol.Account.prototype.setJsonData = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.AccountAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.AccountAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.AccountAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AccountAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    balance: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.AccountAsset}
 */
proto.iroha.protocol.AccountAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.AccountAsset;
  return proto.iroha.protocol.AccountAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.AccountAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.AccountAsset}
 */
proto.iroha.protocol.AccountAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.AccountAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.AccountAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.AccountAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AccountAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBalance();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string asset_id = 1;
 * @return {string}
 */
proto.iroha.protocol.AccountAsset.prototype.getAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.AccountAsset} returns this
 */
proto.iroha.protocol.AccountAsset.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.iroha.protocol.AccountAsset.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.AccountAsset} returns this
 */
proto.iroha.protocol.AccountAsset.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string balance = 3;
 * @return {string}
 */
proto.iroha.protocol.AccountAsset.prototype.getBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.AccountAsset} returns this
 */
proto.iroha.protocol.AccountAsset.prototype.setBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iroha.protocol.AccountAssetResponse.repeatedFields_ = [1];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.iroha.protocol.AccountAssetResponse.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.iroha.protocol.AccountAssetResponse.OptNextAssetIdCase = {
  OPT_NEXT_ASSET_ID_NOT_SET: 0,
  NEXT_ASSET_ID: 3
};

/**
 * @return {proto.iroha.protocol.AccountAssetResponse.OptNextAssetIdCase}
 */
proto.iroha.protocol.AccountAssetResponse.prototype.getOptNextAssetIdCase = function() {
  return /** @type {proto.iroha.protocol.AccountAssetResponse.OptNextAssetIdCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.AccountAssetResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.AccountAssetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.AccountAssetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.AccountAssetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AccountAssetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountAssetsList: jspb.Message.toObjectList(msg.getAccountAssetsList(),
    proto.iroha.protocol.AccountAsset.toObject, includeInstance),
    totalNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextAssetId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.AccountAssetResponse}
 */
proto.iroha.protocol.AccountAssetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.AccountAssetResponse;
  return proto.iroha.protocol.AccountAssetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.AccountAssetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.AccountAssetResponse}
 */
proto.iroha.protocol.AccountAssetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.iroha.protocol.AccountAsset;
      reader.readMessage(value,proto.iroha.protocol.AccountAsset.deserializeBinaryFromReader);
      msg.addAccountAssets(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextAssetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.AccountAssetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.AccountAssetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.AccountAssetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AccountAssetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.iroha.protocol.AccountAsset.serializeBinaryToWriter
    );
  }
  f = message.getTotalNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated AccountAsset account_assets = 1;
 * @return {!Array<!proto.iroha.protocol.AccountAsset>}
 */
proto.iroha.protocol.AccountAssetResponse.prototype.getAccountAssetsList = function() {
  return /** @type{!Array<!proto.iroha.protocol.AccountAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.iroha.protocol.AccountAsset, 1));
};


/**
 * @param {!Array<!proto.iroha.protocol.AccountAsset>} value
 * @return {!proto.iroha.protocol.AccountAssetResponse} returns this
*/
proto.iroha.protocol.AccountAssetResponse.prototype.setAccountAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.iroha.protocol.AccountAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.iroha.protocol.AccountAsset}
 */
proto.iroha.protocol.AccountAssetResponse.prototype.addAccountAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.iroha.protocol.AccountAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iroha.protocol.AccountAssetResponse} returns this
 */
proto.iroha.protocol.AccountAssetResponse.prototype.clearAccountAssetsList = function() {
  return this.setAccountAssetsList([]);
};


/**
 * optional uint32 total_number = 2;
 * @return {number}
 */
proto.iroha.protocol.AccountAssetResponse.prototype.getTotalNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.AccountAssetResponse} returns this
 */
proto.iroha.protocol.AccountAssetResponse.prototype.setTotalNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string next_asset_id = 3;
 * @return {string}
 */
proto.iroha.protocol.AccountAssetResponse.prototype.getNextAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.AccountAssetResponse} returns this
 */
proto.iroha.protocol.AccountAssetResponse.prototype.setNextAssetId = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.iroha.protocol.AccountAssetResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.AccountAssetResponse} returns this
 */
proto.iroha.protocol.AccountAssetResponse.prototype.clearNextAssetId = function() {
  return jspb.Message.setOneofField(this, 3, proto.iroha.protocol.AccountAssetResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.AccountAssetResponse.prototype.hasNextAssetId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.AccountDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.AccountDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.AccountDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AccountDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextRecordId: (f = msg.getNextRecordId()) && primitive_pb.AccountDetailRecordId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.AccountDetailResponse}
 */
proto.iroha.protocol.AccountDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.AccountDetailResponse;
  return proto.iroha.protocol.AccountDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.AccountDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.AccountDetailResponse}
 */
proto.iroha.protocol.AccountDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalNumber(value);
      break;
    case 3:
      var value = new primitive_pb.AccountDetailRecordId;
      reader.readMessage(value,primitive_pb.AccountDetailRecordId.deserializeBinaryFromReader);
      msg.setNextRecordId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.AccountDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.AccountDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.AccountDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AccountDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalNumber();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getNextRecordId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      primitive_pb.AccountDetailRecordId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string detail = 1;
 * @return {string}
 */
proto.iroha.protocol.AccountDetailResponse.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.AccountDetailResponse} returns this
 */
proto.iroha.protocol.AccountDetailResponse.prototype.setDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 total_number = 2;
 * @return {number}
 */
proto.iroha.protocol.AccountDetailResponse.prototype.getTotalNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.AccountDetailResponse} returns this
 */
proto.iroha.protocol.AccountDetailResponse.prototype.setTotalNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional AccountDetailRecordId next_record_id = 3;
 * @return {?proto.iroha.protocol.AccountDetailRecordId}
 */
proto.iroha.protocol.AccountDetailResponse.prototype.getNextRecordId = function() {
  return /** @type{?proto.iroha.protocol.AccountDetailRecordId} */ (
    jspb.Message.getWrapperField(this, primitive_pb.AccountDetailRecordId, 3));
};


/**
 * @param {?proto.iroha.protocol.AccountDetailRecordId|undefined} value
 * @return {!proto.iroha.protocol.AccountDetailResponse} returns this
*/
proto.iroha.protocol.AccountDetailResponse.prototype.setNextRecordId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.AccountDetailResponse} returns this
 */
proto.iroha.protocol.AccountDetailResponse.prototype.clearNextRecordId = function() {
  return this.setNextRecordId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.AccountDetailResponse.prototype.hasNextRecordId = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iroha.protocol.AccountResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.AccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.AccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.AccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: (f = msg.getAccount()) && proto.iroha.protocol.Account.toObject(includeInstance, f),
    accountRolesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.AccountResponse}
 */
proto.iroha.protocol.AccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.AccountResponse;
  return proto.iroha.protocol.AccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.AccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.AccountResponse}
 */
proto.iroha.protocol.AccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.iroha.protocol.Account;
      reader.readMessage(value,proto.iroha.protocol.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAccountRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.AccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.AccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.AccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.iroha.protocol.Account.serializeBinaryToWriter
    );
  }
  f = message.getAccountRolesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional Account account = 1;
 * @return {?proto.iroha.protocol.Account}
 */
proto.iroha.protocol.AccountResponse.prototype.getAccount = function() {
  return /** @type{?proto.iroha.protocol.Account} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.Account, 1));
};


/**
 * @param {?proto.iroha.protocol.Account|undefined} value
 * @return {!proto.iroha.protocol.AccountResponse} returns this
*/
proto.iroha.protocol.AccountResponse.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.AccountResponse} returns this
 */
proto.iroha.protocol.AccountResponse.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.AccountResponse.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string account_roles = 2;
 * @return {!Array<string>}
 */
proto.iroha.protocol.AccountResponse.prototype.getAccountRolesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.iroha.protocol.AccountResponse} returns this
 */
proto.iroha.protocol.AccountResponse.prototype.setAccountRolesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.iroha.protocol.AccountResponse} returns this
 */
proto.iroha.protocol.AccountResponse.prototype.addAccountRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iroha.protocol.AccountResponse} returns this
 */
proto.iroha.protocol.AccountResponse.prototype.clearAccountRolesList = function() {
  return this.setAccountRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.AssetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.AssetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.AssetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AssetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    asset: (f = msg.getAsset()) && proto.iroha.protocol.Asset.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.AssetResponse}
 */
proto.iroha.protocol.AssetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.AssetResponse;
  return proto.iroha.protocol.AssetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.AssetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.AssetResponse}
 */
proto.iroha.protocol.AssetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.iroha.protocol.Asset;
      reader.readMessage(value,proto.iroha.protocol.Asset.deserializeBinaryFromReader);
      msg.setAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.AssetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.AssetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.AssetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.AssetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAsset();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.iroha.protocol.Asset.serializeBinaryToWriter
    );
  }
};


/**
 * optional Asset asset = 1;
 * @return {?proto.iroha.protocol.Asset}
 */
proto.iroha.protocol.AssetResponse.prototype.getAsset = function() {
  return /** @type{?proto.iroha.protocol.Asset} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.Asset, 1));
};


/**
 * @param {?proto.iroha.protocol.Asset|undefined} value
 * @return {!proto.iroha.protocol.AssetResponse} returns this
*/
proto.iroha.protocol.AssetResponse.prototype.setAsset = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.AssetResponse} returns this
 */
proto.iroha.protocol.AssetResponse.prototype.clearAsset = function() {
  return this.setAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.AssetResponse.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iroha.protocol.RolesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.RolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.RolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.RolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.RolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.RolesResponse}
 */
proto.iroha.protocol.RolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.RolesResponse;
  return proto.iroha.protocol.RolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.RolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.RolesResponse}
 */
proto.iroha.protocol.RolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.RolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.RolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.RolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.RolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string roles = 1;
 * @return {!Array<string>}
 */
proto.iroha.protocol.RolesResponse.prototype.getRolesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.iroha.protocol.RolesResponse} returns this
 */
proto.iroha.protocol.RolesResponse.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.iroha.protocol.RolesResponse} returns this
 */
proto.iroha.protocol.RolesResponse.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iroha.protocol.RolesResponse} returns this
 */
proto.iroha.protocol.RolesResponse.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iroha.protocol.RolePermissionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.RolePermissionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.RolePermissionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.RolePermissionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.RolePermissionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.RolePermissionsResponse}
 */
proto.iroha.protocol.RolePermissionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.RolePermissionsResponse;
  return proto.iroha.protocol.RolePermissionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.RolePermissionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.RolePermissionsResponse}
 */
proto.iroha.protocol.RolePermissionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.iroha.protocol.RolePermission>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPermissions(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.RolePermissionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.RolePermissionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.RolePermissionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.RolePermissionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated RolePermission permissions = 1;
 * @return {!Array<!proto.iroha.protocol.RolePermission>}
 */
proto.iroha.protocol.RolePermissionsResponse.prototype.getPermissionsList = function() {
  return /** @type {!Array<!proto.iroha.protocol.RolePermission>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.iroha.protocol.RolePermission>} value
 * @return {!proto.iroha.protocol.RolePermissionsResponse} returns this
 */
proto.iroha.protocol.RolePermissionsResponse.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.iroha.protocol.RolePermission} value
 * @param {number=} opt_index
 * @return {!proto.iroha.protocol.RolePermissionsResponse} returns this
 */
proto.iroha.protocol.RolePermissionsResponse.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iroha.protocol.RolePermissionsResponse} returns this
 */
proto.iroha.protocol.RolePermissionsResponse.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.ErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.ErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.ErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.ErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reason: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.ErrorResponse}
 */
proto.iroha.protocol.ErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.ErrorResponse;
  return proto.iroha.protocol.ErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.ErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.ErrorResponse}
 */
proto.iroha.protocol.ErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.iroha.protocol.ErrorResponse.Reason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.ErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.ErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.ErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.ErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.iroha.protocol.ErrorResponse.Reason = {
  STATELESS_INVALID: 0,
  STATEFUL_INVALID: 1,
  NO_ACCOUNT: 2,
  NO_ACCOUNT_ASSETS: 3,
  NO_ACCOUNT_DETAIL: 4,
  NO_SIGNATORIES: 5,
  NOT_SUPPORTED: 6,
  NO_ASSET: 7,
  NO_ROLES: 8
};

/**
 * optional Reason reason = 1;
 * @return {!proto.iroha.protocol.ErrorResponse.Reason}
 */
proto.iroha.protocol.ErrorResponse.prototype.getReason = function() {
  return /** @type {!proto.iroha.protocol.ErrorResponse.Reason} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.iroha.protocol.ErrorResponse.Reason} value
 * @return {!proto.iroha.protocol.ErrorResponse} returns this
 */
proto.iroha.protocol.ErrorResponse.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.iroha.protocol.ErrorResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.ErrorResponse} returns this
 */
proto.iroha.protocol.ErrorResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 error_code = 3;
 * @return {number}
 */
proto.iroha.protocol.ErrorResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.ErrorResponse} returns this
 */
proto.iroha.protocol.ErrorResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iroha.protocol.SignatoriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.SignatoriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.SignatoriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.SignatoriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.SignatoriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    keysList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.SignatoriesResponse}
 */
proto.iroha.protocol.SignatoriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.SignatoriesResponse;
  return proto.iroha.protocol.SignatoriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.SignatoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.SignatoriesResponse}
 */
proto.iroha.protocol.SignatoriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addKeys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.SignatoriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.SignatoriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.SignatoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.SignatoriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string keys = 1;
 * @return {!Array<string>}
 */
proto.iroha.protocol.SignatoriesResponse.prototype.getKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.iroha.protocol.SignatoriesResponse} returns this
 */
proto.iroha.protocol.SignatoriesResponse.prototype.setKeysList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.iroha.protocol.SignatoriesResponse} returns this
 */
proto.iroha.protocol.SignatoriesResponse.prototype.addKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iroha.protocol.SignatoriesResponse} returns this
 */
proto.iroha.protocol.SignatoriesResponse.prototype.clearKeysList = function() {
  return this.setKeysList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iroha.protocol.TransactionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.TransactionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.TransactionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.TransactionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.TransactionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    transaction_pb.Transaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.TransactionsResponse}
 */
proto.iroha.protocol.TransactionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.TransactionsResponse;
  return proto.iroha.protocol.TransactionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.TransactionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.TransactionsResponse}
 */
proto.iroha.protocol.TransactionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new transaction_pb.Transaction;
      reader.readMessage(value,transaction_pb.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.TransactionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.TransactionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.TransactionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.TransactionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      transaction_pb.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Transaction transactions = 1;
 * @return {!Array<!proto.iroha.protocol.Transaction>}
 */
proto.iroha.protocol.TransactionsResponse.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.iroha.protocol.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, transaction_pb.Transaction, 1));
};


/**
 * @param {!Array<!proto.iroha.protocol.Transaction>} value
 * @return {!proto.iroha.protocol.TransactionsResponse} returns this
*/
proto.iroha.protocol.TransactionsResponse.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.iroha.protocol.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.iroha.protocol.Transaction}
 */
proto.iroha.protocol.TransactionsResponse.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.iroha.protocol.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iroha.protocol.TransactionsResponse} returns this
 */
proto.iroha.protocol.TransactionsResponse.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iroha.protocol.TransactionsPageResponse.repeatedFields_ = [1];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.iroha.protocol.TransactionsPageResponse.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.iroha.protocol.TransactionsPageResponse.NextPageTagCase = {
  NEXT_PAGE_TAG_NOT_SET: 0,
  NEXT_TX_HASH: 3
};

/**
 * @return {proto.iroha.protocol.TransactionsPageResponse.NextPageTagCase}
 */
proto.iroha.protocol.TransactionsPageResponse.prototype.getNextPageTagCase = function() {
  return /** @type {proto.iroha.protocol.TransactionsPageResponse.NextPageTagCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.TransactionsPageResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.TransactionsPageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.TransactionsPageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.TransactionsPageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.TransactionsPageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    transaction_pb.Transaction.toObject, includeInstance),
    allTransactionsSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextTxHash: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.TransactionsPageResponse}
 */
proto.iroha.protocol.TransactionsPageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.TransactionsPageResponse;
  return proto.iroha.protocol.TransactionsPageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.TransactionsPageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.TransactionsPageResponse}
 */
proto.iroha.protocol.TransactionsPageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new transaction_pb.Transaction;
      reader.readMessage(value,transaction_pb.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAllTransactionsSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextTxHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.TransactionsPageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.TransactionsPageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.TransactionsPageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.TransactionsPageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      transaction_pb.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getAllTransactionsSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated Transaction transactions = 1;
 * @return {!Array<!proto.iroha.protocol.Transaction>}
 */
proto.iroha.protocol.TransactionsPageResponse.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.iroha.protocol.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, transaction_pb.Transaction, 1));
};


/**
 * @param {!Array<!proto.iroha.protocol.Transaction>} value
 * @return {!proto.iroha.protocol.TransactionsPageResponse} returns this
*/
proto.iroha.protocol.TransactionsPageResponse.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.iroha.protocol.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.iroha.protocol.Transaction}
 */
proto.iroha.protocol.TransactionsPageResponse.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.iroha.protocol.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iroha.protocol.TransactionsPageResponse} returns this
 */
proto.iroha.protocol.TransactionsPageResponse.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional uint32 all_transactions_size = 2;
 * @return {number}
 */
proto.iroha.protocol.TransactionsPageResponse.prototype.getAllTransactionsSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.TransactionsPageResponse} returns this
 */
proto.iroha.protocol.TransactionsPageResponse.prototype.setAllTransactionsSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string next_tx_hash = 3;
 * @return {string}
 */
proto.iroha.protocol.TransactionsPageResponse.prototype.getNextTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.TransactionsPageResponse} returns this
 */
proto.iroha.protocol.TransactionsPageResponse.prototype.setNextTxHash = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.iroha.protocol.TransactionsPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.TransactionsPageResponse} returns this
 */
proto.iroha.protocol.TransactionsPageResponse.prototype.clearNextTxHash = function() {
  return jspb.Message.setOneofField(this, 3, proto.iroha.protocol.TransactionsPageResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.TransactionsPageResponse.prototype.hasNextTxHash = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iroha.protocol.PendingTransactionsPageResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.PendingTransactionsPageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.PendingTransactionsPageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.PendingTransactionsPageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.PendingTransactionsPageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    transaction_pb.Transaction.toObject, includeInstance),
    allTransactionsSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextBatchInfo: (f = msg.getNextBatchInfo()) && proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.PendingTransactionsPageResponse}
 */
proto.iroha.protocol.PendingTransactionsPageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.PendingTransactionsPageResponse;
  return proto.iroha.protocol.PendingTransactionsPageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.PendingTransactionsPageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.PendingTransactionsPageResponse}
 */
proto.iroha.protocol.PendingTransactionsPageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new transaction_pb.Transaction;
      reader.readMessage(value,transaction_pb.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAllTransactionsSize(value);
      break;
    case 3:
      var value = new proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo;
      reader.readMessage(value,proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.deserializeBinaryFromReader);
      msg.setNextBatchInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.PendingTransactionsPageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.PendingTransactionsPageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.PendingTransactionsPageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.PendingTransactionsPageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      transaction_pb.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getAllTransactionsSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getNextBatchInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstTxHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
    batchSize: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo}
 */
proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo;
  return proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo}
 */
proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstTxHash(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBatchSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstTxHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBatchSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string first_tx_hash = 1;
 * @return {string}
 */
proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.prototype.getFirstTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo} returns this
 */
proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.prototype.setFirstTxHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 batch_size = 2;
 * @return {number}
 */
proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.prototype.getBatchSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo} returns this
 */
proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo.prototype.setBatchSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Transaction transactions = 1;
 * @return {!Array<!proto.iroha.protocol.Transaction>}
 */
proto.iroha.protocol.PendingTransactionsPageResponse.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.iroha.protocol.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, transaction_pb.Transaction, 1));
};


/**
 * @param {!Array<!proto.iroha.protocol.Transaction>} value
 * @return {!proto.iroha.protocol.PendingTransactionsPageResponse} returns this
*/
proto.iroha.protocol.PendingTransactionsPageResponse.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.iroha.protocol.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.iroha.protocol.Transaction}
 */
proto.iroha.protocol.PendingTransactionsPageResponse.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.iroha.protocol.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iroha.protocol.PendingTransactionsPageResponse} returns this
 */
proto.iroha.protocol.PendingTransactionsPageResponse.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional uint32 all_transactions_size = 2;
 * @return {number}
 */
proto.iroha.protocol.PendingTransactionsPageResponse.prototype.getAllTransactionsSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.PendingTransactionsPageResponse} returns this
 */
proto.iroha.protocol.PendingTransactionsPageResponse.prototype.setAllTransactionsSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional BatchInfo next_batch_info = 3;
 * @return {?proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo}
 */
proto.iroha.protocol.PendingTransactionsPageResponse.prototype.getNextBatchInfo = function() {
  return /** @type{?proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo, 3));
};


/**
 * @param {?proto.iroha.protocol.PendingTransactionsPageResponse.BatchInfo|undefined} value
 * @return {!proto.iroha.protocol.PendingTransactionsPageResponse} returns this
*/
proto.iroha.protocol.PendingTransactionsPageResponse.prototype.setNextBatchInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.PendingTransactionsPageResponse} returns this
 */
proto.iroha.protocol.PendingTransactionsPageResponse.prototype.clearNextBatchInfo = function() {
  return this.setNextBatchInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.PendingTransactionsPageResponse.prototype.hasNextBatchInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iroha.protocol.PeersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.PeersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.PeersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.PeersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.PeersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    peersList: jspb.Message.toObjectList(msg.getPeersList(),
    primitive_pb.Peer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.PeersResponse}
 */
proto.iroha.protocol.PeersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.PeersResponse;
  return proto.iroha.protocol.PeersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.PeersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.PeersResponse}
 */
proto.iroha.protocol.PeersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new primitive_pb.Peer;
      reader.readMessage(value,primitive_pb.Peer.deserializeBinaryFromReader);
      msg.addPeers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.PeersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.PeersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.PeersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.PeersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      primitive_pb.Peer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Peer peers = 1;
 * @return {!Array<!proto.iroha.protocol.Peer>}
 */
proto.iroha.protocol.PeersResponse.prototype.getPeersList = function() {
  return /** @type{!Array<!proto.iroha.protocol.Peer>} */ (
    jspb.Message.getRepeatedWrapperField(this, primitive_pb.Peer, 1));
};


/**
 * @param {!Array<!proto.iroha.protocol.Peer>} value
 * @return {!proto.iroha.protocol.PeersResponse} returns this
*/
proto.iroha.protocol.PeersResponse.prototype.setPeersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.iroha.protocol.Peer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.iroha.protocol.Peer}
 */
proto.iroha.protocol.PeersResponse.prototype.addPeers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.iroha.protocol.Peer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iroha.protocol.PeersResponse} returns this
 */
proto.iroha.protocol.PeersResponse.prototype.clearPeersList = function() {
  return this.setPeersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iroha.protocol.EngineReceiptsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.EngineReceiptsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.EngineReceiptsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.EngineReceiptsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.EngineReceiptsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    engineReceiptsList: jspb.Message.toObjectList(msg.getEngineReceiptsList(),
    primitive_pb.EngineReceipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.EngineReceiptsResponse}
 */
proto.iroha.protocol.EngineReceiptsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.EngineReceiptsResponse;
  return proto.iroha.protocol.EngineReceiptsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.EngineReceiptsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.EngineReceiptsResponse}
 */
proto.iroha.protocol.EngineReceiptsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new primitive_pb.EngineReceipt;
      reader.readMessage(value,primitive_pb.EngineReceipt.deserializeBinaryFromReader);
      msg.addEngineReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.EngineReceiptsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.EngineReceiptsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.EngineReceiptsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.EngineReceiptsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEngineReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      primitive_pb.EngineReceipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EngineReceipt engine_receipts = 1;
 * @return {!Array<!proto.iroha.protocol.EngineReceipt>}
 */
proto.iroha.protocol.EngineReceiptsResponse.prototype.getEngineReceiptsList = function() {
  return /** @type{!Array<!proto.iroha.protocol.EngineReceipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, primitive_pb.EngineReceipt, 1));
};


/**
 * @param {!Array<!proto.iroha.protocol.EngineReceipt>} value
 * @return {!proto.iroha.protocol.EngineReceiptsResponse} returns this
*/
proto.iroha.protocol.EngineReceiptsResponse.prototype.setEngineReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.iroha.protocol.EngineReceipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.iroha.protocol.EngineReceipt}
 */
proto.iroha.protocol.EngineReceiptsResponse.prototype.addEngineReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.iroha.protocol.EngineReceipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iroha.protocol.EngineReceiptsResponse} returns this
 */
proto.iroha.protocol.EngineReceiptsResponse.prototype.clearEngineReceiptsList = function() {
  return this.setEngineReceiptsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.iroha.protocol.QueryResponse.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,11,13,12,14,15]];

/**
 * @enum {number}
 */
proto.iroha.protocol.QueryResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  ACCOUNT_ASSETS_RESPONSE: 1,
  ACCOUNT_DETAIL_RESPONSE: 2,
  ACCOUNT_RESPONSE: 3,
  ERROR_RESPONSE: 4,
  SIGNATORIES_RESPONSE: 5,
  TRANSACTIONS_RESPONSE: 6,
  ASSET_RESPONSE: 7,
  ROLES_RESPONSE: 8,
  ROLE_PERMISSIONS_RESPONSE: 9,
  TRANSACTIONS_PAGE_RESPONSE: 11,
  PENDING_TRANSACTIONS_PAGE_RESPONSE: 13,
  BLOCK_RESPONSE: 12,
  PEERS_RESPONSE: 14,
  ENGINE_RECEIPTS_RESPONSE: 15
};

/**
 * @return {proto.iroha.protocol.QueryResponse.ResponseCase}
 */
proto.iroha.protocol.QueryResponse.prototype.getResponseCase = function() {
  return /** @type {proto.iroha.protocol.QueryResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.QueryResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.QueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.QueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.QueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.QueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountAssetsResponse: (f = msg.getAccountAssetsResponse()) && proto.iroha.protocol.AccountAssetResponse.toObject(includeInstance, f),
    accountDetailResponse: (f = msg.getAccountDetailResponse()) && proto.iroha.protocol.AccountDetailResponse.toObject(includeInstance, f),
    accountResponse: (f = msg.getAccountResponse()) && proto.iroha.protocol.AccountResponse.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.iroha.protocol.ErrorResponse.toObject(includeInstance, f),
    signatoriesResponse: (f = msg.getSignatoriesResponse()) && proto.iroha.protocol.SignatoriesResponse.toObject(includeInstance, f),
    transactionsResponse: (f = msg.getTransactionsResponse()) && proto.iroha.protocol.TransactionsResponse.toObject(includeInstance, f),
    assetResponse: (f = msg.getAssetResponse()) && proto.iroha.protocol.AssetResponse.toObject(includeInstance, f),
    rolesResponse: (f = msg.getRolesResponse()) && proto.iroha.protocol.RolesResponse.toObject(includeInstance, f),
    rolePermissionsResponse: (f = msg.getRolePermissionsResponse()) && proto.iroha.protocol.RolePermissionsResponse.toObject(includeInstance, f),
    transactionsPageResponse: (f = msg.getTransactionsPageResponse()) && proto.iroha.protocol.TransactionsPageResponse.toObject(includeInstance, f),
    pendingTransactionsPageResponse: (f = msg.getPendingTransactionsPageResponse()) && proto.iroha.protocol.PendingTransactionsPageResponse.toObject(includeInstance, f),
    blockResponse: (f = msg.getBlockResponse()) && proto.iroha.protocol.BlockResponse.toObject(includeInstance, f),
    peersResponse: (f = msg.getPeersResponse()) && proto.iroha.protocol.PeersResponse.toObject(includeInstance, f),
    engineReceiptsResponse: (f = msg.getEngineReceiptsResponse()) && proto.iroha.protocol.EngineReceiptsResponse.toObject(includeInstance, f),
    queryHash: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.QueryResponse}
 */
proto.iroha.protocol.QueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.QueryResponse;
  return proto.iroha.protocol.QueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.QueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.QueryResponse}
 */
proto.iroha.protocol.QueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.iroha.protocol.AccountAssetResponse;
      reader.readMessage(value,proto.iroha.protocol.AccountAssetResponse.deserializeBinaryFromReader);
      msg.setAccountAssetsResponse(value);
      break;
    case 2:
      var value = new proto.iroha.protocol.AccountDetailResponse;
      reader.readMessage(value,proto.iroha.protocol.AccountDetailResponse.deserializeBinaryFromReader);
      msg.setAccountDetailResponse(value);
      break;
    case 3:
      var value = new proto.iroha.protocol.AccountResponse;
      reader.readMessage(value,proto.iroha.protocol.AccountResponse.deserializeBinaryFromReader);
      msg.setAccountResponse(value);
      break;
    case 4:
      var value = new proto.iroha.protocol.ErrorResponse;
      reader.readMessage(value,proto.iroha.protocol.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    case 5:
      var value = new proto.iroha.protocol.SignatoriesResponse;
      reader.readMessage(value,proto.iroha.protocol.SignatoriesResponse.deserializeBinaryFromReader);
      msg.setSignatoriesResponse(value);
      break;
    case 6:
      var value = new proto.iroha.protocol.TransactionsResponse;
      reader.readMessage(value,proto.iroha.protocol.TransactionsResponse.deserializeBinaryFromReader);
      msg.setTransactionsResponse(value);
      break;
    case 7:
      var value = new proto.iroha.protocol.AssetResponse;
      reader.readMessage(value,proto.iroha.protocol.AssetResponse.deserializeBinaryFromReader);
      msg.setAssetResponse(value);
      break;
    case 8:
      var value = new proto.iroha.protocol.RolesResponse;
      reader.readMessage(value,proto.iroha.protocol.RolesResponse.deserializeBinaryFromReader);
      msg.setRolesResponse(value);
      break;
    case 9:
      var value = new proto.iroha.protocol.RolePermissionsResponse;
      reader.readMessage(value,proto.iroha.protocol.RolePermissionsResponse.deserializeBinaryFromReader);
      msg.setRolePermissionsResponse(value);
      break;
    case 11:
      var value = new proto.iroha.protocol.TransactionsPageResponse;
      reader.readMessage(value,proto.iroha.protocol.TransactionsPageResponse.deserializeBinaryFromReader);
      msg.setTransactionsPageResponse(value);
      break;
    case 13:
      var value = new proto.iroha.protocol.PendingTransactionsPageResponse;
      reader.readMessage(value,proto.iroha.protocol.PendingTransactionsPageResponse.deserializeBinaryFromReader);
      msg.setPendingTransactionsPageResponse(value);
      break;
    case 12:
      var value = new proto.iroha.protocol.BlockResponse;
      reader.readMessage(value,proto.iroha.protocol.BlockResponse.deserializeBinaryFromReader);
      msg.setBlockResponse(value);
      break;
    case 14:
      var value = new proto.iroha.protocol.PeersResponse;
      reader.readMessage(value,proto.iroha.protocol.PeersResponse.deserializeBinaryFromReader);
      msg.setPeersResponse(value);
      break;
    case 15:
      var value = new proto.iroha.protocol.EngineReceiptsResponse;
      reader.readMessage(value,proto.iroha.protocol.EngineReceiptsResponse.deserializeBinaryFromReader);
      msg.setEngineReceiptsResponse(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.QueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.QueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.QueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.QueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountAssetsResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.iroha.protocol.AccountAssetResponse.serializeBinaryToWriter
    );
  }
  f = message.getAccountDetailResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.iroha.protocol.AccountDetailResponse.serializeBinaryToWriter
    );
  }
  f = message.getAccountResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.iroha.protocol.AccountResponse.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.iroha.protocol.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getSignatoriesResponse();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.iroha.protocol.SignatoriesResponse.serializeBinaryToWriter
    );
  }
  f = message.getTransactionsResponse();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.iroha.protocol.TransactionsResponse.serializeBinaryToWriter
    );
  }
  f = message.getAssetResponse();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.iroha.protocol.AssetResponse.serializeBinaryToWriter
    );
  }
  f = message.getRolesResponse();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.iroha.protocol.RolesResponse.serializeBinaryToWriter
    );
  }
  f = message.getRolePermissionsResponse();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.iroha.protocol.RolePermissionsResponse.serializeBinaryToWriter
    );
  }
  f = message.getTransactionsPageResponse();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.iroha.protocol.TransactionsPageResponse.serializeBinaryToWriter
    );
  }
  f = message.getPendingTransactionsPageResponse();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.iroha.protocol.PendingTransactionsPageResponse.serializeBinaryToWriter
    );
  }
  f = message.getBlockResponse();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.iroha.protocol.BlockResponse.serializeBinaryToWriter
    );
  }
  f = message.getPeersResponse();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.iroha.protocol.PeersResponse.serializeBinaryToWriter
    );
  }
  f = message.getEngineReceiptsResponse();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.iroha.protocol.EngineReceiptsResponse.serializeBinaryToWriter
    );
  }
  f = message.getQueryHash();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional AccountAssetResponse account_assets_response = 1;
 * @return {?proto.iroha.protocol.AccountAssetResponse}
 */
proto.iroha.protocol.QueryResponse.prototype.getAccountAssetsResponse = function() {
  return /** @type{?proto.iroha.protocol.AccountAssetResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.AccountAssetResponse, 1));
};


/**
 * @param {?proto.iroha.protocol.AccountAssetResponse|undefined} value
 * @return {!proto.iroha.protocol.QueryResponse} returns this
*/
proto.iroha.protocol.QueryResponse.prototype.setAccountAssetsResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.iroha.protocol.QueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.QueryResponse} returns this
 */
proto.iroha.protocol.QueryResponse.prototype.clearAccountAssetsResponse = function() {
  return this.setAccountAssetsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.QueryResponse.prototype.hasAccountAssetsResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AccountDetailResponse account_detail_response = 2;
 * @return {?proto.iroha.protocol.AccountDetailResponse}
 */
proto.iroha.protocol.QueryResponse.prototype.getAccountDetailResponse = function() {
  return /** @type{?proto.iroha.protocol.AccountDetailResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.AccountDetailResponse, 2));
};


/**
 * @param {?proto.iroha.protocol.AccountDetailResponse|undefined} value
 * @return {!proto.iroha.protocol.QueryResponse} returns this
*/
proto.iroha.protocol.QueryResponse.prototype.setAccountDetailResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.iroha.protocol.QueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.QueryResponse} returns this
 */
proto.iroha.protocol.QueryResponse.prototype.clearAccountDetailResponse = function() {
  return this.setAccountDetailResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.QueryResponse.prototype.hasAccountDetailResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AccountResponse account_response = 3;
 * @return {?proto.iroha.protocol.AccountResponse}
 */
proto.iroha.protocol.QueryResponse.prototype.getAccountResponse = function() {
  return /** @type{?proto.iroha.protocol.AccountResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.AccountResponse, 3));
};


/**
 * @param {?proto.iroha.protocol.AccountResponse|undefined} value
 * @return {!proto.iroha.protocol.QueryResponse} returns this
*/
proto.iroha.protocol.QueryResponse.prototype.setAccountResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.iroha.protocol.QueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.QueryResponse} returns this
 */
proto.iroha.protocol.QueryResponse.prototype.clearAccountResponse = function() {
  return this.setAccountResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.QueryResponse.prototype.hasAccountResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse error_response = 4;
 * @return {?proto.iroha.protocol.ErrorResponse}
 */
proto.iroha.protocol.QueryResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.iroha.protocol.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.ErrorResponse, 4));
};


/**
 * @param {?proto.iroha.protocol.ErrorResponse|undefined} value
 * @return {!proto.iroha.protocol.QueryResponse} returns this
*/
proto.iroha.protocol.QueryResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.iroha.protocol.QueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.QueryResponse} returns this
 */
proto.iroha.protocol.QueryResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.QueryResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SignatoriesResponse signatories_response = 5;
 * @return {?proto.iroha.protocol.SignatoriesResponse}
 */
proto.iroha.protocol.QueryResponse.prototype.getSignatoriesResponse = function() {
  return /** @type{?proto.iroha.protocol.SignatoriesResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.SignatoriesResponse, 5));
};


/**
 * @param {?proto.iroha.protocol.SignatoriesResponse|undefined} value
 * @return {!proto.iroha.protocol.QueryResponse} returns this
*/
proto.iroha.protocol.QueryResponse.prototype.setSignatoriesResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.iroha.protocol.QueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.QueryResponse} returns this
 */
proto.iroha.protocol.QueryResponse.prototype.clearSignatoriesResponse = function() {
  return this.setSignatoriesResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.QueryResponse.prototype.hasSignatoriesResponse = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional TransactionsResponse transactions_response = 6;
 * @return {?proto.iroha.protocol.TransactionsResponse}
 */
proto.iroha.protocol.QueryResponse.prototype.getTransactionsResponse = function() {
  return /** @type{?proto.iroha.protocol.TransactionsResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.TransactionsResponse, 6));
};


/**
 * @param {?proto.iroha.protocol.TransactionsResponse|undefined} value
 * @return {!proto.iroha.protocol.QueryResponse} returns this
*/
proto.iroha.protocol.QueryResponse.prototype.setTransactionsResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.iroha.protocol.QueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.QueryResponse} returns this
 */
proto.iroha.protocol.QueryResponse.prototype.clearTransactionsResponse = function() {
  return this.setTransactionsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.QueryResponse.prototype.hasTransactionsResponse = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AssetResponse asset_response = 7;
 * @return {?proto.iroha.protocol.AssetResponse}
 */
proto.iroha.protocol.QueryResponse.prototype.getAssetResponse = function() {
  return /** @type{?proto.iroha.protocol.AssetResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.AssetResponse, 7));
};


/**
 * @param {?proto.iroha.protocol.AssetResponse|undefined} value
 * @return {!proto.iroha.protocol.QueryResponse} returns this
*/
proto.iroha.protocol.QueryResponse.prototype.setAssetResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.iroha.protocol.QueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.QueryResponse} returns this
 */
proto.iroha.protocol.QueryResponse.prototype.clearAssetResponse = function() {
  return this.setAssetResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.QueryResponse.prototype.hasAssetResponse = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional RolesResponse roles_response = 8;
 * @return {?proto.iroha.protocol.RolesResponse}
 */
proto.iroha.protocol.QueryResponse.prototype.getRolesResponse = function() {
  return /** @type{?proto.iroha.protocol.RolesResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.RolesResponse, 8));
};


/**
 * @param {?proto.iroha.protocol.RolesResponse|undefined} value
 * @return {!proto.iroha.protocol.QueryResponse} returns this
*/
proto.iroha.protocol.QueryResponse.prototype.setRolesResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.iroha.protocol.QueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.QueryResponse} returns this
 */
proto.iroha.protocol.QueryResponse.prototype.clearRolesResponse = function() {
  return this.setRolesResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.QueryResponse.prototype.hasRolesResponse = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional RolePermissionsResponse role_permissions_response = 9;
 * @return {?proto.iroha.protocol.RolePermissionsResponse}
 */
proto.iroha.protocol.QueryResponse.prototype.getRolePermissionsResponse = function() {
  return /** @type{?proto.iroha.protocol.RolePermissionsResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.RolePermissionsResponse, 9));
};


/**
 * @param {?proto.iroha.protocol.RolePermissionsResponse|undefined} value
 * @return {!proto.iroha.protocol.QueryResponse} returns this
*/
proto.iroha.protocol.QueryResponse.prototype.setRolePermissionsResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.iroha.protocol.QueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.QueryResponse} returns this
 */
proto.iroha.protocol.QueryResponse.prototype.clearRolePermissionsResponse = function() {
  return this.setRolePermissionsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.QueryResponse.prototype.hasRolePermissionsResponse = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional TransactionsPageResponse transactions_page_response = 11;
 * @return {?proto.iroha.protocol.TransactionsPageResponse}
 */
proto.iroha.protocol.QueryResponse.prototype.getTransactionsPageResponse = function() {
  return /** @type{?proto.iroha.protocol.TransactionsPageResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.TransactionsPageResponse, 11));
};


/**
 * @param {?proto.iroha.protocol.TransactionsPageResponse|undefined} value
 * @return {!proto.iroha.protocol.QueryResponse} returns this
*/
proto.iroha.protocol.QueryResponse.prototype.setTransactionsPageResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.iroha.protocol.QueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.QueryResponse} returns this
 */
proto.iroha.protocol.QueryResponse.prototype.clearTransactionsPageResponse = function() {
  return this.setTransactionsPageResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.QueryResponse.prototype.hasTransactionsPageResponse = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PendingTransactionsPageResponse pending_transactions_page_response = 13;
 * @return {?proto.iroha.protocol.PendingTransactionsPageResponse}
 */
proto.iroha.protocol.QueryResponse.prototype.getPendingTransactionsPageResponse = function() {
  return /** @type{?proto.iroha.protocol.PendingTransactionsPageResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.PendingTransactionsPageResponse, 13));
};


/**
 * @param {?proto.iroha.protocol.PendingTransactionsPageResponse|undefined} value
 * @return {!proto.iroha.protocol.QueryResponse} returns this
*/
proto.iroha.protocol.QueryResponse.prototype.setPendingTransactionsPageResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.iroha.protocol.QueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.QueryResponse} returns this
 */
proto.iroha.protocol.QueryResponse.prototype.clearPendingTransactionsPageResponse = function() {
  return this.setPendingTransactionsPageResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.QueryResponse.prototype.hasPendingTransactionsPageResponse = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional BlockResponse block_response = 12;
 * @return {?proto.iroha.protocol.BlockResponse}
 */
proto.iroha.protocol.QueryResponse.prototype.getBlockResponse = function() {
  return /** @type{?proto.iroha.protocol.BlockResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.BlockResponse, 12));
};


/**
 * @param {?proto.iroha.protocol.BlockResponse|undefined} value
 * @return {!proto.iroha.protocol.QueryResponse} returns this
*/
proto.iroha.protocol.QueryResponse.prototype.setBlockResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.iroha.protocol.QueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.QueryResponse} returns this
 */
proto.iroha.protocol.QueryResponse.prototype.clearBlockResponse = function() {
  return this.setBlockResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.QueryResponse.prototype.hasBlockResponse = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PeersResponse peers_response = 14;
 * @return {?proto.iroha.protocol.PeersResponse}
 */
proto.iroha.protocol.QueryResponse.prototype.getPeersResponse = function() {
  return /** @type{?proto.iroha.protocol.PeersResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.PeersResponse, 14));
};


/**
 * @param {?proto.iroha.protocol.PeersResponse|undefined} value
 * @return {!proto.iroha.protocol.QueryResponse} returns this
*/
proto.iroha.protocol.QueryResponse.prototype.setPeersResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.iroha.protocol.QueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.QueryResponse} returns this
 */
proto.iroha.protocol.QueryResponse.prototype.clearPeersResponse = function() {
  return this.setPeersResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.QueryResponse.prototype.hasPeersResponse = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional EngineReceiptsResponse engine_receipts_response = 15;
 * @return {?proto.iroha.protocol.EngineReceiptsResponse}
 */
proto.iroha.protocol.QueryResponse.prototype.getEngineReceiptsResponse = function() {
  return /** @type{?proto.iroha.protocol.EngineReceiptsResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.EngineReceiptsResponse, 15));
};


/**
 * @param {?proto.iroha.protocol.EngineReceiptsResponse|undefined} value
 * @return {!proto.iroha.protocol.QueryResponse} returns this
*/
proto.iroha.protocol.QueryResponse.prototype.setEngineReceiptsResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.iroha.protocol.QueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.QueryResponse} returns this
 */
proto.iroha.protocol.QueryResponse.prototype.clearEngineReceiptsResponse = function() {
  return this.setEngineReceiptsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.QueryResponse.prototype.hasEngineReceiptsResponse = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string query_hash = 10;
 * @return {string}
 */
proto.iroha.protocol.QueryResponse.prototype.getQueryHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.QueryResponse} returns this
 */
proto.iroha.protocol.QueryResponse.prototype.setQueryHash = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.BlockResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.BlockResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.BlockResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.BlockResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    block: (f = msg.getBlock()) && block_pb.Block.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.BlockResponse}
 */
proto.iroha.protocol.BlockResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.BlockResponse;
  return proto.iroha.protocol.BlockResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.BlockResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.BlockResponse}
 */
proto.iroha.protocol.BlockResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new block_pb.Block;
      reader.readMessage(value,block_pb.Block.deserializeBinaryFromReader);
      msg.setBlock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.BlockResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.BlockResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.BlockResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.BlockResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlock();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      block_pb.Block.serializeBinaryToWriter
    );
  }
};


/**
 * optional Block block = 1;
 * @return {?proto.iroha.protocol.Block}
 */
proto.iroha.protocol.BlockResponse.prototype.getBlock = function() {
  return /** @type{?proto.iroha.protocol.Block} */ (
    jspb.Message.getWrapperField(this, block_pb.Block, 1));
};


/**
 * @param {?proto.iroha.protocol.Block|undefined} value
 * @return {!proto.iroha.protocol.BlockResponse} returns this
*/
proto.iroha.protocol.BlockResponse.prototype.setBlock = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.BlockResponse} returns this
 */
proto.iroha.protocol.BlockResponse.prototype.clearBlock = function() {
  return this.setBlock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.BlockResponse.prototype.hasBlock = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.BlockErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.BlockErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.BlockErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.BlockErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.BlockErrorResponse}
 */
proto.iroha.protocol.BlockErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.BlockErrorResponse;
  return proto.iroha.protocol.BlockErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.BlockErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.BlockErrorResponse}
 */
proto.iroha.protocol.BlockErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.BlockErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.BlockErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.BlockErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.BlockErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.iroha.protocol.BlockErrorResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iroha.protocol.BlockErrorResponse} returns this
 */
proto.iroha.protocol.BlockErrorResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.iroha.protocol.BlockQueryResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.iroha.protocol.BlockQueryResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  BLOCK_RESPONSE: 1,
  BLOCK_ERROR_RESPONSE: 2
};

/**
 * @return {proto.iroha.protocol.BlockQueryResponse.ResponseCase}
 */
proto.iroha.protocol.BlockQueryResponse.prototype.getResponseCase = function() {
  return /** @type {proto.iroha.protocol.BlockQueryResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.BlockQueryResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.BlockQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.BlockQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.BlockQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.BlockQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockResponse: (f = msg.getBlockResponse()) && proto.iroha.protocol.BlockResponse.toObject(includeInstance, f),
    blockErrorResponse: (f = msg.getBlockErrorResponse()) && proto.iroha.protocol.BlockErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.BlockQueryResponse}
 */
proto.iroha.protocol.BlockQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.BlockQueryResponse;
  return proto.iroha.protocol.BlockQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.BlockQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.BlockQueryResponse}
 */
proto.iroha.protocol.BlockQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.iroha.protocol.BlockResponse;
      reader.readMessage(value,proto.iroha.protocol.BlockResponse.deserializeBinaryFromReader);
      msg.setBlockResponse(value);
      break;
    case 2:
      var value = new proto.iroha.protocol.BlockErrorResponse;
      reader.readMessage(value,proto.iroha.protocol.BlockErrorResponse.deserializeBinaryFromReader);
      msg.setBlockErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.BlockQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.BlockQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.BlockQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.BlockQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.iroha.protocol.BlockResponse.serializeBinaryToWriter
    );
  }
  f = message.getBlockErrorResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.iroha.protocol.BlockErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional BlockResponse block_response = 1;
 * @return {?proto.iroha.protocol.BlockResponse}
 */
proto.iroha.protocol.BlockQueryResponse.prototype.getBlockResponse = function() {
  return /** @type{?proto.iroha.protocol.BlockResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.BlockResponse, 1));
};


/**
 * @param {?proto.iroha.protocol.BlockResponse|undefined} value
 * @return {!proto.iroha.protocol.BlockQueryResponse} returns this
*/
proto.iroha.protocol.BlockQueryResponse.prototype.setBlockResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.iroha.protocol.BlockQueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.BlockQueryResponse} returns this
 */
proto.iroha.protocol.BlockQueryResponse.prototype.clearBlockResponse = function() {
  return this.setBlockResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.BlockQueryResponse.prototype.hasBlockResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BlockErrorResponse block_error_response = 2;
 * @return {?proto.iroha.protocol.BlockErrorResponse}
 */
proto.iroha.protocol.BlockQueryResponse.prototype.getBlockErrorResponse = function() {
  return /** @type{?proto.iroha.protocol.BlockErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.iroha.protocol.BlockErrorResponse, 2));
};


/**
 * @param {?proto.iroha.protocol.BlockErrorResponse|undefined} value
 * @return {!proto.iroha.protocol.BlockQueryResponse} returns this
*/
proto.iroha.protocol.BlockQueryResponse.prototype.setBlockErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.iroha.protocol.BlockQueryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iroha.protocol.BlockQueryResponse} returns this
 */
proto.iroha.protocol.BlockQueryResponse.prototype.clearBlockErrorResponse = function() {
  return this.setBlockErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.BlockQueryResponse.prototype.hasBlockErrorResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.iroha.protocol.HealthcheckData.oneofGroups_ = [[1],[2],[3],[4],[5]];

/**
 * @enum {number}
 */
proto.iroha.protocol.HealthcheckData.OptMemoryConsumptionCase = {
  OPT_MEMORY_CONSUMPTION_NOT_SET: 0,
  MEMORY_CONSUMPTION: 1
};

/**
 * @return {proto.iroha.protocol.HealthcheckData.OptMemoryConsumptionCase}
 */
proto.iroha.protocol.HealthcheckData.prototype.getOptMemoryConsumptionCase = function() {
  return /** @type {proto.iroha.protocol.HealthcheckData.OptMemoryConsumptionCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.HealthcheckData.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.iroha.protocol.HealthcheckData.OptIsHealthyCase = {
  OPT_IS_HEALTHY_NOT_SET: 0,
  IS_HEALTHY: 2
};

/**
 * @return {proto.iroha.protocol.HealthcheckData.OptIsHealthyCase}
 */
proto.iroha.protocol.HealthcheckData.prototype.getOptIsHealthyCase = function() {
  return /** @type {proto.iroha.protocol.HealthcheckData.OptIsHealthyCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.HealthcheckData.oneofGroups_[1]));
};

/**
 * @enum {number}
 */
proto.iroha.protocol.HealthcheckData.OptIsSyncingCase = {
  OPT_IS_SYNCING_NOT_SET: 0,
  IS_SYNCING: 3
};

/**
 * @return {proto.iroha.protocol.HealthcheckData.OptIsSyncingCase}
 */
proto.iroha.protocol.HealthcheckData.prototype.getOptIsSyncingCase = function() {
  return /** @type {proto.iroha.protocol.HealthcheckData.OptIsSyncingCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.HealthcheckData.oneofGroups_[2]));
};

/**
 * @enum {number}
 */
proto.iroha.protocol.HealthcheckData.OptLastBlockHeightCase = {
  OPT_LAST_BLOCK_HEIGHT_NOT_SET: 0,
  LAST_BLOCK_HEIGHT: 4
};

/**
 * @return {proto.iroha.protocol.HealthcheckData.OptLastBlockHeightCase}
 */
proto.iroha.protocol.HealthcheckData.prototype.getOptLastBlockHeightCase = function() {
  return /** @type {proto.iroha.protocol.HealthcheckData.OptLastBlockHeightCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.HealthcheckData.oneofGroups_[3]));
};

/**
 * @enum {number}
 */
proto.iroha.protocol.HealthcheckData.OptLastBlockRejectCase = {
  OPT_LAST_BLOCK_REJECT_NOT_SET: 0,
  LAST_BLOCK_REJECT: 5
};

/**
 * @return {proto.iroha.protocol.HealthcheckData.OptLastBlockRejectCase}
 */
proto.iroha.protocol.HealthcheckData.prototype.getOptLastBlockRejectCase = function() {
  return /** @type {proto.iroha.protocol.HealthcheckData.OptLastBlockRejectCase} */(jspb.Message.computeOneofCase(this, proto.iroha.protocol.HealthcheckData.oneofGroups_[4]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iroha.protocol.HealthcheckData.prototype.toObject = function(opt_includeInstance) {
  return proto.iroha.protocol.HealthcheckData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iroha.protocol.HealthcheckData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.HealthcheckData.toObject = function(includeInstance, msg) {
  var f, obj = {
    memoryConsumption: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isHealthy: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isSyncing: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    lastBlockHeight: jspb.Message.getFieldWithDefault(msg, 4, 0),
    lastBlockReject: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iroha.protocol.HealthcheckData}
 */
proto.iroha.protocol.HealthcheckData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iroha.protocol.HealthcheckData;
  return proto.iroha.protocol.HealthcheckData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iroha.protocol.HealthcheckData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iroha.protocol.HealthcheckData}
 */
proto.iroha.protocol.HealthcheckData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMemoryConsumption(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHealthy(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSyncing(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastBlockHeight(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastBlockReject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iroha.protocol.HealthcheckData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iroha.protocol.HealthcheckData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iroha.protocol.HealthcheckData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iroha.protocol.HealthcheckData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional uint64 memory_consumption = 1;
 * @return {number}
 */
proto.iroha.protocol.HealthcheckData.prototype.getMemoryConsumption = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.HealthcheckData} returns this
 */
proto.iroha.protocol.HealthcheckData.prototype.setMemoryConsumption = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.iroha.protocol.HealthcheckData.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.HealthcheckData} returns this
 */
proto.iroha.protocol.HealthcheckData.prototype.clearMemoryConsumption = function() {
  return jspb.Message.setOneofField(this, 1, proto.iroha.protocol.HealthcheckData.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.HealthcheckData.prototype.hasMemoryConsumption = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_healthy = 2;
 * @return {boolean}
 */
proto.iroha.protocol.HealthcheckData.prototype.getIsHealthy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.iroha.protocol.HealthcheckData} returns this
 */
proto.iroha.protocol.HealthcheckData.prototype.setIsHealthy = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.iroha.protocol.HealthcheckData.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.HealthcheckData} returns this
 */
proto.iroha.protocol.HealthcheckData.prototype.clearIsHealthy = function() {
  return jspb.Message.setOneofField(this, 2, proto.iroha.protocol.HealthcheckData.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.HealthcheckData.prototype.hasIsHealthy = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_syncing = 3;
 * @return {boolean}
 */
proto.iroha.protocol.HealthcheckData.prototype.getIsSyncing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.iroha.protocol.HealthcheckData} returns this
 */
proto.iroha.protocol.HealthcheckData.prototype.setIsSyncing = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.iroha.protocol.HealthcheckData.oneofGroups_[2], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.HealthcheckData} returns this
 */
proto.iroha.protocol.HealthcheckData.prototype.clearIsSyncing = function() {
  return jspb.Message.setOneofField(this, 3, proto.iroha.protocol.HealthcheckData.oneofGroups_[2], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.HealthcheckData.prototype.hasIsSyncing = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 last_block_height = 4;
 * @return {number}
 */
proto.iroha.protocol.HealthcheckData.prototype.getLastBlockHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.HealthcheckData} returns this
 */
proto.iroha.protocol.HealthcheckData.prototype.setLastBlockHeight = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.iroha.protocol.HealthcheckData.oneofGroups_[3], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.HealthcheckData} returns this
 */
proto.iroha.protocol.HealthcheckData.prototype.clearLastBlockHeight = function() {
  return jspb.Message.setOneofField(this, 4, proto.iroha.protocol.HealthcheckData.oneofGroups_[3], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.HealthcheckData.prototype.hasLastBlockHeight = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 last_block_reject = 5;
 * @return {number}
 */
proto.iroha.protocol.HealthcheckData.prototype.getLastBlockReject = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.iroha.protocol.HealthcheckData} returns this
 */
proto.iroha.protocol.HealthcheckData.prototype.setLastBlockReject = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.iroha.protocol.HealthcheckData.oneofGroups_[4], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.iroha.protocol.HealthcheckData} returns this
 */
proto.iroha.protocol.HealthcheckData.prototype.clearLastBlockReject = function() {
  return jspb.Message.setOneofField(this, 5, proto.iroha.protocol.HealthcheckData.oneofGroups_[4], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iroha.protocol.HealthcheckData.prototype.hasLastBlockReject = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto.iroha.protocol);
