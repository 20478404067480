import { FeaturesService } from '@services';

import { Feature } from '@enums';

/**
 * Init service.
 *
 * @author Ihar Kazlouski
 * @class InitServiceClass
 * @category Services
 */
class InitServiceClass {
  /**
   * Is app init.
   *
   * @property {boolean} isInit is app init.
   * @private
   */
  private isInit = false;

  /**
   * Web worker.
   *
   * @property {Worker} worker web worker instance.
   * @private
   */
  public worker: Worker;

  /**
   * Set service workers.
   *
   * @author Ihar Kazlouski
   * @private
   * @static
   * @return {Promise<ServiceWorkerRegistration | undefined>} set service workers promise.
   */
  private static async setServiceWorkers (): Promise<
  ServiceWorkerRegistration | undefined
  > {
    if (
      'serviceWorker' in navigator &&
      FeaturesService.available(Feature.ServiceWorker)
    ) {
      return await navigator.serviceWorker.register('/service.worker.js');
    }
  }

  /**
   * Init method.
   *
   * @author Ihar Kazlouski
   * @return {Promise<void>} app init promise.
   */
  async init (): Promise<void> {
    if (!this.isInit) {
      await InitServiceClass.setServiceWorkers();
      const worker = new Worker(new URL('../../workers/web.worker.ts', import.meta.url));

      this.worker = worker;

      this.isInit = true;
    } else {
      throw new Error();
    }
  }
}

const InitService = new InitServiceClass();

export { InitService };
