import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Container } from '@mui/material';

import { PublicLayoutProps } from '@hocs/layouts/public-layout/public-layout.types';

import { StyledMain, StyledPublicWrapper } from './public-layout.styles';

/**
 * Page hoc component.
 *
 * @author Ihar Kazlouski
 * @function PublicLayout
 * @category Hocs
 * @return {JSX.Element} wrapped component.
 */
const PublicLayout: FC<PublicLayoutProps> = ({ inline }): JSX.Element => {
  return (
    <StyledPublicWrapper>
      <StyledMain inline={inline} component='main'>
        {inline ? (
          <Outlet />
        ) : (
          <Container fixed>
            <Outlet />
          </Container>
        )}
      </StyledMain>
    </StyledPublicWrapper>
  );
};

export { PublicLayout };
