import { AxiosResponse } from 'axios';

import { dispatch as storeDispatch } from '@store/index';
import { actions, AddNewAddressRequestPayload } from '@store/wallet';

import { FeaturesService } from '@services/features';

import { Feature } from '@enums';
import {
  AddBitcoinWalletRequest,
  AddBitcoinWalletResponse,
  AddressOutputs,
  ApiError,
  AsyncDispatch,
  BitcoinAddress,
  BitcoinResponseAddresses,
  BitcoinWallets,
} from '@types';

import { ApiService } from '../api';

/**
 * Wallet service.
 *
 * @author Ihar Kazlouski
 * @class WalletServiceClass
 * @category Services
 */
class WalletServiceClass {
  /**
   * Get bitcoin wallets api.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<BitcoinWallets[]>>} bitcoin wallets list promise.
   */
  async apiWalletsGet (): Promise<ApiError | AxiosResponse<BitcoinWallets[]>> {
    return ApiService.apiGet(
      '/btc/user-wallet',
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Get bitcoin wallet info api.
   *
   * @author Ihar Kazlouski
   * @param {string} id id.
   * @return {Promise<ApiError | AxiosResponse<BitcoinWallets>>} bitcoin wallet info promise.
   */
  async apiWalletGet (
    id: string,
  ): Promise<ApiError | AxiosResponse<BitcoinWallets>> {
    return ApiService.apiGet(
      `/btc/user-wallet/${id}`,
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Delete bitcoin wallet api.
   *
   * @author Ihar Kazlouski
   * @param {string} id id.
   * @return {Promise<ApiError | AxiosResponse<BitcoinWallets>>} bitcoin wallet delete promise.
   */
  async apiWalletDelete (
    id: string,
  ): Promise<ApiError | AxiosResponse<BitcoinWallets>> {
    return ApiService.apiDelete(
      '/btc/user-wallet',
      id,
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Delete wallet.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<BitcoinWallets>>} bitcoin wallet delete promise.
   */
  async deleteWallet (id: string): Promise<ApiError | BitcoinWallets> {
    const dispatch: AsyncDispatch<BitcoinWallets | ApiError> = storeDispatch;

    return dispatch(actions.deleteWalletRequest({ id }));
  }

  /**
   * Check is bitcoin wallet name exists api.
   *
   * @author Ihar Kazlouski
   * @param {string} name wallet name.
   * @return {Promise<ApiError | AxiosResponse<boolean>>} is bitcoin wallet exist promise.
   */
  async apiWalletNameCheck (
    name: string,
  ): Promise<ApiError | AxiosResponse<boolean>> {
    return ApiService.apiGet(`/btc/user-wallet/${name}/is-exists`);
  }

  /**
   * Check is bitcoin wallet name exists.
   *
   * @author Ihar Kazlouski
   * @param {string} name wallet name.
   * @return {Promise<ApiError | AxiosResponse<boolean>>} is bitcoin wallet exist promise.
   */
  async walletNameCheck (
    name: string,
  ): Promise<ApiError | { isWalletExist: boolean }> {
    const dispatch: AsyncDispatch<{ isWalletExist: boolean } | ApiError> =
      storeDispatch;

    return dispatch(actions.checkWalletNameRequest({ name }));
  }

  /**
   * Add new wallet api.
   *
   * @author Ihar Kazlouski
   * @param {BitcoinDbWallet} walletData wallet data.
   * @return {Promise<ApiError | AxiosResponse<boolean>>} add new wallet promise.
   */
  async apiAddWalletPost (
    walletData: AddBitcoinWalletRequest,
  ): Promise<ApiError | AxiosResponse<AddBitcoinWalletResponse>> {
    return ApiService.apiPost(
      '/btc/user-wallet',
      { ...walletData },
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Add new bitcoin wallet.
   *
   * @author Ihar Kazlouski
   * @param {BitcoinDbWallet} walletData wallet data.
   * @return {Promise<ApiError | AxiosResponse<AddBitcoinWalletResponse>>} add new bitcoin wallet promise.
   */
  async addNewWallet ({
    name,
    addresses,
    type,
  }: {
    name: string;
    addresses: BitcoinAddress[];
    type: number;
  }): Promise<ApiError | AddBitcoinWalletResponse> {
    const dispatch: AsyncDispatch<AddBitcoinWalletResponse | ApiError> =
      storeDispatch;
    const newAddresses = addresses?.map((address) => {
      return { address: address.address, type: address.type };
    });

    return dispatch(
      actions.addNewWalletRequest({ name, addresses: newAddresses, type }),
    );
  }

  /**
   * Delete bitcoin wallet address api.
   *
   * @author Ihar Kazlouski
   * @param {string} id id.
   * @return {Promise<ApiError | AxiosResponse<BitcoinResponseAddresses>>} bitcoin wallet address delete promise.
   */
  async apiWalletAddressDelete (
    id: string,
  ): Promise<ApiError | AxiosResponse<BitcoinResponseAddresses>> {
    return ApiService.apiDelete(
      '/btc/user-wallet/address',
      id,
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Delete bitcoin wallet address.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<BitcoinResponseAddresses>>} delete bitcoin wallet address promise.
   */
  async deleteWalletAddress (
    id: string,
  ): Promise<ApiError | BitcoinResponseAddresses> {
    const dispatch: AsyncDispatch<BitcoinResponseAddresses | ApiError> =
      storeDispatch;

    return dispatch(actions.deleteWalletAddressRequest({ id }));
  }

  /**
   * Get address outputs api.
   *
   * @author Ihar Kazlouski
   * @param {string} address address.
   * @return {Promise<ApiError | AxiosResponse<AddressOutputs>>} address outputs promise.
   */
  async apiAddressOutputsGet (
    address: string,
  ): Promise<ApiError | AxiosResponse<AddressOutputs>> {
    return ApiService.apiGet(
      `/btc/user-wallet/${address}`,
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Get address outputs.
   *
   * @author Ihar Kazlouski
   * @param {string} address address.
   * @return {Promise<ApiError | AxiosResponse<AddressOutputs>>} address outputs promise.
   */
  async getAddressOutputs (address: string): Promise<ApiError | AddressOutputs> {
    const dispatch: AsyncDispatch<AddressOutputs | ApiError> = storeDispatch;

    return dispatch(actions.getAddressOutputsRequest({ address }));
  }

  /**
   * Refresh bitcoin wallet api.
   *
   * @author Ihar Kazlouski
   * @param {string} id id.
   * @return {Promise<ApiError | AxiosResponse<BitcoinWallets>>} bitcoin wallet promise.
   */
  async apiRefreshWalletGet (
    id: string,
  ): Promise<ApiError | AxiosResponse<BitcoinWallets>> {
    return ApiService.apiGet(
      `/btc/user-wallet/${id}/refresh`,
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Refresh bitcoin wallet.
   *
   * @author Ihar Kazlouski
   * @param {string} id id.
   * @return {Promise<ApiError | AxiosResponse<BitcoinWallets>>} bitcoin wallet promise.
   */
  async refreshWallet (id: string): Promise<ApiError | BitcoinWallets> {
    const dispatch: AsyncDispatch<BitcoinWallets | ApiError> = storeDispatch;

    return dispatch(actions.refreshWalletRequest({ id }));
  }

  /**
   * Add new address to the wallet api.
   *
   * @author Ihar Kazlouski
   * @param {string} newAddress new wallet address.
   * @param {string} id id.
   * @return {Promise<ApiError | AxiosResponse<BitcoinResponseAddresses>>} add address promise.
   */
  async apiAddAddressPost (
    payload: AddNewAddressRequestPayload,
  ): Promise<ApiError | AxiosResponse<BitcoinResponseAddresses>> {
    return ApiService.apiPost(
      `/btc/user-wallet/${payload.id}/address`,
      { ...payload.address },
      FeaturesService.available(Feature.PayloadEncryption),
    );
  }

  /**
   * Add new address to the wallet.
   *
   * @author Ihar Kazlouski
   * @param {string} id id.
   * @return {Promise<ApiError | AxiosResponse<BitcoinResponseAddresses>>} add new address to the wallet promise.
   */
  async addNewAddress (
    payload: AddNewAddressRequestPayload,
  ): Promise<ApiError | BitcoinResponseAddresses> {
    const dispatch: AsyncDispatch<BitcoinResponseAddresses | ApiError> =
      storeDispatch;

    return dispatch(actions.addNewAddressRequest(payload));
  }
}

const WalletService = new WalletServiceClass();

export { WalletService };
