// eslint-disable-next-line import/no-named-as-default
import { DefaultResources, initReactI18next } from 'react-i18next';

// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';

import en from './en.localization.json';
import ru from './ru.localization.json';

const getTranslateConfig = (): DefaultResources => ({
  resources: {
    en,
    ru,
  },
  lng:         'en',
  fallbackLng: 'en',
  debug:       false,
});

// eslint-disable-next-line import/no-named-as-default-member
void i18n.use(initReactI18next).init(getTranslateConfig());

export { i18n };
