import { AxiosResponse } from 'axios';
import BigInteger from 'big-integer';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import SimpleLamport from 'simple-lamport';

import { GetLamportKeysRequestPayload, SendLamportPubKeyRequestPayload } from '@store/lamport';

import { LamportService } from '@services';
import { ConstantsUtil, toBigEndian } from '@utils';

import { ApiError, LamportServerKeys, PayloadAction } from '@types';

import { GET_LAMPORT_KEYS_REQUEST, SEND_LAMPORT_PUB_KEY_REQUEST } from './lamport.action-types';
import { actions } from './lamport.actions';

/**
 * Send Lamport public key saga worker.
 *
 * @author Ihar Kazlouski
 * @function sendLamportPubKeySagaWorker
 * @category Sagas
 * @subcategory Lamport
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<Record<string, unknown>>>} data.
 */
function* sendLamportPubKeySagaWorker (
  action: PayloadAction<SendLamportPubKeyRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<Record<string, unknown>>
  > {
  try {
    const { data } = yield call(
      LamportService.apiSendLamportPubKeyPost,
      action.payload,
    );

    yield put(
      actions.sendLamportPubKeySuccess(),
    );

    yield put({
      type:   `${SEND_LAMPORT_PUB_KEY_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      wallet: data,
    });
  } catch (error) {
    yield put(
      actions.sendLamportPubKeyFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${SEND_LAMPORT_PUB_KEY_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Send Lamport public key saga watcher.
 *
 * @author Ihar Kazlouski
 * @function sendLamportPubKeySagaWatcher
 * @category Sagas
 * @subcategory Lamport
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* sendLamportPubKeySagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([
    takeLatest(SEND_LAMPORT_PUB_KEY_REQUEST, sendLamportPubKeySagaWorker),
  ]);
}

/**
 * Get lamport keys saga worker.
 *
 * @author Ihar Kazlouski
 * @function getLamportKeysSagaWorker
 * @category Sagas
 * @subcategory Lamport
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<Record<string, unknown>>>} data.
 */
function* getLamportKeysSagaWorker (
  action: PayloadAction<GetLamportKeysRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<LamportServerKeys>
  > {
  try {
    const { data } = yield call(LamportService.apiLamportKeysPost, action.payload.clientNumber);
    const privateKey = data.privateKey.map(x => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return x.map(y => Buffer.from(toBigEndian(BigInteger(y))).toString('base64'));
    });
    const lamport = new SimpleLamport();

    const publicKey = privateKey.map(privateKeyPart => {
      return privateKeyPart.map((encodedString: string) =>
        lamport.hash(encodedString, 'base64') as string,
      );
    });

    const transformedData = {
      privateKey: lamport.encodeKey(privateKey) as string,
      publicKey:  lamport.encodeKey(publicKey) as string,
    };

    yield put(
      actions.getLamportKeysSuccess({
        lamportKeys: transformedData,
      }),
    );

    yield put({
      type:    `${GET_LAMPORT_KEYS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: transformedData,
    });
  } catch (error) {
    yield put(
      actions.sendLamportPubKeyFailure({
        error: error as ApiError[],
      }),
    );

    yield put({
      type:  `${GET_LAMPORT_KEYS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: error as ApiError[],
    });
  }
}

/**
 * Get lamport keys saga watcher.
 *
 * @author Ihar Kazlouski
 * @function getLamportKeysSagaWatcher
 * @category Sagas
 * @subcategory Lamport
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* getLamportKeysSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([
    takeLatest(GET_LAMPORT_KEYS_REQUEST, getLamportKeysSagaWorker),
  ]);
}

/**
 * Lamport saga.
 *
 * @author Ihar Kazlouski
 * @function lamportSaga
 * @category Sagas
 * @subcategory Lamport
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* lamportSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(sendLamportPubKeySagaWatcher),
    fork(getLamportKeysSagaWatcher),
  ]);
}
