import { Buffer } from 'buffer/';
import * as Crypto from 'crypto';

import { KeyEntity } from '@utils/encryption';

import { PhoneNumberResponse } from '@types';

const algorithm = 'aes-256-cbc';
const PBKDF_ITERATIONS_NUMBER = 20000;
const PBKDF_KEY_LENGTH = 32;
const PBKDF_ALGO_DIGEST = 'sha512';

/**
 * Encrypt data util.
 *
 * @author Ihar Kazlouski
 * @param {string} secret secret.
 * @param {string} key key.
 * @param {string} salt salt.
 * @return {string} encrypted value.
 */
const cryptoSimpleEncryption = (secret: string, key: string, salt: string): string => {
  const aesKey = Crypto.pbkdf2Sync(
    key,
    salt,
    PBKDF_ITERATIONS_NUMBER,
    PBKDF_KEY_LENGTH,
    PBKDF_ALGO_DIGEST,
  );
  const cipher = Crypto.createCipheriv(algorithm, aesKey, aesKey.subarray(0, 16));
  const encrypted = Buffer.concat([cipher.update(secret), cipher.final()]);

  return encrypted.toString('hex');
};

/**
 * Decrypt data util.
 *
 * @author Ihar Kazlouski
 * @param {string} encryptedData encrypted data.
 * @param {string} key key.
 * @param {string} salt salt.
 * @return {string} decrypted value.
 */
const cryptoSimpleDecryption = (encryptedData: string, key: string, salt: string): string => {
  const aesKey = Crypto.pbkdf2Sync(
    key,
    salt,
    PBKDF_ITERATIONS_NUMBER,
    PBKDF_KEY_LENGTH,
    PBKDF_ALGO_DIGEST,
  );

  const decipher = Crypto.createDecipheriv(algorithm, aesKey, aesKey.subarray(0, 16));
  const decryptedData = Buffer.concat([decipher.update(Buffer.from(encryptedData, 'hex')), decipher.final()]);

  return decryptedData.toString();
};

const encryptPinOnSms = (attempt: PhoneNumberResponse, smsCode: string, pin: string): string => {
  const iv = Buffer.from(attempt.initialVector, 'base64');
  const salt = Buffer.from(attempt.salt, 'base64');

  const aesKey = Crypto.pbkdf2Sync(
    smsCode,
    salt,
    KeyEntity.PBKDF_ITERATIONS_NUMBER,
    KeyEntity.PBKDF_KEY_LENGTH,
    KeyEntity.PBKDF_ALGO_DIGEST,
  );

  const cipher = Crypto.createCipheriv(KeyEntity.CIPHER_ALGORITHM,
    aesKey,
    iv).setAutoPadding(true);

  const encryptedData = Buffer.concat(
    [cipher.update(pin),
      cipher.final()],
  );

  return encryptedData.toString('base64');
};

export { cryptoSimpleDecryption, cryptoSimpleEncryption, encryptPinOnSms };
