import { produce } from 'immer';

import {
  API_GRID_FAILURE,
  API_GRID_SUCCESS,
  CLOSE_ALL_GRIDS,
  CLOSE_GRID,
  GRID_INIT,
  UPDATE_GRID,
} from './grids.action-types';
import { ActionsTypes, GridState } from './grids.types';

const initialState: GridState[] = [];

/**
 * Grids reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Grids
 * @param {GridState} state state.
 * @param {ActionsTypes} action action.
 * @return {GridState[]} modals state.
 */
const gridsReducer = (
  state = initialState,
  action: ActionsTypes,
): GridState[] => {
  switch (action.type) {
    case GRID_INIT:
    case UPDATE_GRID:
    case API_GRID_SUCCESS:
    case API_GRID_FAILURE: {
      return produce(state, (draft) => {
        const idx = state.findIndex(
          (grid) => grid.gridId === action.payload.gridId,
        );
        if (idx >= 0) {
          draft[idx] = {
            gridId:    action.payload.gridId,
            items:     action.payload.items,
            isError:   action.payload.isError,
            isLoading: action.payload.isLoading,
          };
        } else {
          draft.push({
            gridId:    action.payload.gridId,
            items:     action.payload.items,
            isError:   action.payload.isError,
            isLoading: action.payload.isLoading,
          });
        }
      });
    }

    case CLOSE_GRID: {
      return produce(state, (draft) => {
        const idx = state.findIndex(
          (grid) => grid.gridId === action.payload.gridId,
        );

        draft.splice(idx, 1);
      });
    }

    case CLOSE_ALL_GRIDS: {
      return [];
    }

    default: {
      return state;
    }
  }
};

export default gridsReducer;
