import { Box, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { pxToRem } from '@themes';

const AuthPageWrapper = styled(Box)<{ theme?: DefaultTheme }>(({ theme }) => ({
  margin:         '0 auto',
  display:        'flex',
  justifyContent: 'center',

  width: pxToRem(400),

  [theme.breakpoints.down('sm')]: {
    width: pxToRem(288),
  },
}));

export { AuthPageWrapper };
