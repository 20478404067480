import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import { ModalsService } from '@services';
import { useDeviceSize } from '@hooks';

import { Modals, Routes } from '@enums';

import { Modal } from '@elements';

/**
 * Download keys notification component.
 *
 * @author Ihar Kazlouski
 * @function DownloadKeysNotification
 * @category Modals
 * @return {FC} download keys notification component.
 */
const DownloadKeysNotification: FC = () => {
  const deviceSize = useDeviceSize();
  const navigate = useNavigate();
  const { t } = useTranslation();

  /**
   * Download keys from keys service.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const downloadKeys = (): void => {
    ModalsService.close(Modals.DownloadKeysNotification);
    navigate(Routes.SignUp);
  };

  return (
    <Modal maxWidth='sm' fullWidth>
      <Modal.Title>&nbsp;</Modal.Title>
      <Modal.Content>
        <Typography textAlign='center'>
          {t('modals.downloadKeysNotification.description')}
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Box
          pt={4}
          pb={deviceSize.tablet || deviceSize.mobile ? 0 : 2}
          display='flex'
          justifyContent='space-around'
        >
          <Button variant='contained' onClick={downloadKeys}>
            {t('modals.downloadKeysNotification.next')}
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};

export { DownloadKeysNotification };
