import { ListItem, styled } from '@mui/material';

import { pxToRem } from '@themes';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display:      'flex',
  padding:      `0 ${pxToRem(16)}`,
  marginBottom: pxToRem(8),
}));

export { StyledListItem };
