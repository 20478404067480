import crc from 'crc';
import * as Crypto from 'crypto';

const IS_DEBUG = false;

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
const Buffer = require('buffer/').Buffer;

export class KeyEntity {
  static readonly ENCRYPTED_KEY_LENGTH = 96;
  static readonly SALT_OFFSET = 0;
  static readonly SALT_LENGTH = 32;
  static readonly KEY_OFFSET = 32;
  static readonly KEY_LENGTH = 32;
  static readonly COUNTER_OFFSET = 64;
  static readonly COUNTER_LENGTH = 16;
  static readonly CRC_LENGTH = 4;
  static readonly PASSWORD_SALT_LENGTH = 8;
  static readonly CIPHER_ALGORITHM = 'aes-256-cbc';
  static readonly PBKDF_ITERATIONS_NUMBER = 20000;
  static readonly PBKDF_KEY_LENGTH = 32;
  static readonly PBKDF_ALGO_DIGEST = 'sha512';
  private encryptedData: Buffer;
  private decryptedData: Buffer;
  private decryptedKey: Buffer;
  private decryptedCounter: Buffer;
  private readonly aesKey: Buffer;
  private readonly salt: Buffer;
  private counter = BigInt(0);

  constructor (
    private rawKeyEntity: Buffer,
    private initialVector: Buffer,
    password: string,
  ) {
    if (this.rawKeyEntity.byteLength !== KeyEntity.ENCRYPTED_KEY_LENGTH) {
      throw new ContainerFormatError();
    }

    this.salt = this.rawKeyEntity.slice(KeyEntity.SALT_OFFSET,
      KeyEntity.SALT_OFFSET + KeyEntity.SALT_LENGTH);

    if (IS_DEBUG) console.log(`PDKF2 salt: ${this.salt.toString('hex')}`);

    this.encryptedData = this.rawKeyEntity.slice(
      KeyEntity.KEY_OFFSET, KeyEntity.ENCRYPTED_KEY_LENGTH);

    if (IS_DEBUG) console.log(`Encrypted data: ${this.encryptedData.toString('hex')}`);

    this.aesKey = Crypto.pbkdf2Sync(
      password,
      this.salt,
      KeyEntity.PBKDF_ITERATIONS_NUMBER,
      KeyEntity.PBKDF_KEY_LENGTH,
      KeyEntity.PBKDF_ALGO_DIGEST,
    );

    if (IS_DEBUG) console.log(`AES key from PDKF2 algo: ${this.aesKey.toString('hex')}`);
  }

  public decrypt (): void {
    const decipher = Crypto.createDecipheriv(
      KeyEntity.CIPHER_ALGORITHM,
      this.aesKey,
      this.initialVector,
    ).setAutoPadding(true);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    this.decryptedData = Buffer.concat([
      decipher.update(this.encryptedData),
      decipher.final(),
    ]);

    if (IS_DEBUG) console.log(`Decrypted unpadded data: ${this.decryptedData.toString('hex')}`);

    this.decryptedKey = this.decryptedData.slice(0, KeyEntity.KEY_LENGTH);
    if (IS_DEBUG) console.log(`Decrypted key: ${this.decryptedKey.toString('hex')}`);
    this.decryptedCounter = this.decryptedData.slice(
      KeyEntity.KEY_LENGTH,
      KeyEntity.KEY_LENGTH + KeyEntity.COUNTER_LENGTH,
    );

    this.counter = this.decryptedCounter.readBigUInt64BE();

    if (IS_DEBUG) console.log(`decryptedCounter ${this.decryptedCounter.toString('hex')}`);

    const decryptedCrc = this.decryptedData.slice(
      KeyEntity.KEY_LENGTH + KeyEntity.COUNTER_LENGTH,
      KeyEntity.KEY_LENGTH + KeyEntity.COUNTER_LENGTH + KeyEntity.CRC_LENGTH,
    );

    if (IS_DEBUG) console.log(`Decrypted CRC: ${decryptedCrc.readUInt32BE()}`);
    const calculatedCrc = crc.crc32(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      Buffer.concat([
        this.decryptedKey,
        this.decryptedCounter,
      ]),
    );
    if (IS_DEBUG) console.log(`calculated CRC: ${calculatedCrc}`);

    if (decryptedCrc.readUInt32BE() !== calculatedCrc) {
      throw new IncorrectContainerPassword();
    }
  }

  encrypt (password: string): void {
    const aesKey = Crypto.pbkdf2Sync(
      password,
      this.salt,
      KeyEntity.PBKDF_ITERATIONS_NUMBER,
      KeyEntity.PBKDF_KEY_LENGTH,
      KeyEntity.PBKDF_ALGO_DIGEST,
    );
    const cipher = Crypto.createCipheriv(KeyEntity.CIPHER_ALGORITHM,
      aesKey,
      this.initialVector).setAutoPadding(true);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    const counterBuffer = Buffer.alloc(16);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    counterBuffer.writeBigUInt64BE(this.counter);

    const crc32 = crc.crc32(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      Buffer.concat([
        this.decryptedKey,
        counterBuffer,
      ]),
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const crc32Buffer = Buffer.alloc(4);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    crc32Buffer.writeUInt32BE(crc32);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    const encryptionBuffer = Buffer.concat(
      [
        this.decryptedKey,
        counterBuffer,
        crc32Buffer,
      ],
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    this.encryptedData = Buffer.concat(
      [cipher.update(encryptionBuffer),
        cipher.final()],
    );
  }

  public increaseCounter (add: number): void {
    this.counter += BigInt(add);
  }

  public getSalt (): Buffer {
    return this.salt;
  }

  public getDecryptedKey (): Buffer {
    return this.decryptedKey;
  }

  public getEncryptedKeyData (): Buffer {
    return this.encryptedData;
  }
}

export class QKeyReader {
  static readonly INITIAL_VECTOR_OFFSET = 0;
  static readonly INITIAL_VECTOR_LENGTH = 16;
  private initialVector: Buffer;
  private encryptedKeyList: Array<KeyEntity> = [];
  private containerData = '';
  private containerName = '';
  private containerBuffer: Buffer;
  private keyCount = 0;
  public clientNumber: string;
  public keyNumber: string;

  constructor (private containerJsonString: string, private password: string, private isReEncrypted?: boolean) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedJson = JSON.parse(this.containerJsonString);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    this.containerData = parsedJson.quantum_key_container;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    this.containerName = parsedJson.name;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    this.clientNumber = parsedJson.client;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    this.keyNumber = parsedJson.number;

    this.parseContainerData();
  }

  getClientNumber (): string {
    return this.clientNumber;
  }

  getKeyNumber (): string {
    return this.keyNumber;
  }

  private parseContainerData (): void {
    const separatedContainerData = this.containerData.split('\n');

    if (separatedContainerData.length !== 3) {
      throw new ContainerFormatError();
    }

    const base64ContainerContent = separatedContainerData[1];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    this.containerBuffer = Buffer.from(base64ContainerContent, 'base64');

    if (IS_DEBUG) console.log(`Container binary buffer ${this.containerBuffer.toString('hex')}`);

    this.initialVector = this.containerBuffer.slice(QKeyReader.INITIAL_VECTOR_OFFSET,
      QKeyReader.INITIAL_VECTOR_OFFSET + QKeyReader.INITIAL_VECTOR_LENGTH);

    if (IS_DEBUG) console.log(`Initial vector: ${this.initialVector.toString('hex')}`);

    if (
      (this.containerBuffer.byteLength - this.initialVector.byteLength) % KeyEntity.ENCRYPTED_KEY_LENGTH !== 0
    ) {
      throw new ContainerFormatError();
    }

    this.keyCount = (this.containerBuffer.byteLength - this.initialVector.byteLength) /
        KeyEntity.ENCRYPTED_KEY_LENGTH;

    if (this.keyCount <= 0) {
      throw new ContainerFormatError();
    }

    for (let index = 0; index < this.keyCount; index++) {
      if (IS_DEBUG) {
        console.log(`Key group #${index + 1}: ${this.containerBuffer.slice(
          QKeyReader.INITIAL_VECTOR_OFFSET + QKeyReader.INITIAL_VECTOR_LENGTH +
          index * KeyEntity.ENCRYPTED_KEY_LENGTH,
          QKeyReader.INITIAL_VECTOR_OFFSET + QKeyReader.INITIAL_VECTOR_LENGTH +
          (index + 1) * KeyEntity.ENCRYPTED_KEY_LENGTH,
      ).toString('hex')}`);
      }

      const encryptedKey = new KeyEntity(
        this.containerBuffer.slice(
          QKeyReader.INITIAL_VECTOR_OFFSET + QKeyReader.INITIAL_VECTOR_LENGTH +
              index * KeyEntity.ENCRYPTED_KEY_LENGTH,
          QKeyReader.INITIAL_VECTOR_OFFSET + QKeyReader.INITIAL_VECTOR_LENGTH +
              (index + 1) * KeyEntity.ENCRYPTED_KEY_LENGTH,
        ), this.initialVector, this.password,
      );

      encryptedKey.decrypt();

      this.encryptedKeyList.push(encryptedKey);
    }
  }

  public getEncryptedKeyList (): KeyEntity[] {
    return this.encryptedKeyList;
  }

  public encryptContainer (password: string): string {
    let encryptedContainer = this.initialVector;

    for (let index = 0; index < this.keyCount; index++) {
      this.encryptedKeyList[index].encrypt(password);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      encryptedContainer = Buffer.concat([
        encryptedContainer,
        this.encryptedKeyList[index].getSalt(),
        this.encryptedKeyList[index].getEncryptedKeyData(),
      ]);
    }
    const containerLines = this.containerData.split('\n');

    this.containerData =
        `${containerLines[0]}
${encryptedContainer.toString('base64')}
${containerLines[2]}`;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedJson = JSON.parse(this.containerJsonString);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    parsedJson.quantum_key_container = this.containerData;

    this.containerJsonString = JSON.stringify(parsedJson);
    return this.containerJsonString;
  }
}

export class ClientKeyEntity {
  static readonly CONTAINER_KEY_LENGTH = 80;
  static readonly SALT_OFFSET = 0;
  static readonly SALT_LENGTH = 32;
  static readonly KEY_OFFSET = 32;
  static readonly KEY_LENGTH = 32;
  static readonly COUNTER_OFFSET = 64;
  static readonly COUNTER_LENGTH = 16;

  private readonly key: Buffer;
  private readonly salt: Buffer;
  private counter: Buffer;

  constructor (private readonly clientKey: Buffer) {
    this.salt = clientKey.slice(ClientKeyEntity.SALT_OFFSET, ClientKeyEntity.SALT_OFFSET + ClientKeyEntity.SALT_LENGTH);
    this.key = clientKey.slice(ClientKeyEntity.KEY_OFFSET, ClientKeyEntity.KEY_OFFSET + ClientKeyEntity.KEY_LENGTH);
    this.counter = clientKey.slice(ClientKeyEntity.COUNTER_OFFSET, ClientKeyEntity.COUNTER_OFFSET + ClientKeyEntity.COUNTER_LENGTH);
  }

  getKey (): Buffer {
    return this.key;
  }

  getSalt (): Buffer {
    return this.salt;
  }

  getCounter (): Buffer {
    return this.counter;
  }
}

export class QClientToClientKeyReader {
  static readonly INITIAL_VECTOR_OFFSET = 0;
  static readonly INITIAL_VECTOR_LENGTH = 16;
  private initialVector: Buffer;
  private keyList: Array<ClientKeyEntity> = [];
  private containerBuffer: Buffer;
  private keyCount = 0;

  constructor (private container: string) {
    this.parseContainerData();
  }

  private parseContainerData (): void {
    const separatedContainerData = this.container.split('\n');
    if (separatedContainerData.length !== 3) {
      throw new ContainerFormatError();
    }

    const base64ContainerContent = separatedContainerData[1];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    this.containerBuffer = Buffer.from(base64ContainerContent, 'base64');

    this.initialVector = this.containerBuffer.slice(QKeyReader.INITIAL_VECTOR_OFFSET,
      QKeyReader.INITIAL_VECTOR_OFFSET + QKeyReader.INITIAL_VECTOR_LENGTH);

    if (
      (this.containerBuffer.byteLength - this.initialVector.byteLength) % ClientKeyEntity.CONTAINER_KEY_LENGTH !== 0
    ) {
      throw new ContainerFormatError();
    }

    this.keyCount = (this.containerBuffer.byteLength - this.initialVector.byteLength) /
        ClientKeyEntity.CONTAINER_KEY_LENGTH;

    if (this.keyCount <= 0) {
      throw new ContainerFormatError();
    }

    for (let index = 0; index < this.keyCount; index++) {
      this.keyList.push(new ClientKeyEntity(
        this.containerBuffer.slice(
          QKeyReader.INITIAL_VECTOR_OFFSET + QKeyReader.INITIAL_VECTOR_LENGTH +
              index * ClientKeyEntity.CONTAINER_KEY_LENGTH,
          QKeyReader.INITIAL_VECTOR_OFFSET + QKeyReader.INITIAL_VECTOR_LENGTH +
              (index + 1) * ClientKeyEntity.CONTAINER_KEY_LENGTH,
        )),
      );
    }
  }

  public getKeyList (): ClientKeyEntity[] {
    return this.keyList;
  }
}

export class ContainerFormatError extends Error {
}

export class IncorrectContainerPassword extends Error {
}
