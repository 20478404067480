import { setIn, ValidationErrors } from 'final-form';
import { BaseSchema } from 'yup';

/**
 * yup schema validator.
 *
 * @author Ihar Kazlouski
 * @function validator
 * @category helper
 * @param {BaseSchema} schema validation form.
 * @return {ValidationErrors | Promise<ValidationErrors>) | undefined} validation errors.
 */
function validator (
  schema: BaseSchema,
):
  | ((
    values: Record<string, unknown>,
  ) => ValidationErrors | Promise<ValidationErrors>)
  | undefined {
  return async (values): Promise<ValidationErrors> => {
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (e) {
      const catchError = e as { inner: { path: string; message: unknown }[] };

      // eslint-disable-next-line @typescript-eslint/ban-types
      return catchError.inner.reduce((errors, error): object => {
        return setIn(errors, error.path, error.message);
      }, {});
    }
  };
}

export { validator };
