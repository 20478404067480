import * as bitcoin from 'bitcoinjs-lib';

import { i18n } from '@localization';

/**
 * Constants util.
 *
 * @author Ihar Kazlouski
 * @const constantsUtil
 * @category Utils
 */
const ConstantsUtil = {
  info:     {},
  warnings: {},
  errors:   {
    PhoneNumberIsEmpty:                       i18n.t('modals.error.incorrectPhoneNumber'),
    PhoneNumberIsInvalid:                     i18n.t('modals.error.incorrectPhoneNumber'),
    EmailIsInvalid:                           i18n.t('modals.error.invalidEmail'),
    UserWithLoginOrEmailExists:               i18n.t('modals.error.loginExists'),
    RegistrationLinkIsExpired:                i18n.t('modals.error.linkExpired'),
    UserWithPhoneAlreadyRegistered:           i18n.t('modals.error.phoneIsAlreadyExists'),
    ActivationEmailAlreadySent:               i18n.t('modals.error.activationEmailAlreadySent'),
    UserNotFound:                             i18n.t('modals.error.userNotFound'),
    UserNotActivated:                         i18n.t('modals.error.userNotActivated'),
    WrongContainerPassword:                   i18n.t('modals.error.wrongPassword'),
    CannotCreateQcAccount:                    i18n.t('modals.error.failedIrohaAccountCreation'),
    UnableToLoadKeyContainer:                 i18n.t('modals.error.unableToLoadContainer'),
    LamportSignatureInvalid:                  i18n.t('modals.error.lamportSignatureInvalid'),
    QcToIrohaTransactionRejected:             i18n.t('modals.error.rejectedQcTransaction'),
    QcToIrohaTransactionFailed:               i18n.t('modals.error.rejectedQcTransaction'),
    BtcToIrohaTransactionRejected:            i18n.t('modals.error.unfinishedQcTransaction'),
    BtcToIrohaTransactionFailed:              i18n.t('modals.error.unfinishedQcTransaction'),
    SubtractAssetQuantityTransactionRejected: i18n.t('modals.error.unfinishedQcTransaction'),
    SubtractAssetQuantityTransactionFailed:   i18n.t('modals.error.unfinishedQcTransaction'),
    NoPermissionToTransferIrohaAssetAccount:  i18n.t('modals.error.rejectedQcTransaction'),
    NotEnoughIrohaAssetBalanceAccount:        i18n.t('modals.error.rejectedQcTransaction'),
    ResultIrohaAssetBalanceTooMuch:           i18n.t('modals.error.rejectedQcTransaction'),
    IrohaTransferDescriptionTooLong:          i18n.t('modals.error.rejectedQcTransaction'),
    CannotTransferIrohaAsset:                 i18n.t('modals.error.rejectedQcTransaction'),
    InvalidIrohaAccount:                      i18n.t('modals.error.invalidIrohaAccount'),
    QkeyServerError:                          i18n.t('modals.error.wrongPassword'),
  },
  actions: {
    ASYNC_SUCCESS: 'ASYNC_SUCCESS',
    ASYNC_FAILED:  'ASYNC_FAILED',
  },
  localStorage: {
    keysContainer:       'keysContainer',
    unFinishedContainer: 'unfinishedContainer',
    salt:                'salt',
    cipher:              'cipher',
    cookie:              'cookie',
    encryptedSeed:       'encryptedSeed',
    currentUser:         'currentUser',
    irohaKey:            'irohaKey',
  },
  params: {
    PREV_PAGE: 'prevPage',
  },
  network: process.env.server === 'master' ? bitcoin.networks.bitcoin : bitcoin.networks.testnet,
  headers: {
    applicationJson:        'application/json',
    applicationOctetStream: 'application/octet-stream',
  },
  iroha: {
    toQcAsset:     'btc#qtc',
    fromQcAsset:   'qtc#btc',
    qcAsset:       'qtc#qtc',
    destAccountId: 'bank@qtc',
  },
  transactionStatuses: {
    BtcToQcExchange:     'BtcToQcExchange',
    QcWithdraw:          'QcWithdraw',
    QcTransfer:          'QcTransfer',
    QcTransferRecipient: 'QcTransferRecipient',
  },
};

export { ConstantsUtil };
